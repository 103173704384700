import TreeDisplayEnum from "utils/constants/enums/TreeDisplayEnum";
import { SolutionsFilterEnum } from "utils/constants/enums/SolutionsFilterEnum";

import { getVariablesValuesFromQuestionnaire } from "utils/helpers/questionnaireHelper";
import {
  getSolutionFilterValue,
  suffleOrderOfSolutions,
} from "utils/helpers/solutionsHelper";

/**
 * The function that prepares the data of Solutions by the tree, questionnaire id and sector Id
 *
 * @param {Object} tree the repertoire of system tree that holds all the data of branches
 * @param {Object} solutions the repertoire that holds all the data of solution
 * @param {string} idQuestionnaire the id generated when we finish the questions (ie branch id in old version)
 * @param {string} sectorId the id of sector answer chosen by the user
 * @returns {Object} holds all the solutions by their display modes
 */
export const getSolutionsByBranchQuestionnaireIdFromTree = (
  tree,
  solutions,
  idQuestionnaire,
  algoliaSectorId
) => {
  const allSolutionsIds = {
    Principal: [],
    Secondary: [],
    Public: [],
    Event: [],
  };
  if (tree.data?.length <= 0) return allSolutionsIds;
  const branchId = tree.mapQuestionnaireIdBranch[idQuestionnaire];
  const sectorId = tree.mapSectorsIdAlgolia[algoliaSectorId];
  if (!branchId) return allSolutionsIds;
  tree.data?.[branchId]?.displayConfig?.rules?.forEach((rule) => {
    // if rule has a sectorsId we must filter by the answered sector by the user
    if (rule.sectorsId && rule.sectorsId.includes(sectorId)) {
      allSolutionsIds[rule.displayMode].push(rule.solutionId);
    } else if (!rule.sectorsId) {
      allSolutionsIds[rule.displayMode].push(rule.solutionId);
    }
  });
  // Filtering the undefined solutions in case of solutions repertoire doesn't contains the wanted solution that we identify by id
  return {
    formations: allSolutionsIds.Principal.map((id) => solutions[id]).filter(
      (s) => s && !["Draft", "Archived", "Inactive"].includes(s.status)
    ),
    suggestions: allSolutionsIds.Secondary.map((id) => solutions[id]).filter(
      (s) => s && !["Draft", "Archived", "Inactive"].includes(s.status)
    ),
    publicServices: allSolutionsIds.Public.map((id) => solutions[id]).filter(
      (s) => s && !["Draft", "Archived", "Inactive"].includes(s.status)
    ),
    events: allSolutionsIds.Event.map((id) => solutions[id]).filter(
      (s) => s && !["Draft", "Archived", "Inactive"].includes(s.status)
    ),
  };
};

/**
 * Getting all solutions defined in solutionsData and also in sectorsData and filling the solutions with the right data
 *
 * @param {object} questionAnswerMap a map of questions/answers
 * @param {arrayOfObject} sector [{ aid: string }, ...] an array that contain the sector selected in form
 * @param {string} branchId id of the branch
 * @returns {object} {formations : array, suggestions: array, publicServices: array} the final result of hydration
 */
export function hydrateAllSolutionsResults(
  treeSystem,
  solutions,
  questionAnswerMap,
  sector,
  branchId
) {
  const hydratedSolutions = getSolutionsByBranchQuestionnaireIdFromTree(
    treeSystem,
    solutions,
    branchId,
    sector[0]?.aid
  );

  const sectorId = treeSystem.mapSectorsIdAlgolia[sector[0]?.aid];

  const sectorInfo = treeSystem.refSectors?.[sectorId];

  hydratedSolutions.formations = fillSolutionsInfoFromQuestionnaire(
    fillSolutionsInfoFromSectorsReferential(
      sectorInfo,
      hydratedSolutions.formations
    ),
    questionAnswerMap
  );

  hydratedSolutions.suggestions = fillSolutionsInfoFromQuestionnaire(
    fillSolutionsInfoFromSectorsReferential(
      sectorInfo,
      hydratedSolutions.suggestions
    ),
    questionAnswerMap
  );

  hydratedSolutions.publicServices = fillSolutionsInfoFromQuestionnaire(
    fillSolutionsInfoFromSectorsReferential(
      sectorInfo,
      hydratedSolutions.publicServices
    ),
    questionAnswerMap
  );

  hydratedSolutions.events = fillSolutionsInfoFromQuestionnaire(
    fillSolutionsInfoFromSectorsReferential(
      sectorInfo,
      hydratedSolutions.events
    ),
    questionAnswerMap
  );

  suffleOrderOfSolutions(hydratedSolutions, TreeDisplayEnum.formations);

  suffleOrderOfSolutions(hydratedSolutions, TreeDisplayEnum.suggestions);

  return hydratedSolutions;
}

/**
 * Fill the solutions Info variables from the questionnaire
 *
 * @param {array} solutionsResults a list of solutions (formations or suggestions)
 * @param {object} questionAnswerMap a map of questions/answers
 * @returns {array} a list of an updated solutions
 */
function fillSolutionsInfoFromQuestionnaire(
  solutionsResults,
  questionAnswerMap
) {
  return solutionsResults.map((solution) => {
    return {
      ...solution,
      title: getVariablesValuesFromQuestionnaire(
        solution.title,
        questionAnswerMap
      ),
      name: getVariablesValuesFromQuestionnaire(
        solution.name,
        questionAnswerMap
      ),
      intro: getVariablesValuesFromQuestionnaire(
        solution.intro,
        questionAnswerMap
      ),
      link: getVariablesValuesFromQuestionnaire(
        solution.link,
        questionAnswerMap,
        true
      ),
    };
  });
}

/**
 * Fill the solutions Info variables from the sectors referential
 *
 * @param {array} solutionsResults a list of solutions (formations or suggestions)
 * @returns
 */
function fillSolutionsInfoFromSectorsReferential(sectorInfo, solutionsResults) {
  return solutionsResults.map((solution) => {
    if (solution.link?.includes(SolutionsFilterEnum.SEARCHABLE)) {
      return {
        ...solution,
        link: sectorInfo?.[
          `link::${getSolutionFilterValue(
            solution.filter,
            SolutionsFilterEnum.SEARCHABLE
          )}`
        ],
      };
    } else if (solution.link?.includes("link::")) {
      return {
        ...solution,
        link: sectorInfo?.[solution.link],
      };
    }
    return solution;
  });
}

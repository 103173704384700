const MuiContainer = {
  styleOverrides: {
    maxWidthXs: {
      maxWidth: 240,
    },
    maxWidthSm: {
      maxWidth: 400,
    },
    maxWidthMd: {
      maxWidth: 720,
    },
    maxWidthLg: {
      maxWidth: 960,
    },
    maxWidthxl: {
      maxWidth: 1204,
    },
  },
};

export default MuiContainer;

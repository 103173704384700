import { TrainingProgressFactory } from "domain/interview/interviewModels";
import { LastProgressNumberEnum } from "utils/constants/enums/LastProgressNumberEnum";
import { TrainingTagEnum } from "utils/constants/enums/TrainingTagEnum";
import { checkShowAdviceBySkillRate } from "utils/helpers/profileSkillsHelpers";

const interviewProfileReducer = {
  saveProfileSkillRateInterviewData: (state, action) => {
    const profileSkills = state.currentInterview?.profile?.skills;

    // Get current skill
    const skill = (profileSkills || []).find(
      (skill) => skill.name === action.payload.name
    );
    // Remove current skill from skills list if exists
    const skills = (profileSkills || []).filter(
      (skill) => skill.name !== action.payload.name
    );

    let showAdvice = checkShowAdviceBySkillRate(
      action.payload.rate,
      action.payload.id,
      profileSkills
    );

    if (action.payload.rate === 0 && action.payload.id === "1") {
      showAdvice = true;
    }

    return {
      ...state,
      currentInterview: {
        ...state.currentInterview,
        profile: {
          ...state.currentInterview.profile,
          skills: [
            ...skills,
            {
              ...skill,
              ...action.payload,
              showAdvice,
            },
          ],
        },
        trainingProgress: TrainingProgressFactory(
          TrainingTagEnum.InterviewProfile,
          LastProgressNumberEnum.InterviewProfile.Q1[action.payload.id]
        ).instance,
      },
    };
  },
  saveProfileSkillExampleInterviewData: (state, action) => {
    const profileSkills = state.currentInterview?.profile?.skills;

    // Get current skill
    const skill = (profileSkills || []).find(
      (skill) => skill.name === action.payload.name
    );
    // Remove current skill from skills list if exists
    const skills = (profileSkills || []).filter(
      (skill) => skill.name !== action.payload.name
    );

    return {
      ...state,
      currentInterview: {
        ...state.currentInterview,
        profile: {
          ...state.currentInterview.profile,
          skills: [...skills, { ...skill, ...action.payload }],
        },
        trainingProgress: TrainingProgressFactory(
          TrainingTagEnum.InterviewProfile,
          LastProgressNumberEnum.InterviewProfile.Q2[action.payload.id]
        ).instance,
      },
    };
  },
  resetProfileSkillInterviewData: (state, action) => {
    const profileSkills = state.currentInterview?.profile?.skills;

    return {
      ...state,
      currentInterview: {
        ...state.currentInterview,
        profile: {
          ...state.currentInterview?.profile,
          skills: profileSkills || [],
        },
      },
    };
  },
};

export default interviewProfileReducer;

import axios from "axios";

import env from ".env.json";
import continueToSolutionDataGA from "config/googleAnalytics/continueToSolutionDataGA";
import subscribeDataGA from "config/googleAnalytics/subscribeDataGA";

import rdvSolutionData from "utils/data/rdvSolutionData.json";
import Errors from "utils/translations/en/errors.json";

import { updateUser } from "domain/user/userControllers";
import { sendMail, subscribeToSendinblue } from "domain/mail/mailControllers";
import { MailFactory, MessageFactory } from "domain/mail/mailModels";
import SubjectMailEnum from "utils/constants/enums/SubjectMailEnum";
import {
  generateHtmlWSolutionAndResponsesEmailDoc,
  generateHtmlWSolutionEmailDoc,
  getSubjectMailWithVariables,
} from "utils/helpers/mailHelpers";
import { getTextsForEmailTemplate } from "./solutionHooks";
import { InstructorMail } from "utils/constants/mailConstants";
import endpoints from "utils/constants/endpoints";
import { UserUpdateFactory } from "domain/user/userModels";
import SendInBlueSourceEnum from "utils/constants/enums/SendinBlueSourceEnum";
import { hydrateAllSolutionsResults } from "./solutionServices";
import SubscribeAnalyticSourceEnum from "utils/constants/enums/SubscribeAnalyticSourceEnum";
import actionConfirmRDVDataGA from "config/googleAnalytics/actionConfirmRDVDataGA";
import { sendTiktokEventData } from "domain/tiktokEvent/tiktokEventController";
import { TiktokEventEnum } from "utils/constants/enums/TiktokEventEnum";
import { getPhoneNumberFromCalendlyUserData } from "utils/helpers/usersHelper";

export const handleScheduledCalendlyUser = async (
  userUID,
  inscriptionDate,
  uriInvitee,
  userAnswers,
  hydratedSolutions,
  hasSubmitedEmailInForm
) => {
  try {
    const response = await axios({
      method: "get",
      url: endpoints.GET_CALENDLY_INFO(uriInvitee),
    });
    const {
      email,
      first_name,
      last_name,
      name,
      questions_and_answers,
      ...calendlyMetaData
    } = response.data?.resource;
    if (email) {
      // ** Updating the user data in database
      // ? Note : We ensure that the user is initialized in each page therefore the userUID is never null
      await updateUser(
        userUID,
        UserUpdateFactory({
          email,
          firstName: first_name || name,
          lastName: last_name,
          calendlyQuestionsAndAnswers: questions_and_answers,
          hasCompletedCalendlySchedule: true,
          calendlyMetaData,
        })
      );

      const type_de_coaching =
        JSON.parse(env.CalendlyEventNames)?.[calendlyMetaData.event_name] ||
        calendlyMetaData.event_name;

      await subscribeToSendinblue(SendInBlueSourceEnum.CALENDLY, email, {
        prenom: first_name || name || last_name,
        type_de_coaching,
      });

      // Sending the recap emails if the user had already completed the form
      if (!hasSubmitedEmailInForm && hydratedSolutions) {
        const {
          responsesTextsForEmailTemplate,
          solutionsTextsForEmailTemplate,
          suggestionsTextsForEmailTemplate,
        } = getTextsForEmailTemplate(userAnswers, hydratedSolutions);

        await sendMail(
          userUID,
          MailFactory({
            to: email,
            message: MessageFactory({
              subject: SubjectMailEnum.SOLUTIONS,
              html: generateHtmlWSolutionEmailDoc(
                first_name,
                solutionsTextsForEmailTemplate,
                suggestionsTextsForEmailTemplate
              ),
            }),
          })
        );

        await sendMail(
          userUID,
          MailFactory({
            to: InstructorMail,
            message: MessageFactory({
              subject: getSubjectMailWithVariables(
                SubjectMailEnum.SOLUTIONS_RESUME_CONTACT,
                [first_name]
              ),
              html: generateHtmlWSolutionAndResponsesEmailDoc(
                solutionsTextsForEmailTemplate,
                responsesTextsForEmailTemplate
              ),
            }),
          })
        );
      }

      // Sending google analytic action
      continueToSolutionDataGA({
        solutionNom: rdvSolutionData.title,
        mailLaisse: true,
      });

      actionConfirmRDVDataGA();

      const phoneNumber = getPhoneNumberFromCalendlyUserData(
        questions_and_answers
      );

      subscribeDataGA(SubscribeAnalyticSourceEnum.CALENDLY, email, phoneNumber);

      sendTiktokEventData(
        TiktokEventEnum.completeRegistration,
        userUID,
        email,
        phoneNumber
      );
    } else {
      console.error(
        Errors.SOLUTIONS.CALENDLY.GET_INFO_BAD_RESPONSE,
        "not getting the info of first_name and email",
        {
          email,
          first_name,
        }
      );
    }
  } catch (error) {
    console.error(Errors.SOLUTIONS.CALENDLY.GET_INFO_SERVER_ERROR, error);
  }
};

export const getAllSolutionsFromServer = async (
  authTokenId,
  treeTokenId,
  questionAnswerMap,
  sector,
  branchId
) => {
  try {
    if (
      authTokenId === null ||
      treeTokenId === null ||
      treeTokenId === "undefined"
    ) {
      const response = await axios({
        method: "get",
        url: endpoints.GET_TREE_DATA_PROD(),
      });

      const {
        data: { tree, solutions },
      } = response;
      return hydrateAllSolutionsResults(
        tree,
        solutions,
        questionAnswerMap,
        sector,
        branchId
      );
    }

    const response = await axios({
      method: "get",
      url: endpoints.GET_TREE_DATA_BY_TOKEN_ID(treeTokenId),
      headers: {
        Authorization: `Bearer ${authTokenId}`,
      },
    });

    const {
      data: { tree, solutions },
    } = response;

    return hydrateAllSolutionsResults(
      tree,
      solutions,
      questionAnswerMap,
      sector,
      branchId
    );
  } catch (error) {
    console.error(Errors.SOLUTIONS.ON_GETTING_TREE_DATA, error);
    throw new Error(Errors.SOLUTIONS.ON_GETTING_TREE_DATA);
  }
};

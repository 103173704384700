import React from "react";
import Lottie from "react-lottie";
import { useTranslation } from "react-i18next";
import Interweave from "interweave";

import LogoMV from "assets/images/logos/logo-mavoieV3.png";
import questionnaireLoader from "../../../utils/constants/questionnaireLoader.json";

import {
  Box,
  Container,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";

import useQuery from "hooks/useQuery";

const QuestionnaireLoaderPage = () => {
  const query = useQuery();

  const { t } = useTranslation(["questionnaireEmail"]);

  const theme = useTheme();
  const {
    breakpoints,
    typography: { ParagraphStyles, FontFamilies },
    variables: {
      navbar: { small: variablesSM, large: variablesLarge },
    },
  } = theme;

  const isScreenDownSM = useMediaQuery(breakpoints.down("sm"));

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: questionnaireLoader,
    speed: 0.1,
  };

  return (
    <Container
      maxWidth="xl"
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Box textAlign={"center"} mt={10} mb={30}>
        <img
          src={LogoMV}
          width={
            isScreenDownSM ? variablesSM.imgWidth : variablesLarge.imgWidth
          }
          height={"auto"}
          alt="logo-MaVoie"
        />
      </Box>
      <Typography
        textAlign={"center"}
        fontFamily={FontFamilies.ProximaNovaBold}
        fontSize={ParagraphStyles.fontSizes.lg}
        mb={20}
      >
        <Interweave content={t("loaderTitle")} />
      </Typography>
      <Lottie options={defaultOptions} height={400} width={400} />
      {query.get("email") ? (
        <Typography
          textAlign={"center"}
          fontFamily={FontFamilies.ProximaNovaBold}
          fontSize={ParagraphStyles.fontSizes.lg}
        >
          <Interweave content={t("loaderText")} />
        </Typography>
      ) : (
        <div style={{ height: "72px", marginTop: "25%" }} />
      )}
    </Container>
  );
};

export default QuestionnaireLoaderPage;

import React, { Component } from "react";
import { Highlight, connectAutoComplete } from "react-instantsearch-dom";
import AutoSuggest from "react-autosuggest";
import AlgoliaKeys from "utils/constants/keys.json";

class InputAutoComplete extends Component {
  state = {
    value: this.props.currentRefinement,
  };

  lastHighlightedValue = "";

  componentDidMount() {
    if (this.props.value) {
      this.setState({
        value: this.props.value,
      });
    }
  }

  onChange = (_, { newValue }) => {
    if (!newValue) {
      this.props.onSuggestionCleared();
    }
    this.setState({
      value: newValue,
    });
    this.props.onChange(newValue, this.lastHighlightedValue);
  };

  onSuggestionsFetchRequested = ({ value }) => {
    this.props.refine(value);
  };

  onSuggestionsClearRequested = () => {
    this.props.refine();
  };

  onSuggestionHighlighted = ({ suggestion }) => {
    if (suggestion) {
      this.lastHighlightedValue =
        suggestion[AlgoliaKeys.ALGOLIA_INDEX_JOB_ATTRIBUTE_SEARCH];
    }
  };

  getSuggestionValue(hit) {
    return hit[AlgoliaKeys.ALGOLIA_INDEX_JOB_ATTRIBUTE_SEARCH];
  }

  renderSuggestion(hit) {
    return (
      <Highlight
        attribute={AlgoliaKeys.ALGOLIA_INDEX_JOB_ATTRIBUTE_SEARCH}
        hit={hit}
      />
    );
  }

  render() {
    const { hits, onSuggestionSelected } = this.props;
    const { value } = this.state;

    const inputProps = {
      placeholder: this.props.placeHolder,
      name: this.props.name,
      onChange: this.onChange,
      value,
    };

    return (
      <>
        <AutoSuggest
          suggestions={hits}
          onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
          onSuggestionsClearRequested={this.onSuggestionsClearRequested}
          onSuggestionSelected={onSuggestionSelected}
          onSuggestionHighlighted={this.onSuggestionHighlighted}
          getSuggestionValue={this.getSuggestionValue}
          renderSuggestion={this.renderSuggestion}
          focusInputOnSuggestionClick={false}
          inputProps={inputProps}
          theme={{
            container: this.props.theme.container,
            input: this.props.theme.input,
            inputFocused: this.props.theme.inputFocused,
            inputOpen: this.props.theme.inputOpen,
            suggestionsContainer: this.props.theme.suggestionsContainer,
            suggestionsContainerOpen: this.props.theme.suggestionsContainerOpen,
            sectionTitle: this.props.theme.sectionTitle,
            suggestionsList: this.props.theme.suggestionsList,
            suggestion: this.props.theme.suggestion,
            suggestionFirst: this.props.theme.suggestionFirst,
            suggestionHighlighted: this.props.theme.suggestionHighlighted,
          }}
        />
      </>
    );
  }
}

export default connectAutoComplete(InputAutoComplete);

/**
 * Opens the specified URL in a new browser tab and keeps the focus on the new tab.
 *
 * @param {string} url - The URL to open in a new tab.
 */
export function openInNewTabKeepFocus(url) {
  const win = window.open(url, "_blank");
  win.focus();
}

/**
 * Function that creates a callback for handling the popstate event and skipping the history go back action.
 *
 * @param {object} history - The history object from the React Router.
 * @returns {function} - The callback function for the popstate event.
 */
export const onPopStateHistoryGoBackSkip = (history) => (event) => {
  history.goBack();
  // Prevent the default behavior of the event
  event.preventDefault();
};

export const QUESTIONNAIRE_LOAD_TIME = 3000;

const re = /<<q[0-9]{1,2}[a-z]{0,1}>>/g;

export const OrderedAnswersIndexesForResumePage = [
  "q1",
  "q1a",
  "q1b",
  "q1c",
  "q2",
  "q3",
  "q3b",
  "q3c",
  "q3e",
  "q3f",
  "q4",
  "q4a",
  "q4b",
];

/**
 * Retrieves the final value by replacing variables in a given string with their corresponding values.
 *
 * @param {string} valueFromFormationData - The initial value to be processed.
 * @param {Array} questionAnswerMap - An array of objects containing key-value pairs representing variables and their values.
 * @returns {string} - The final value with variables replaced by their corresponding values.
 */
export function getVariablesValuesFromQuestionnaire(
  valueFromFormationData,
  questionAnswerMap
) {
  let valueFinal = valueFromFormationData;
  if (questionAnswerMap && Object.values(questionAnswerMap)?.length > 0) {
    questionAnswerMap?.map((variable) => {
      if (valueFinal?.includes(">>") && valueFinal.includes(">>")) {
        valueFinal = valueFinal.replace(
          "<<" + variable.key + ">>",
          variable.value
        );
      }
      return valueFinal;
    });
  }
  if (valueFinal) {
    valueFinal = valueFinal.replace(re, "");
  }
  return valueFinal;
}

/**
 * Getting id (as key), type, answer (as value), resume of the all questions from users
 *
 * @param {Object} questionWithAnswers [{qid: string, question: string, type: string, anonymous: boolean, answers : [{aid: string, value: string, path: string}], resume: string}]
 * @returns {Object} questionAnswerPair [{key: string, type: string, value: string, resume: string}]
 */
export function getQuestionAnswers(questionWithAnswers) {
  let questionAnswerPair = [];
  if (questionWithAnswers !== undefined) {
    for (let i = 0; i < questionWithAnswers.length; i++) {
      let value = "";
      if (questionWithAnswers[i].type !== "multichoice") {
        value =
          questionWithAnswers[i].answers !== undefined &&
          questionWithAnswers[i].answers[0] !== undefined
            ? questionWithAnswers[i].answers[0].value
            : "";
      } else {
        const answerArray =
          questionWithAnswers[i].answers !== undefined
            ? questionWithAnswers[i].answers.filter(
                (answer) => answer.value && answer.value !== ""
              )
            : [];
        const answerValueArray = answerArray
          ? answerArray.map((answer) => answer.value)
          : [];
        value = answerValueArray ? answerValueArray.join(", ") : "";
      }
      const resumeFinal = questionWithAnswers?.[i]?.resume?.replace(re, value);

      questionAnswerPair[i] = {
        key: questionWithAnswers[i].qid,
        type: questionWithAnswers[i].type,
        value: value ? value : "",
        resume: resumeFinal,
      };
    }
  }
  return questionAnswerPair;
}

/**
 * Getting all answers {id (as key), type, answer (as value), resume} for each question
 *
 * @param {Object} questionWithAnswers [{qid: string, question: string, type: string, anonymous: boolean, answers : [{aid: string, value: string, path: string}], resume: string}]
 * @returns {Object} questionAnswerDict {qid: {key: string, type: string, value: string, resume: string} }
 */
export function formatQuestionAnswersDictionary(questionWithAnswers) {
  let questionAnswerDict = {};
  if (questionWithAnswers !== undefined) {
    for (let i = 0; i < questionWithAnswers.length; i++) {
      let value = "";
      if (questionWithAnswers[i].type !== "multichoice") {
        value =
          questionWithAnswers[i].answers !== undefined &&
          questionWithAnswers[i].answers[0] !== undefined
            ? questionWithAnswers[i].answers[0].value
            : "";
      } else {
        const answerArray =
          questionWithAnswers[i].answers !== undefined
            ? questionWithAnswers[i].answers.filter(
                (answer) => answer.value && answer.value !== ""
              )
            : [];
        const answerValueArray = answerArray
          ? answerArray.map((answer) => answer.value)
          : [];
        value = answerValueArray ? answerValueArray.join(", ") : "";
      }
      const resumeFinal = questionWithAnswers?.[i]?.resume?.replace(re, value);

      questionAnswerDict[questionWithAnswers[i].qid] = {
        key: questionWithAnswers[i].qid,
        type: questionWithAnswers[i].type,
        value: value || "",
        resume: resumeFinal,
      };
    }
  }
  return questionAnswerDict;
}

/**
 * Getting all answers for each question
 *
 * @param {Array} questionWithAnswers - The array of question objects containing question IDs and answers.
 * @returns {Object} - The dictionary of question answers, where the question ID is the key and the question object is the value.
 */
function createQuestionAnswerDictionary(questionWithAnswers) {
  let questionWithAnswerDict = {};
  if (questionWithAnswers !== undefined) {
    for (let i = 0; i < questionWithAnswers.length; i++) {
      if (questionWithAnswers[i].qid) {
        questionWithAnswerDict[questionWithAnswers[i].qid] =
          questionWithAnswers[i];
      }
    }
  }
  return questionWithAnswerDict;
}

/**
 * Reorders the question answers based on a predefined order.
 *
 * @param {Array} questionWithAnswers - The array of question answers to be reordered.
 * @returns {Array} - The reordered list of question answers.
 */
const reorderQuestionAnswers = (questionWithAnswers) => {
  const dict = createQuestionAnswerDictionary(questionWithAnswers);
  const hydratedList = [];
  OrderedAnswersIndexesForResumePage.forEach((answerIndex) => {
    if (dict[answerIndex]) {
      hydratedList.push(dict[answerIndex]);
    }
  });
  return hydratedList;
};

/**
 * Updates the answer in a question with answers list.
 * If the question already exists in the list, it updates the answer.
 * If the question doesn't exist, it adds the question to the list.
 *
 * @param {Array} questionWithAnswers - The list of questions with answers
 * @param {Object} payload - The payload containing the question and answer to update/add
 * @returns {Array} - The updated list of questions with answers
 */
export const newAnswerInQuestionWithAnswersList = (
  questionWithAnswers,
  payload
) => {
  let questionWithAnswersPre = [];
  let isUpdated = false;
  for (let i = 0; i < questionWithAnswers.length; i++) {
    questionWithAnswersPre[i] = {
      qid: questionWithAnswers[i].qid,
      question: questionWithAnswers[i].question,
      type: questionWithAnswers[i].type,
      anonymous: questionWithAnswers[i].anonymous,
      answers: questionWithAnswers[i].answers,
      resume: questionWithAnswers[i].resume,
    };
    if (questionWithAnswers[i].qid === payload.qid) {
      questionWithAnswersPre[i].answers = payload.answers;
      questionWithAnswersPre[i].question = payload.question;
      questionWithAnswersPre[i].anonymous = payload.anonymous;
      questionWithAnswersPre[i].type = payload.type;
      questionWithAnswersPre[i].qid = payload.qid;
      questionWithAnswersPre[i].resume = payload.resume;
      isUpdated = true;
      break;
    }
  }
  if (!isUpdated) {
    questionWithAnswersPre.push(payload);
  }

  //update OrderedAnswersIndexesForResumePage is required
  const rehydratedList = reorderQuestionAnswers(questionWithAnswersPre);

  return rehydratedList;
};

/**
 * Creates an answer instance object.
 *
 * @param {string} aid - The answer ID.
 * @param {any} value - The value of the answer.
 * @param {string} path - The path of the answer.
 * @returns {object} - The answer instance object.
 */
export function getAnswerInstance(aid, value, path) {
  return { aid, value, path };
}

/**
 * Returns an empty answer object.
 *
 * @returns {Object} The empty answer object.
 */
export function getAnswerEmpty() {
  return { aid: "", value: "", path: "" };
}

/**
 * Retrieves the answers for a specific question ID from a given array of question objects.
 *
 * @param {Array} questionWithAnswers - The array of question objects containing answers.
 * @param {string} qid - The ID of the question to retrieve answers for.
 * @returns {Array|null} - The answers for the specified question ID, or null if not found.
 */
export function getAnswersByQid(questionWithAnswers, qid) {
  let question = [];
  if (questionWithAnswers) {
    question = questionWithAnswers.filter((question) => question.qid === qid);
  }
  if (question && question.length > 0) {
    return question[0].answers;
  }
  return null;
}

const MuiLinearProgress = {
  styleOverrides: {
    root: ({ theme }) => ({
      height: "14px",
      color: theme.palette.green.main,
      backgroundColor: theme.palette.grey[300],
      borderRadius: "10px",
      "& .MuiLinearProgress-bar": {
        backgroundColor: theme.palette.green.main,
        borderRadius: "10px",
      },
    }),
  },
};

export default MuiLinearProgress;

const MuiButton = {
  styleOverrides: {
    root: ({ ownerState, theme }) => {
      // Getting from the palette the customized color styles of MV Button
      let customColorStyles = {};
      if (
        ownerState.color &&
        ownerState.variant &&
        // The prop disableMaVoieTheme disables the custom styles
        !ownerState.disableMaVoieTheme
      ) {
        customColorStyles =
          theme.palette.button?.[ownerState.color]?.[ownerState.variant];
      } else {
        console.warn(
          "This Button doesn't use the colors themes of MaVoie \n Please provide enter a value in the attribute colormv (ex: colormv='PrimaryMV.black') \n Visit MaVoie StoryBook for extra infos !"
        );
      }

      return {
        // *** Overriding general props of button ****
        textTransform: "none",
        fontStyle: "normal",
        fontWeight: 700,
        fontFamily: "ProximaNova",
        lineHeight: "140%",
        padding: ownerState.size === "large" ? "8px 16px" : "4px 16px",
        border: ["contained", "outlined"].includes(ownerState.variant)
          ? "solid 2px"
          : "none",
        // *** Overriding some styles depending the size of the button *****
        ...(ownerState.size === "large"
          ? { borderRadius: "10px", fontSize: "16px" }
          : {
              borderRadius: "8px",
              fontSize: "14px",
            }),
        // *** Overriding all colors props from the customColorStyles *****
        ...(customColorStyles && Object.keys(customColorStyles).length > 0
          ? {
              color: customColorStyles.textColor,
              backgroundColor: customColorStyles.backgroundColor,
              borderColor: customColorStyles.borderColor,
              "&.Mui-disabled": {
                backgroundColor: customColorStyles.disabledBackgroundColor,
                color: customColorStyles.disabledTextColor,
                pointerEvents: "none",
                border: "none",
              },
              "&:hover": {
                // padding: ownerState.variant === "outlined" && "9px 17px",
                color: customColorStyles.hoveredTextColor,
                backgroundColor: customColorStyles.hoveredBackgroundColor,
                border: ["contained", "outlined"].includes(ownerState.variant)
                  ? `solid 2px ${customColorStyles.hoveredBackgroundColor}`
                  : "none",
                // We check href or the prop link is set because it means it's a link button
                textDecoration:
                  ownerState.islink || ownerState.href ? "underline" : "none",
                textUnderlineOffset: "2px",
                transition: "text-decoration 1s ease-in-out",
                "& .MuiButton-startIcon svg ": {
                  fill: customColorStyles.hoveredIconColor,
                },
                "& .MuiButton-endIcon svg": {
                  fill: customColorStyles.hoveredIconColor,
                },
              },
              "& .MuiButton-startIcon svg": {
                fill: customColorStyles.iconColor,
              },
              "& .MuiButton-endIcon svg": {
                fill: customColorStyles.iconColor,
              },
            }
          : {}),
      };
    },
  },
};

export default MuiButton;

const DocumentDescriptionEnum = {
  HOMEPAGE:
    "MaVoie te propose des solutions pratiques qui correspondent à ton parcours : orientation, formations, coaching, simulation d’entretien pour réussir tes candidatures.",
  CONTACT: "Contact",
  RESUME: "Résumé",
  FORM: "Fomulaire",
  SOLUTIONS: "Solutions",
  CGU: "CGU",
  MISSION:
    "À travers de maraudes numériques, MaVoie veut casser l’isolement et l’invisibilisation des jeunes générations en recherche d’emploi.",
  TEAM: "MaVoie c’est une équipe engagée chaque jour pour une société plus inclusive et juste.",
  INTERVIEW:
    "L'utilisation du questionnaire d'1entretien 1job t'aidera à te préparer de façon efficace afin de maximiser tes chances de réussite lors de ton entretien d'embouche.",
};

export default DocumentDescriptionEnum;

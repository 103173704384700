import clsx from "clsx";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { v4 as uuidv4 } from "uuid";
import Interweave from "interweave";
import InterviewSummaryStyles from "../InterviewSummary.module.scss";
import ProfileInformationsStyles from "./ProfileInformations.module.scss";

import { useMediaQueries } from "hooks/useMediaQueries";
import useSummaryInterviewData from "hooks/useSummaryInterviewData";
import { getProfileSkillWithRateTranslations } from "utils/helpers/profileSkillsHelpers";
import InterviewToolTipBox from "components/common/InterviewToolTipBox/InterviewToolTipBox";
import { InterviewAdviceNumberEnum } from "utils/constants/enums/InterviewAdviceNumberEnum";
import { LastProgressNumberEnum } from "utils/constants/enums/LastProgressNumberEnum";

const ProfileInformations = () => {
  const { t } = useTranslation("interview");

  const { isTablet, isDesktop, isMobile } = useMediaQueries();

  const { companyContent, profileSkills } = useSummaryInterviewData();

  const { profileTitle, profileSkillSubTitle, profileSkillsTexts } = t(
    "interviewSummary",
    {
      returnObjects: true,
      company: companyContent,
    }
  );

  const profileSkillsReworked = useMemo(
    () =>
      profileSkills?.length > 0
        ? getProfileSkillWithRateTranslations(profileSkills, profileSkillsTexts)
        : [],
    [profileSkills, profileSkillsTexts]
  );

  const { textAdviceSC } = t("interviewAdvice", {
    returnObjects: true,
  });

  const SkillRateClassName = {
    [profileSkillsTexts.skillRate_0]: ProfileInformationsStyles.skillRateTag0,
    [profileSkillsTexts.skillRate_1]: ProfileInformationsStyles.skillRateTag1,
    [profileSkillsTexts.skillRate_2]: ProfileInformationsStyles.skillRateTag2,
  };

  return (
    <section name="profileInformations">
      <section
        name="profileTitle"
        className={InterviewSummaryStyles.containerTitle}
      >
        <Interweave
          className={InterviewSummaryStyles.sectionTitle}
          content={profileTitle}
        />
      </section>
      <section
        name="profileContent"
        className={clsx({
          [InterviewSummaryStyles.containerInfosProfileS]: isMobile,
          [InterviewSummaryStyles.containerInfosProfileM]: isTablet,
          [InterviewSummaryStyles.containerInfosProfileL]: isDesktop,
        })}
      >
        <Interweave
          className={InterviewSummaryStyles.sectionSubtitle}
          content={profileSkillSubTitle}
        />
        <div>
          {profileSkillsReworked?.map((profileSkill) => (
            <React.Fragment key={uuidv4()}>
              <div className={ProfileInformationsStyles.profileSkill}>
                <Interweave
                  className={InterviewSummaryStyles.sectionJobDetailTitle}
                  content={profileSkill.name}
                />
                <Interweave
                  className={`${ProfileInformationsStyles.skillRateTag} ${
                    SkillRateClassName[profileSkill.rateTranslation]
                  }`}
                  content={profileSkill.rateTranslation}
                />
              </div>
              {profileSkill.showAdvice && (
                <InterviewToolTipBox
                  toolTipTextTitle={t("interviewSummary.toolTipTextTitle", {
                    number:
                      InterviewAdviceNumberEnum[
                        LastProgressNumberEnum.InterviewProfile.C1[
                          profileSkill.id
                        ]
                      ],
                  })}
                  toolTipTextContent={textAdviceSC}
                />
              )}
              {profileSkill.rate !== 0 && (
                <Interweave
                  className={clsx({
                    [ProfileInformationsStyles.sectionProfileNoExampleBlock]:
                      !profileSkill.hasExample,
                    [ProfileInformationsStyles.sectionProfileExampleBlock]:
                      profileSkill.hasExample,
                  })}
                  content={profileSkill.example}
                />
              )}
            </React.Fragment>
          ))}
        </div>
      </section>
    </section>
  );
};

export default ProfileInformations;

export const MuiMenu = {
  styleOverrides: {
    paper: {
      backgroundColor: "#f5f5f5",
      borderRadius: "4px",
      boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.12)",
      "& .MuiMenuList-list": {
        padding: "8px",
      },
    },
  },
};

export const MuiMenuItem = {
  styleOverrides: {
    root: {
      display: "flex",
      justifyContent: "center",
      "&:hover": {},
    },
  },
};

import React, { useEffect } from "react";

import VideosCarouselStyles from "./VideosCarousel.module.scss";

import socialNetworkMissionPageData from "utils/data/socialNetworkMissionPageData";
import VideosCarouselButtons from "pages/MissionPage/VideosCarouselButtons";

const VideosCarousel = () => {
  const videos = socialNetworkMissionPageData.videos.map((video) => ({
    ...video,
    ref: React.createRef(),
  }));

  useEffect(() => {
    if (videos) {
      videos.forEach((video) => {
        const videoElement = video.ref.current;
        videoElement.addEventListener("play", () => {
          // Pause all other videos
          videos.forEach((otherVideo) => {
            const otherVideoElement = otherVideo.ref.current;
            if (
              otherVideo.id !== video.id &&
              otherVideoElement &&
              !otherVideoElement.paused
            ) {
              otherVideoElement.pause();
            }
          });
        });
      });
    }

    return () => {
      videos.forEach((video) => {
        const videoElement = video.ref.current;
        if (videoElement) {
          videoElement.removeEventListener("play");
        }
      });
    };
  }, [videos]);

  return (
    <VideosCarouselButtons>
      <div
        style={{
          width: socialNetworkMissionPageData.totalVideosWidth + "px",
        }}
        className={VideosCarouselStyles.videoCarousel}
      >
        {videos?.map((video) => (
          <div
            key={video.id}
            style={{ width: video.videoWidthPx + "px" }}
            className={VideosCarouselStyles.videoCarouselItem}
          >
            <video ref={video.ref} controls>
              <source src={video.src} type={video.type} />
            </video>
          </div>
        ))}
        {/** Blank Spance To ensure all videos are seen */}
        <div className={VideosCarouselStyles.blankSpaceForMobile} />
      </div>
    </VideosCarouselButtons>
  );
};

export default VideosCarousel;

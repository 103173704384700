import { createSlice } from "@reduxjs/toolkit";
import StoreKeys from "utils/constants/store-keys.json";
import { UserAnonymFactory } from "domain/user/userModels";
import {
  userInterviewReducers,
  userManagementReducers,
  userQuestionnaireReducers,
  userSolutionsReducers,
} from "./reducers";

const initialState = {
  user: UserAnonymFactory().instance,
  // TODO(mehdi): Add Factory for questionnaire
  questionWithAnswers: [
    {
      qid: "q1",
      type: "",
      question: "",
    },
  ],
  tempAnswer: [
    {
      aid: "",
      value: "",
      path: "",
    },
  ],
  lastProgress: 0,
  hydratedSolutions: null,
};

export const usersSlice = createSlice({
  name: StoreKeys.USERS,
  initialState,
  reducers: {
    ...userInterviewReducers,
    ...userManagementReducers,
    ...userQuestionnaireReducers,
    ...userSolutionsReducers,
  },
});

export const {
  saveAnswer,
  saveTempAnswer,
  saveUser,
  saveLastProgress,
  deleteAnswer,
  saveHydratedSolutions,
  saveUserQuestionnaire,
  saveAllDiagnostics,
  setTreeTokenId,
} = usersSlice.actions;

export default usersSlice.reducer;

import React from "react";

import { v4 as uuidv4 } from "uuid";
import { Box, Typography, useTheme } from "@mui/material";

import TestimonyStars from "assets/images/testimony-reviews-stars.svg";

const TestimonyCard = ({ testimonies }) => {
  const theme = useTheme();

  return (
    <>
      {testimonies.map((testimony, index) => (
        <Box
          key={uuidv4()}
          mx={5}
          sx={{
            display: "flex",
            height: "150px",
            width: "390px",
            flexDirection: "column",
            justifyContent: "space-between",
            textAlign: "left",
            [theme.breakpoints.down("sm")]: {
              paddingLeft: "20px",
              paddingRight: "20px",
            },
          }}
        >
          <Box>
            <Typography variant="paragraph">{testimony.text}</Typography>
          </Box>
          <Box
            sx={{
              borderTop: "1px solid #E8E8E8",
              paddingTop: "4px",
              display: "flex",
              alignItems: "center",
            }}
          >
            <img
              style={{
                marginTop: "3px",
                marginRight: "10px",
                width: "33%",
                height: "21px",
              }}
              src={TestimonyStars}
              alt="googleNote"
            />
            <Box mt={1}>
              <Typography
                sx={{
                  fontFamily: "ProximaNovaBold",
                  fontSize: "14px",
                }}
              >
                {testimony.name}, {testimony.age}
              </Typography>
            </Box>
          </Box>
        </Box>
      ))}
    </>
  );
};

export default TestimonyCard;

import axios from "axios";

import Errors from "utils/translations/en/errors.json";
import endpoints from "utils/constants/endpoints";
import { MailFactory, MessageFactory } from "./mailModels";
import SubjectMailEnum from "utils/constants/enums/SubjectMailEnum";
import {
  generateHtmlWSolutionAndResponsesEmailDoc,
  generateHtmlWSolutionEmailDoc,
  getSubjectMailWithVariables,
} from "utils/helpers/mailHelpers";
import { InstructorMail } from "utils/constants/mailConstants";

/**
 * Function that sends the mail and persist the data on the collection Mail
 *
 * @param {ObjectOfMail} mail object that contains mail infos
 * @param {ObjectOfAny} payload other infos that we want to persist
 * @returns mail payload if success or null if an error is occurred
 */
export async function sendMail(userId, mail, payload = {}) {
  if (
    mail?.to &&
    mail?.message?.subject &&
    (mail?.message?.text || mail?.message?.html)
  ) {
    try {
      await axios({
        method: "put",
        url: endpoints.SEND_MAIL_USER(userId),
        data: mail,
      }).then((response) => {
        console.log("sending mail successfully");
      });
      return { ...mail, ...payload };
    } catch (error) {
      console.error(Errors.MAIL.SERVER, error, { mail });
    }
  } else {
    console.error(Errors.MAIL.PARAMS, { mail });
  }
  return null;
}

export async function sendRecapMail(
  userId,
  mail,
  firstName,
  solutionsTextsForEmailTemplate,
  suggestionsTextsForEmailTemplate,
  responsesTextsForEmailTemplate
) {
  try {
    await sendMail(
      userId,
      MailFactory({
        to: mail,
        message: MessageFactory({
          subject: SubjectMailEnum.SOLUTIONS,
          html: generateHtmlWSolutionEmailDoc(
            firstName,
            solutionsTextsForEmailTemplate,
            suggestionsTextsForEmailTemplate
          ),
        }),
      })
    );
    await sendMail(
      userId,
      MailFactory({
        to: InstructorMail,
        message: MessageFactory({
          subject: getSubjectMailWithVariables(
            SubjectMailEnum.SOLUTIONS_RESUME_CONTACT,
            [firstName]
          ),
          html: generateHtmlWSolutionAndResponsesEmailDoc(
            solutionsTextsForEmailTemplate,
            responsesTextsForEmailTemplate
          ),
        }),
      })
    );
  } catch (error) {
    console.error(Errors.MAIL.SERVER, error, { mail });
  }
}

/**
 * Function that save the user in the subscription list of sendinblue
 *
 * @param {string} email of the user
 * @param {string} srouce
 * @param {Object} attributes
 */
export async function subscribeToSendinblue(source, email, attributes) {
  if (email && source) {
    // paramentre "rdv_decouverte" mis en commentaire pour teser si on peut l'enlever : le but est de lui donner une valeur directement en beckend
    try {
      const {
        prenom,
        age,
        status_pro,
        /* rdv_decouverte, */
        sendInBlueStarAttribute,
        sendInBlueInterviewAttribute,
        interviewDate,
        companyName,
        type_de_coaching,
      } = attributes;
      await axios({
        method: "get",
        url: endpoints.SUBSCRIBE_SENDINBLUE(
          source,
          email,
          sendInBlueStarAttribute,
          prenom,
          /* rdv_decouverte, */
          sendInBlueInterviewAttribute,
          interviewDate,
          companyName,
          type_de_coaching,
          age,
          status_pro
        ),
      });
    } catch (error) {
      console.error(Errors.MAIL.SERVER, error, { email });
    }
  }
}

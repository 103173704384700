/**
 * Object containing display information for different solution types.
 * @typedef {Object} DisplayInfoBySolutionType
 * @property {Object} Accompagnement - Display information for "Accompagnement" solution type.
 * @property {Object} Accompagnement.tagStyle - Tag style information for "Accompagnement" solution type.
 * @property {string} Accompagnement.tagStyle.bgBackground - Background color for the tag.
 * @property {Object} Accompagnement.buttonCta - Button CTA information for "Accompagnement" solution type.
 * @property {string} Accompagnement.buttonCta.text - Text for the button CTA.
 * @property {Object} Formation - Display information for "Formation" solution type.
 * @property {Object} Formation.tagStyle - Tag style information for "Formation" solution type.
 * @property {string} Formation.tagStyle.bgBackground - Background color for the tag.
 * @property {Object} Formation.buttonCta - Button CTA information for "Formation" solution type.
 * @property {string} Formation.buttonCta.text - Text for the button CTA.
 * @property {Object} "Outil numérique" - Display information for "Outil numérique" solution type.
 * @property {Object} "Outil numérique".tagStyle - Tag style information for "Outil numérique" solution type.
 * @property {string} "Outil numérique".tagStyle.bgBackground - Background color for the tag.
 * @property {Object} "Outil numérique".buttonCta - Button CTA information for "Outil numérique" solution type.
 * @property {string} "Outil numérique".buttonCta.text - Text for the button CTA.
 */
export const DisplayInfoBySolutionType = {
  Accompagnement: {
    tagStyle: {
      bgBackground: "#FBE2D5",
    },
    buttonCta: {
      text: "Découvrir l'accompagnement",
    },
  },
  Formation: {
    tagStyle: {
      bgBackground: "#FFECF9",
    },
    buttonCta: {
      text: "Découvrir la formation",
    },
  },
  "Outil numérique": {
    tagStyle: {
      bgBackground: "#CBF1F5",
    },
    buttonCta: {
      text: "Découvrir l'outil",
    },
  },
};

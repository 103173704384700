import axios from "axios";

import { setDoc, doc } from "firebase/firestore";

import { db, auth } from "config/initConfig";
import subscribeDataGA from "config/googleAnalytics/subscribeDataGA";
import goDirectlyToSolutionsPageDataGA from "config/googleAnalytics/goDirectlyToSolutionsPageDataGA";

import endpoints from "utils/constants/endpoints";
import sendInBlueData from "utils/data/sendInBlueData";
import DocKeys from "utils/constants/doc-keys.json";
import Errors from "utils/translations/en/errors.json";
import Path from "utils/translations/fr/path.json";
import { delayPromise } from "utils/helpers/apiHelpers";
import {
  getAnswersByQid,
  getQuestionAnswers,
  QUESTIONNAIRE_LOAD_TIME,
} from "utils/helpers/questionnaireHelper";
import { getQuestionnaireAnswersWithRgpdRules } from "utils/helpers/usersHelper";
import SendInBlueSourceEnum from "utils/constants/enums/SendinBlueSourceEnum";
import MapBranchIdGroupStar from "utils/constants/enums/MapBranchIdGroupStar";
import SubscribeAnalyticSourceEnum from "utils/constants/enums/SubscribeAnalyticSourceEnum";

import {
  saveAllDiagnostics,
  saveHydratedSolutions,
  saveUser,
  saveUserQuestionnaire,
} from "store/users/usersSlice";
import {
  selectAuthTokenBackoffice,
  selectTokenTreeId,
  selectUser,
  selectUserAnalyticSolutions,
  selectUserAnswers,
  selectUserDiagnostics,
  selectUserEmail,
  selectUserQuestionnaire,
  selectUserUID,
} from "store/users/usersSelectors";
import { UserAnonymFactory, UserFieldEnum } from "domain/user/userModels";
import {
  sendRecapMail,
  subscribeToSendinblue,
} from "domain/mail/mailControllers";
import { getTextsForEmailTemplate } from "domain/solution/solutionHooks";
import { getAllSolutionsFromServer } from "domain/solution/solutionControllers";

import { UserUpdateFactory } from "./userModels";

// Api Callbacks (not redux actions)

/**
 * Function that create a new anonym user with a generated id created by the anonym authentication feature of firebase
 *
 * @returns user payload if success or null if an error is occurred
 */
async function newUserAnonym() {
  let user = UserAnonymFactory();
  try {
    if (auth?.currentUser?.uid) {
      user.setValue(UserFieldEnum.uid, auth.currentUser.uid);
      try {
        await setDoc(
          doc(db, DocKeys.USERS, auth.currentUser.uid),
          user.value()
        );
      } catch (error) {
        console.error(error);
      }
      return user.value();
    } else {
      // Setting an interval of 3 seconds and retrying the authentication
      const idInterval = setInterval(async (_) => {
        if (auth?.currentUser?.uid) {
          user.setValue(UserFieldEnum.uid, auth.currentUser.uid);
          await setDoc(
            doc(db, DocKeys.USERS, auth.currentUser.uid),
            user.value()
          );
          clearInterval(idInterval);
          return;
        }
        console.warn(Errors.USER.NO_USER);
      }, 3000);
    }
  } catch (error) {
    console.error(Errors.USER.SERVER_ON_CREATE, error);
  }
  return null;
}

/**
 * Helper to construct the object of param query firestore
 *
 * @param {string} key
 * @param {string} value
 * @returns {Object} {key: string, value: string}
 */
const getParamFirestore = (key, value) => ({
  key,
  value,
});

/**
 * Execute a query on firestore to get sorted user info
 *
 * @param {Object} param {Object} {key: string, value: string}
 *
 * @returns user payload if success or null
 */
async function getRegistredUserByParam(param) {
  try {
    if (Boolean(param.key) && Boolean(param.value)) {
      const {
        data: { response: usersResult },
      } = await axios({
        method: "get",
        url: endpoints.CHECK_USER_BY_PARAM(param.key, param.value),
      });
      return usersResult?.[0] || null;
    }
  } catch (error) {
    console.error(Errors.USER.SERVER_ON_GET, error);
  }
  return null;
}

/**
 * Function that returns a new anonym user with a a generated id created by the anonym authentication feature of firebase
 *
 * @returns user payload if success or null if an error is occurred
 */
export async function updateUser(uid, userPayload) {
  try {
    await axios({
      method: "put",
      url: endpoints.UPDATE_USER(uid || auth.currentUser.uid),
      data: userPayload,
    });
  } catch (error) {
    console.error(Errors.USER.SERVER_ON_UPDATE, error, { uid, userPayload });
  }
}

export async function updateUserQuestionnaire(
  uid,
  questionnaire,
  isUserAnonym
) {
  try {
    await updateUser(uid, {
      questionnaire: getQuestionnaireAnswersWithRgpdRules(
        questionnaire,
        isUserAnonym
      ),
      updateDate: new Date().toLocaleString("fr-Fr"),
    });
  } catch (error) {
    console.error(Errors.USER.SERVER_ON_UPDATE_QUESTIONS, error, {
      uid,
      questionnaire,
      isUserAnonym,
    });
  }
}

/**
 * Function that update user when he starts the form
 *
 * @returns void
 */
export async function startForm(userUID) {
  try {
    await updateUser(userUID, {
      startForm: true,
    });
  } catch (error) {
    console.error(Errors.USER.SERVER_ON_UPDATE, error, {
      userUID,
      startForm: true,
    });
  }
}

/**
 * Function that update user when he finishes the form
 *
 * @returns void
 */
export async function endForm(userUID) {
  try {
    await updateUser(userUID, {
      endForm: true,
    });
  } catch (error) {
    console.error(Errors.USER.SERVER_ON_UPDATE, error, {
      userUID,
      endForm: true,
    });
  }
}

// Redux Callbacks Actions :

/**
 * Check if the navigator already has info of users then try to load the user
 * If we didn't find any user info we create a new anonym user
 *
 * @returns logged user
 */
export function loginUserAnonymAction() {
  return async (dispatch, getState) => {
    try {
      // ** We check if the navigator already stored the email
      const userEmail = selectUserEmail(getState());
      const userByEmail = await getRegistredUserByParam(
        getParamFirestore("email", userEmail)
      );
      if (userByEmail) {
        dispatch(saveUser(userByEmail));
        return userByEmail;
      }
      // ** We check if the navigator already stored the uid
      const userUID = selectUserUID(getState());
      const userByUID = await getRegistredUserByParam(
        getParamFirestore("uid", userUID)
      );
      if (userByUID) {
        dispatch(saveUser(userByUID));
        return userByUID;
      }
      // ** Otherwise we create an anonym user
      const newUser = await newUserAnonym();
      dispatch(saveUser(newUser));
      return newUser;
    } catch (error) {
      console.error(Errors.USER.SERVER_ON_LOGIN, error);
      return null;
    }
  };
}

export async function sendRecapMailFromQuestionnaireForm(
  uid,
  emailValue,
  firstName,
  userAnswers,
  aid,
  hydratedSolutionsResultsValue
) {
  const {
    responsesTextsForEmailTemplate,
    solutionsTextsForEmailTemplate,
    suggestionsTextsForEmailTemplate,
  } = getTextsForEmailTemplate(userAnswers, hydratedSolutionsResultsValue);

  await sendRecapMail(
    uid,
    emailValue,
    firstName,
    solutionsTextsForEmailTemplate,
    suggestionsTextsForEmailTemplate,
    responsesTextsForEmailTemplate
  );
}

/**
 * Function that subcribes the user by saving its email in database and in sendinblue and saving his answers
 *
 * @param {string} uid the id of user
 * @param {string} emailValue value of the email
 * @param {Object} user entity of the user containing all his infos
 * @param {Object} userAnswers map of questions and answers in the form
 * @param {string} sendInBlueStarAttribute the name of sendinblue attribute
 *
 * @returns user payload if success or null if an error is occurred
 */
export function subscribeUserAction(
  uid,
  emailValue,
  user,
  userAnswers,
  sendInBlueStarAttribute
) {
  return async (dispatch, getState) => {
    try {
      // We can decompose this block to a function
      // ** Loading user if email exists in data base
      const userByEmail = await getRegistredUserByParam(
        getParamFirestore("email", emailValue)
      );

      const addedEmail = {
        date: new Date().toLocaleString("fr-Fr"),
        email: emailValue,
      };

      const userAnswers = selectUserAnswers(getState());

      const newAllEmails = [...(user?.allEmails || []), addedEmail];

      const userToUpdate = {
        ...UserUpdateFactory(user),
        uid: userByEmail?.uid || uid,
        createDate: userByEmail?.createDate || user.createDate,
        email: emailValue,
        allEmails: newAllEmails,
      };

      const age = getAnswersByQid(userAnswers, "q1a")?.[0]?.value || "";

      const status_pro = getAnswersByQid(userAnswers, "q1b")?.[0]?.value || "";

      // ** Sending the email in sendinblue subscription list
      await subscribeToSendinblue(
        SendInBlueSourceEnum.QUESTIONNAIRE,
        userToUpdate.email,
        {
          prenom: userToUpdate.firstName,
          sendInBlueStarAttribute,
          age,
          status_pro,
        }
      );

      await updateUser(userToUpdate.uid, userToUpdate);

      updateUserQuestionnaire(userToUpdate.uid, userAnswers, false);

      dispatch(saveUser(userToUpdate));

      return userToUpdate;
    } catch (error) {
      console.error(Errors.USER.SERVER_ON_UPDATE, error, { uid, user });
    }
    return null;
  };
}

/**
 * Function that subcribes the user in the step of questionnaire
 *
 * @param {string} uid the id of user
 * @param {Object} user entity of the user containing all his infos
 * @param {Object} userAnswers map of questions and answers in the form
 * @returns user payload if success or null if an error is occurred
 */
export function subscribeUserQuestionnaireFormAction(values, history) {
  return async (dispatch, getState) => {
    const { emailForm, aid } = values;

    const groupStar = MapBranchIdGroupStar[aid];

    subscribeDataGA(SubscribeAnalyticSourceEnum.QUESTIONNAIRE, emailForm);

    const sendInBlueStarAttribute = sendInBlueData[groupStar].attribute;

    const user = selectUser(getState());

    const userAnswers = selectUserAnswers(getState());

    history.push(`${Path["path.questionnaire.loader"]}?email=${emailForm}`);

    // use Promise.allSettled to wait for both tasks to settle
    Promise.allSettled([
      dispatch(
        subscribeUserAction(
          user.uid,
          emailForm,
          user,
          userAnswers,
          sendInBlueStarAttribute
        )
      ),
      delayPromise(QUESTIONNAIRE_LOAD_TIME).then(() => ({})),
      dispatch(saveUserSolutionsAndDiagnostics(user.uid, aid, groupStar)),
    ])
      .then((results) => {
        // check if any task failed
        let hydratedSolutionsResultsValue = null;

        const failedTasks = results.filter((result) => {
          if (result.value?.hydratedSolutionsResultsValue) {
            hydratedSolutionsResultsValue =
              result.value?.hydratedSolutionsResultsValue;
          }
          return result.status === "rejected";
        });
        if (failedTasks.length > 0) {
          // if any task failed, throw an error to stop the other task
          console.error(`Task ${failedTasks[0].index + 1} failed`);
          history.goBack();
        } else {
          if (hydratedSolutionsResultsValue) {
            // Get Results and then send the mail
            sendRecapMailFromQuestionnaireForm(
              user.uid,
              emailForm,
              user.firstName,
              userAnswers,
              aid,
              hydratedSolutionsResultsValue
            );
          }
          // if both tasks succeeded, use Promise.race to return the first resolved value
          history.push(`${Path["path.solutions"]}/${aid}`);
        }
      })
      .catch((error) => {
        console.error(error.message);
      });
  };
}

export function goToSolutionPageWithoutSubcriptionAction(aid, history) {
  return async (dispatch, getState) => {
    goDirectlyToSolutionsPageDataGA();

    const groupStar = MapBranchIdGroupStar[aid];

    const userId = selectUserUID(getState());

    history.push(Path["path.questionnaire.loader"]);

    // use Promise.allSettled to wait for both tasks to settle
    Promise.allSettled([
      delayPromise(QUESTIONNAIRE_LOAD_TIME).then(() => ({})),
      dispatch(saveUserSolutionsAndDiagnostics(userId, aid, groupStar)),
    ])
      .then((results) => {
        // check if any task failed
        const failedTasks = results.filter(
          (result) => result.status === "rejected"
        );

        if (failedTasks.length > 0) {
          // if any task failed, throw an error to stop the other task
          console.error(`Task ${failedTasks[0].index + 1} failed`);
          history.goBack();
        } else {
          // if both tasks succeeded, use Promise.race to return the first resolved value
          history.push(`${Path["path.solutions"]}/${aid}`);
        }
      })
      .catch((error) => {
        console.error(error.message);
      });
  };
}

/**
 * Function that update the questionnaire of user (questions & answers)
 *
 * @param {Boolean} isUserAnonym boolean whether the user is rgpd compliant or not
 * @param {Object} questionnaire map of questions and answers in the form
 * @returns {void}
 */
export function updateUserQuestionnaireAction(
  isUserAnonym = true,
  questionnaire
) {
  return async (dispatch, getState) => {
    const userId = selectUserUID(getState());
    const questionWithAnswers = selectUserAnswers(getState());

    try {
      // Saving in database the questionnaire
      await updateUserQuestionnaire(
        userId,
        questionnaire || questionWithAnswers,
        isUserAnonym
      );

      // Saving the questionnaire in the redux store
      dispatch(
        saveUserQuestionnaire(
          getQuestionnaireAnswersWithRgpdRules(
            questionnaire || questionWithAnswers,
            isUserAnonym
          )
        )
      );
    } catch (error) {
      console.error(Errors.USER.SERVER_ON_UPDATE_QUESTIONS, error, {
        userId,
        questionnaire,
        isUserAnonym,
      });
    }
  };
}

/**
 * Function that saves the suggested solutions of user and all diagnostics linked to the user
 *
 * @param {string} uid the id of user
 * @param {string} branchId the id of branch
 * @returns
 */
export function saveUserSolutionsAndDiagnostics(uid, branchId, groupStar) {
  return async (dispatch, getState) => {
    try {
      const treeTokenId = selectTokenTreeId(getState());
      const authTokenId = selectAuthTokenBackoffice(getState());

      const userAnswers = selectUserAnswers(getState());

      const questionAnswerMap = getQuestionAnswers(userAnswers);

      const sector = getAnswersByQid(userAnswers, "q2");

      // Getting hydrated solutions from server
      const hydratedSolutionsResultsValue = await getAllSolutionsFromServer(
        authTokenId,
        treeTokenId,
        questionAnswerMap,
        sector,
        branchId
      );

      // Saving hydrated solutions in the redux store
      dispatch(saveHydratedSolutions(hydratedSolutionsResultsValue));

      const questionnaire = selectUserQuestionnaire(getState());

      const analyticSolutions = selectUserAnalyticSolutions(getState());

      const dignostic = {
        questionnaire,
        solutions: hydratedSolutionsResultsValue,
        date: new Date().toLocaleString("fr-Fr"),
        groupStar,
        analyticSolutions,
      };

      const newDiagnostics = {
        ...(selectUserDiagnostics(getState()) || {}),
        [branchId + "-" + new Date().getTime()]: dignostic,
      };

      await updateUser(uid, {
        solutions: hydratedSolutionsResultsValue,
        updateDate: new Date().toLocaleString("fr-Fr"),
        allDiagnostics: newDiagnostics,
      });

      // Saving the diagnostic in the redux store
      dispatch(saveAllDiagnostics(newDiagnostics));

      return {
        hydratedSolutionsResultsValue,
        allDiagnostics: newDiagnostics,
      };
    } catch (error) {
      console.error(Errors.USER.SERVER_ON_UPDATE_SOLUTIONS, error, {
        uid,
      });
      throw new Error(error);
    }
  };
}

/**
 * Function that add info analytic of clicked solutions of user
 *
 * @returns void
 */
export async function updateUserAnalyticsSolutions(
  userUID,
  solutionName,
  isPrincipal = true,
  hasEmail = true
) {
  try {
    const attributeName = `${
      isPrincipal ? "principal" : "secondary"
    }SolutionsClickCountsByName`;

    const userByUID = await getRegistredUserByParam(
      getParamFirestore("uid", userUID)
    );

    const analyticSolutionsValue = userByUID?.analyticSolutions || {};

    if (solutionName) {
      const userPayload = {
        analyticSolutions: {
          ...analyticSolutionsValue,
          hasEmail,
          [attributeName]: {
            ...(analyticSolutionsValue[attributeName] || {}),
            [solutionName]:
              (analyticSolutionsValue[attributeName]?.[solutionName] || 0) + 1,
          },
        },
      };
      await updateUser(userUID, userPayload);
    }
  } catch (error) {
    console.error(Errors.USER.SERVER_ON_UPDATE, error, {
      userUID,
      solutionName,
    });
  }
}

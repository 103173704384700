import React from "react";
import Icon from "@mdi/react";

import MdiIconButtonStyles from "./MdiIconButton.module.scss";

const MdiIconButton = ({
  color,
  mdiIcon,
  buttonType,
  onClick,
  buttonStyles,
  iconStyles,
  iconWidth,
  iconHeight,
}) => {
  return (
    <>
      {mdiIcon && (
        <button
          type={buttonType}
          style={buttonStyles}
          className={MdiIconButtonStyles.buttonIcon}
        >
          <Icon
            path={mdiIcon}
            color={color}
            onClick={onClick}
            style={{ width: iconWidth, height: iconHeight, ...iconStyles }}
          />
        </button>
      )}
    </>
  );
};

MdiIconButton.defaultProps = {
  color: "#013332",
  buttonType: "button",
  onClick: () => ({}),
  buttonStyles: {},
  iconStyles: {},
  iconWidth: "2.5rem",
  iconHeight: "2.5rem",
};

export default MdiIconButton;

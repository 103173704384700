import React, { useEffect } from "react";
import { Route, Switch, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import {
  selectInterviewLastProgressNumber,
  selectIsCurrentInterviewLoading,
  selectProfileSkillsFromCurrentInterview,
} from "store/interview/interviewSelectors";
import { setCurrentInterviewLoadingState } from "store/interview/interviewSlice";

import InterviewPageEnum from "utils/constants/enums/InterviewPageEnum";

import InterviewGenericPage from "components/common/InterviewGenericPage";
import InterviewStart from "components/Interviews/InterviewStart";
import InterviewMailScreen from "components/Interviews/InterviewMailScreen";
import Redirect from "components/common/Redirect";
import InterviewCompanyActivitiesForm from "components/Interviews/InterviewCompanyActivitiesForm";
import InterviewCompanyValuesForm from "components/Interviews/InterviewCompanyValuesForm";
import InterviewCompanySimulationForm from "components/Interviews/InterviewCompanySimulationForm";
import InterviewAdvice from "components/Interviews/InterviewAdvice";
import InterviewJobDetails from "components/Interviews/InterviewJobDetails/InterviewJobDetails";
import InterviewJobSoftSkillsForm from "../../components/Interviews/InterviewJobSoftSkillsForm";
import InterviewJobChallengesForm from "components/Interviews/InterviewJobChallengesForm";
import InterviewJobBestofForm from "../../components/Interviews/InterviewJobBestofForm";
import InterviewSummary from "components/Interviews/InterviewSummary/InterviewSummary";
import InterviewSkillRatePage from "../../components/Interviews/InterviewSkillRatePage";
import InterviewSkillExamplePage from "components/Interviews/InterviewSkillExamplePage";
import InterviewFinish from "../../components/Interviews/InterviewFinish";
import CircularLoaderPage from "components/common/CircularLoaderPage";
import { LastProgressNumberEnum } from "utils/constants/enums/LastProgressNumberEnum";
import { getNextPageDynamically } from "utils/helpers/profileSkillsHelpers";

function InterviewPage() {
  const { t } = useTranslation("path");
  const {
    location: { pathname, search },
    push,
  } = useHistory();
  const dispatch = useDispatch();
  const lastProgressNumber = useSelector(selectInterviewLastProgressNumber);
  const profileSkills = useSelector(selectProfileSkillsFromCurrentInterview);
  const isCurrentInterviewLoading = useSelector(
    selectIsCurrentInterviewLoading
  );

  useEffect(() => {
    dispatch(setCurrentInterviewLoadingState(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!isCurrentInterviewLoading) {
      if (
        !lastProgressNumber ||
        (pathname === t("path.interview") &&
          lastProgressNumber === LastProgressNumberEnum.InterviewEnd[1])
      ) {
        push(t("path.interview") + search);
        return;
      }

      const nextPage = getNextPageDynamically(
        profileSkills,
        lastProgressNumber
      );

      if (pathname !== nextPage) {
        push(nextPage + search);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastProgressNumber, isCurrentInterviewLoading, profileSkills]);

  return (
    <>
      {isCurrentInterviewLoading && <CircularLoaderPage />}
      <Switch>
        <Route exact path={t("path.interview")}>
          <InterviewGenericPage page={InterviewPageEnum.HOME} />
        </Route>
        <Route path={t("path.interview.start")}>
          <InterviewStart />
        </Route>
        <Route path={t("path.interview.email")}>
          <InterviewMailScreen />
        </Route>
        <Route path={t("path.interview.finish")}>
          <InterviewFinish />
        </Route>
        <Route path={t("path.interview.company")}>
          <Switch>
            <Route path={t("path.interview.company.question1")}>
              <InterviewCompanyActivitiesForm />
            </Route>
            <Route path={t("path.interview.company.advice1")}>
              <InterviewAdvice advicePage={InterviewPageEnum.EC} page={1} />
            </Route>
            <Route path={t("path.interview.company.question2")}>
              <InterviewCompanyValuesForm />
            </Route>
            <Route path={t("path.interview.company.advice2")}>
              <InterviewAdvice advicePage={InterviewPageEnum.EC} page={2} />
            </Route>
            <Route path={t("path.interview.company.question3")}>
              <InterviewCompanySimulationForm />
            </Route>
            <Route path={t("path.interview.company.advice3")}>
              <InterviewAdvice advicePage={InterviewPageEnum.EC} page={3} />
            </Route>
            <Route path={t("path.interview.company.step")}>
              <InterviewGenericPage page={InterviewPageEnum.COMPANY} />
            </Route>
            <Redirect to={t("path.interview")} />
          </Switch>
        </Route>
        <Route path={t("path.interview.job")}>
          <Switch>
            <Route path={t("path.interview.job.question1")}>
              <InterviewJobDetails />
            </Route>
            <Route path={t("path.interview.job.advice1")}>
              <InterviewAdvice advicePage={InterviewPageEnum.PC} page={1} />
            </Route>
            <Route path={t("path.interview.job.question2")}>
              <InterviewJobSoftSkillsForm />
            </Route>
            <Route path={t("path.interview.job.advice2")}>
              <InterviewAdvice advicePage={InterviewPageEnum.PC} page={2} />
            </Route>
            <Route path={t("path.interview.job.question4")}>
              <InterviewJobChallengesForm />
            </Route>
            <Route path={t("path.interview.job.advice3")}>
              <InterviewAdvice advicePage={InterviewPageEnum.PC} page={3} />
            </Route>
            <Route path={t("path.interview.job.question3")}>
              <InterviewJobBestofForm />
            </Route>
            <Route path={t("path.interview.job.step")}>
              <InterviewGenericPage page={InterviewPageEnum.JOB} />
            </Route>
          </Switch>
        </Route>
        <Route path={t("path.interview.profile")}>
          <Switch>
            <Route path={t("path.interview.profile.question1")}>
              <InterviewSkillRatePage />
            </Route>
            <Route path={t("path.interview.profile.question2")}>
              <InterviewSkillExamplePage />
            </Route>
            <Route path={t("path.interview.profile.advice")}>
              <InterviewAdvice advicePage={InterviewPageEnum.SC} />
            </Route>
            <Route path={t("path.interview.profile.step")}>
              <InterviewGenericPage page={InterviewPageEnum.PROFILE} />
            </Route>
          </Switch>
        </Route>
        <Route path={t("path.interview.summary")}>
          <InterviewSummary />
        </Route>
        <Redirect to={t("path.interview")} />
      </Switch>
    </>
  );
}

export default InterviewPage;

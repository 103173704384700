import React from "react";
import clsx from "clsx";
import { useSelector } from "react-redux";
import { Trans } from "react-i18next";
import Tag from "components/common/Tag";
import clickSuggestionSolutionDataGA from "config/googleAnalytics/clickSuggestionSolutionDataGA";
import "owl.carousel/dist/assets/owl.theme.default.css";
import "owl.carousel/dist/assets/owl.carousel.css";
import "components/common/Carousels/carousel.owl.custom.scss";
import carouselsStyles from "components/common/Carousels/Carousels.module.scss";
import Button from "components/common/Button(ToBeUpdated)";
import Interweave from "interweave";
import {
  selectUserEmail,
  selectUserFirstName,
  selectUserUID,
} from "store/users/usersSelectors";
import { goToSolutionLinkCondition } from "domain/solution/rules";
import { updateUserAnalyticsSolutions } from "domain/user/userControllers";

// Must tag as obsolete file
const CardContent = ({
  type,
  item,
  index,
  openMailModal,
  setSelectedSolution,
}) => {
  const userEmail = useSelector(selectUserEmail);
  const userFirstName = useSelector(selectUserFirstName);
  const userId = useSelector(selectUserUID);

  if (type === "star") {
    const iconStyle = {
      width: item.icon.width ? item.icon.width : carouselsStyles.iconWidth,
    };
    return (
      <div className={"owl-content"}>
        <img
          loading="lazy"
          className={"owl-icon"}
          style={iconStyle}
          src={item.icon}
          alt={item.title}
        />
        <div>
          <Trans>
            <b className={"owl-titre"}>{item.title}</b>
          </Trans>
        </div>
      </div>
    );
  } else if (type === "accompanier") {
    return (
      <div>
        <Trans parent={null}>
          <b>{item.name + ", " + item.age}</b>
          <br />
          <br />“{item.expr}"<br />
          <br />
        </Trans>
        <div>
          {item.tags.map((tag, index) => (
            <>
              <Tag
                text={tag.text}
                width={2 * tag.text.length}
                height={26}
                bgColor={tag.bgColor}
                txColor={carouselsStyles.whiteColor}
                radius={6}
              />
              <br />
            </>
          ))}
        </div>
      </div>
    );
  } else if (type === "suggestions") {
    return (
      <div className={carouselsStyles.suggestionsContent}>
        <div
          className={clsx(
            index % 4 === 1 &&
              carouselsStyles.suggestionsImageContainerBgMandarine,
            index % 4 === 2 && carouselsStyles.suggestionsImageContainerBgGreen,
            index % 4 === 3 && carouselsStyles.suggestionsImageContainerBgCyan,
            index % 4 === 0 && carouselsStyles.suggestionsImageContainerBgPink
          )}
        >
          <img
            loading="lazy"
            src={item.srcLogo}
            alt={item.title}
            className={carouselsStyles.suggestionsImage}
          />
        </div>

        <div className={carouselsStyles.suggestionsTextContainer}>
          <Trans parent={null}>
            <p
              className={carouselsStyles.suggestionsTextTitle}
            >{`${item.name} - ${item.partner}`}</p>
            <br />
            <p className={carouselsStyles.suggestionsTextDescription}>
              {item.descriptions?.[1] && (
                <Interweave content={item.descriptions[1]} />
              )}
            </p>
            <br />
            <br />
          </Trans>
        </div>
        <Button
          onClick={() => {
            console.log("hello sug");
            clickSuggestionSolutionDataGA({
              solutionNom: item.title,
              mailLaisse: Boolean(userEmail),
            });
            updateUserAnalyticsSolutions(
              userId,
              item.title,
              false,
              Boolean(userEmail)
            );
            if (
              goToSolutionLinkCondition({
                title: item.title,
              })
            ) {
              window
                .open(
                  `${item.link}?name=${userFirstName}&email=${userEmail}`,
                  "_self"
                )
                .focus();
            } else if (userEmail) {
              window.open(item.link, "_blank").focus();
            } else {
              openMailModal(true);
              setSelectedSolution({
                title: item.title,
                link: item.link,
              });
            }
          }}
          text={"Découvrir"}
          width={80}
          mode={"suggestions"}
        />
      </div>
    );
  } else {
    return null;
  }
};

export default React.memo(CardContent);

const MuiTabs = {
  styleOverrides: {
    root: ({ theme }) => ({
      width: "100%",
      "& .MuiTabs-indicator": {
        display: "none",
      },
    }),
  },
};

export const MuiTab = {
  styleOverrides: {
    root: ({ ownerState, theme }) => {
      const {
        palette: {
          tab,
          tabFoundersPartners: {
            tabBackgroundColor,
            tabSelectedBackgroundColor,
            tabSelectedTextColor,
            tabTextColor,
          },
        },
        typography: { ParagraphStyles },
        variables: {
          tabFoundersPartners: { tabBorderRadius, tabPadding },
        },
      } = theme;

      return {
        ...(ownerState.tabtype === "tabGreen" && {
          ...ParagraphStyles,
          fontWeight: 700,
          fontSize: ParagraphStyles.fontSizes.sm,
          borderRadius: "20px 20px 0px 0px",
          color: tab.green.textColor,
          width: "25%",
          height: "82px",
          padding: theme.spacing(1),
          transition: "0.3s ease-out",
          "&:hover": {
            backgroundColor: tab.green.hover.backgroundColor,
          },
          "&.Mui-selected": {
            backgroundColor: tab.green.selected.backgroundColor,
            color: tab.green.selected.textColor,
          },
        }),
        ...(ownerState.tabtype === "tabFoundersPartners" && {
          ...ParagraphStyles,
          textTransform: "Capitalize",
          backgroundColor: tabBackgroundColor,
          marginRight: "18px",
          borderRadius: tabBorderRadius,
          padding: tabPadding,
          color: tabTextColor,
          "&.Mui-selected": {
            backgroundColor: tabSelectedBackgroundColor,
            color: tabSelectedTextColor,
          },
        }),
      };
    },
  },
};

export default MuiTabs;

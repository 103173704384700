import { GoogleAnalyticsEvents } from "./../analyticsConfig";

const continueToSolutionDataGA = ({ solutionNom, mailLaisse }) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: GoogleAnalyticsEvents.LienVersSolution,
    solutionNom,
    mailLaisse,
  });
};

export default continueToSolutionDataGA;

const FontFamilies = {
  DMSerif: "DMSerifText",
  DMSerifItalic: "DMSerifTextItalic",
  ProximaNova: "ProximaNova",
  ProximaNovaMedium: "ProximaNovaMedium",
  ProximaNovaItalic: "ProximaNovaItalic",
  ProximaNovaBold: "ProximaNovaBold",
  ProximaNovaBoldItalic: "ProximaNovaBoldItalic",
  ProximaNovaSemiBold: "ProximaNovaSemiBold",
  ProximaNovaSemiBoldItalic: "ProximaNovaSemiBoldItalic",
};

const FontStyles = {
  normal: "normal",
  italic: "italic",
  oblique: "oblique",
};

const FontSizes = {
  xs: "12px",
  sm: "14px",
  md: "16px",
  lg: "18px",
  xl: "20px",
  xl2: "24px",
  xl3: "26px",
  xl4: "28px",
  xl5: "32px",
  xl6: "36px",
  xl7: "48px",
  xl8: "62px",
};

const HeadingStyles = {
  fontFamily: FontFamilies.DMSerif,
  fontStyle: FontStyles.normal,
  fontWeight: 400,
  lineHeight: "100%",
  fontSizes: {
    xs: "12px",
    sm: "14px",
    md: "16px",
    lg: "18px",
    xl: "20px",
    xl2: "24px",
    xl3: "26px",
    xl4: "28px",
    xl5: "32px",
    xl6: "36px",
    xl7: "48px",
    xl8: "62px",
  },
};

const SubtitleStyles = {
  fontFamily: FontFamilies.ProximaNovaBold,
  fontStyle: FontStyles.normal,
  fontWeight: 700,
  lineHeight: "160%",
  fontSizes: {
    xs: "10px",
    sm: "12px",
    md: "14px",
    lg: "16px",
    xl: "18px",
    xl2: "20px",
    xl3: "22px",
    xl4: "24px",
  },
};

const ParagraphStyles = {
  fontFamily: FontFamilies.ProximaNova,
  fontStyle: FontStyles.normal,
  fontWeight: 400,
  lineHeight: "140%",
  fontSizes: {
    xs: "14px",
    sm: "16px",
    md: "18px",
    lg: "20px",
    xl: "24px",
    xl2: "36px",
    xl3: "64px",
  },
};

const NavigationStyles = {
  fontFamily: FontFamilies.ProximaNova,
  fontStyle: FontStyles.normal,
  fontWeight: 500,
  lineHeight: "140%",
  fontSizes: {
    xs: "12px",
    sm: "14px",
    md: "16px",
    lg: "18px",
    xl: "20px",
  },
};

const typography = {
  HeadingStyles,
  SubtitleStyles,
  ParagraphStyles,
  NavigationStyles,
  FontSizes,
  FontStyles,
  FontFamilies,
  h1: {
    ...HeadingStyles,
    fontSize: HeadingStyles.fontSizes.xl8,
    color: "black",
    "@media (max-width: 576px)": {
      fontSize: HeadingStyles.fontSizes.xl3,
      lineHeight: "140%",
    },
  },
  h2: {
    ...HeadingStyles,
    fontSize: HeadingStyles.fontSizes.xl6,
    color: "black",
    "@media (max-width: 576px)": {
      fontSize: HeadingStyles.fontSizes.xl,
    },
  },
  h3: {
    ...ParagraphStyles,
    fontSize: ParagraphStyles.fontSizes.lg,
    "@media (max-width: 576px)": {
      fontSize: ParagraphStyles.fontSizes.xs,
    },
    letterSpacing: "0.5px",
  },

  paragraph: {
    ...ParagraphStyles,
    fontSize: ParagraphStyles.fontSizes.sm,
    "@media (max-width: 576px)": {
      fontSize: ParagraphStyles.fontSizes.xs,
    },
    letterSpacing: "0.5px",
  },
  titleSolutionCard: {
    ...ParagraphStyles,
    fontWeight: 700,
    fontSize: ParagraphStyles.fontSizes.xl,
    "@media (max-width: 576px)": {
      fontSize: ParagraphStyles.fontSizes.md,
    },
  },
  homePage: {
    starsSectionSubtitle: {
      ...SubtitleStyles,
      fontFamily: FontFamilies.ProximaNovaMedium,
      fontSize: SubtitleStyles.fontSizes.xl3,
      "@media (max-width: 576px)": {
        fontSize: SubtitleStyles.fontSizes.lg,
      },
    },
  },
  missionPage: {
    subTitleStyles: {
      ...HeadingStyles,
      fontSize: HeadingStyles.fontSizes.xl6,
      width: "21%",
      "@media (max-width: 768px)": {
        width: "100%",
        lineHeight: "140%",
        fontSize: HeadingStyles.fontSizes.xl,
      },
    },
    missionDescriptionStyles: {
      width: "80%",
      fontSize: ParagraphStyles.fontSizes.lg,
      textAlign: "center",
      "@media (max-width: 576px)": {
        width: "90%",
      },
    },
    observationsNumberStyles: {
      ...ParagraphStyles,
      fontSize: ParagraphStyles.fontSizes.xl3,
      textTransform: "uppercase",
      fontWeight: 700,
      lineHeight: "100%",
      textAlign: "center",
      "@media (max-width: 576px)": {
        width: "100%",
        fontSize: ParagraphStyles.fontSizes.xl2,
        lineHeight: "140%",
      },
    },
    observationsDescriptionStyles: {
      ...ParagraphStyles,
      fontSize: ParagraphStyles.fontSizes.lg,
      textAlign: "center",
      "@media (max-width: 576px)": {
        width: "100%",
        fontSize: ParagraphStyles.fontSizes.xs,
        lineHeight: "140%",
      },
    },
    observationsSourceStyles: {
      ...ParagraphStyles,
      fontSize: ParagraphStyles.fontSizes.sm,
      textAlign: "center",
    },
    shortVideosDescriptionFontFamily: FontFamilies.ProximaNovaBold,
  },
  modalMail: {
    titleStyles: {
      ...ParagraphStyles,
      fontWeight: 700,
    },
    titleFontSizeDesktop: ParagraphStyles.fontSizes.md,
    titleFontSizeMobile: ParagraphStyles.fontSizes.sm,
    termsStyles: {
      ...ParagraphStyles,
      fontSize: "12px",
    },
  },
  teamPage: {
    nameFontSizeDesktop: ParagraphStyles.fontSizes.md,
    profileTextFontsizeDesktop: ParagraphStyles.fontSizes.sm,
    profileFamilyWeightDesktop: FontFamilies.ProximaNovaBold,
    titleStylesDesktop: {
      ...HeadingStyles,
      fontSize: HeadingStyles.fontSizes.xl6,
      "@media (max-width: 576px)": {
        fontSize: HeadingStyles.fontSizes.xl,
      },
    },
    //mavoieTeam
    descriptionTextStyles: {
      fontSize: ParagraphStyles.fontSizes.md,
      "@media (max-width: 576px)": {
        fontSize: ParagraphStyles.fontSizes.xs,
      },
    },
    //teamMember
    nameMarginRightDesktop: 2,
    nameMarginLeftDesktop: 6,
  },
  solutionCard: {
    subtitle: {
      ...SubtitleStyles,
      fontSize: SubtitleStyles.fontSizes.lg,
    },
  },
};

export default typography;

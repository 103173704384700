const SourceClickRDVEnum = {
  HOMEPAGE_FROM_ROLE_SECTION: "RDV Homepage Depuis CTA secondaire",
  HOMEPAGE_FROM_FOOTER: "RDV Homepage Depuis CTA footer",
  HOMEPAGE_FROM_CARD: "RDV Homepage Depuis CTA bannière",
  HOMEPAGE_FROM_FORMATION_SERVICE:
    "RDV Homepage Depuis CTA découvrir des formations",
  HOMEPAGE_FROM_COACH_SERVICE: "RDV Homepage Depuis CTA échanger avec qqun",
  QUESTIONNAIRE_FROM_SECTOR: "RDV Questionnaire Depuis secteur",
  QUESTIONNAIRE_FROM_RESUME: "RDV Questionnaire Depuis résumé",
  QUESTIONNAIRE_FROM_EMAIL: "RDV Questionnaire Depuis email",
  QUESTIONNAIRE_FROM_RESULT: "RDV Questionnaire Depuis résultats",
};

export default SourceClickRDVEnum;

import palette from "styles/theme/palette";

export const pillCardWithCTATheme = {
  interviewSection: {
    bgcolor: palette.orange.complementary,
  },
  calendlyHPSection: {
    bgcolor: palette.blue.complementary,
  },
  solutionsPage: {
    bgcolor: palette.blue.complementary,
  },
};

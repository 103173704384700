const DocumentTitleEnum = {
  HOMEPAGE:
    "MaVoie t'aide à trouver les clés pour réussir ta recherche d'emploi",
  CONTACT: "Contact",
  RESUME: "Résumé",
  FORM: {
    TEXT: "Formulaire",
    Q1A: "Trouve ta voie - Profil - Âge",
    Q1B: "Trouve ta voie - Profil - Statut professionnel",
    Q1C: "Trouve ta voie - Profil - Ville",
  },
  FORM_EMAIL: "Formulaire email",
  SOLUTIONS: "Solutions",
  DISCOVERY: "Rendez-vous découverte",
  MISSION: "MaVoie - Nos missions et actions dans l'insertion professionnelle",
  CGU: "CGU",
  TEAM: "L'équipe MaVoie",
  INTERVIEW: {
    HOME: "Accueil Entretien",
    COMPANY: "Étape Entreprise",
    JOB: "Étape Poste",
    PROFIL: "Étape Profil",
    START: "Fiche entretien",
    E1_ACTIVITY: "Entreprise - Activités",
    EC1_ADVICE: "Entreprise - Conseil 1",
    E2_VALUES: "Entreprise - Valeurs",
    EC2_ADVICE: "Entreprise - Conseil 2",
    E3_SIMULATION: "Entreprise - Simulation",
    EC3_ADVICE: "Entreprise - Conseil 3",
    P1_JOB: "Poste - fiche métier",
    PC1_ADVICE: "Poste - Conseil 1",
    P2_SOFTSKILLS: "Poste - Compétences transversales",
    PC2_ADVICE: "Poste - Conseil 2",
    P3_BESTOF: "Poste - Le meilleur du job",
    P4_CHALLENGES: "Poste - Les défis du job",
    PC3_ADVICE: "Poste - Conseil 3",
    F2_SUMMARY: "Résumé entrainement",
    C_SKILLRATE: "Compétence - Évaluation",
    CE_SKILL_EXAMPLE: "Compétence - Exemple",
    F1_FINISH: "Fin entraînement",
  },
};

export default DocumentTitleEnum;

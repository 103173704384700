import React, { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import clsx from "clsx";

import { selectJobDetailPoleEmploie } from "../../../store/interview/interviewSelectors";

import { LastProgressNumberEnum } from "../../../utils/constants/enums/LastProgressNumberEnum";
import { MapLastProgressNumberToPathnameEnum } from "../../../utils/constants/enums/MapLastProgressNumberToPathnameEnum";

import { useMediaQueries } from "../../../hooks/useMediaQueries";

import TemplateFormInterview from "../../common/TemplateFormInterview";

import InterviewSkillRatePageStyles from "./InterviewSkillRatePage.module.scss";
import { usePageStepCount } from "hooks/usePageStepCount";

const InterviewSkillRatePage = ({ setFieldValue, values, initialValues }) => {
  const { skills } = useSelector(selectJobDetailPoleEmploie);
  const { skillNumber } = useParams();
  // the hook pageStepCount, will check if we are in "competences" step
  // and accept the skill number as props to display step counter.
  const pageStepCount = usePageStepCount(skillNumber);
  const { isMobile, isTablet } = useMediaQueries();
  const { t } = useTranslation("interview");
  const { push } = useHistory();
  const {
    questionName,
    toolTipTextTitle,
    toolTipTextContent,
    rates,
    buttonLabelName,
  } = t("profileSkillRate", {
    returnObjects: true,
    skill: skills[skillNumber - 1],
    class: InterviewSkillRatePageStyles.label,
  });

  // Redirection to the finish page when the skill number is not included in the Profile Section of the map of last progress number to path
  if (
    !skills?.[skillNumber - 1] ||
    !MapLastProgressNumberToPathnameEnum[
      LastProgressNumberEnum.InterviewProfile.Q1[skillNumber]
    ]
  )
    push(
      MapLastProgressNumberToPathnameEnum[
        LastProgressNumberEnum.InterviewEnd[1]
      ].previousPage
    );

  useEffect(() => {
    const foundedSkill = initialValues?.rateList?.find(
      (rate) => rate.id === skillNumber
    );
    setFieldValue(
      "rate",
      typeof foundedSkill?.rate === "number" && foundedSkill.rate > -1
        ? foundedSkill.rate
        : -1
    );
    setFieldValue("id", foundedSkill?.id || null);
    setFieldValue("name", foundedSkill?.name || null);
  }, [setFieldValue, initialValues, skillNumber]);

  const isButtonDisabled = useMemo(() => {
    let isDisabled = true;
    if (initialValues?.rateList?.find((rate) => rate.id === skillNumber)) {
      isDisabled = false;
    }
    if (typeof values?.rate === "number" && values.rate > -1) {
      isDisabled = false;
    }
    return isDisabled;
  }, [values, initialValues, skillNumber]);

  const choices = useMemo(
    () =>
      rates.map((choice, id) => {
        // this is to pre-select depend of recorded value (if there is)
        // or select when user click on choice.
        const handleIsSelected = () => {
          if (values?.rate > -1) {
            return values.rate === id;
          }
          if (initialValues?.rateList?.length && !values?.rate?.id) {
            const skillNameDisplayed = skills[skillNumber - 1];
            const index = initialValues.rateList
              .map((skill) => skill.name)
              .indexOf(skillNameDisplayed);
            return initialValues.rateList[index]?.rate === id;
          }
        };
        return (
          <div
            key={id}
            onClick={() => {
              setFieldValue("rate", id);
              setFieldValue("id", skillNumber);
              setFieldValue("name", skills[skillNumber - 1]);
            }}
            className={clsx({
              [InterviewSkillRatePageStyles.choice]: true,
              [InterviewSkillRatePageStyles.choiceS]: isMobile,
              [InterviewSkillRatePageStyles.choiceM]: isTablet,
              [InterviewSkillRatePageStyles.selected]: handleIsSelected(),
            })}
          >
            {choice}
          </div>
        );
      }), // eslint-disable-next-line react-hooks/exhaustive-deps
    [rates]
  );

  return (
    <TemplateFormInterview
      pageStepCount={pageStepCount}
      questionName={questionName}
      toolTipTextTitle={pageStepCount === "1" ? toolTipTextTitle : ""}
      toolTipTextContent={pageStepCount === "1" ? toolTipTextContent : ""}
      isButtonDisabled={isButtonDisabled}
      buttonLabelName={buttonLabelName}
    >
      <div
        className={clsx({
          [InterviewSkillRatePageStyles.multiInputListL]: true,
          [InterviewSkillRatePageStyles.multiInputListS]: isMobile,
        })}
      >
        {choices}
      </div>
    </TemplateFormInterview>
  );
};

export default InterviewSkillRatePage;

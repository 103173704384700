export const MessageFactory = ({
  messageId,
  subject, // required
  text, // required if html not specified
  html, // required if text not specified
  amp,
  attachments,
}) => {
  const message = {};
  if (messageId) message.messageId = messageId;
  if (subject) message.subject = subject;
  if (text) message.text = text;
  if (html) message.html = html;
  if (amp) message.amp = amp;
  if (attachments) message.attachments = attachments;
  return message;
};

export const MailFactory = ({
  from, // per default (orientation@mavoie.org or mehdi@mavoie.org on dev)
  replyTo,
  to, // required
  toUids,
  cc,
  ccUIDS,
  bcc,
  headers,
  message, // required
}) => {
  const mail = {};
  if (from) mail.from = from;
  if (replyTo) mail.replyTo = replyTo;
  if (to) mail.to = to;
  if (toUids) mail.toUids = toUids;
  if (cc) mail.cc = cc;
  if (ccUIDS) mail.ccUIDS = ccUIDS;
  if (bcc) mail.bcc = bcc;
  if (headers) mail.headers = headers;
  if (message) mail.message = message;
  return mail;
};

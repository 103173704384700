import React from "react";
import { useTranslation } from "react-i18next";
import Interweave from "interweave";
import { Box, Typography, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/system";

import InterviewSectionStyles from "./InterviewSection.module.scss";

import PillCardWithCTA from "components/common/PillCardWithCTA";

const InterviewSection = () => {
  const { t } = useTranslation(["homePage", "path"]);
  const theme = useTheme();
  const isScreenDownSmall = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <section name="interviewSection">
      <PillCardWithCTA
        bgcolor={theme.pillCardWithCTA.interviewSection.bgcolor}
        pillLarge={t("interviewSection.pillLarge")}
        pillSmall={t("interviewSection.pillSmall")}
        pillAlt={t("interviewSection.imgPillAlt")}
        title={t("interviewSection.title")}
        buttonLink={t("path:path.interview")}
        buttonText={t("interviewSection.CTA")}
      >
        <Box mt={10} sx={{ width: isScreenDownSmall ? "100%" : "80%" }}>
          <Typography
            variant="paragraph"
            mb={14}
            sx={{
              [theme.breakpoints.down("sm")]: {
                fontSize: "14px",
              },
            }}
          >
            <Interweave content={t("interviewSection.paragraph.line1")} />
          </Typography>
        </Box>
        <ul className={InterviewSectionStyles.list}>
          <li>
            <Typography
              variant="paragraph"
              mb={14}
              sx={{
                [theme.breakpoints.down("sm")]: {
                  fontSize: "14px",
                },
              }}
            >
              <Interweave
                content={t("interviewSection.paragraph.bulletpoint1")}
              />
            </Typography>
          </li>
          <li>
            <Typography
              variant="paragraph"
              mb={14}
              sx={{
                [theme.breakpoints.down("sm")]: {
                  fontSize: "14px",
                },
              }}
            >
              <Interweave
                content={t("interviewSection.paragraph.bulletpoint2")}
              />
            </Typography>
          </li>
          <li>
            <Typography
              variant="paragraph"
              mb={14}
              sx={{
                [theme.breakpoints.down("sm")]: {
                  fontSize: "14px",
                },
              }}
            >
              <Interweave
                content={t("interviewSection.paragraph.bulletpoint3")}
              />
            </Typography>
          </li>
        </ul>
        <br />
        <Box sx={{ width: isScreenDownSmall ? "100%" : "80%" }}>
          <Typography
            variant="paragraph"
            mb={14}
            sx={{
              fontStyle: "italic",
              [theme.breakpoints.down("sm")]: {
                fontSize: "14px",
              },
            }}
          >
            <Interweave content={t("interviewSection.paragraph.line2")} />
          </Typography>
        </Box>
        <br />
      </PillCardWithCTA>
    </section>
  );
};

export default InterviewSection;

import React from "react";
import {
  Button,
  Breadcrumbs,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { dictionaryPathNames } from "utils/constants/navigationPathnamesConstant";
import Icon from "../Icon";

const MuiBreadcrumbs = ({ pathname }) => {
  let currentPath = pathname?.split("/").slice(1)[0];

  const { breakpoints } = useTheme();
  const isScreenDownSM = useMediaQuery(breakpoints.down("sm"));

  return (
    <Breadcrumbs
      separator={
        <Icon
          iconName="keyBoardArrowRight"
          iconSize={isScreenDownSM ? 12 : 14}
        />
      }
      aria-label="breadcrumb"
    >
      {(dictionaryPathNames[currentPath] || []).map((element) => {
        return element.id === currentPath ? (
          <Typography
            key={element.id}
            fontSize={isScreenDownSM ? " 12px" : "14px"}
            paddingY="8px"
            fontFamily="ProximaNovaBold"
          >
            {element.label}
          </Typography>
        ) : (
          <Button
            key={element.id}
            variant="text"
            color="black"
            size="large"
            href={element.path}
            sx={{ fontSize: isScreenDownSM ? "12px" : "14px", fontWeight: 400 }}
          >
            {element.label}
          </Button>
        );
      })}
    </Breadcrumbs>
  );
};

export default React.memo(MuiBreadcrumbs);

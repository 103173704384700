import React, { useMemo } from "react";
import { Page, Text, View, Document, Image } from "@react-pdf/renderer";
import { useTranslation } from "react-i18next";
import { v4 as uuidv4 } from "uuid";
import Interweave from "interweave";

import { getProfileSkillWithRateTranslationsForPdfUse } from "utils/helpers/profileSkillsHelpers";

import PDFImageLogo from "assets/images/interviewPDF-logo-1entretien1job.png";
import bulletPointImage from "assets/images/interview-summary-bullet-point.png";
import ToolTipIcon from "assets/images/interview-template-form-toolTip-icon.png";

import interviewPDFStyles from "./interviewPDFStyles";

const InterviewPDF = ({
  data: {
    jobContent,
    companyContent,
    interviewDateContent,
    activitiesContent,
    companyAdviceC1,
    valuesContent,
    companyAdviceC2,
    simulation,
    companyAdviceC3,
    interviewEmail,
    jobDomainContent,
    jobDescriptionsContent,
    jobSoftSkillsContent,
    jobBestOfContent,
    jobChallengesContent,
    jobAdviceC1,
    jobAdviceC2,
    jobAdviceC3,
    profileSkills,
  },
}) => {
  const { t } = useTranslation("interview");

  let number = 1;

  const {
    synthesisTitlePdf,
    synthesisTextPdf,
    informationsTitlePdf,
    jobLabelPdf,
    companyLabelPdf,
    dateLabelPdf,
    toolTipsTitlePdf,
    companyTitlePdf,
    companyActivitesPdf,
    companyValuesPdf,
    companySimulationPdf,
    emailLabelPdf,
    noAnswerPdf,
    profileTitlePdf,
    profileSkillSubTitlePdf,
    profileSkillsTextsPdf,
    bestOfTitlePdf,
    jobChallengeTitlePdf,
    softSkillsTitlePdf,
    jobTitlePdf,
  } = t("interviewPDF", {
    returnObjects: true,
    company: companyContent,
  });

  const profileSkillsReworked = useMemo(
    () =>
      profileSkills?.length > 0
        ? getProfileSkillWithRateTranslationsForPdfUse(
            profileSkills,
            profileSkillsTextsPdf
          )
        : [],
    [profileSkills, profileSkillsTextsPdf]
  );

  return (
    <Document>
      <Page wrap={true} size={"A4"} style={interviewPDFStyles.page}>
        <View style={interviewPDFStyles.container}>
          <View style={interviewPDFStyles.logoSection}>
            <Image src={PDFImageLogo} style={interviewPDFStyles.logo} />
          </View>
          <section name="generalInfo">
            <View style={interviewPDFStyles.synthesis}>
              <Text style={interviewPDFStyles.synthesisTitle}>
                {synthesisTitlePdf}
              </Text>
              <Text>{synthesisTextPdf}</Text>
            </View>
            <View style={interviewPDFStyles.informationsSection}>
              <Text style={interviewPDFStyles.informationsTitle}>
                {informationsTitlePdf}
              </Text>
              <View style={interviewPDFStyles.informationsJob}>
                <Text>{emailLabelPdf}</Text>
                <Text style={interviewPDFStyles.informationsContent}>
                  {interviewEmail}
                </Text>
              </View>
              <View style={interviewPDFStyles.informationsJob}>
                <Text>{jobLabelPdf}</Text>
                <Text style={interviewPDFStyles.informationsContent}>
                  {jobContent}
                </Text>
              </View>
              <View style={interviewPDFStyles.informationsJob}>
                <Text>{companyLabelPdf}</Text>
                <Text style={interviewPDFStyles.informationsContent}>
                  {companyContent}
                </Text>
              </View>
              <View style={interviewPDFStyles.informationsJob}>
                <Text>{dateLabelPdf}</Text>
                <Text style={interviewPDFStyles.informationsContent}>
                  {interviewDateContent}
                </Text>
              </View>
            </View>
          </section>
          <section name="company">
            <Text style={interviewPDFStyles.sectionTitle}>
              {companyTitlePdf}
            </Text>
            <View style={interviewPDFStyles.contentSection}>
              <Text style={interviewPDFStyles.contentTextSection}>
                {companyActivitesPdf}
              </Text>
              {activitiesContent?.map((activity) => (
                <View
                  key={uuidv4()}
                  style={interviewPDFStyles.bulletPointContent}
                >
                  <Image
                    src={bulletPointImage}
                    style={interviewPDFStyles.bulletPoint}
                  />
                  <Text>{activity}</Text>
                </View>
              ))}
              <View wrap={false} style={interviewPDFStyles.toolTips}>
                <View style={interviewPDFStyles.toolTipPosition}>
                  <Image
                    src={ToolTipIcon}
                    style={interviewPDFStyles.toolTipIcon}
                  />
                </View>
                <View wrap>
                  <Text style={interviewPDFStyles.toolTipsTitle}>
                    {toolTipsTitlePdf + number++}
                  </Text>
                  <Text style={interviewPDFStyles.adviceText}>
                    {companyAdviceC1}
                  </Text>
                </View>
              </View>
            </View>
            <View style={interviewPDFStyles.contentSection}>
              <Text style={interviewPDFStyles.contentTextSection}>
                {companyValuesPdf}
              </Text>
              {valuesContent?.length > 0 ? (
                valuesContent.map((value) => (
                  <View
                    key={uuidv4()}
                    style={interviewPDFStyles.bulletPointContent}
                  >
                    <Image
                      src={bulletPointImage}
                      style={interviewPDFStyles.bulletPoint}
                    />
                    <Text>{value}</Text>
                  </View>
                ))
              ) : (
                <>
                  <Text style={interviewPDFStyles.noAnswerLine}>
                    {noAnswerPdf?.[0]}
                  </Text>
                  <Text style={interviewPDFStyles.noAnswerLine}>
                    {noAnswerPdf?.[1]}
                  </Text>
                </>
              )}
              <View wrap={false} style={interviewPDFStyles.toolTips}>
                <View style={interviewPDFStyles.toolTipPosition}>
                  <Image
                    src={ToolTipIcon}
                    style={interviewPDFStyles.toolTipIcon}
                  />
                </View>
                <View wrap>
                  <Text style={interviewPDFStyles.toolTipsTitle}>
                    {toolTipsTitlePdf + number++}
                  </Text>
                  <Text style={interviewPDFStyles.adviceText}>
                    {companyAdviceC2}
                  </Text>
                </View>
              </View>
            </View>
            <View wrap={false} style={interviewPDFStyles.lastContentSection}>
              <Text style={interviewPDFStyles.contentTextSection}>
                {companySimulationPdf}
              </Text>
              {simulation ? (
                <Text style={interviewPDFStyles.lastContentSectionTextSection}>
                  {simulation}
                </Text>
              ) : (
                <>
                  <Text style={interviewPDFStyles.noAnswerLine}>
                    {noAnswerPdf?.[0]}
                  </Text>
                  <Text style={interviewPDFStyles.noAnswerLine}>
                    {noAnswerPdf?.[1]}
                  </Text>
                </>
              )}
              <View wrap={false} style={interviewPDFStyles.toolTips}>
                <View style={interviewPDFStyles.toolTipPosition}>
                  <Image
                    src={ToolTipIcon}
                    style={interviewPDFStyles.toolTipIcon}
                  />
                </View>
                <View wrap>
                  <Text style={interviewPDFStyles.toolTipsTitle}>
                    {toolTipsTitlePdf + number++}
                  </Text>
                  <Text style={interviewPDFStyles.adviceText}>
                    {companyAdviceC3}
                  </Text>
                </View>
              </View>
            </View>
          </section>
          <section name="job">
            <Text style={interviewPDFStyles.sectionTitle}>{jobTitlePdf}</Text>
            <View wrap={false} style={interviewPDFStyles.contentSection}>
              <Text style={interviewPDFStyles.titlePostSection}>
                {`${jobDomainContent} : ${jobContent}`}
              </Text>
              <Text style={interviewPDFStyles.descriptionPostSection}>
                <Interweave content={jobDescriptionsContent?.[0]} />
              </Text>
              <View wrap={false} style={interviewPDFStyles.toolTips}>
                <View style={interviewPDFStyles.toolTipPosition}>
                  <Image
                    src={ToolTipIcon}
                    style={interviewPDFStyles.toolTipIcon}
                  />
                </View>
                <View>
                  <Text style={interviewPDFStyles.toolTipsTitle}>
                    {toolTipsTitlePdf + number++}
                  </Text>
                  <Text style={interviewPDFStyles.adviceText}>
                    {jobAdviceC1}
                  </Text>
                </View>
              </View>
            </View>
            <View wrap={false} style={interviewPDFStyles.contentSection}>
              <Text style={interviewPDFStyles.contentTextSection}>
                {softSkillsTitlePdf}
              </Text>
              {jobSoftSkillsContent ? (
                jobSoftSkillsContent.map((softSkill) => (
                  <View
                    key={uuidv4()}
                    style={interviewPDFStyles.bulletPointContent}
                  >
                    <Image
                      src={bulletPointImage}
                      style={interviewPDFStyles.bulletPoint}
                    />
                    <Text>{softSkill}</Text>
                  </View>
                ))
              ) : (
                <>
                  <Text style={interviewPDFStyles.noAnswerLine}>
                    {noAnswerPdf?.[0]}
                  </Text>
                  <Text style={interviewPDFStyles.noAnswerLine}>
                    {noAnswerPdf?.[1]}
                  </Text>
                </>
              )}
              <View wrap={false} style={interviewPDFStyles.toolTips}>
                <View style={interviewPDFStyles.toolTipPosition}>
                  <Image
                    src={ToolTipIcon}
                    style={interviewPDFStyles.toolTipIcon}
                  />
                </View>
                <View>
                  <Text style={interviewPDFStyles.toolTipsTitle}>
                    {toolTipsTitlePdf + number++}
                  </Text>
                  <Text style={interviewPDFStyles.adviceText}>
                    {jobAdviceC2}
                  </Text>
                </View>
              </View>
            </View>
            <View wrap={false} style={interviewPDFStyles.contentSection}>
              <Text style={interviewPDFStyles.titlePostSection}>
                {bestOfTitlePdf}
              </Text>
              <Text style={interviewPDFStyles.descriptionPostSection}>
                {jobBestOfContent || (
                  <>
                    <Text style={interviewPDFStyles.noAnswerLine}>
                      {noAnswerPdf?.[0]}
                    </Text>
                    <Text style={interviewPDFStyles.noAnswerLine}>
                      {noAnswerPdf?.[1]}
                    </Text>
                  </>
                )}
              </Text>
              <Text style={interviewPDFStyles.titlePostSection}>
                {jobChallengeTitlePdf}
              </Text>
              <Text style={interviewPDFStyles.descriptionPostSection}>
                {jobChallengesContent || (
                  <View>
                    <Text style={interviewPDFStyles.noAnswerLine}>
                      {noAnswerPdf?.[0]}
                    </Text>
                    <br></br>
                    <Text style={interviewPDFStyles.noAnswerLine}>
                      {noAnswerPdf?.[1]}
                    </Text>
                  </View>
                )}
              </Text>
              <View wrap={false} style={interviewPDFStyles.toolTips}>
                <View style={interviewPDFStyles.toolTipPosition}>
                  <Image
                    src={ToolTipIcon}
                    style={interviewPDFStyles.toolTipIcon}
                  />
                </View>
                <View>
                  <Text style={interviewPDFStyles.toolTipsTitle}>
                    {toolTipsTitlePdf + number++}
                  </Text>
                  <Text style={interviewPDFStyles.adviceText}>
                    {jobAdviceC3}
                  </Text>
                </View>
              </View>
            </View>
          </section>
          <section name="profile">
            <Text style={interviewPDFStyles.sectionTitle}>
              {profileTitlePdf}
            </Text>
            <Text style={interviewPDFStyles.contentTextSection}>
              {profileSkillSubTitlePdf}
            </Text>
            {profileSkillsReworked?.map((profileSkill) => (
              <React.Fragment key={uuidv4()}>
                <View
                  wrap={false}
                  style={interviewPDFStyles.profileSkillContainer}
                >
                  <Text style={interviewPDFStyles.profileSkillTitle}>
                    {profileSkill.name}
                  </Text>
                  <Text style={interviewPDFStyles[profileSkill.styleTagName]}>
                    {profileSkill.rateTranslation}
                  </Text>
                </View>
                {profileSkill.hasExample &&
                profileSkill.showAdvice === false ? (
                  <View
                    wrap={false}
                    style={interviewPDFStyles.bulletPointContentProfile}
                  >
                    <View style={interviewPDFStyles.bulletPointProfile}>
                      <Image
                        src={bulletPointImage}
                        style={interviewPDFStyles.bulletPoint}
                      />
                    </View>
                    <Text>{profileSkill.example}</Text>
                  </View>
                ) : (
                  <View wrap={false} style={interviewPDFStyles.toolTips}>
                    <View style={interviewPDFStyles.toolTipPosition}>
                      <Image
                        src={ToolTipIcon}
                        style={interviewPDFStyles.toolTipIcon}
                      />
                    </View>
                    <View>
                      <Text style={interviewPDFStyles.toolTipsTitle}>
                        {toolTipsTitlePdf + number++}
                      </Text>
                      <Text style={interviewPDFStyles.adviceText}>
                        {profileSkill.tips?.[0]}
                      </Text>
                      <Text style={interviewPDFStyles.adviceText}>
                        {profileSkill.tips?.[1]}
                      </Text>
                    </View>
                  </View>
                )}
              </React.Fragment>
            ))}
          </section>
        </View>
      </Page>
    </Document>
  );
};

export default InterviewPDF;

import * as Yup from "yup";
import Errors from "utils/translations/en/errors.json";

import { regexEmail } from "utils/helpers/usersHelper";

export const QuestionnaireEmailFormName = "QuestionnaireEmailFormName";

export const ValidationSchemasEmail = Yup.object().shape({
  emailForm: Yup.string()
    .email(Errors.INTERVIEW.ERROR_FORMAT_EMAIL)
    .matches(regexEmail)
    .required(),
});

import { createTheme } from "@mui/material/styles";

import palette from "./palette";
import MuiButton from "./buttonTheme";
import MuiContainer from "./containerTheme";
import typography from "./typographyTheme";

import MuiLinearProgress from "./linearProgressTheme";
import MuiBreadcrumbs from "./breadcrumbsTheme";
import MuiPaper from "./paperTheme";
import MuiDrawer from "./drawerTheme";
import MuiTabs, { MuiTab } from "./tabsTheme";
import MuiIcon, { MuiIconButton } from "./iconTheme";
import MuiSnackbar from "./snackbarTheme";
import { MuiMenu } from "./menuTheme";
import { MuiMenuItem } from "./menuTheme";
import MuiInputBase from "./inputBaseTheme";

import variablesTheme from "./variablesTheme";
import { pillCardWithCTATheme } from "./pillCardWithCTATheme";

const theme = createTheme({
  spacing: 4, // applies to all measures
  variables: variablesTheme,
  breakpoints: {
    values: {
      xs: 0,
      sm: 576,
      md: 768,
      lg: 992,
      xl: 1200,
      xxl: 1400,
    },
  },
  shadowsImages: {
    small: {
      images: "16px 16px 0px 0px #00827F",
      neutral: "4px 4px 10px 0px rgba(0, 0, 0, 0.20)",
    },
    large: {
      images: "8px 8px 0px 0px #00827F",
      neutral: "2px 2px 10px 0px rgba(0, 0, 0, 0.20)",
    },
  },
  shadow: {
    xs: "{CSS box-shadow}",
    sm: "{CSS box-shadow}",
    md: "{CSS box-shadow}",
    lg: "{CSS box-shadow}",
    xl: "{CSS box-shadow}",
  },
  palette,
  typography,
  components: {
    MuiButton,
    MuiContainer,
    MuiLinearProgress,
    MuiBreadcrumbs,
    MuiPaper,
    MuiDrawer,
    MuiTab,
    MuiTabs,
    MuiIcon,
    MuiIconButton,
    MuiSnackbar,
    MuiMenu,
    MuiMenuItem,
    MuiInputBase,
  },
  sizes: {
    small: "small",
    large: "large",
  },
  pillCardWithCTA: pillCardWithCTATheme,
});

export default theme;

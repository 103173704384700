const MuiSnackbar = {
  styleOverrides: {
    root: ({ theme }) => ({
      padding: theme.spacing(1),
      margin: theme.spacing(2),
      color: theme.palette.text.primary,
      backgroundColor: theme.palette.background.paper,
      "&:hover": {
        backgroundColor: theme.palette.grey[200],
      },
    }),
  },
};

export default MuiSnackbar;

import Path from "utils/translations/fr/path.json";
import { LastProgressNumberEnum } from "./LastProgressNumberEnum";

//For next Button

export const MapLastProgressNumberToPathnameEnum = {
  [LastProgressNumberEnum.InterviewStart[0]]: {
    // Home
    previousPage: Path["path.interview"],
    nextPage: Path["path.interview.start"],
  },
  [LastProgressNumberEnum.InterviewStart[1]]: {
    // Fiche entretien
    previousPage: Path["path.interview.start"],
    nextPage: Path["path.interview.email"],
  },
  [LastProgressNumberEnum.InterviewStart[2]]: {
    //  Email
    previousPage: Path["path.interview.email"],
    nextPage: Path["path.interview.company.step"].replace(":step", "etape"),
  },

  [LastProgressNumberEnum.InterviewSteps[1]]: {
    // Company
    previousPage: Path["path.interview.company.step"].replace(":step", "etape"),
    nextPage: Path["path.interview.company.question1"],
  },
  // InterviewCompany Section:
  [LastProgressNumberEnum.InterviewCompany.Q1]: {
    previousPage: Path["path.interview.company.question1"],
    nextPage: Path["path.interview.company.advice1"],
  },
  [LastProgressNumberEnum.InterviewCompany.C1]: {
    previousPage: Path["path.interview.company.advice1"],
    nextPage: Path["path.interview.company.question2"],
  },
  [LastProgressNumberEnum.InterviewCompany.Q2]: {
    previousPage: Path["path.interview.company.question2"],
    nextPage: Path["path.interview.company.advice2"],
  },
  [LastProgressNumberEnum.InterviewCompany.C2]: {
    previousPage: Path["path.interview.company.advice2"],
    nextPage: Path["path.interview.company.question3"],
  },
  [LastProgressNumberEnum.InterviewCompany.Q3]: {
    previousPage: Path["path.interview.company.question3"],
    nextPage: Path["path.interview.company.advice3"],
  },
  [LastProgressNumberEnum.InterviewCompany.C3]: {
    previousPage: Path["path.interview.company.advice3"],
    nextPage: Path["path.interview.job.step"].replace(":step", "etape"),
  },
  [LastProgressNumberEnum.InterviewSteps[2]]: {
    // Job
    previousPage: Path["path.interview.job.step"].replace(":step", "etape"),
    nextPage: Path["path.interview.job.question1"],
  },
  // InterviewJob Section:
  [LastProgressNumberEnum.InterviewJob.Q1]: {
    previousPage: Path["path.interview.job.question1"],
    nextPage: Path["path.interview.job.advice1"],
  },
  [LastProgressNumberEnum.InterviewJob.C1]: {
    previousPage: Path["path.interview.job.advice1"],
    nextPage: Path["path.interview.job.question2"],
  },
  [LastProgressNumberEnum.InterviewJob.Q2]: {
    previousPage: Path["path.interview.job.question2"],
    nextPage: Path["path.interview.job.advice2"],
  },
  [LastProgressNumberEnum.InterviewJob.C2]: {
    previousPage: Path["path.interview.job.advice2"],
    nextPage: Path["path.interview.job.question3"],
  },
  [LastProgressNumberEnum.InterviewJob.Q3]: {
    previousPage: Path["path.interview.job.question3"],
    nextPage: Path["path.interview.job.question4"],
  },
  [LastProgressNumberEnum.InterviewJob.Q4]: {
    previousPage: Path["path.interview.job.question4"],
    nextPage: Path["path.interview.job.advice3"],
  },
  [LastProgressNumberEnum.InterviewJob.C3]: {
    previousPage: Path["path.interview.job.advice3"],
    nextPage: Path["path.interview.profile.step"].replace(":step", "etape"),
  },
  // InterviewProfile Section:
  [LastProgressNumberEnum.InterviewSteps[3]]: {
    previousPage: Path["path.interview.profile.step"].replace(":step", "etape"),
    nextPage: Path["path.interview.profile.question1"].replace(
      ":skillNumber",
      "1"
    ),
  },
  [LastProgressNumberEnum.InterviewProfile.Q1[1]]: {
    //3.1q.1
    previousPage: Path["path.interview.profile.question1"].replace(
      ":skillNumber",
      1
    ),
    nextPage: Path["path.interview.profile.question2"].replace(
      ":skillNumber",
      1
    ),
    advicePage: Path["path.interview.profile.advice"].replace(
      ":skillNumber",
      "1"
    ),
  },
  [LastProgressNumberEnum.InterviewProfile.Q2[1]]: {
    previousPage: Path["path.interview.profile.question1"].replace(
      ":skillNumber",
      1
    ),
    nextPage: Path["path.interview.profile.question1"].replace(
      ":skillNumber",
      2
    ),
  },
  [LastProgressNumberEnum.InterviewProfile.Q1[2]]: {
    //3.1q.2
    previousPage: Path["path.interview.profile.question1"].replace(
      ":skillNumber",
      2
    ),
    nextPage: Path["path.interview.profile.question2"].replace(
      ":skillNumber",
      2
    ),
    advicePage: Path["path.interview.profile.advice"].replace(
      ":skillNumber",
      2
    ),
  },
  [LastProgressNumberEnum.InterviewProfile.Q2[2]]: {
    previousPage: Path["path.interview.profile.question1"].replace(
      ":skillNumber",
      2
    ),
    nextPage: Path["path.interview.profile.question1"].replace(
      ":skillNumber",
      3
    ),
  },
  [LastProgressNumberEnum.InterviewProfile.Q1[3]]: {
    //3.1q.3
    previousPage: Path["path.interview.profile.question1"].replace(
      ":skillNumber",
      3
    ),
    nextPage: Path["path.interview.profile.question2"].replace(
      ":skillNumber",
      3
    ),
    advicePage: Path["path.interview.profile.advice"].replace(
      ":skillNumber",
      3
    ),
  },
  [LastProgressNumberEnum.InterviewProfile.Q2[3]]: {
    previousPage: Path["path.interview.profile.question1"].replace(
      ":skillNumber",
      3
    ),
    nextPage: Path["path.interview.finish"],
  },
  [LastProgressNumberEnum.InterviewProfile.C1[1]]: {
    previousPage: Path["path.interview.profile.question1"].replace(
      ":skillNumber",
      1 //TODO
    ),
    nextPage: Path["path.interview.profile.question1"].replace(
      ":skillNumber",
      2
    ),
  },
  [LastProgressNumberEnum.InterviewProfile.C1[2]]: {
    previousPage: Path["path.interview.profile.question1"].replace(
      ":skillNumber",
      1 //TODO
    ),
    nextPage: Path["path.interview.profile.question1"].replace(
      ":skillNumber",
      3
    ),
  },
  [LastProgressNumberEnum.InterviewProfile.C1[3]]: {
    previousPage: Path["path.interview.profile.question1"].replace(
      ":skillNumber",
      1 //TODO
    ),
    nextPage: Path["path.interview.finish"],
  },

  // Finish
  [LastProgressNumberEnum.InterviewEnd[1]]: {
    previousPage: Path["path.interview.finish"],
    nextPage: Path["path.interview.summary"],
  },
  [LastProgressNumberEnum.InterviewEnd[2]]: {
    previousPage: Path["path.interview.summary"],
    nextPage: Path["path.home"],
  },
};

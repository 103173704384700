import React, { useCallback, useMemo, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { Field } from "formik";
import { useTranslation } from "react-i18next";
import { mdiArrowLeft } from "@mdi/js";
import Interweave from "interweave";
import clsx from "clsx";

import { useMediaQueries } from "hooks/useMediaQueries";

import MdiIconButton from "components/common/MdiIconButton";
import Button from "components/common/Button(ToBeUpdated)";

import InterviewMailLetter from "assets/images/mailInterview.png";
import InterviewMailScreenStyles from "./InterviewMailScreen.module.scss";
import { MapPathnameToProgressNumberEnum } from "../../../utils/constants/enums/MapPathnameToProgressNumberEnum";
import { sendTiktokEventData } from "domain/tiktokEvent/tiktokEventController";
import { TiktokEventEnum } from "utils/constants/enums/TiktokEventEnum";
import {
  selectUserUID,
  selectCalendlyQuestionsAndAnswers,
} from "store/users/usersSelectors";
import { getPhoneNumberFromCalendlyUserData } from "utils/helpers/usersHelper";

const InterviewMailScreen = ({ setFieldValue, errors, isValid, values }) => {
  const history = useHistory();
  const [displayError, setDisplayError] = useState(false);
  const { isMobile, isTablet } = useMediaQueries();
  const refMailImg = useRef();
  const { t } = useTranslation("interview");
  const { title, text, textButton, placeholderEmail, mentions } = t(
    "interviewEmail",
    {
      returnObjects: true,
    }
  );
  const userUID = useSelector(selectUserUID);
  const calendlyQuestionsAndAnswers = useSelector(
    selectCalendlyQuestionsAndAnswers
  );

  const isButtonDisabled = useMemo(() => {
    return !isValid || values.email === "";
  }, [isValid, values.email]);

  const { previousPage } =
    MapPathnameToProgressNumberEnum[history.location.pathname];
  const goBack = useCallback(
    () => (previousPage ? history.push(previousPage) : history.goBack()),
    [previousPage, history]
  );

  const phoneNumber = getPhoneNumberFromCalendlyUserData(
    calendlyQuestionsAndAnswers
  );

  const handleTikTokDataSubmit = useCallback(() => {
    sendTiktokEventData(
      TiktokEventEnum.completeRegistration,
      userUID,
      values.email,
      phoneNumber
    );
    sendTiktokEventData(
      TiktokEventEnum.submitForm,
      userUID,
      values.email,
      phoneNumber
    );
  }, [userUID, values.email, phoneNumber]);

  return (
    <div className={InterviewMailScreenStyles.section}>
      <div className={InterviewMailScreenStyles.arche} />
      <div className={InterviewMailScreenStyles.backArrow}>
        <MdiIconButton
          buttonStyles={{
            backgroundColor: "white",
          }}
          mdiIcon={mdiArrowLeft}
          onClick={goBack}
        />
      </div>
      <img
        className={clsx({
          [InterviewMailScreenStyles.imgMailS]: isMobile,
          [InterviewMailScreenStyles.imgMailM]: isTablet,
          [InterviewMailScreenStyles.imgMail]: true,
        })}
        ref={refMailImg}
        src={InterviewMailLetter}
        alt="email for interview"
      />
      <div
        className={clsx({
          [InterviewMailScreenStyles.middleS]: isMobile,
          [InterviewMailScreenStyles.middleM]: isTablet,
          [InterviewMailScreenStyles.middle]: true,
        })}
        style={{ width: "150%" }}
      />
      <div
        className={clsx({
          [InterviewMailScreenStyles.middleS]: isMobile,
          [InterviewMailScreenStyles.middle]: true,
        })}
      >
        <div
          className={clsx({
            [InterviewMailScreenStyles.formS]: isMobile,
            [InterviewMailScreenStyles.formM]: isTablet,
            [InterviewMailScreenStyles.form]: true,
          })}
        >
          <div>
            <div className={InterviewMailScreenStyles.title}>{title}</div>
            <div className={InterviewMailScreenStyles.text}>{text}</div>
          </div>
          <Field
            type="text"
            className={InterviewMailScreenStyles.input}
            name="email"
            onFocus={() => {
              if (refMailImg.current) {
                refMailImg.current.style.transform = "scale(0.5)";
                refMailImg.current.style.top = "-5%";
              }
              setDisplayError(false);
            }}
            onBlur={() => {
              if (refMailImg.current) {
                refMailImg.current.style.transform = "scale(1)";
                refMailImg.current.style.top = "0";
              }
              setDisplayError(true);
            }}
            placeholder={placeholderEmail}
          />

          <div style={{ width: "90%" }}>
            {errors.email && displayError && (
              <div className={InterviewMailScreenStyles.errors}>
                {errors.email}
              </div>
            )}
            <p className={InterviewMailScreenStyles.mentions}>
              <Interweave content={mentions} />
            </p>
          </div>
        </div>
        <div className={InterviewMailScreenStyles.buttonContainer}>
          <Button
            text={textButton}
            type={"submit"}
            disabled={isButtonDisabled}
            style={{
              padding: "25px",
              marginTop: "8%",
              width: "100%",
              maxWidth: "480px",
            }}
            onClick={() => handleTikTokDataSubmit()}
          />
        </div>
      </div>
    </div>
  );
};

export default InterviewMailScreen;

import GroupStarEnum from "./../constants/enums/GroupStarEnum";

/**
 * Data object containing information about different star groups.
 *
 * @typedef {Object} StarsData
 * @property {Object} [GroupStarEnum.CONVINCE] - Information about the "Convaincre en entretien" star group.
 * @property {string} GroupStarEnum.CONVINCE.text - The text for the star group.
 * @property {JSX.Element} GroupStarEnum.CONVINCE.svg - The SVG element for the star group.
 * @property {string} GroupStarEnum.CONVINCE.description - The description for the star group.
 * @property {string} GroupStarEnum.CONVINCE.image - The image path for the star group.
 * @property {string} GroupStarEnum.CONVINCE.icon - The icon path for the star group.
 * @property {Object} [GroupStarEnum.APPLY] - Information about the "Savoir postuler" star group.
 * @property {string} GroupStarEnum.APPLY.text - The text for the star group.
 * @property {JSX.Element} GroupStarEnum.APPLY.svg - The SVG element for the star group.
 * @property {string} GroupStarEnum.APPLY.description - The description for the star group.
 * @property {string} GroupStarEnum.APPLY.image - The image path for the star group.
 * @property {string} GroupStarEnum.APPLY.icon - The icon path for the star group.
 * @property {Object} [GroupStarEnum.LEARN] - Information about the "Se former à un métier" star group.
 * @property {string} GroupStarEnum.LEARN.text - The text for the star group.
 * @property {JSX.Element} GroupStarEnum.LEARN.svg - The SVG element for the star group.
 * @property {string} GroupStarEnum.LEARN.description - The description for the star group.
 * @property {string} GroupStarEnum.LEARN.image - The image path for the star group.
 * @property {string} GroupStarEnum.LEARN.icon - The icon path for the star group.
 */
const starsData = {
  [GroupStarEnum.CONVINCE]: {
    // ** Convaincre en entretien
    text: "Convaincre en entretien",
    svg: (
      <svg
        width="40"
        height="40"
        viewBox="0 0 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_38_1332)">
          <path
            d="M17.4291 10.4855V3.46143H4.5243C3.52389 3.46143 2.71002 4.2753 2.71002 5.27584V12.5843C2.71002 13.4818 3.38086 14.2537 4.27056 14.3801L4.83635 14.4603L5.03258 14.9972C5.28888 15.6987 5.96349 16.1702 6.7111 16.1702C7.44969 16.1702 8.1216 15.706 8.38262 15.0151L8.61575 14.3985H17.5722C18.0368 14.3985 18.4606 14.2228 18.7815 13.9346C17.9429 13.0274 17.4291 11.8155 17.4291 10.4855Z"
            fill="#FF6000"
          />
          <path
            d="M22.5142 13.6626H30.4992L30.7838 14.0722C31.3765 14.9251 32.3454 15.4343 33.3752 15.4343C34.4739 15.4343 35.5095 14.8474 36.0777 13.9025L36.2568 13.6047L36.5852 13.4919C37.8734 13.0492 38.7391 11.8409 38.7391 10.4855V3.17724C38.7392 1.42532 37.3139 0 35.562 0H22.5141C20.7621 0 19.3369 1.42532 19.3369 3.17724V10.4857C19.3371 12.2374 20.7623 13.6626 22.5142 13.6626ZM31.8851 5.53589C32.14 5.28229 32.4901 5.13643 32.8485 5.13643C33.2085 5.13643 33.5585 5.28229 33.8134 5.53589C34.0669 5.78936 34.2128 6.14088 34.2128 6.49926C34.2128 6.85778 34.0669 7.20808 33.8134 7.4629C33.5585 7.71636 33.2085 7.86209 32.8485 7.86209C32.4901 7.86209 32.1399 7.71636 31.8851 7.4629C31.6315 7.20929 31.4871 6.85778 31.4871 6.49926C31.4871 6.13966 31.6315 5.78936 31.8851 5.53589ZM28.2312 5.53589C28.4847 5.28229 28.8363 5.13643 29.1947 5.13643C29.5531 5.13643 29.9049 5.28229 30.1582 5.53589C30.4132 5.78936 30.5576 6.14088 30.5576 6.49926C30.5576 6.85778 30.4132 7.20808 30.1582 7.4629C29.9048 7.71636 29.5531 7.86209 29.1947 7.86209C28.8363 7.86209 28.4847 7.71636 28.2312 7.4629C27.9778 7.20929 27.8319 6.85778 27.8319 6.49926C27.8319 6.13966 27.9778 5.78936 28.2312 5.53589ZM24.5773 5.53589C24.831 5.28229 25.1823 5.13643 25.5409 5.13643C25.8994 5.13643 26.251 5.28229 26.5044 5.53589C26.758 5.78936 26.9038 6.14088 26.9038 6.49926C26.9038 6.85778 26.758 7.20808 26.5044 7.4629C26.2509 7.71636 25.8994 7.86209 25.5409 7.86209C25.1823 7.86209 24.831 7.71636 24.5773 7.4629C24.3237 7.20929 24.178 6.85778 24.178 6.49926C24.178 6.13966 24.3237 5.78936 24.5773 5.53589Z"
            fill="#FF6000"
          />
          <path
            d="M9.09694 30.4427C12.3283 30.4427 14.9573 27.8138 14.9573 24.5822C14.9573 21.351 12.3285 18.722 9.09694 18.722C5.86569 18.722 3.2366 21.351 3.2366 24.5822C3.23674 27.8138 5.86569 30.4427 9.09694 30.4427Z"
            fill="#FF6000"
          />
          <path
            d="M16.3052 34.8659C15.6971 32.4334 13.2567 30.5281 10.7495 30.5281H7.44457C4.93736 30.5281 2.49709 32.4335 1.88887 34.8659L1.02867 38.3065C0.926854 38.7138 1.0183 39.1452 1.27662 39.476C1.53493 39.8067 1.93116 40.0001 2.35096 40.0001H15.8434C16.2629 40.0001 16.6594 39.8069 16.9176 39.476C17.1759 39.1452 17.2673 38.7139 17.1655 38.3065L16.3052 34.8659Z"
            fill="#FF6000"
          />
          <path
            d="M38.9713 38.3067L38.1981 35.2134C37.6367 32.9683 35.3842 31.2095 33.0699 31.2095H30.0987C27.7844 31.2095 25.5318 32.9683 24.9705 35.2134L24.1973 38.3066C24.0955 38.7137 24.1869 39.1451 24.4452 39.4759C24.7034 39.8066 25.0998 40 25.5196 40H37.6493C38.0689 40 38.4654 39.8068 38.7236 39.4759C38.9817 39.1451 39.0731 38.7139 38.9713 38.3067Z"
            fill="#FF6000"
          />
          <path
            d="M31.5842 19.4717C28.766 19.4717 26.4735 21.7642 26.4735 24.5823C26.4735 27.4004 28.766 29.693 31.5842 29.693C34.4024 29.693 36.6949 27.4003 36.6949 24.5823C36.6951 21.7643 34.4024 19.4717 31.5842 19.4717Z"
            fill="#FF6000"
          />
        </g>
        <defs>
          <clipPath id="clip0_38_1332">
            <rect width="40" height="40" fill="white" />
          </clipPath>
        </defs>
      </svg>
    ),
    description:
      "Les solutions ci-dessous vont t’aider à : Montrer ta motivation et tes compétences à ton prochain entretien. À décrocher le job de tes rêves.",
    image: "/assets/images/stars_section/imgConvaincreEntretien.svg",
    icon: "/assets/images/stars_section/Convaincre-en-entretien.svg",
  },
  [GroupStarEnum.APPLY]: {
    // ** Savoir postuler
    text: "Savoir postuler",
    svg: (
      <svg
        width="40"
        height="40"
        viewBox="0 0 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_38_1328)">
          <path
            d="M35.8006 31.3312C35.1424 30.673 34.2663 30.3671 33.4027 30.4173L31.0886 28.1033C32.0277 26.9044 32.8032 25.5881 33.4027 24.1711C34.3082 22.0248 34.7693 19.7484 34.7693 17.405C34.7693 15.0617 34.3082 12.7811 33.4027 10.639C32.5266 8.56808 31.2731 6.71098 29.6759 5.11379C28.0787 3.5166 26.2216 2.26316 24.1507 1.38701C22.0044 0.481517 19.728 0.0203857 17.3847 0.0203857C15.0413 0.0203857 12.7608 0.481517 10.6186 1.38701C8.54774 2.25897 6.69064 3.51241 5.09345 5.1096C3.49626 6.70679 2.24282 8.56389 1.36667 10.6348C0.461131 12.7812 0 15.0575 0 17.4009C0 19.7442 0.461131 22.0248 1.36663 24.1669C2.24277 26.2378 3.49621 28.0949 5.09341 29.6922C6.6906 31.2894 8.5477 32.5428 10.6186 33.4189C12.765 34.3245 15.0413 34.7856 17.3847 34.7856C19.728 34.7856 22.0085 34.3245 24.1507 33.4189C25.5844 32.811 26.9175 32.0271 28.129 31.0671L30.4388 33.377C30.3927 34.2406 30.6946 35.1167 31.3527 35.7749L34.6352 39.0573C35.2472 39.6694 36.0521 39.9795 36.857 39.9795C37.6619 39.9795 38.4667 39.6735 39.0788 39.0573C40.3071 37.829 40.3071 35.8378 39.0788 34.6094L35.8006 31.3312ZM28.0955 25.106C27.6805 25.6761 27.2193 26.2211 26.7121 26.7283C26.2174 27.223 25.6892 27.6715 25.1358 28.0739C22.8973 29.7089 20.2059 30.5892 17.3804 30.5892C13.8549 30.5892 10.5431 29.2184 8.05299 26.7241C5.56288 24.234 4.18787 20.9181 4.18787 17.3967C4.18787 13.8753 5.55869 10.5594 8.05299 8.06927C10.5431 5.57916 13.8591 4.20415 17.3804 4.20415C20.9018 4.20415 24.2178 5.57497 26.7078 8.06927C29.198 10.5594 30.573 13.8753 30.573 17.3967C30.5772 20.2054 29.7094 22.8757 28.0955 25.106Z"
            fill="#FF6000"
          />
          <path
            d="M16.1815 8.11961H18.592C19.3466 8.11961 19.9712 8.71489 20.0886 9.48624H22.1972C22.063 7.55368 20.4994 6.02356 18.592 6.02356H16.1815C14.2741 6.02356 12.7105 7.55368 12.5763 9.48624H14.6849C14.7981 8.7107 15.4269 8.11961 16.1815 8.11961Z"
            fill="#FF6000"
          />
          <path
            d="M22.2056 10.5343H20.1095H14.6598H12.5637H9.14717C8.50997 10.5343 7.79312 11.1422 7.40325 11.5236C7.16011 11.8171 7.114 12.0183 7.13496 12.2447C7.29007 13.7496 8.29198 15.1666 9.98978 16.2817C11.4947 17.2668 13.3854 17.9166 15.4479 18.1639C16.0767 18.2394 16.7265 18.2813 17.3805 18.2813C18.592 18.2813 19.7742 18.1472 20.8809 17.8914C22.3355 17.5561 23.6603 17.0111 24.7753 16.2817C26.4731 15.1666 27.4708 13.7455 27.6301 12.2447C27.6553 12.0141 27.5296 11.6829 27.3619 11.5236C26.972 11.1422 26.2552 10.5343 25.6179 10.5343H22.2056Z"
            fill="#FF6000"
          />
          <path
            d="M25.7018 17.686C24.6873 18.3525 23.5219 18.8849 22.2559 19.2622C20.7425 19.7191 19.0909 19.9581 17.3847 19.9581C17.2631 19.9581 17.1374 19.9581 17.0158 19.9539C14.0226 19.8952 11.2139 19.0945 9.06752 17.686C8.16202 17.0907 7.41164 16.4116 6.82474 15.6612C6.68221 15.4767 6.57741 15.5103 6.57741 15.7408V21.5846C6.57741 23.0644 7.72604 24.2676 9.14298 24.2676H21.321H25.6179C26.0833 24.2676 26.5192 24.1376 26.8923 23.9112C27.6637 23.4501 28.1835 22.5823 28.1835 21.5846V15.745C28.1835 15.5145 28.0787 15.4809 27.9362 15.6654C27.3577 16.4115 26.6073 17.0907 25.7018 17.686Z"
            fill="#FF6000"
          />
        </g>
        <defs>
          <clipPath id="clip0_38_1328">
            <rect width="40" height="40" fill="white" />
          </clipPath>
        </defs>
      </svg>
    ),
    description:
      "Les solutions ci-dessous vont t’aider à : Envoyer des candidatures de qualité, améliorer ton CV, lettre de motivation ou candidature spontanée.",
    image: "/assets/images/stars_section/imgSavoirPostuler.svg",
    icon: "/assets/images/stars_section/Savoir-postuler.svg",
  },
  [GroupStarEnum.LEARN]: {
    // ** Se former à un métier
    text: "Se former à un métier",
    svg: (
      <svg
        width="40"
        height="27"
        viewBox="0 0 40 27"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M19.7745 16.514C19.1297 16.514 18.1787 16.4638 17.3065 16.2256L10.3279 14.3138H6.90957V17.2912C6.90957 18.2066 7.86719 19.2454 9.04783 19.6102L17.4978 22.2254C18.6785 22.5901 20.5952 22.5917 21.7773 22.229L30.3193 19.6065C31.5007 19.2438 32.4591 18.2065 32.4591 17.2911V14.3138H28.6867L22.5023 16.1675C21.7589 16.391 20.7888 16.5139 19.7745 16.5139V16.514ZM0.898953 10.4274L17.7268 15.0377C18.9288 15.3663 20.8646 15.3449 22.0512 14.989L38.1216 10.1725V17.2787L37.1286 19.6343H40L38.9725 17.2541V9.88516H38.9438C39.6379 9.54497 39.4759 9.12055 38.4484 8.85937L22.099 4.72274C20.886 4.41502 18.9258 4.42807 17.7201 4.74994L0.906271 9.24768C-0.299403 9.57007 -0.302314 10.0986 0.898912 10.4273L0.898953 10.4274Z"
          fill="#FF6000"
        />
      </svg>
    ),
    description:
      "Les solutions ci-dessous vont t’aider à : Connaître les formations aux métiers d'avenir qui te plaisent ! ",
    image: "/assets/images/stars_section/imgSeFormer.svg",
    icon: "/assets/images/stars_section/Se-former-à-un-métier.svg",
  },
  [GroupStarEnum.KNOW]: {
    // ** Se connaître
    text: "Se connaître",
    svg: (
      <svg
        width="40"
        height="40"
        viewBox="0 0 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M21.1882 29.633C17.7953 29.633 14.3482 27.6189 12.3365 24.953C2.68471 24.953 2.39294 38.7413 2.39294 38.7413H39.9812C39.9812 38.7413 40.7388 24.8918 29.8847 24.8918C27.8753 27.5907 24.5812 29.633 21.1882 29.633Z"
          fill="#FF6000"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M29.2729 13.1036C29.2729 17.0706 25.6518 24.6565 21.1812 24.6565C16.7176 24.6565 13.0941 17.0683 13.0941 13.1036C13.0941 9.13887 16.7153 5.92004 21.1812 5.92004C25.6518 5.9224 29.2729 9.14122 29.2729 13.1036Z"
          fill="#FF6000"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M32.8823 9.68004C32.8823 8.93651 31.8471 8.33651 30.5623 8.3318V6.80004C30.5623 6.5718 30.6494 1.22827 21.2094 1.22827C11.7741 1.22827 11.8612 6.5718 11.8612 6.80004V8.38592C11.8376 8.38592 11.8188 8.37886 11.7976 8.37886C10.52 8.37886 9.49176 8.96945 9.49176 9.70121V16.1812C9.49176 16.9083 10.5223 17.5012 11.7976 17.5012C13.0729 17.5012 14.1082 16.9083 14.1082 16.1812V9.70121C14.1082 9.60004 14.0447 9.50827 14.0071 9.41415V7.6471C14.0071 7.48474 13.3294 3.4518 21.2094 3.4518C29.0918 3.4518 28.2729 7.48474 28.2729 7.6471V9.50827C28.2588 9.56709 28.2141 9.61886 28.2141 9.68004V16.3083C28.2141 17.0542 29.2565 17.6589 30.5482 17.6589C30.5765 17.6589 30.5976 17.6494 30.6259 17.6494V20.0706H28.3106V22.353H32.9059L32.8823 9.68004Z"
          fill="#FF6000"
        />
      </svg>
    ),
    description:
      "Les solutions ci-dessous vont t’aider à : Connaître ce que tu veux faire et ce que tu sais déjà faire, trouver du sens.",
    image: "/assets/images/stars_section/imgSeConnaitre.svg",
    icon: "/assets/images/stars_section/Se-connaître.svg",
  },
};

export default starsData;

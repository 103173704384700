import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import clickPrincipalSolutionDataGA from "config/googleAnalytics/clickPrincipalSolutionDataGA";

import {
  Box,
  Button,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";

import { selectUserEmail, selectUserUID } from "store/users/usersSelectors";

import { goToSolutionLinkCondition } from "domain/solution/rules";

import { useChildrenSolutionCardStyles } from "hooks/withSolutionCardStyles";

import { formatFrequency } from "utils/helpers/solutionsHelper";
import { DisplayInfoBySolutionType } from "utils/constants/displayInfoBySolutionType";

import TimeIcon from "assets/images/Time.svg";
import PriceTagEuroIcon from "assets/images/PriceTagEuro.svg";
import PlaceMarkerIcon from "assets/images/PlaceMarker.svg";
import Icon from "components/common/Icon/Icon";
import { updateUserAnalyticsSolutions } from "domain/user/userControllers";

const TagIconByType = {
  cost: PriceTagEuroIcon,
  duration: TimeIcon,
  location: PlaceMarkerIcon,
};

const SolutionCard = ({
  setSelectedSolution,
  openMailModal,
  srcLogo,
  title,
  tags,
  listDescriptions,
  link,
  type,
  intro,
  section = "formation",
}) => {
  const theme = useTheme();
  const userId = useSelector(selectUserUID);

  const { titleStyles, contentStyles } = theme.palette.solutionCard.green;
  const {
    ParagraphStyles,
    solutionCard: { subtitle },
  } = theme.typography;
  const isScreenDownSM = useMediaQuery(theme.breakpoints.down("sm"));

  const {
    articleStyle,
    titleContainerStyle,
    titleContainerRef,
    textContainerStyle,
    textContainerRef,
  } = useChildrenSolutionCardStyles();

  const userEmail = useSelector(selectUserEmail);

  const contentDescription = useMemo(() => {
    const list = listDescriptions?.[0]?.split("\n");
    return (
      <>
        {section === "formation" ? (
          <>
            <Box
              mb={2}
              width="100%"
              fontSize={
                isScreenDownSM
                  ? ParagraphStyles.fontSizes.xs
                  : ParagraphStyles.fontSizes.sm
              }
            >
              <Typography
                variant="paragraph"
                sx={{
                  fontWeight: 700,
                }}
              >
                C'est quoi?
              </Typography>
              <Typography variant="paragraph">
                {list[0]
                  ?.replace("C'est quoi ?", "")
                  .replace("C’est quoi ?", "")}
              </Typography>
            </Box>

            <Box width="100%">
              <Typography
                variant="paragraph"
                sx={{
                  fontWeight: 700,
                }}
              >
                Et après?
              </Typography>
              <Typography variant="paragraph">
                {list[1]?.replace("Et après ?", "")}
              </Typography>
            </Box>
          </>
        ) : (
          <Typography variant="paragraph">{intro}</Typography>
        )}
      </>
    );
  }, [
    ParagraphStyles.fontSizes.xs,
    isScreenDownSM,
    listDescriptions,
    ParagraphStyles.fontSizes.sm,
    intro,
    section,
  ]);

  return (
    <Box
      name="card"
      display="block"
      sx={{ ...articleStyle, marginBottom: isScreenDownSM ? "20px" : "0px" }}
    >
      <Box
        name="titleContainer"
        display="flex"
        justifyContent="center"
        textAlign="center"
        alignItems="center"
        padding="20px"
        position="relative"
        borderRadius="20px 20px 0 0"
        sx={{
          ...titleContainerStyle,
          ...titleStyles,
        }}
        ref={titleContainerRef}
      >
        <Typography variant="titleSolutionCard">{title}</Typography>
      </Box>
      <Box
        name="cardContent"
        sx={{
          ...contentStyles,
          borderRadius: "0 0 20px 20px",
        }}
      >
        <Box
          name="typeBox"
          display="flex"
          justifyContent="center"
          position="relative"
          zIndex={12}
          bottom="13px"
        >
          <Typography
            variant="paragraph"
            px={4}
            py={1}
            borderRadius="5px"
            sx={{
              fontWeight: 700,
              backgroundColor: theme.palette.orange.orange100,
            }}
          >
            {type}
          </Typography>
        </Box>
        <Box
          name="imageDiv"
          minHeight="140px"
          width="100%"
          display="flex"
          justifyContent="center"
          alignItems="center"
          borderBottom="1px solid #CED4DA"
        >
          <img
            alt="description of the solution"
            src={srcLogo}
            loading="lazy"
            style={{ maxHeight: "80px", maxWidth: "70%" }}
          />
        </Box>
        <Box display="flex" flexDirection="column" alignContent="center">
          <Box
            name="contentDescription"
            p={4}
            ref={textContainerRef}
            sx={{ ...textContainerStyle }}
          >
            {contentDescription}
            <Box my={5}>
              {(tags || []).map((tag) => (
                <Box key={tag.value} display="flex" alignItems="center" mb={1}>
                  <img
                    src={TagIconByType[tag.type]}
                    alt={tag.type}
                    style={{
                      width: "8%",
                      display: "inline-block",
                    }}
                  />
                  <Typography variant="paragraph" marginLeft={2} width="92%">
                    {tag.value}
                    {tag.type === "duration" &&
                      ` ${formatFrequency(tag.value, tag.frequency)}`}
                  </Typography>
                </Box>
              ))}
            </Box>
          </Box>
          <Box name="CTA" width="100%" display="flex" justifyContent="center">
            <Button
              variant="contained"
              color="orange"
              endIcon={<Icon iconName="arrowRightShort" iconSize={16} />}
              sx={{ width: "90%", paddingY: "8px", marginBottom: "16px" }}
              onClick={() => {
                updateUserAnalyticsSolutions(
                  userId,
                  title,
                  section === "formation",
                  Boolean(userEmail)
                );
                clickPrincipalSolutionDataGA({
                  solutionNom: title,
                  mailLaisse: Boolean(userEmail),
                });
                if (
                  userEmail ||
                  goToSolutionLinkCondition({
                    title,
                  })
                ) {
                  window.open(link, "_blank").focus();
                } else {
                  openMailModal(true);
                  setSelectedSolution({
                    title,
                    link,
                  });
                }
              }}
            >
              <Typography sx={{ ...subtitle }}>
                {DisplayInfoBySolutionType[type]?.buttonCta?.text || "J'y vais"}
              </Typography>
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default SolutionCard;

import { current } from "@reduxjs/toolkit";
import { newAnswerInQuestionWithAnswersList } from "utils/helpers/questionnaireHelper";

const userQuestionnaireReducers = {
  saveAnswer: (state, action) => {
    return {
      ...state,
      questionWithAnswers: newAnswerInQuestionWithAnswersList(
        state.questionWithAnswers,
        action.payload
      ),
    };
  },
  deleteAllAnswers: (state, action) => {
    return {
      ...state,
      questionWithAnswers: [],
    };
  },
  deleteAnswer: (state, action) => {
    const questionWithAnswersNew = Object.assign(
      [],
      state.questionWithAnswers.filter(
        (questionWithAnswerItem) =>
          questionWithAnswerItem.qid !== action.payload
      )
    );
    return {
      ...state,
      questionWithAnswers: questionWithAnswersNew,
    };
  },
  saveTempAnswer: (state, action) => {
    return {
      ...state,
      tempAnswer: action.payload,
    };
  },
  saveLastProgress: (state, action) => {
    const currentState = current(state);
    if (action.payload < 100) {
      return {
        ...state,
        lastProgress: action.payload,
        user: {
          ...currentState.user,
          firstName:
            currentState.questionWithAnswers?.[0]?.answers?.[0]?.value ||
            currentState.user?.firstName,
          email: "",
        },
      };
    }
    return {
      ...state,
      lastProgress: action.payload,
    };
  },
};

export default userQuestionnaireReducers;

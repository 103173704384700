import axios from "axios";
import endpoints from "utils/constants/endpoints";
import Errors from "utils/translations/en/errors.json";

export async function subscribeToNewsletter(email, uid) {
  try {
    await axios({
      method: "get",
      url: endpoints.SUBSCRIBE_NEWSLETTER(),
      params: { email, userId: uid },
    });
  } catch (error) {
    console.error(Errors.SUBSCRIBE_NEWSLETTER, error, { email, uid });
  }
}

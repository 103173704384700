import React, { useState, useCallback, useMemo } from "react";
import { useHistory } from "react-router-dom";
import { Field } from "formik";
import clsx from "clsx";
import Calendar from "react-calendar";
import { useTranslation } from "react-i18next";
import { mdiArrowLeft } from "@mdi/js";
import { useSelector } from "react-redux";

import { useMediaQueries } from "hooks/useMediaQueries";

import { InterviewFieldNames } from "domain/interview/interviewFormUtils";

import MdiIconButton from "components/common/MdiIconButton";
import Button from "components/common/Button(ToBeUpdated)";

import { selectJobCodeRome } from "store/interview/interviewSelectors";

import "./Calendar.scss";
import InterviewStartClock from "assets/images/interview-start-clock.png";
import InterviewStartStyles from "./InterviewStart.module.scss";
import AutoCompleteJobInterview from "components/common/AutoCompleteJobInterview";
import { MapPathnameToProgressNumberEnum } from "../../../utils/constants/enums/MapPathnameToProgressNumberEnum";
import SimpleInputField from "components/common/SimpleInputField";

const DEFAULT_MAX_LENGTH_CHARACTERS = 100;

const InterviewStart = ({ setFieldValue, errors, values }) => {
  const history = useHistory();
  const [calendar, setCalendar] = useState(false);
  const { isTablet, isMobile } = useMediaQueries();
  const { t } = useTranslation("interview");
  const {
    title,
    text,
    textButton,
    textCompany,
    textJob,
    textDate,
    textRequired,
    titleCalendar,
    textButtonNoDate,
    imageClockAlt,
  } = t("interviewStart", {
    returnObjects: true,
  });

  const noInterviewDate = useCallback(() => {
    setFieldValue("date", textButtonNoDate);
    setCalendar(false);
  }, [setFieldValue, textButtonNoDate]);

  const interwievDateSelected = useCallback(
    (date) => {
      setFieldValue("date", Intl.DateTimeFormat("fr-Fr").format(date));
      setCalendar(false);
    },
    [setFieldValue]
  );

  const { previousPage } =
    MapPathnameToProgressNumberEnum[history.location.pathname];
  const goBack = useCallback(
    () => (previousPage ? history.push(previousPage) : history.goBack()),
    [previousPage, history]
  );

  const codeRome = useSelector(selectJobCodeRome);

  const isButtonDisabled = useMemo(() => {
    return values.company === "" || values.date === "" || !codeRome;
  }, [values, codeRome]);

  return (
    <div className={InterviewStartStyles.section}>
      <div className={InterviewStartStyles.arche} />
      <div className={InterviewStartStyles.backArrow}>
        <MdiIconButton
          buttonStyles={{
            backgroundColor: "white",
          }}
          mdiIcon={mdiArrowLeft}
          onClick={goBack}
        />
      </div>
      <div className={InterviewStartStyles.timer}>
        <img src={InterviewStartClock} alt={imageClockAlt} />
      </div>
      <div
        className={clsx({
          [InterviewStartStyles.middleS]: isMobile,
          [InterviewStartStyles.middleM]: isTablet,
          [InterviewStartStyles.middle]: true,
        })}
        style={{ width: "150%" }}
      >
        {!calendar ? (
          <>
            <div className={InterviewStartStyles.title}>{title}</div>

            <div
              className={clsx({
                [InterviewStartStyles.formS]: isMobile,
                [InterviewStartStyles.formM]: isTablet,
                [InterviewStartStyles.form]: true,
              })}
            >
              <div className={InterviewStartStyles.text}>{text}</div>
              <div>
                <SimpleInputField
                  className={clsx({
                    [InterviewStartStyles.inputS]: isMobile,
                    [InterviewStartStyles.input]: true,
                  })}
                  name={InterviewFieldNames.INTERVIEW_START.COMPANY}
                  maxLength={DEFAULT_MAX_LENGTH_CHARACTERS}
                  placeholder={textCompany}
                />
                {errors.company && (
                  <div className={InterviewStartStyles.errors}>
                    {errors.company}
                  </div>
                )}
                <Field
                  type="text"
                  className={InterviewStartStyles.input}
                  as={AutoCompleteJobInterview}
                  setFieldValue={setFieldValue}
                  name={InterviewFieldNames.INTERVIEW_START.JOB}
                  placeholder={textJob}
                />
                {errors.job && (
                  <div className={InterviewStartStyles.errors}>
                    {errors.job}
                  </div>
                )}
                <Field
                  type="text"
                  className={clsx({
                    [InterviewStartStyles.inputS]: isMobile,
                    [InterviewStartStyles.input]: true,
                  })}
                  name={InterviewFieldNames.INTERVIEW_START.DATE}
                  placeholder={textDate}
                  onClick={() => setCalendar(true)}
                />
                {errors.date && (
                  <div className={InterviewStartStyles.errors}>
                    {errors.date}
                  </div>
                )}
                <div className={InterviewStartStyles.textRequired}>
                  {textRequired}
                </div>
              </div>
              <Button
                text={textButton}
                type={"submit"}
                disabled={isButtonDisabled}
                style={{
                  padding: "25px",
                  marginTop: "8%",
                  width: "100%",
                }}
              />
            </div>
          </>
        ) : (
          <div
            className={clsx({
              [InterviewStartStyles.calendarS]: isMobile,
              [InterviewStartStyles.calendar]: true,
            })}
          >
            <div className={InterviewStartStyles.titleCalendar}>
              {titleCalendar}
            </div>
            <Calendar
              className={clsx({
                [InterviewStartStyles.calendarS]: isMobile,
                [InterviewStartStyles.calendar]: true,
              })}
              minDate={new Date()}
              selectRange={false}
              next2Label=""
              prev2Label=""
              onChange={(date) => interwievDateSelected(date)}
            />
            <Button
              className={InterviewStartStyles.noDateButton}
              text={textButtonNoDate}
              type={"button"}
              style={{
                padding: "25px",
                marginTop: "8%",
                width: "100%",
              }}
              onClick={noInterviewDate}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default InterviewStart;

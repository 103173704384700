/* Actions Rules */

// ** Condition in the Solution Card to go directly to solution link
export const goToSolutionLinkCondition = (selectedSolution) => {
  // f023 represents the solution Rdv d'orientation
  if (
    selectedSolution.title.includes("Tu as besoin de conseils ou de soutien ?")
  ) {
    return true;
  }
  // f041 represents the solution 1entretien 1job
  if (selectedSolution.title.includes("1entretien 1job")) {
    return true;
  }
  return false;
};

import React from "react";
import Interweave from "interweave";

import InterviewToolTipBoxStyles from "../InterviewToolTipBox/InterviewToolTipBox.module.scss";
import ToolTipIcon from "assets/images/interview-template-form-toolTip-icon.svg";

const InterviewToolTipBox = ({ toolTipTextTitle, toolTipTextContent }) => {
  return (
    <div className={InterviewToolTipBoxStyles.toolTipBox}>
      <div className={InterviewToolTipBoxStyles.toolTipBoxImg}>
        <img src={ToolTipIcon} alt="interview question tip" />
      </div>
      <div className={InterviewToolTipBoxStyles.toolTipTextContainer}>
        <div className={InterviewToolTipBoxStyles.toolTipTextTitle}>
          <Interweave content={toolTipTextTitle} />
        </div>
        <div className={InterviewToolTipBoxStyles.toolTipTextContent}>
          <Interweave content={toolTipTextContent} />
        </div>
      </div>
    </div>
  );
};

export default InterviewToolTipBox;

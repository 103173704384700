import React, { useEffect, useCallback } from "react";
import { useParams } from "react-router-dom";
import Interweave from "interweave";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  Box,
  Button,
  Container,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";

import { getPillImageUrlFromFirebaseDevStorage } from "utils/helpers/assetsHelpers";
import {
  selectCalendlyQuestionsAndAnswers,
  selectUserEmail,
  selectUserUID,
} from "store/users/usersSelectors";
import { goToSolutionPageWithoutSubcriptionAction } from "domain/user/userControllers";

import SimpleInputField from "components/common/SimpleInputField";
import Icon from "components/common/Icon";
import LinearProgress from "components/common/LinearProgress";
import { sendTiktokEventData } from "domain/tiktokEvent/tiktokEventController";
import { TiktokEventEnum } from "utils/constants/enums/TiktokEventEnum";
import { getPhoneNumberFromCalendlyUserData } from "utils/helpers/usersHelper";

function QuestionnaireEmailPage({ setFieldValue, values, isValid, ...props }) {
  const history = useHistory();
  const { aid } = useParams();
  const dispatch = useDispatch();
  const { t } = useTranslation(["path", "questionnaireEmail"]);
  const userEmail = useSelector(selectUserEmail);
  const userUID = useSelector(selectUserUID);
  const calendlyQuestionsAndAnswers = useSelector(
    selectCalendlyQuestionsAndAnswers
  );

  const {
    breakpoints,
    variables: {
      containerPX,
      questionnaire: { contentMaxWidth },
    },
    typography: { FontSizes },
    palette: { blue, black },
  } = useTheme();
  const isScreenUpSM = useMediaQuery(breakpoints.up("sm"));

  useEffect(() => {
    if (aid) {
      setFieldValue("aid", aid);
    }
    if (userEmail) {
      setFieldValue("emailForm", userEmail);
    }
  }, [aid, setFieldValue, userEmail]);

  const goDirectlyToSolutionsPage = useCallback(async () => {
    dispatch(goToSolutionPageWithoutSubcriptionAction(aid, history));
  }, [history, dispatch, aid]);

  const phoneNumber = getPhoneNumberFromCalendlyUserData(
    calendlyQuestionsAndAnswers
  );

  return (
    <Container
      sx={{
        px: containerPX,
        mt: 10,
      }}
      maxWidth="xl"
    >
      <LinearProgress progressValue={100} />
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        textAlign="center"
        mt={8}
      >
        {isScreenUpSM && (
          <Box>
            <img
              src={getPillImageUrlFromFirebaseDevStorage(
                "PillPaulineSmallBlue.png"
              )}
              loading="lazy"
              alt={t("interviewSection.imgPillAlt")}
            />
          </Box>
        )}
        <Box mt={2} maxWidth={contentMaxWidth}>
          <Typography
            sx={{
              color: blue.main,
              "@media (max-width: 576px)": {
                fontSize: FontSizes.sm,
              },
              // Since it's a paragraph by default in breakpoint it will change to xs
              fontSize: FontSizes.md,
            }}
            variant="paragraph"
          >
            <Interweave content={t("questionnaireEmail:intro")} />
          </Typography>
        </Box>
        <Box
          maxWidth={contentMaxWidth}
          width="100%"
          sx={{
            mt: 7,
            "@media (max-width: 576px)": {
              mt: 4,
            },
          }}
        >
          <SimpleInputField
            name="emailForm"
            placeholder={
              values?.emailForm || t("questionnaireEmail:placeholder")
            }
            fullWidth
            withCount={false}
          />
        </Box>
        <Box maxWidth={contentMaxWidth} width="100%" textAlign={"left"}>
          <Typography
            sx={{
              fontSize: FontSizes.xs,
              color: black.neutral600,
            }}
            variant="paragraph"
          >
            <Interweave content={t("questionnaireEmail:mentions")} />
          </Typography>
        </Box>
        <Box
          sx={{
            mt: 10,
            mb: 38,
            "@media (max-width: 576px)": {
              mt: 8,
            },
          }}
          maxWidth={contentMaxWidth}
          width="100%"
        >
          <Button
            sx={{ mt: 10 }}
            type="submit"
            size="large"
            fullWidth
            variant="contained"
            color="orange"
            disabled={!isValid}
            onClick={() => {
              sendTiktokEventData(
                TiktokEventEnum.completeRegistration,
                userUID,
                userEmail,
                phoneNumber
              );
            }}
          >
            {t("questionnaireEmail:ctaSendMail")}
          </Button>
          <Button
            fullWidth
            variant="text"
            color="black"
            onClick={goDirectlyToSolutionsPage}
            sx={{ mt: 2 }}
            endIcon={<Icon iconName="arrowRightShort" iconSize={16} />}
          >
            {t("questionnaireEmail:linkToSolutionPage")}
          </Button>
        </Box>
      </Box>
    </Container>
  );
}

export default QuestionnaireEmailPage;

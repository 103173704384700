import { useTranslation } from "react-i18next";
import TemplateFormInterview from "../../common/TemplateFormInterview";
import React from "react";
import clsx from "clsx";
import InterviewJobBestofFormStyles from "./InterviewJobBestofForm.module.scss";
import { useMediaQueries } from "../../../hooks/useMediaQueries";
import SingleInputField from "../../common/SingleInputField";

const InterviewJobBestofForm = ({ values }) => {
  const { t } = useTranslation("interview");
  const {
    questionName,
    toolTipTextTitle,
    toolTipTextContent,
    placeHolder,
    buttonLabelName,
  } = t("jobBestof", { returnObjects: true });
  const { isMobile } = useMediaQueries();

  return (
    <TemplateFormInterview
      questionName={questionName}
      toolTipTextTitle={toolTipTextTitle}
      toolTipTextContent={toolTipTextContent}
      buttonLabelName={buttonLabelName}
    >
      <div
        className={clsx({
          [InterviewJobBestofFormStyles.multiInputListL]: true,
          [InterviewJobBestofFormStyles.multiInputListS]: isMobile,
        })}
      >
        <SingleInputField
          name="bestof"
          maxLength={400}
          placeHolder={placeHolder}
        />
      </div>
    </TemplateFormInterview>
  );
};

export default InterviewJobBestofForm;

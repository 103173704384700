import * as Yup from "yup";
import Errors from "utils/translations/en/errors.json";
import { TrainingProgressFactory } from "./interviewModels";
import { TrainingTagEnum } from "../../utils/constants/enums/TrainingTagEnum";
import { LastProgressNumberEnum } from "../../utils/constants/enums/LastProgressNumberEnum";
import { MapLastProgressNumberToPathnameEnum } from "../../utils/constants/enums/MapLastProgressNumberToPathnameEnum";
import { regexEmail } from "utils/helpers/usersHelper";

export const InterviewInitialValues = {
  InterviewStartForm: (currentInterview) => ({
    company: currentInterview?.interviewFormData?.company || "",
    job: currentInterview?.interviewFormData?.job || "",
    date: currentInterview?.interviewFormData?.date || "",
  }),
  InterviewCompanyActivitesForm: (currentInterview) => ({
    activities: currentInterview?.company?.activities || [],
  }),
  InterviewCompanySimulationForm: (currentInterview) => ({
    simulation: currentInterview?.company?.simulation || "",
  }),
  InterviewCompanyValuesForm: (currentInterview) => ({
    values: currentInterview?.company?.values || [],
  }),
  InterviewEmailForm: (currentInterview) => ({
    email: currentInterview?.email || "",
  }),
  InterviewJobSoftSkillsForm: (currentInterview) => ({
    softskills: currentInterview?.job?.softskills || [],
  }),
  InterviewJobChallengesForm: (currentInterview) => ({
    challenges: currentInterview?.job?.challenges || "",
  }),
  InterviewJobBestofForm: (currentInterview) => ({
    bestof: currentInterview?.job?.bestof || "",
  }),
  InterviewProfileSkillRateForm: (currentInterview) => ({
    rateList: currentInterview?.profile?.skills?.map((skill) => ({
      id: skill.id,
      name: skill.name,
      rate: skill.rate,
    })),
    rate: -1,
    id: null,
    name: null,
  }),
  InterviewProfileSkillExampleForm: (currentInterview) => ({
    exampleList: currentInterview?.profile?.skills?.map((skill) => ({
      id: skill.id,
      name: skill.name,
      example: skill.example,
    })),
    example: "",
    id: null,
    name: null,
  }),
  InterviewFinishForm: () => ({
    trainingProgress: TrainingProgressFactory(
      TrainingTagEnum.InterviewEnd,
      LastProgressNumberEnum.InterviewEnd[1]
    ).instance,
    redirectTo:
      MapLastProgressNumberToPathnameEnum[
        LastProgressNumberEnum.InterviewEnd[1]
      ].nextPage,
  }),
};

export const InterviewFieldNames = {
  INTERVIEW_START: {
    COMPANY: "company",
    JOB: "job",
    DATE: "date",
  },
};

export const ValidationSchemas = {
  INTERVIEW_EMAIL: Yup.object().shape({
    email: Yup.string()
      .email(Errors.INTERVIEW.ERROR_FORMAT_EMAIL)
      .matches(regexEmail),
  }),
  INTERVIEW_COMPANY_ACTIVITES: Yup.object().shape({
    activites: Yup.array().min(1),
  }),
  INTERVIEW_COMPANY_VALUES: Yup.object().shape({
    values: Yup.array(),
  }),
  INTERVIEW_COMPANY_SIMULATION: Yup.object().shape({
    simulation: Yup.string(),
  }),
  INTERVIEW_START: Yup.object().shape({
    company: Yup.string().min(1).max(100, Errors.INTERVIEW.MAX_LETTERS_COMPANY),
    job: Yup.string().min(1).max(100, Errors.INTERVIEW.MAX_LETTERS_JOB),
    date: Yup.string(),
  }),
  INTERVIEW_JOB_SOFTSKILLS: Yup.object().shape({
    softskills: Yup.array()
      .min(1)
      .of(Yup.string().max(100, Errors.MAX_LETTERS_SOFTSKILLS_JOB)),
  }),
  INTERVIEW_JOB_CHALLENGES: Yup.object().shape({
    challenges: Yup.string(),
  }),
  INTERVIEW_JOB_BESTOF: Yup.object().shape({
    bestof: Yup.string().max(400, Errors.MAX_LETTERS_BESTOF_JOB),
  }),
  INTERVIEW_PROFILE_SKILLRATE: Yup.object().shape({
    rateList: Yup.array(),
    rate: Yup.number().required().integer().min(0).max(2),
    id: Yup.number().required().integer().min(1).max(5),
    name: Yup.string().required().min(1).max(400),
  }),
  INTERVIEW_PROFILE_SKILL_EXAMPLE: Yup.object().shape({
    exampleList: Yup.array(),
    example: Yup.string().max(400, Errors.MAX_LETTERS_SKILL_EXAMPLE_PROFILE),
    id: Yup.number().required().integer().min(1).max(5),
    name: Yup.string().required().min(1).max(400),
  }),
  INTERVIEW_FINISH: Yup.object().shape({
    trainingProgress: Yup.object(),
    redirectTo: Yup.string(),
  }),
};

export const filterNullAndEmptyValues = (newData) => {
  return newData.filter(
    (data) => data !== "" && data !== null && data !== undefined
  );
};

import React from "react";
import { useTranslation } from "react-i18next";
import commonStyles from "./Button.module.scss";
import Interweave from "interweave";

const ButtonClassNameTypes = {
  suggestions: commonStyles.buttonSuggestion,
  default: commonStyles.buttonPrincipal,
};

const Button = ({
  mode = "default",
  shadow = "0px 4px 12px rgba(34, 40, 47, 0.1)",
  fontFamily = "ProximaSoft",
  width = 60,
  isBold,
  text = "",
  ...otherProps
}) => {
  const { t } = useTranslation();
  return (
    <button
      className={ButtonClassNameTypes[mode]}
      style={{
        boxShadow: shadow,
        fontFamily,
        fontStyle: isBold ? "bold" : "normal",
        width: width + "%",
        ...(otherProps.styles || {}),
      }}
      {...otherProps}
    >
      <Interweave content={t(text)} />
    </button>
  );
};

export default Button;

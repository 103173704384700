import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";

import {
  Box,
  Button,
  Container,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import Interweave from "interweave";

import {
  getMissionPicturesUrlFromFirebaseDevStorage,
  getDocumentsPDFUrlFromFirebaseDevStorage,
} from "utils/helpers/assetsHelpers";
import clickDownloadActivitiesReportDataGA from "config/googleAnalytics/clickDownloadActivitiesReportDataGA";
import navigateToDiagnosticDataGA from "config/googleAnalytics/navigateToDiagnosticDataGA";

import Icon from "components/common/Icon";
import VideosCarousel from "components/common/VideosCarousel";
import TestimonyCarousel from "components/common/TestimoniesCarousel";
import FoundersPartnersSection from "components/common/FoundersPartnersSection";
import ServicesSection from "components/common/ServicesSection";

import discordIcon from "assets/images/missionPage_discord-icon.svg";
import instagramIcon from "assets/images/missionPage_instagram-icon.svg";
import linkedIn from "assets/images/missionPage_linkedin-icon.svg";
import tiktokIcon from "assets/images/missionPage_tiktok-icon.svg";

import MissionPageStyles from "./MissionPage.module.scss";

const MissionPage = () => {
  const { t } = useTranslation("mission");
  const history = useHistory();

  const links = [
    { icon: tiktokIcon, url: t("shortVideosTiktokUrl") },
    { icon: linkedIn, url: t("shortVideosLinkedinUrl") },
    {
      icon: instagramIcon,
      url: t("shortVideosInstagramUrl"),
    },
    { icon: discordIcon, url: t("shortVideosDiscordUrl") },
  ];

  const {
    breakpoints,
    variables: {
      containerPX,
      spaceBetweenSectionsDesktop,
      spaceBetweenSectionsMobile,
      missionPage: {
        containerMarginY,
        desktop: {
          responseShortVideosPaddingYDesktop,
          missionDescriptionMarginTopDesktop,
          missionPictureMarginTopDesktop,
          missionPictureWidthDesktop,
          missionPictureHeightDesktop,
          observationsWidthDesktop,
          responseContainerGapDesktop,
          responseDescriptionWidthDesktop,
          proposalPictureWidthDesktop,
          proposalPictureHeightDesktop,
          proposalContainerGapDesktop,
          proposalContainerMarginBottomDesktop,
          proposalPictureBoxWidthDesktop,
          proposalPictureBoxShadowDesktop,
          proposalPictureBorderRadiusDesktop,
          proposalTitleMarginBottomDesktop,
          proposalTitleWidthDesktop,
          proposalButtonMarginTopDesktop,
          testimoniesSectionMarginBottomDesktop,
          testimoniesSectionMarginBottomMobile,
          shortVideosLeftBoxWidthDesktop,
          shortVideosTitleWidthDesktop,
          shortVideosTitleMarginBottomDesktop,
          shortVideosDescriptionWidthDesktop,
          shortVideosLinksGapDesktop,
          shortVideosLinksMarginTopDesktop,
          shortVideosRightBoxWidthDesktop,
          shortVideosMarginBottomDesktop,
          ecosystemContainerGapDesktop,
          ecosystemBoxLeftWidthDesktop,
          ecosystemBoxLeftPaddingDesktop,
          ecosystemBoxLeftBorderRadiusDesktop,
          ecosystemImageTopDesktop,
          ecosystemImageMarginTopDesktop,
          ecosystemBoxRightWidthDesktop,
          foundersPartnersDescriptionWidthDesktop,
        },
        mobile: {
          responseShortVideosPaddingYMobile,
          teamPictureSmallHeightMobile,
          observationsWidthMobile,
          responseContainerGapMobile,
          responseDescriptionWidthMobile,
          proposalContainerGapMoible,
          proposalPictureHeightMobile,
          shortVideosLeftBoxWidthMobile,
          shortVideosTitleMarginBottomMobile,
          shortVideosDescriptionWidthMobile,
          shortVideosRightBoxWidthMobile,
          shortVideosCarouselMarginBottomMobile,
          ecosystemLeftBoxWidthMobile,
          ecosystemRightBoxWidthMobile,
          ecosystemBoxMarginBottomMobile,
          foundersPartnersDescriptionWidthMobile,
        },
      },
    },
    typography: {
      missionPage: {
        subTitleStyles,
        missionDescriptionStyles,
        observationsNumberStyles,
        observationsDescriptionStyles,
        observationsSourceStyles,
        shortVideosDescriptionFontFamily,
      },
    },
    palette: {
      missionPage: {
        observationsSourceColor,
        responseBackgroundColor,
        shortVideosBackgroundColor,
        ecosystemBoxBackgroundColor,
      },
    },
  } = useTheme();

  const isScreenDownSM = useMediaQuery(breakpoints.down("sm"));
  const isScreenUpLG = useMediaQuery(breakpoints.up("lg"));
  const isScreenDownLG = useMediaQuery(breakpoints.down("lg"));

  const goToQuestionnaireQ1 = useCallback(() => {
    navigateToDiagnosticDataGA("MissionPage");
    history.push(t("path:path.questionnaire") + "/q1");
  }, [history, t]);

  return (
    <>
      <section name="mission">
        <Container
          maxWidth="xl"
          sx={{ px: containerPX, marginTop: containerMarginY }}
        >
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            mb={
              isScreenDownSM
                ? spaceBetweenSectionsMobile
                : spaceBetweenSectionsDesktop
            }
          >
            <Typography variant="h1">
              <Interweave content={t("title")} />
            </Typography>
            <Typography
              variant="paragraph"
              sx={{
                ...missionDescriptionStyles,
                marginTop: missionDescriptionMarginTopDesktop,
              }}
            >
              <Interweave content={t("missionDescription")} />
            </Typography>
            <Box mt={missionPictureMarginTopDesktop}>
              <img
                src={
                  isScreenDownSM
                    ? getMissionPicturesUrlFromFirebaseDevStorage(
                        t("missionTeamPictureSmall")
                      )
                    : getMissionPicturesUrlFromFirebaseDevStorage(
                        t("missionTeamPictureLarge")
                      )
                }
                alt={t("missionTeamPictureAlt")}
                width={missionPictureWidthDesktop}
                height={
                  isScreenDownSM
                    ? teamPictureSmallHeightMobile
                    : missionPictureHeightDesktop
                }
              />
            </Box>
          </Box>
        </Container>
      </section>
      <section name="observations">
        <Container maxWidth="xl" sx={{ px: containerPX }}>
          <Box
            display="flex"
            flexDirection={isScreenDownLG ? "column" : "row"}
            gap={isScreenDownLG ? 8 : 30}
            mb={
              isScreenDownLG
                ? spaceBetweenSectionsMobile
                : spaceBetweenSectionsDesktop
            }
          >
            <Typography sx={subTitleStyles}>
              <Interweave content={t("observationsTitle")} />
            </Typography>
            <Box
              width={
                isScreenDownLG
                  ? observationsWidthMobile
                  : observationsWidthDesktop
              }
            >
              <Typography sx={observationsNumberStyles}>
                <Interweave content={t("observationsNumber_1")} />
              </Typography>
              <Typography sx={observationsDescriptionStyles}>
                <Interweave content={t("observationsDescription_1")} />
              </Typography>
              <Typography
                sx={{
                  ...observationsSourceStyles,
                  color: observationsSourceColor,
                }}
              >
                <Interweave content={t("observationsSource_1")} />
              </Typography>
            </Box>
            <Box
              width={
                isScreenDownLG
                  ? observationsWidthMobile
                  : observationsWidthDesktop
              }
            >
              <Typography sx={observationsNumberStyles}>
                <Interweave content={t("observationsNumber_2")} />
              </Typography>
              <Typography sx={observationsDescriptionStyles}>
                <Interweave content={t("observationsDescription_2")} />
              </Typography>
              <Typography
                sx={{
                  ...observationsSourceStyles,
                  color: observationsSourceColor,
                }}
              >
                <Interweave content={t("observationsSource_2")} />
              </Typography>
            </Box>
          </Box>
        </Container>
      </section>
      <section name="response">
        <Box
          bgcolor={responseBackgroundColor}
          paddingY={
            isScreenDownSM
              ? responseShortVideosPaddingYMobile
              : responseShortVideosPaddingYDesktop
          }
          px={containerPX}
          mb={
            isScreenDownLG
              ? spaceBetweenSectionsMobile
              : spaceBetweenSectionsDesktop
          }
        >
          <Container
            maxWidth="xl"
            sx={{
              display: "flex",
              flexDirection: isScreenDownLG ? "column" : "row",
              gap: isScreenDownLG
                ? responseContainerGapMobile
                : responseContainerGapDesktop,
            }}
          >
            <Typography sx={subTitleStyles}>
              <Interweave content={t("responseTitle")} />
            </Typography>
            <Box
              width={
                isScreenDownLG
                  ? responseDescriptionWidthMobile
                  : responseDescriptionWidthDesktop
              }
            >
              <Typography variant="paragraph">
                <Interweave content={t("responseDescription")} />
              </Typography>
              <br /> <br />
              <a
                href={getDocumentsPDFUrlFromFirebaseDevStorage(
                  "Rapportdactivite-VF-MaVoieOrg.pdf"
                )}
              >
                <Button
                  variant="contained"
                  color="black"
                  size="large"
                  endIcon={<Icon iconName="arrowRightShort" />}
                  onClick={clickDownloadActivitiesReportDataGA}
                >
                  {t("responseButtonText")}
                </Button>
              </a>
            </Box>
          </Container>
        </Box>
      </section>
      <section name="proposal">
        <Container
          maxWidth="xl"
          sx={{
            display: "flex",
            flexDirection: isScreenDownLG ? "column" : "row",
            gap: isScreenDownLG
              ? proposalContainerGapMoible
              : proposalContainerGapDesktop,
            alignItems: "center",
            mb: proposalContainerMarginBottomDesktop,
          }}
        >
          <Box
            width={proposalPictureBoxWidthDesktop}
            boxShadow={proposalPictureBoxShadowDesktop}
            borderRadius={proposalPictureBorderRadiusDesktop}
            className={MissionPageStyles.proposalPictureBox}
          >
            <img
              src={getMissionPicturesUrlFromFirebaseDevStorage(
                t("proposalPicture")
              )}
              alt={t("missionPageProposalPicture")}
              width={proposalPictureWidthDesktop}
              height={
                isScreenDownSM
                  ? proposalPictureHeightMobile
                  : proposalPictureHeightDesktop
              }
              style={{ borderRadius: proposalPictureBorderRadiusDesktop }}
              className={MissionPageStyles.proposalPicture}
            />
          </Box>
          <Box px={containerPX}>
            <Typography
              sx={{
                ...subTitleStyles,
                width: proposalTitleWidthDesktop,
                mb: proposalTitleMarginBottomDesktop,
              }}
            >
              <Interweave content={t("proposalTitle")} />
            </Typography>
            <Typography variant="paragraph">
              <Interweave content={t("proposalDescription")} />
            </Typography>
            <Button
              sx={{ mt: proposalButtonMarginTopDesktop }}
              variant="contained"
              color="black"
              size="large"
              endIcon={<Icon iconName="arrowRightShort" />}
              onClick={goToQuestionnaireQ1}
            >
              {t("proposalButtonText")}
            </Button>
          </Box>
        </Container>
      </section>
      <section name="testimonies" style={{ overflow: "hidden" }}>
        <Container maxWidth="xl">
          <Box
            display="flex"
            justifyContent="center"
            sx={{
              mb: isScreenDownSM
                ? testimoniesSectionMarginBottomMobile
                : testimoniesSectionMarginBottomDesktop,
            }}
          >
            <TestimonyCarousel
              size={
                isScreenUpLG
                  ? "isDesktop"
                  : isScreenDownSM
                  ? "isMobile"
                  : "isTablet"
              }
            />
          </Box>
        </Container>
      </section>
      <section name="shortVideos">
        <Box
          bgcolor={shortVideosBackgroundColor}
          paddingY={
            isScreenDownSM
              ? responseShortVideosPaddingYMobile
              : responseShortVideosPaddingYDesktop
          }
          px={containerPX}
          mb={
            isScreenDownSM
              ? spaceBetweenSectionsMobile
              : shortVideosMarginBottomDesktop
          }
        >
          <Container
            maxWidth="xl"
            sx={{
              display: "flex",
              flexDirection: isScreenDownSM ? "column" : "row",
              alignItems: isScreenDownSM ? "start" : "center",
            }}
          >
            <Box
              width={
                isScreenDownSM
                  ? shortVideosLeftBoxWidthMobile
                  : shortVideosLeftBoxWidthDesktop
              }
            >
              <Typography
                variant="h2"
                sx={{
                  width: shortVideosTitleWidthDesktop,
                  mb: isScreenDownSM
                    ? shortVideosTitleMarginBottomMobile
                    : shortVideosTitleMarginBottomDesktop,
                }}
              >
                <Interweave content={t("shortVideosTitle")} />
              </Typography>
              <Box
                width={
                  isScreenDownSM
                    ? shortVideosDescriptionWidthMobile
                    : shortVideosDescriptionWidthDesktop
                }
              >
                <Typography variant="paragraph">
                  <Interweave content={t("shortVideosDescription_1")} />
                </Typography>
                <br /> <br />
                {!isScreenDownSM && (
                  <>
                    <Typography
                      variant="paragraph"
                      sx={{ fontFamily: shortVideosDescriptionFontFamily }}
                    >
                      <Interweave content={t("shortVideosDescription_2")} />
                    </Typography>
                    <Box
                      display="flex"
                      gap={shortVideosLinksGapDesktop}
                      mt={shortVideosLinksMarginTopDesktop}
                    >
                      {links.map((link) => (
                        <a
                          key={uuidv4()}
                          href={t(link.url)}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <img src={link.icon} alt={link.title} />
                        </a>
                      ))}
                    </Box>
                  </>
                )}
              </Box>
            </Box>
            <Box
              width={
                isScreenDownSM
                  ? shortVideosRightBoxWidthMobile
                  : shortVideosRightBoxWidthDesktop
              }
              mb={isScreenDownSM ? shortVideosCarouselMarginBottomMobile : 0}
            >
              <VideosCarousel />
            </Box>
            {isScreenDownSM && (
              <>
                <Typography
                  variant="paragraph"
                  sx={{ fontFamily: shortVideosDescriptionFontFamily }}
                >
                  <Interweave content={t("shortVideosDescription_2")} />
                </Typography>
                <Box
                  display="flex"
                  gap={shortVideosLinksGapDesktop}
                  mt={shortVideosLinksMarginTopDesktop}
                >
                  {links.map((link) => (
                    <a
                      key={uuidv4()}
                      href={t(link.url)}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img src={link.icon} alt={link.title} />
                    </a>
                  ))}
                </Box>
              </>
            )}
          </Container>
        </Box>
      </section>
      <section name="ecoSystem">
        <Container
          maxWidth="xl"
          sx={{
            display: "flex",
            flexDirection: isScreenDownSM ? "column-reverse" : "row",
            gap: ecosystemContainerGapDesktop,
            alignItems: "center",
            mb: isScreenDownSM
              ? spaceBetweenSectionsMobile
              : spaceBetweenSectionsDesktop,
            px: containerPX,
          }}
        >
          <Box
            width={
              isScreenDownSM
                ? ecosystemRightBoxWidthMobile
                : ecosystemBoxLeftWidthDesktop
            }
            padding={ecosystemBoxLeftPaddingDesktop}
            bgcolor={ecosystemBoxBackgroundColor}
            borderRadius={ecosystemBoxLeftBorderRadiusDesktop}
            textAlign="center"
          >
            <Box
              position="relative"
              top={ecosystemImageTopDesktop}
              mt={ecosystemImageMarginTopDesktop}
            >
              <img
                src={getMissionPicturesUrlFromFirebaseDevStorage(
                  t("ecoSystemDivPicture")
                )}
                alt={t("ecoSystemDivPictureAlt")}
              />
            </Box>
            <Typography variant="paragraph">
              <Interweave content={t("ecoSystemDivQuote")} />
            </Typography>
            <br />
            <br />
            <Typography variant="paragraph" fontFamily="ProximaNovaBold">
              <Interweave content={t("ecoSystemDivName")} />
            </Typography>
          </Box>
          <Box
            width={
              isScreenDownSM
                ? ecosystemLeftBoxWidthMobile
                : ecosystemBoxRightWidthDesktop
            }
            textAlign={isScreenDownSM && "center"}
            mb={isScreenDownSM && ecosystemBoxMarginBottomMobile}
          >
            <Typography variant="h2">
              <Interweave content={t("ecoSystemTitle")} />
            </Typography>
            <br />
            <br />
            <Typography variant="paragraph">
              <Interweave content={t("ecoSystemDescription")} />
            </Typography>
          </Box>
        </Container>
      </section>
      <section name="foundersPartners">
        <Container
          maxWidth="xl"
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: isScreenDownSM ? "start" : "center",
            mb: isScreenDownSM
              ? spaceBetweenSectionsMobile
              : spaceBetweenSectionsDesktop,
            px: containerPX,
          }}
        >
          <Typography
            variant="h2"
            sx={{
              mb: 10,
            }}
          >
            <Interweave content={t("foundersPartnersTitle")} />
          </Typography>
          <Typography
            variant="paragraph"
            textAlign={isScreenDownSM ? "left" : "center"}
            width={
              isScreenDownSM
                ? foundersPartnersDescriptionWidthMobile
                : foundersPartnersDescriptionWidthDesktop
            }
            sx={{
              mb: 10,
            }}
          >
            <Interweave content={t("foundersPartnersDescription_1")} />
            <br /> <br />
            <Interweave content={t("foundersPartnersDescription_2")} />
          </Typography>

          <FoundersPartnersSection />
        </Container>
      </section>
      <section name="missionPageServices">
        <Container maxWidth="xl">
          <ServicesSection
            title={t("missionPageServicesTitle")}
            description={t("missionPageServicesDescription")}
            orangeText={t("missionPageOrangeText")}
            greenText={t("missionPageGreenText")}
            brownText={t("missionPageBrownText")}
            blueText={t("missionPageBlueText")}
          />
        </Container>
      </section>
    </>
  );
};

export default MissionPage;

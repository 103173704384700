import { useEffect } from "react";

import { getQuestionAnswers } from "utils/helpers/questionnaireHelper";
import rdvSolutionData from "utils/data/rdvSolutionData.json";

import { handleScheduledCalendlyUser } from "./solutionControllers";

function isCalendlyViewEvent(e) {
  return e.data.event?.indexOf("calendly.event_type_viewed") === 0;
}

function isCalendlySelectDateEvent(e) {
  return e.data.event?.indexOf("calendly.date_and_time_selected") === 0;
}

function isCalendlyScheduledEvent(e) {
  return e.data.event?.indexOf("calendly.event_scheduled") === 0;
}

const initiateCalendlyEventsCallback =
  (
    userUID,
    inscriptionDate,
    setDisplayMentions,
    setDisplayBackToHomeScreen,
    userAnswers,
    hydratedSolutions,
    hasSubmitedEmailInForm
  ) =>
  () => {
    window.addEventListener("message", async (e) => {
      if (isCalendlyViewEvent(e)) {
        setDisplayMentions(false);
        setDisplayBackToHomeScreen(false);
      } else if (isCalendlyScheduledEvent(e)) {
        const uriInvitee = e.data?.payload?.invitee?.uri;

        if (uriInvitee) {
          setDisplayMentions(false);
          setDisplayBackToHomeScreen(true);
          handleScheduledCalendlyUser(
            userUID,
            inscriptionDate,
            uriInvitee,
            userAnswers,
            hydratedSolutions,
            hasSubmitedEmailInForm
          );
        }
      } else if (isCalendlySelectDateEvent(e)) {
        setDisplayMentions(true);
        setDisplayBackToHomeScreen(false);
      }
    });
  };

export const useCalendlyEventsEffect = (
  widgetRef,
  userUID,
  inscriptionDate,
  setDisplayMentions,
  setDisplayBackToHomeScreen,
  userAnswers,
  hydratedSolutions,
  hasSubmitedEmailInForm
) =>
  useEffect(() => {
    if (widgetRef != null) {
      initiateCalendlyEventsCallback(
        userUID,
        inscriptionDate,
        setDisplayMentions,
        setDisplayBackToHomeScreen,
        userAnswers,
        hydratedSolutions,
        hasSubmitedEmailInForm
      )();
    }
  }, [
    widgetRef,
    userUID,
    inscriptionDate,
    setDisplayMentions,
    setDisplayBackToHomeScreen,
    userAnswers,
    hydratedSolutions,
    hasSubmitedEmailInForm,
  ]);

export const getTextsForEmailTemplate = (
  userAnswers,
  hydratedSolutionsResults
) => {
  const questionAnswerMap = getQuestionAnswers(userAnswers);
  return {
    responsesTextsForEmailTemplate: (questionAnswerMap || [])
      .map((answer) => answer.resume)
      .filter((resume) => resume),
    solutionsTextsForEmailTemplate: [
      ...(hydratedSolutionsResults.formations || [])
        .map((f) => {
          const listDescriptions = f.descriptions?.[0]?.split("\n");

          return {
            logo: f.srcLogo,
            title: `${f.name} - ${f.partner}`,
            link: f.name.includes("1entretien 1job")
              ? "https://mavoie.org/1entretien1job"
              : f.link,
            descriptions: listDescriptions,
            secondaryDescription: f.descriptions?.[1],
          };
        })
        .concat({
          logo: rdvSolutionData?.srcLogo,
          title: rdvSolutionData?.title,
          link: "https://mavoie.org/rdv-decouverte",
          descriptions: rdvSolutionData?.descriptions,
          secondaryDescription: rdvSolutionData?.intro,
        }),
    ],
    suggestionsTextsForEmailTemplate: [
      ...(hydratedSolutionsResults.suggestions || []).map((f) => ({
        logo: f.srcLogo,
        title: f.title,
        link: f.name.includes("1entretien 1job")
          ? "https://mavoie.org/1entretien1job"
          : f.link,
        secondaryDescription: f.descriptions?.[1],
      })),
    ],
  };
};

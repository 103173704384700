export const colors = {
  //orange
  orange: "#FF6000",
  orange100: "#FFEFE5",
  orange200: "#FFDFCC",
  orange300: "#FFCFB3",
  orange400: "#FFBF99",
  orange500: "#FFAF80",
  orange600: "#FFA066",
  orange700: "#FF904D",
  orange800: "#FF8033",
  orange900: "#FF701A",
  //green
  green: "#00827F",
  green100: "#E5F3F2",
  green200: "#CCE6E5",
  green300: "#B3DAD9",
  green400: "#99CDCC",
  green500: "#80C0BF",
  green600: "#66B4B2",
  green700: "#4DA7A5",
  green800: "#339B99",
  green900: "#1A8F8C",
  //blue
  blue: "#5794F7",
  blue100: "#EEF4FE",
  blue200: "#DDEAFD",
  blue300: "#CDDFFD",
  blue400: "#BCD4FC",
  blue500: "#ABC9FB",
  blue600: "#9ABFFA",
  blue700: "#89B4F9",
  blue800: "#79A9F9",
  blue900: "#689FF8",
  //brown
  brown: "#352D2D",
  brown100: "#EBEAEA",
  brown200: "#D7D5D5",
  brown300: "#C2C0C0",
  brown400: "#AEABAB",
  brown500: "#9A9696",
  brown600: "#868181",
  brown700: "#726C6C",
  brown800: "#5E5757",
  brown900: "#494242",
  //complementary
  lightOrange: "#FFF2EB",
  lightGreen: "#EBF5F5",
  lightBlue: "#EBF2FE",
  lightBrown: "#EFEEEE",
  //neutral
  white: "#FFFFFF",
  neutral000: "#F8F9FA",
  neutral100: "#F1F3F5",
  neutral200: "#E9ECEF",
  neutral300: "#DEE2E6",
  neutral400: "#CED4DA",
  neutral500: "#ADB5BD",
  neutral600: "#868E96",
  neutral700: "#585F66",
  neutral800: "#1D222A",
  neutral900: "#0D131B",
  black: "#000000",
};

const getButtonColorProps = (
  textColor,
  hoveredTextColor,
  backgroundColor,
  hoveredBackgroundColor,
  iconColor,
  hoveredIconColor,
  borderColor = "transparent",
  disabledTextColor = colors.white,
  disabledBackgroundColor = colors.neutral400
) => ({
  textColor,
  hoveredTextColor,
  backgroundColor,
  hoveredBackgroundColor,
  iconColor,
  hoveredIconColor,
  borderColor,
  disabledTextColor,
  disabledBackgroundColor,
});

const allStylesButtonColor = {
  orange: {
    outlined: getButtonColorProps(
      colors.orange,
      colors.white,
      colors.white,
      colors.orange,
      colors.orange,
      colors.white,
      colors.orange
    ),
    contained: getButtonColorProps(
      colors.white,
      colors.orange,
      colors.orange,
      colors.orange100,
      colors.white,
      colors.orange
    ),
    text: getButtonColorProps(
      colors.orange,
      colors.orange,
      "transparent",
      "transparent",
      colors.orange,
      colors.orange,
      "transparent",
      colors.neutral400,
      "transparent"
    ),
  },
  green: {
    outlined: getButtonColorProps(
      colors.green,
      colors.white,
      colors.white,
      colors.green,
      colors.green,
      colors.white,
      colors.green
    ),
    contained: getButtonColorProps(
      colors.white,
      colors.white,
      colors.green,
      colors.green100,
      colors.white,
      colors.green
    ),
    text: getButtonColorProps(
      colors.green,
      colors.green,
      "transparent",
      "transparent",
      colors.green,
      colors.green,
      "transparent",
      colors.neutral400,
      "transparent"
    ),
  },
  black: {
    outlined: getButtonColorProps(
      colors.neutral900,
      colors.white,
      colors.white,
      colors.neutral900,
      colors.neutral900,
      colors.white,
      colors.neutral900
    ),
    contained: getButtonColorProps(
      colors.white,
      colors.white,
      colors.neutral900,
      colors.neutral700,
      colors.white,
      colors.white
    ),
    text: getButtonColorProps(
      colors.neutral900,
      colors.neutral900,
      "transparent",
      "transparent",
      colors.neutral900,
      colors.neutral900,
      "transparent",
      colors.neutral400,
      "transparent"
    ),
  },
  blue: {
    outlined: getButtonColorProps(
      colors.blue,
      colors.white,
      colors.white,
      colors.blue,
      colors.blue,
      colors.white,
      colors.blue
    ),
    contained: getButtonColorProps(
      colors.white,
      colors.white,
      colors.blue,
      colors.blue100,
      colors.white,
      colors.blue
    ),
    text: getButtonColorProps(
      colors.blue,
      colors.blue,
      "transparent",
      "transparent",
      colors.blue,
      colors.blue,
      "transparent",
      colors.neutral400,
      "transparent"
    ),
  },
  brown: {
    outlined: getButtonColorProps(
      colors.brown,
      colors.white,
      colors.white,
      colors.brown,
      colors.brown,
      colors.white,
      colors.brown
    ),
    contained: getButtonColorProps(
      colors.white,
      colors.white,
      colors.brown,
      colors.brown100,
      colors.white,
      colors.white
    ),
    text: getButtonColorProps(
      colors.brown,
      colors.brown,
      "transparent",
      "transparent",
      colors.brown,
      colors.brown,
      "transparent",
      colors.neutral400,
      "transparent"
    ),
  },
};

const palette = {
  orange: {
    main: colors.orange,
    orange100: colors.orange100,
    orange200: colors.orange200,
    orange300: colors.orange300,
    orange400: colors.orange400,
    orange500: colors.orange500,
    orange600: colors.orange600,
    orange700: colors.orange700,
    orange800: colors.orange800,
    orange900: colors.orange900,
    complementary: colors.lightOrange,
  },
  green: {
    main: colors.green,
    green100: colors.green100,
    green200: colors.green200,
    green300: colors.green300,
    green400: colors.green400,
    green500: colors.green500,
    green600: colors.green600,
    green700: colors.green700,
    green800: colors.green800,
    green900: colors.green900,
    complementary: colors.lightGreen,
  },
  blue: {
    main: colors.blue,
    blue100: colors.blue100,
    blue200: colors.blue200,
    blue300: colors.blue300,
    blue400: colors.blue400,
    blue500: colors.blue500,
    blue600: colors.blue600,
    blue700: colors.blue700,
    blue800: colors.blue800,
    blue900: colors.blue900,
    complementary: colors.lightBlue,
  },
  brown: {
    main: colors.brown,
    brown100: colors.brown100,
    brown200: colors.brown200,
    brown300: colors.brown300,
    brown400: colors.brown400,
    brown500: colors.brown500,
    brown600: colors.brown600,
    brown700: colors.brown700,
    brown800: colors.brown800,
    brown900: colors.brown900,
    complementary: colors.lightBrown,
  },
  white: {
    main: colors.white,
    neutral000: colors.neutral000,
    neutral100: colors.neutral100,
    neutral200: colors.neutral200,
    neutral300: colors.neutral300,
    neutral400: colors.neutral400,
  },
  black: {
    main: colors.black,
    neutral500: colors.neutral500,
    neutral600: colors.neutral600,
    neutral700: colors.neutral700,
    neutral800: colors.neutral800,
    neutral900: colors.neutral900,
  },
  //order by alphabetique
  breadcrumbs: {
    textColor: colors.black,
    separatorColor: colors.orange,
  },
  button: allStylesButtonColor,
  drawer: {
    backgroundColor: colors.white,
    borderColor: colors.orange,
  },
  tabFoundersPartners: {
    tabBackgroundColor: colors.lightOrange,
    tabSelectedBackgroundColor: colors.orange,
    tabSelectedTextColor: colors.white,
    tabTextColor: colors.neutral800,
  },
  icon: {
    color: colors.orange,
    hoveredColor: colors.orange,
    disabledColor: colors.neutral400,
  },
  iconButton: {
    color: colors.orange,
    hoveredColor: colors.orange,
    disabledColor: colors.neutral400,
  },
  linearProgress: {
    backgroundColor: colors.neutral400,
    color: colors.orange,
  },
  missionPage: {
    observationsSourceColor: colors.green,
    responseBackgroundColor: colors.lightOrange,
    shortVideosBackgroundColor: colors.lightGreen,
    ecosystemBoxBackgroundColor: colors.lightOrange,
  },
  modalMail: {
    backgroundColor: colors.white,
    titleColor: colors.green,
    continuerWithoutEmailColor: colors.neutral700,
    termsTextColor: colors.neutral600,
    inputBorderColor: colors.neutral700,
  },
  paper: {
    backgroundColor: colors.white,
    borderColor: colors.orange,
  },
  snackbar: {
    backgroundColor: colors.orange,
    textColor: colors.white,
  },
  solutionCard: {
    green: {
      titleStyles: {
        backgroundColor: colors.green,
        color: colors.white,
      },
      contentStyles: {
        backgroundColor: colors.white,
      },
    },
  },
  teamPage: {
    //contact-us
    bgcolor: colors.blue100,
  },
  teamDirectorsCard: {
    otherJobTextColor: colors.blue,
  },
  tab: {
    green: {
      textColor: colors.brown700,
      backgroundColor: colors.lightGreen,
      hover: { backgroundColor: colors.lightGreen },
      selected: { textColor: colors.green, backgroundColor: colors.lightGreen },
    },
  },
  tabs: {},
  videosCarouselButton: {
    buttonColor: colors.green,
    isAtStartEndColor: colors.green400,
  },
};

export default palette;

import React from "react";

import { SolutionsCardStylesProvider } from "hooks/withSolutionCardStyles";

import SolutionCard from "components/Solutions/SolutionCard";

import { Box, Container, useMediaQuery, useTheme } from "@mui/material";

const FormationsSection = ({
  tabValue,
  formations,
  openMailModal,
  setSelectedSolution,
}) => {
  const theme = useTheme();
  const isScreenSmall = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Box
      py={isScreenSmall ? 6 : 17}
      sx={{ width: "100%", bgcolor: theme.palette.green.complementary }}
    >
      <Container
        maxWidth="xl"
        sx={{
          display: "flex",
          gap: 3,
          flexWrap: "wrap",
          justifyContent: "center",
        }}
      >
        {tabValue === 0 && (
          <SolutionsCardStylesProvider>
            {formations?.map((solution) => (
              <SolutionCard
                key={solution.name}
                srcLogo={solution.srcLogo}
                title={`${solution.name} - ${solution.partner}`}
                tags={solution.tags}
                type={solution.type}
                link={solution.link}
                listDescriptions={solution.descriptions}
                openMailModal={openMailModal}
                setSelectedSolution={setSelectedSolution}
              />
            ))}
          </SolutionsCardStylesProvider>
        )}
        {tabValue === 1 && (
          <SolutionsCardStylesProvider>
            {formations?.map(
              (solution) =>
                solution.type === "Formation" && (
                  <SolutionCard
                    key={solution.name}
                    srcLogo={solution.srcLogo}
                    title={`${solution.name} - ${solution.partner}`}
                    tags={solution.tags}
                    type={solution.type}
                    link={solution.link}
                    listDescriptions={solution.descriptions}
                    openMailModal={openMailModal}
                    setSelectedSolution={setSelectedSolution}
                  />
                )
            )}
          </SolutionsCardStylesProvider>
        )}
        {tabValue === 2 && (
          <SolutionsCardStylesProvider>
            {formations?.map(
              (solution) =>
                solution.type === "Outil numérique" && (
                  <SolutionCard
                    key={solution.name}
                    srcLogo={solution.srcLogo}
                    title={`${solution.name} - ${solution.partner}`}
                    tags={solution.tags}
                    type={solution.type}
                    link={solution.link}
                    listDescriptions={solution.descriptions}
                    openMailModal={openMailModal}
                    setSelectedSolution={setSelectedSolution}
                  />
                )
            )}
          </SolutionsCardStylesProvider>
        )}
        {tabValue === 3 && (
          <SolutionsCardStylesProvider>
            {formations?.map(
              (solution) =>
                solution.type === "Accompagnement" && (
                  <SolutionCard
                    key={solution.name}
                    srcLogo={solution.srcLogo}
                    title={`${solution.name} - ${solution.partner}`}
                    tags={solution.tags}
                    type={solution.type}
                    link={solution.link}
                    listDescriptions={solution.descriptions}
                    openMailModal={openMailModal}
                    setSelectedSolution={setSelectedSolution}
                  />
                )
            )}
          </SolutionsCardStylesProvider>
        )}
      </Container>
    </Box>
  );
};

export default FormationsSection;

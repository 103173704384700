import {
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  FormGroup,
  IconButton,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import Icon from "components/common/Icon/Icon";
import NewsletterFormStyles from "./NewsletterForm.module.scss";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { isEmailValidRFC822 } from "utils/helpers/usersHelper";
import { useSelector } from "react-redux";
import { selectUser } from "store/users/usersSelectors";
import { subscribeToNewsletter } from "domain/newsletter/newsletterController";

const NewsletterForm = () => {
  const theme = useTheme();
  const isScreenDownSM = useMediaQuery(theme.breakpoints.down("sm"));

  const { t } = useTranslation("homePage");

  const [email, setEmail] = useState("");
  const [acceptTerms, setAcceptTerms] = useState(false);
  const [showWarningTerms, setShowWarningTerms] = useState(false);
  const [showErrorEmailFormat, setShowErrorEmailFormat] = useState(false);
  const [loading, setLoading] = useState(false);

  const [emailSent, setEmailSent] = useState(false);

  const user = useSelector(selectUser);

  const handleSaveEmailNewsletter = useCallback(() => {
    if (!acceptTerms) {
      setShowWarningTerms(true);
      return;
    }
    if (!isEmailValidRFC822(email)) {
      setShowErrorEmailFormat(true);
      return;
    }
    setLoading(true);
    subscribeToNewsletter(email, user.uid)
      .then(() => {
        setLoading(false);
        setEmailSent(true);
        setEmail(t("footersection.emailSentValue"));
      })
      .error(() => {
        setLoading(false);
      });
  }, [acceptTerms, email, user.uid, t]);

  const handleEmailInputValue = useCallback((event) => {
    setEmail(event.target.value);
    setShowErrorEmailFormat(false);
  }, []);

  const handleAcceptTerms = useCallback(() => {
    setAcceptTerms(!acceptTerms);
    setShowWarningTerms(false);
  }, [acceptTerms]);

  const emailNotValid =
    email !== "" &&
    email !== t("footersection.emailSentValue") &&
    !isEmailValidRFC822(email);

  const emailInpuClassName = emailNotValid
    ? NewsletterFormStyles.emailInputError
    : emailSent
    ? NewsletterFormStyles.emailInputDone
    : NewsletterFormStyles.emailInput;

  const iconName = emailSent
    ? "checkSharp"
    : emailNotValid || (isEmailValidRFC822(email) && !acceptTerms)
    ? "close"
    : "arrowCircleRight";

  const iconColor =
    !emailNotValid && acceptTerms
      ? "green"
      : emailSent
      ? "#004DCD"
      : emailNotValid
      ? "#c10000"
      : "#a69c95";

  const iconSize = emailSent
    ? 18
    : emailNotValid || (isEmailValidRFC822(email) && !acceptTerms)
    ? 13
    : 24;

  const iconButtonClassname = emailNotValid
    ? NewsletterFormStyles.emailSendButtonError
    : emailNotValid ||
      (isEmailValidRFC822(email) &&
        !acceptTerms &&
        NewsletterFormStyles.emailSendButton);

  return (
    <FormGroup sx={{ marginBottom: isScreenDownSM ? "20px" : "0" }}>
      <TextField
        type={"email"}
        placeholder={t("footersection.emailPlaceholder")}
        value={email}
        onChange={(evt) => handleEmailInputValue(evt)}
        InputProps={{
          endAdornment: loading ? (
            <CircularProgress size={18} />
          ) : (
            <IconButton
              onClick={handleSaveEmailNewsletter}
              className={iconButtonClassname}
              edge="end"
              sx={{
                backgroundColor: theme.palette.orange.orange100,
                "&:hover": {
                  backgroundColor: theme.palette.orange.orange100,
                },
              }}
            >
              <Icon
                iconColor={iconColor}
                iconName={iconName}
                iconSize={iconSize}
              />
            </IconButton>
          ),
          className: emailInpuClassName,
        }}
      />
      <FormControlLabel
        label={
          <Typography
            variant="paragraph"
            className={NewsletterFormStyles.acceptTermsLabel}
          >
            {t("footersection.acceptTerms")}
            <Button
              variant="text"
              color="black"
              href={t("path:path.terms")}
              target="_blank"
              className={NewsletterFormStyles.buttonTerms}
            >
              {t("footersection.terms")}
            </Button>
          </Typography>
        }
        control={
          <Checkbox checked={acceptTerms} onChange={handleAcceptTerms} />
        }
      />
      <Typography
        variant="paragraph"
        className={NewsletterFormStyles.warningTermsNotChecked}
        color={theme.palette.orange.main}
        hidden={!showWarningTerms}
      >
        {t("footersection.warningTermsNotChecked")}
      </Typography>
      <Typography
        variant="paragraph"
        className={NewsletterFormStyles.errorEmailFormat}
        color={theme.palette.orange.main}
        hidden={!showErrorEmailFormat}
      >
        {t("footersection.errorEmailFormat")}
      </Typography>
    </FormGroup>
  );
};

export default NewsletterForm;

import { initializeApp, getApps } from "firebase/app";
import {
  getAuth,
  signInAnonymously,
  setPersistence,
  inMemoryPersistence,
} from "firebase/auth";
import { getStorage } from "firebase/storage";
import { getFirestore } from "firebase/firestore";
import axios from "axios";
import algoliasearch from "algoliasearch";

import endpoints from "utils/constants/endpoints";

export let auth = {};
export let db = {};
export let searchClient = {};
export let storage = {};
export let searchIndex = "";
export let app = {};
export let projectId = "";
export let hitsPerPage = 20;

export const PERSIST_CONFIG_KEY = "root-reducer-MaVoie-webapp";

export async function invokeAppKeys() {
  axios({
    method: "get",
    url: endpoints.CONF_ALGOLIA(),
  }).then((response) => {
    searchClient = algoliasearch(
      response.data.algoliaAppId,
      response.data.algoliaApikey
    );
    searchIndex = response.data.algoliaIndex;
  });

  const response = await axios({
    method: "get",
    url: endpoints.CONF_FIREBASE(),
  });

  // Getting Keys

  if (getApps().length < 1) {
    // TODO(mehdi): to change when deciding the strategy to merge the interview branch with dev
    app = initializeApp({
      apiKey: response.data.apiKey,
      authDomain: response.data.authDomain,
      projectId: response.data.projectId,
      storageBucket: response.data.storagebucket,
      messagingSenderId: response.data.messagingSenderId,
      appId: response.data.appId,
    });
    storage = getStorage(app, "gs://mavoie-development.appspot.com");
  }
  auth = getAuth();
  await setPersistence(auth, inMemoryPersistence);
  await signInAnonymously(auth);
  db = getFirestore();
}

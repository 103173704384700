import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import clsx from "clsx";
import Interweave from "interweave";

import { useMediaQueries } from "hooks/useMediaQueries";
import SectionStyles from "./InterviewSteps.module.scss";

import Button from "components/common/Button(ToBeUpdated)";

import { saveInterviewSteps } from "domain/interview/interviewControllers";

import { getInterviewInfoPage } from "../../../utils/helpers/pathnameHelper";
import { Fade, Zoom } from "react-reveal";

const InterviewSteps = ({ page }) => {
  const { isTablet, isDesktop, isMobile } = useMediaQueries();
  const { t } = useTranslation("interview", "path");
  const history = useHistory();
  const dispatch = useDispatch();
  const { number, title, text, time, buttonText } = t(page, {
    returnObjects: true,
  });
  const { nextPage, trainingProgress } = useMemo(
    () => getInterviewInfoPage(page),
    [page]
  );

  const updateInterviewTraining = useCallback(() => {
    dispatch(saveInterviewSteps(trainingProgress, history, nextPage));
  }, [dispatch, trainingProgress, history, nextPage]);

  return (
    <>
      <div className={SectionStyles.frame}>{number}</div>
      <div className={SectionStyles.titleContainer}>
        <Zoom left>
          <Interweave className={SectionStyles.title} content={title} />
        </Zoom>
      </div>
      <div className={SectionStyles.textContainer}>
        <Fade delay={1000}>
          <Interweave className={SectionStyles.text} content={text} />
        </Fade>
      </div>

      <Fade delay={2000}>
        <div
          className={clsx({
            [SectionStyles.timeContainer]: true,
            [SectionStyles.timeMarginL]: isDesktop,
            [SectionStyles.timeMarginM]: isTablet,
            [SectionStyles.timeMarginS]: isMobile,
          })}
        >
          <Interweave
            className={clsx({
              [SectionStyles.time]: true,
            })}
            content={time}
          />
        </div>
      </Fade>

      <Button
        text={buttonText}
        onClick={updateInterviewTraining}
        style={{
          padding: "25px",
          width: "90%",
          maxWidth: "480px",
        }}
      />
    </>
  );
};

export default InterviewSteps;

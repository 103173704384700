const MuiPaper = {
  styleOverrides: {
    root: ({ theme }) => ({
      backgroundColor: theme.palette.green.complementary,
      color: theme.palette.black.main,
      width: "100%",
      " .MuiPaper-root": { left: "0px" },
      "&:hover": {
        backgroundColor: theme.palette.green.complementary,
      },
    }),
  },
};

export default MuiPaper;

import { useTranslation } from "react-i18next";
import TemplateFormInterview from "../../common/TemplateFormInterview";
import React, { useMemo } from "react";
import clsx from "clsx";
import InterviewCompanyActivitiesFormStyles from "../InterviewCompanyActivitiesForm/InterviewCompanyActivitiesForm.module.scss";
import MultiInputField from "../../common/MultiInputField";
import { useMediaQueries } from "../../../hooks/useMediaQueries";

const InterviewJobSoftSkillsForm = ({ values }) => {
  const { t } = useTranslation("interview");
  const {
    questionName,
    toolTipTextTitle,
    toolTipTextContent,
    placeHolders,
    textRequired,
    buttonLabelName,
  } = t("jobSoftSkills", {
    returnObjects: true,
  });
  const { isMobile } = useMediaQueries();
  const isButtonDisabled = useMemo(
    () => values.softskills?.join("") === "",
    [values.softskills]
  );

  return (
    <TemplateFormInterview
      questionName={questionName}
      toolTipTextTitle={toolTipTextTitle}
      toolTipTextContent={toolTipTextContent}
      isButtonDisabled={isButtonDisabled}
      buttonLabelName={buttonLabelName}
    >
      <div
        className={clsx({
          [InterviewCompanyActivitiesFormStyles.multiInputListL]: true,
          [InterviewCompanyActivitiesFormStyles.multiInputListS]: isMobile,
        })}
      >
        <MultiInputField
          name="softskills"
          number={5}
          maxLength={100}
          placeHolders={placeHolders}
        />
        <div className={InterviewCompanyActivitiesFormStyles.textRequired}>
          {textRequired}
        </div>
      </div>
    </TemplateFormInterview>
  );
};

export default InterviewJobSoftSkillsForm;

import { TrainingProgressFactory } from "domain/interview/interviewModels";
import { LastProgressNumberEnum } from "utils/constants/enums/LastProgressNumberEnum";
import { TrainingTagEnum } from "utils/constants/enums/TrainingTagEnum";

const interviewCompanyReducer = {
  saveCompanyActivitiesInterviewData: (state, action) => {
    return {
      ...state,
      currentInterview: {
        ...state.currentInterview,
        company: {
          ...state.currentInterview.company,
          activities: action.payload,
        },
        trainingProgress: TrainingProgressFactory(
          TrainingTagEnum.InterviewCompany,
          LastProgressNumberEnum.InterviewCompany["Q1"]
        ).instance,
      },
    };
  },

  saveCompanyValuesInterviewData: (state, action) => {
    return {
      ...state,
      currentInterview: {
        ...state.currentInterview,
        company: {
          ...state.currentInterview.company,
          values: action.payload,
        },
        trainingProgress: TrainingProgressFactory(
          TrainingTagEnum.InterviewCompany,
          LastProgressNumberEnum.InterviewCompany["Q2"]
        ).instance,
      },
    };
  },

  saveCompanySimulationInterviewData: (state, action) => {
    return {
      ...state,
      currentInterview: {
        ...state.currentInterview,
        company: {
          ...state.currentInterview.company,
          simulation: action.payload,
        },
        trainingProgress: TrainingProgressFactory(
          TrainingTagEnum.InterviewCompany,
          LastProgressNumberEnum.InterviewCompany["Q3"]
        ).instance,
      },
    };
  },

  saveInterviewCompanyAdviceData: (state, action) => {
    return {
      ...state,
      currentInterview: {
        ...state.currentInterview,
        company: {
          ...state.currentInterview.company,
          advices: [
            ...(state.currentInterview.company?.advices || []),
            {
              id: action.payload?.id,
              adviceText: action.payload?.adviceText,
            },
          ],
        },
        trainingProgress: action.payload?.trainingProgress,
      },
    };
  },
};

export default interviewCompanyReducer;

import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import Interweave from "interweave";

import {
  selectHydratedSolutions,
  selectUserAnswers,
  selectUserEmail,
  selectUserFirstName,
  selectUserUID,
} from "store/users/usersSelectors";

import {
  Box,
  Button,
  Container,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";

import PillCardWithCTA from "components/common/PillCardWithCTA";
import CustomTabs from "components/common/CustomTabs";
import FormationsSection from "components/Solutions/FormationsSection";
import PublicServiceSection from "components/Solutions/PublicServicesSection";
import EventsSection from "components/Solutions/EventsSection";
import SuggestionsSection from "components/Solutions/SuggestionsSection";
import ModalMail from "components/common/ModalMail";
import FilterMenu from "components/common/FilterMenu";

import { getTextsForEmailTemplate } from "domain/solution/solutionHooks";

import sendInBlueData from "utils/data/sendInBlueData";
import MapBranchIdGroupStar from "utils/constants/enums/MapBranchIdGroupStar";
import { onPopStateHistoryGoBackSkip } from "utils/helpers/windowHelpers";
import rdvSolutionData from "utils/data/rdvSolutionData.json";

import Icon from "components/common/Icon";
import { updateUserAnalyticsSolutions } from "domain/user/userControllers";
import clicRDVDataGA from "config/googleAnalytics/clicRDVDataGA";
import SourceClickRDVEnum from "utils/constants/enums/SourceClickRDVEnum";

const SolutionsPage = () => {
  const history = useHistory();
  const { aid } = useParams();
  const groupStar = MapBranchIdGroupStar[aid];

  //Using MuiTheme
  const theme = useTheme();
  const isScreenDownSM = useMediaQuery(theme.breakpoints.down("sm"));

  // Skipping the loader when we go back from solutions page
  useEffect(() => {
    const onPopState = onPopStateHistoryGoBackSkip(history);
    window.addEventListener("popstate", onPopState);
    return () => {
      // Remove the event listener to reset the default behavior
      window.removeEventListener("popstate", onPopState);
    };
  }, [history, aid]);

  //Getting solutions from redux
  const hydratedSolutionsResults = useSelector(selectHydratedSolutions);
  const { formations, publicServices, events, suggestions } =
    hydratedSolutionsResults;

  //Preparing data for Modalmail
  const [isMailModalOpen, openMailModal] = useState(false);
  const [selectedSolution, setSelectedSolution] = useState(null);
  const userAnswers = useSelector(selectUserAnswers);
  const {
    responsesTextsForEmailTemplate,
    solutionsTextsForEmailTemplate,
    suggestionsTextsForEmailTemplate,
  } = useMemo(
    () => getTextsForEmailTemplate(userAnswers, hydratedSolutionsResults),
    [hydratedSolutionsResults, userAnswers]
  );

  //Setup for tabs
  const [tabValue, setTabValue] = useState(0);
  const handleTabsChange = (event, newValue) => {
    setTabValue(newValue);
  };

  //Getting data from solutions.json
  const { t } = useTranslation(["solutions"]);
  const {
    calendlyText,
    calendlyButtonText,
    calendlyTitle,
    imgPillAlt,
    pillLarge,
    pillSmall,
  } = t("calendly", {
    returnObjects: true,
  });

  //onChange function for filterMenu only in mobile mode
  const handleFilterChange = useCallback((value) => {
    setTabValue(value);
  }, []);

  //Setup onClick function for Calendly CTA
  const userId = useSelector(selectUserUID);
  const userEmail = useSelector(selectUserEmail);
  const userFirstName = useSelector(selectUserFirstName);
  const handleOnClickCalendly = useCallback(() => {
    clicRDVDataGA(SourceClickRDVEnum.QUESTIONNAIRE_FROM_RESULT);
    updateUserAnalyticsSolutions(userId, rdvSolutionData?.title);
    window
      .open(
        `${rdvSolutionData?.link}?name=${userFirstName}&email=${userEmail}`,
        "_self"
      )
      .focus();
  }, [userEmail, userFirstName, userId]);

  return (
    <>
      <Container
        maxWidth="xl"
        sx={{
          marginY: 25,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography
          mb={10}
          textAlign="center"
          fontSize={
            isScreenDownSM
              ? theme.typography.HeadingStyles.fontSizes.xl6
              : theme.typography.HeadingStyles.fontSizes.xl8
          }
          sx={theme.typography.HeadingStyles}
        >
          {groupStar}
        </Typography>
        <Typography
          textAlign="center"
          fontSize={
            isScreenDownSM
              ? theme.typography.ParagraphStyles.fontSizes.xs
              : theme.typography.ParagraphStyles.fontSizes.md
          }
          sx={theme.typography.ParagraphStyles}
        >
          <Interweave content={t("solutionIntroText")} />
          <br />
          <Button
            startIcon={<Icon iconName="edit" iconColor="#1D222A" />}
            color="black"
            variant="text"
            href="/questionnaire/q1a"
          >
            {t("changeMySearch")}
          </Button>
        </Typography>
      </Container>
      {isScreenDownSM ? (
        <section name="filterMenu">
          <Box display="flex" justifyContent="center">
            <FilterMenu
              formations={formations}
              handleFilterChange={handleFilterChange}
            />
          </Box>
        </section>
      ) : (
        <section name="tabs">
          <CustomTabs
            tabValue={tabValue}
            handleTabsChange={handleTabsChange}
            formations={formations}
          />
        </section>
      )}
      <section name="formations">
        <FormationsSection
          tabValue={tabValue}
          formations={formations}
          openMailModal={openMailModal}
          setSelectedSolution={setSelectedSolution}
        />
      </section>
      <section name="calendly">
        <PillCardWithCTA
          bgcolor={theme.pillCardWithCTA.solutionsPage.bgcolor}
          pillLarge={pillLarge}
          pillSmall={pillSmall}
          pillAlt={imgPillAlt}
          title={calendlyTitle}
          buttonText={calendlyButtonText}
          handleOnClickCalendly={handleOnClickCalendly}
          iconName="videoFill"
          leftGridStyle={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <Box mt={isScreenDownSM ? 8 : 6} mb={isScreenDownSM ? 7 : 6}>
            <Typography
              variant="paragraph"
              sx={{
                fontSize: "18px",
                [theme.breakpoints.down("sm")]: {
                  fontSize: "14px",
                },
              }}
            >
              <Interweave content={calendlyText} />
            </Typography>
          </Box>
        </PillCardWithCTA>
      </section>
      {publicServices?.length > 0 && (
        <section name="public-services">
          <PublicServiceSection publicServices={publicServices} />
        </section>
      )}
      {events?.length > 0 && (
        <section name="events">
          <EventsSection events={events} />
        </section>
      )}
      <section name="suggestions">
        <Container maxWidth="xl" sx={{ marginBottom: 10 }}>
          <Box display="flex" justifyContent="center">
            <Typography
              textAlign="center"
              sx={{
                ...theme.typography.HeadingStyles,
                fontSize: isScreenDownSM
                  ? theme.typography.FontSizes.xl
                  : theme.typography.FontSizes.xl6,
              }}
            >
              <Interweave content={t("suggestionsTitle")} />
            </Typography>
          </Box>
        </Container>
        <SuggestionsSection
          openMailModal={openMailModal}
          setSelectedSolution={setSelectedSolution}
          data={suggestions || []}
        />
      </section>
      <ModalMail
        externalOpenMailModal={openMailModal}
        isOpen={isMailModalOpen}
        selectedSolution={selectedSolution}
        solutionsTextsForEmailTemplate={solutionsTextsForEmailTemplate}
        responsesTextsForEmailTemplate={responsesTextsForEmailTemplate}
        suggestionsTextsForEmailTemplate={suggestionsTextsForEmailTemplate}
        sendInBlueStarAttribute={sendInBlueData[groupStar].attribute}
      />
    </>
  );
};

export default SolutionsPage;

const userSolutionsReducers = {
  saveHydratedSolutions: (state, action) => {
    return {
      ...state,
      user: {
        ...state.user,
        solutions: action.payload,
      },
      hydratedSolutions: action.payload,
    };
  },

  setTreeTokenId: (state, action) => {
    return {
      ...state,
      treeTokenId: action.payload?.treeId,
      authTokenBackoffice: action.payload?.authToken,
    };
  },
};

export default userSolutionsReducers;

import Path from "utils/translations/fr/path.json";
import { LastProgressNumberEnum } from "./LastProgressNumberEnum";
import InterviewPageEnum from "./InterviewPageEnum";
import { TrainingProgressFactory } from "../../../domain/interview/interviewModels";
import { TrainingTagEnum } from "./TrainingTagEnum";

export const MapInterviewStepPageToPathnameEnum = {
  [InterviewPageEnum.COMPANY]: {
    // Company
    previousPage: Path["path.interview.email"],
    nextPage: Path["path.interview.company.question1"],
    trainingProgress: TrainingProgressFactory(
      TrainingTagEnum.InterviewCompany,
      LastProgressNumberEnum.InterviewSteps[1]
    ).instance,
  },
  [InterviewPageEnum.JOB]: {
    // Job
    previousPage: Path["path.interview.company.advice3"],
    nextPage: Path["path.interview.job.question1"],
    trainingProgress: TrainingProgressFactory(
      TrainingTagEnum.InterviewJob,
      LastProgressNumberEnum.InterviewSteps[2]
    ).instance,
  },
  [InterviewPageEnum.PROFILE]: {
    // Profile
    previousPage: Path["path.interview.job.advice3"],
    nextPage: Path["path.interview.profile.question1"].replace(
      ":skillNumber",
      "1"
    ),
    trainingProgress: TrainingProgressFactory(
      TrainingTagEnum.InterviewProfile,
      LastProgressNumberEnum.InterviewSteps[3]
    ).instance,
  },
};

export const MuiIconButton = {
  styleOverrides: {
    root: ({ theme }) => ({
      padding: theme.spacing(1),
      // margin: theme.spacing(0.5),
      color: theme.palette.text.primary,
      backgroundColor: theme.palette.background.paper,
      "&:hover": {
        color: theme.palette.primary.main,
        backgroundColor: theme.palette.grey[200],
      },
    }),
  },
};

const MuiIcon = {
  styleOverrides: {
    root: ({ theme }) => ({
      padding: theme.spacing(1),
      color: theme.palette.text.primary,
      "&:hover": {
        color: theme.palette.primary.main,
      },
    }),
  },
};

export default MuiIcon;

import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import globalFR from "utils/translations/fr/global.json";
import homePageFR from "utils/translations/fr/homePage.json";
import contactPageFR from "utils/translations/fr/contactPage.json";
import pathFR from "utils/translations/fr/path.json";
import starsFR from "utils/translations/fr/stars.json";
import questionnaire from "utils/translations/fr/questionnaire.json";
import mentions from "utils/translations/fr/mentions.json";
import teamFr from "utils/translations/fr/team.json";
import missionFr from "utils/translations/fr/mission.json";
import calendlyFr from "utils/translations/fr/calendly.json";
import solutionsFR from "utils/translations/fr/solutions.json";
import interviewFR from "utils/translations/fr/interview.json";
import testimonyFR from "utils/translations/fr/testimony.json";
import directorsBoardFR from "utils/translations/fr/directorsBoard.json";
import mailFR from "utils/translations/fr/mail.json";
import questionnaireEmailFR from "utils/translations/fr/questionnaireEmail.json";
import missionPartnersFR from "utils/translations/fr/missionPartners.json";
import headerFR from "utils/translations/fr/header.json";

import globalEN from "utils/translations/fr/global.json";
import homePageEN from "utils/translations/fr/homePage.json";
import contactPageEN from "utils/translations/fr/contactPage.json";
import pathEN from "utils/translations/fr/path.json";
import starsEN from "utils/translations/en/stars.json";
import survey from "utils/translations/en/survey.json";
import terms from "utils/translations/en/terms.json";
import teamEn from "utils/translations/en/team.json";
import missionEn from "utils/translations/en/mission.json";
import calendlyEn from "utils/translations/en/calendly.json";
import solutionsEN from "utils/translations/en/solutions.json";
import interviewEN from "utils/translations/en/interview.json";
import testimonyEN from "utils/translations/en/testimony.json";
import directorsBoardEN from "utils/translations/en/directorsBoard.json";
import mailEN from "utils/translations/en/mail.json";
import questionnaireEmailEN from "utils/translations/en/questionnaireEmail.json";
import missionPartnersEN from "utils/translations/en/missionPartners.json";
import headerEN from "utils/translations/en/header.json";

const resources = {
  fr: {
    // TODO(mehdi): check if really we use global json
    global: globalFR,
    homePage: homePageFR,
    contactPage: contactPageFR,
    path: pathFR,
    stars: starsFR,
    questionnaire: questionnaire,
    mentions: mentions,
    team: teamFr,
    mission: missionFr,
    calendly: calendlyFr,
    solutions: solutionsFR,
    interview: interviewFR,
    testimony: testimonyFR,
    directorsBoard: directorsBoardFR,
    mail: mailFR,
    questionnaireEmail: questionnaireEmailFR,
    missionPartners: missionPartnersFR,
    header: headerFR,
  },
  en: {
    // TODO(mehdi): check if really we use global json
    global: globalEN,
    homePage: homePageEN,
    contactPage: contactPageEN,
    path: pathEN,
    stars: starsEN,
    questionnaire: survey,
    mentions: terms,
    team: teamEn,
    mission: missionEn,
    calendly: calendlyEn,
    solutions: solutionsEN,
    interview: interviewEN,
    testimony: testimonyEN,
    directorsBoard: directorsBoardEN,
    mail: mailEN,
    questionnaireEmail: questionnaireEmailEN,
    missionPartners: missionPartnersEN,
    header: headerEN,
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: "fr",
  fallbackLng: "en",
  saveMissing: true,
  escapeValue: false,
  react: {
    useSuspense: false,
  },
});

export default i18n;

import { LastProgressNumberEnum } from "utils/constants/enums/LastProgressNumberEnum";

const getAdviceSelectorById = (advices, id) => {
  return advices?.find((advice) => advice.id === id);
};

export const selectCurrentInterviewId = (state) =>
  state.interview?.currentInterview?.id;

export const selectInterviewLastProgressNumber = (state) =>
  state.interview?.currentInterview?.trainingProgress?.lastProgressNumber;

export const selectCurrentInterview = (state) =>
  state.interview?.currentInterview;

export const selectEmailFromCurrentInterview = (state) =>
  state.interview?.currentInterview?.email;

// InterviewFormData selectors :

export const selectCompanyNameFromCurrentInterview = (state) =>
  state.interview?.currentInterview?.interviewFormData?.company;

export const selectInterviewDateFromCurrentInterview = (state) =>
  state.interview?.currentInterview?.interviewFormData?.date;

export const selectJobNameFromCurrentInterview = (state) =>
  state.interview?.currentInterview?.interviewFormData?.job;

// Company selectors :

export const selectCompanyActivitiesFromCurrentInterview = (state) =>
  state.interview?.currentInterview?.company?.activities;

export const selectCompanyValuesFromCurrentInterview = (state) =>
  state.interview?.currentInterview?.company?.values;

export const selectCompanySimulationFromCurrentInterview = (state) =>
  state.interview?.currentInterview?.company?.simulation;

// Job selectors :

export const selectJobCodeRome = (state) =>
  state.interview?.jobData?.poleEmploieData?.codeRome;

export const selectJobLibelleAppellationCourt = (state) =>
  state.interview?.jobData?.poleEmploieData?.libelleAppellationCourt;

export const selectJobDetailPoleEmploie = (state) =>
  state.interview?.jobData?.poleEmploieData?.jobDetail || {};

export const selectJobDomainFromCurrentInterview = (state) =>
  state.interview?.currentInterview?.jobDetail?.domain;

export const selectJobDescriptionsFromCurrentInterview = (state) =>
  state.interview?.currentInterview?.jobDetail?.descriptions;

export const selectJobSoftSkillsFromCurrentInterview = (state) =>
  state.interview?.currentInterview?.job?.softskills;

export const selectJobBestOfFromCurrentInterview = (state) =>
  state.interview?.currentInterview?.job?.bestof;

export const selectJobChallengesFromCurrentInterview = (state) =>
  state.interview?.currentInterview?.job?.challenges;

// Profile Selectors

export const selectProfileSkillsFromCurrentInterview = (state) =>
  state.interview?.currentInterview?.profile?.skills;

// Advices selectors :

export const selectInterviewCompanyAdviceC1 = (state) =>
  getAdviceSelectorById(
    state.interview?.currentInterview?.advices,
    LastProgressNumberEnum.InterviewCompany.C1
  )?.adviceText;

export const selectInterviewCompanyAdviceC2 = (state) =>
  getAdviceSelectorById(
    state.interview?.currentInterview?.advices,
    LastProgressNumberEnum.InterviewCompany.C2
  )?.adviceText;

export const selectInterviewCompanyAdviceC3 = (state) =>
  getAdviceSelectorById(
    state.interview?.currentInterview?.advices,
    LastProgressNumberEnum.InterviewCompany.C3
  )?.adviceText;

export const selectInterviewJobAdviceC1 = (state) =>
  getAdviceSelectorById(
    state.interview?.currentInterview?.advices,
    LastProgressNumberEnum.InterviewJob.C1
  )?.adviceText;

export const selectInterviewJobAdviceC2 = (state) =>
  getAdviceSelectorById(
    state.interview?.currentInterview?.advices,
    LastProgressNumberEnum.InterviewJob.C2
  )?.adviceText;

export const selectInterviewJobAdviceC3 = (state) =>
  getAdviceSelectorById(
    state.interview?.currentInterview?.advices,
    LastProgressNumberEnum.InterviewJob.C3
  )?.adviceText;

export const selectIsCurrentInterviewLoading = (state) =>
  state.interview?.isCurrentInterviewLoading;

const userInterviewReducers = {
  saveUser: (state, action) => {
    return {
      ...state,
      user: action.payload,
    };
  },
};

export default userInterviewReducers;

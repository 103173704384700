//please use the same name as in figma

export const IconsEnum = {
  arrowRightShort: {
    pathD:
      "M13.3596 2.51163C13.6811 2.18403 14.1171 2 14.5717 2C15.0262 2 15.4622 2.18403 15.7837 2.51163L23.4981 10.3751C23.8195 10.7028 24 11.1472 24 11.6105C24 12.0739 23.8195 12.5183 23.4981 12.846L15.7837 20.7095C15.4603 21.0278 15.0273 21.2039 14.5778 21.1999C14.1283 21.196 13.6984 21.0122 13.3805 20.6882C13.0627 20.3642 12.8824 19.9259 12.8785 19.4677C12.8746 19.0096 13.0474 18.5682 13.3596 18.2386L18.0003 13.358H1.71431C1.25965 13.358 0.823606 13.1739 0.50211 12.8462C0.180615 12.5185 0 12.074 0 11.6105C0 11.1471 0.180615 10.7026 0.50211 10.3749C0.823606 10.0472 1.25965 9.86311 1.71431 9.86311H18.0003L13.3596 4.98251C13.0382 4.65481 12.8577 4.21043 12.8577 3.74707C12.8577 3.28371 13.0382 2.83932 13.3596 2.51163Z",
    viewBox: "0 0 24 24",
  },
  arrowLeftShort: {
    pathD:
      "M24 12.5C24 12.8978 23.842 13.2792 23.5607 13.5605C23.2794 13.8417 22.898 13.9997 22.5002 13.9997H5.12311L11.5634 20.4366C11.7028 20.576 11.8135 20.7415 11.8889 20.9237C11.9644 21.1059 12.0032 21.3012 12.0032 21.4984C12.0032 21.6956 11.9644 21.8908 11.8889 22.073C11.8135 22.2552 11.7028 22.4207 11.5634 22.5602C11.4239 22.6996 11.2584 22.8102 11.0762 22.8857C10.894 22.9612 10.6987 23 10.5015 23C10.3043 23 10.109 22.9612 9.92682 22.8857C9.74463 22.8102 9.57908 22.6996 9.43963 22.5602L0.440634 13.5618C0.30096 13.4225 0.190143 13.257 0.114532 13.0748C0.0389214 12.8926 1.90735e-06 12.6973 1.90735e-06 12.5C1.90735e-06 12.3027 0.0389214 12.1074 0.114532 11.9252C0.190143 11.743 0.30096 11.5775 0.440634 11.4382L9.43963 2.43982C9.72126 2.15821 10.1032 2 10.5015 2C10.8998 2 11.2818 2.15821 11.5634 2.43982C11.845 2.72142 12.0032 3.10337 12.0032 3.50162C12.0032 3.89988 11.845 4.28182 11.5634 4.56343L5.12311 11.0003H22.5002C22.898 11.0003 23.2794 11.1583 23.5607 11.4395C23.842 11.7208 24 12.1022 24 12.5Z",
    viewBox: "0 0 24 24",
  },
  arrowQuickAccess: {
    pathD:
      "M0.402925 17.2891C0.13164 16.9982 -0.0129677 16.6114 0.000914668 16.2139C0.014797 15.8164 0.186032 15.4406 0.47695 15.1693L13.1857 3.31821L4.08539 3.00254C3.68735 2.98864 3.31113 2.81719 3.0395 2.5259C2.76787 2.23462 2.62309 1.84736 2.63698 1.44932C2.65088 1.05128 2.82234 0.675063 3.11362 0.403434C3.40491 0.131806 3.79217 -0.012984 4.19021 0.000915823L16.909 0.445065C17.1061 0.4517 17.3 0.497169 17.4796 0.578866C17.6592 0.660563 17.8209 0.776884 17.9554 0.921165C18.09 1.06545 18.1947 1.23485 18.2637 1.41968C18.3327 1.60451 18.3645 1.80114 18.3574 1.99829L17.9132 14.717C17.8993 15.1151 17.7279 15.4913 17.4366 15.7629C17.1453 16.0345 16.758 16.1793 16.36 16.1654C15.9619 16.1515 15.5857 15.9801 15.3141 15.6888C15.0425 15.3975 14.8977 15.0103 14.9116 14.6122L15.2315 5.51202L2.52272 17.3632C2.2318 17.6344 1.84503 17.779 1.44749 17.7652C1.04995 17.7513 0.674211 17.58 0.402925 17.2891Z",
    viewBox: "0 0 19 18",
  },
  arrowCircleRight: {
    pathD:
      "M1.5 12C1.5 14.7848 2.60625 17.4555 4.57538 19.4246C6.54451 21.3938 9.21523 22.5 12 22.5C14.7848 22.5 17.4555 21.3938 19.4246 19.4246C21.3938 17.4555 22.5 14.7848 22.5 12C22.5 9.21523 21.3938 6.54451 19.4246 4.57538C17.4555 2.60625 14.7848 1.5 12 1.5C9.21523 1.5 6.54451 2.60625 4.57538 4.57538C2.60625 6.54451 1.5 9.21523 1.5 12ZM24 12C24 15.1826 22.7357 18.2348 20.4853 20.4853C18.2348 22.7357 15.1826 24 12 24C8.8174 24 5.76516 22.7357 3.51472 20.4853C1.26428 18.2348 0 15.1826 0 12C0 8.8174 1.26428 5.76516 3.51472 3.51472C5.76516 1.26428 8.8174 0 12 0C15.1826 0 18.2348 1.26428 20.4853 3.51472C22.7357 5.76516 24 8.8174 24 12ZM6.75 11.25C6.55109 11.25 6.36032 11.329 6.21967 11.4697C6.07902 11.6103 6 11.8011 6 12C6 12.1989 6.07902 12.3897 6.21967 12.5303C6.36032 12.671 6.55109 12.75 6.75 12.75H15.4395L12.219 15.969C12.1493 16.0387 12.094 16.1215 12.0562 16.2126C12.0185 16.3037 11.9991 16.4014 11.9991 16.5C11.9991 16.5986 12.0185 16.6963 12.0562 16.7874C12.094 16.8785 12.1493 16.9613 12.219 17.031C12.2887 17.1007 12.3715 17.156 12.4626 17.1938C12.5537 17.2315 12.6514 17.2509 12.75 17.2509C12.8486 17.2509 12.9463 17.2315 13.0374 17.1938C13.1285 17.156 13.2113 17.1007 13.281 17.031L17.781 12.531C17.8508 12.4613 17.9063 12.3786 17.9441 12.2874C17.9819 12.1963 18.0013 12.0987 18.0013 12C18.0013 11.9013 17.9819 11.8037 17.9441 11.7125C17.9063 11.6214 17.8508 11.5387 17.781 11.469L13.281 6.969C13.2113 6.89927 13.1285 6.84395 13.0374 6.80621C12.9463 6.76848 12.8486 6.74905 12.75 6.74905C12.6514 6.74905 12.5537 6.76848 12.4626 6.80621C12.3715 6.84395 12.2887 6.89927 12.219 6.969C12.1493 7.03873 12.094 7.12152 12.0562 7.21262C12.0185 7.30373 11.9991 7.40138 11.9991 7.5C11.9991 7.59862 12.0185 7.69627 12.0562 7.78738C12.094 7.87848 12.1493 7.96127 12.219 8.031L15.4395 11.25H6.75Z",
    viewBox: "0 0 24 24",
  },
  keyboardArrowDown: {
    pathD:
      "M6.175 7.65833L10 11.475L13.825 7.65833L15 8.83333L10 13.8333L5 8.83333L6.175 7.65833Z",
    viewBox: "0 0 20 21",
  },
  checkSharp: {
    pathD: "M9 16.17 4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41L9 16.17z",
    viewBox: "0 0 24 24",
  },
  timer: {
    pathD:
      "M25.1167 1.68332H15.1167V5.01665H25.1167V1.68332ZM18.45 23.35H21.7833V13.35H18.45V23.35ZM31.8333 12.3167L34.2 9.94999C33.4833 9.09999 32.7 8.29999 31.85 7.59999L29.4833 9.96665C26.9 7.89999 23.65 6.66665 20.1167 6.66665C11.8333 6.66665 5.11667 13.3833 5.11667 21.6667C5.11667 29.95 11.8167 36.6667 20.1167 36.6667C28.4167 36.6667 35.1167 29.95 35.1167 21.6667C35.1167 18.15 33.8833 14.9 31.8333 12.3167ZM20.1167 33.35C13.6667 33.35 8.45 28.1333 8.45 21.6833C8.45 15.2333 13.6667 10.0167 20.1167 10.0167C26.5667 10.0167 31.7833 15.2333 31.7833 21.6833C31.7833 28.1333 26.5667 33.35 20.1167 33.35Z",
    viewBox: "0 0 40 40",
  },
  close: {
    // pathD:
    // "M23.25 12C23.25 18.2132 18.2132 23.25 12 23.25C5.7868 23.25 0.75 18.2132 0.75 12C0.75 5.7868 5.7868 0.75 12 0.75C18.2132 0.75 23.25 5.7868 23.25 12Z",
    pathD:
      "M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41Z",
    viewBox: "0 0 24 24",
  },
  edit: {
    pathD:
      "M13 8.13843C13 8.00582 13.0527 7.87864 13.1464 7.78487C13.2402 7.69111 13.3674 7.63843 13.5 7.63843C13.6326 7.63843 13.7598 7.69111 13.8536 7.78487C13.9473 7.87864 14 8.00582 14 8.13843V13.6384C14 13.771 13.9473 13.8982 13.8536 13.992C13.7598 14.0857 13.6326 14.1384 13.5 14.1384H2.5C2.36739 14.1384 2.24021 14.0857 2.14645 13.992C2.05268 13.8982 2 13.771 2 13.6384V2.63843C2 2.50582 2.05268 2.37864 2.14645 2.28487C2.24021 2.19111 2.36739 2.13843 2.5 2.13843H8C8.13261 2.13843 8.25979 2.19111 8.35355 2.28487C8.44732 2.37864 8.5 2.50582 8.5 2.63843C8.5 2.77104 8.44732 2.89821 8.35355 2.99198C8.25979 3.08575 8.13261 3.13843 8 3.13843H3V13.1384H13V8.13843Z",
    pathD2:
      "M7.34298 8.79843L8.16798 8.68043L13.236 3.61343C13.2837 3.56731 13.3218 3.51214 13.348 3.45114C13.3742 3.39013 13.388 3.32452 13.3886 3.25813C13.3892 3.19174 13.3765 3.1259 13.3514 3.06446C13.3263 3.00301 13.2891 2.94718 13.2422 2.90024C13.1952 2.85329 13.1394 2.81616 13.078 2.79102C13.0165 2.76588 12.9507 2.75323 12.8843 2.75381C12.8179 2.75439 12.7523 2.76818 12.6913 2.79438C12.6303 2.82059 12.5751 2.85868 12.529 2.90643L7.45998 7.97343L7.34198 8.79843H7.34298ZM13.943 2.19843C14.0824 2.33773 14.193 2.50314 14.2684 2.68519C14.3438 2.86724 14.3827 3.06237 14.3827 3.25943C14.3827 3.4565 14.3438 3.65163 14.2684 3.83368C14.193 4.01573 14.0824 4.18113 13.943 4.32043L8.75798 9.50543C8.68153 9.58218 8.58221 9.63201 8.47498 9.64743L6.82498 9.88343C6.74809 9.89448 6.66968 9.88745 6.59597 9.86291C6.52226 9.83837 6.45528 9.797 6.40035 9.74206C6.34542 9.68713 6.30405 9.62016 6.27951 9.54645C6.25497 9.47274 6.24794 9.39433 6.25898 9.31743L6.49498 7.66743C6.51014 7.56033 6.55961 7.46103 6.63598 7.38443L11.822 2.19943C12.1033 1.91823 12.4847 1.76025 12.8825 1.76025C13.2802 1.76025 13.6617 1.91823 13.943 2.19943V2.19843Z",
    viewBox: "0 0 16 16",
  },
  keyBoardArrowRight: {
    pathD:
      "M4.64592 1.64592C4.69236 1.59935 4.74754 1.56241 4.80828 1.5372C4.86903 1.512 4.93415 1.49902 4.99992 1.49902C5.06568 1.49902 5.13081 1.512 5.19155 1.5372C5.2523 1.56241 5.30747 1.59935 5.35392 1.64592L11.3539 7.64592C11.4005 7.69236 11.4374 7.74754 11.4626 7.80828C11.4878 7.86903 11.5008 7.93415 11.5008 7.99992C11.5008 8.06568 11.4878 8.1308 11.4626 8.19155C11.4374 8.2523 11.4005 8.30747 11.3539 8.35392L5.35392 14.3539C5.26003 14.4478 5.13269 14.5005 4.99992 14.5005C4.86714 14.5005 4.7398 14.4478 4.64592 14.3539C4.55203 14.26 4.49929 14.1327 4.49929 13.9999C4.49929 13.8671 4.55203 13.7398 4.64592 13.6459L10.2929 7.99992L4.64592 2.35392C4.59935 2.30747 4.56241 2.2523 4.5372 2.19155C4.512 2.13081 4.49902 2.06568 4.49902 1.99992C4.49902 1.93415 4.512 1.86903 4.5372 1.80828C4.56241 1.74754 4.59935 1.69236 4.64592 1.64592Z",
    viewBox: "0 0 16 16",
  },
  videoFill: {
    pathD:
      "M0.5 5C0.5 4.46957 0.710714 3.96086 1.08579 3.58579C1.46086 3.21071 1.96957 3 2.5 3H10C10.4851 2.99994 10.9537 3.17619 11.3185 3.49593C11.6833 3.81566 11.9195 4.25709 11.983 4.738L15.093 3.356C15.2452 3.28817 15.412 3.25946 15.5781 3.27249C15.7443 3.28551 15.9045 3.33985 16.0443 3.43056C16.1841 3.52128 16.2991 3.6455 16.3786 3.79192C16.4582 3.93835 16.4999 4.10235 16.5 4.269V11.731C16.4999 11.8975 16.4581 12.0614 16.3786 12.2077C16.2991 12.354 16.1843 12.4781 16.0446 12.5688C15.905 12.6595 15.7449 12.7139 15.5788 12.727C15.4128 12.7401 15.2462 12.7116 15.094 12.644L11.983 11.262C11.9195 11.7429 11.6833 12.1843 11.3185 12.5041C10.9537 12.8238 10.4851 13.0001 10 13H2.5C1.96957 13 1.46086 12.7893 1.08579 12.4142C0.710714 12.0391 0.5 11.5304 0.5 11V5Z",
  },
  linkedin: {
    pathD:
      "M17 13.13V16.827H14.857V13.377C14.857 12.511 14.547 11.92 13.771 11.92C13.179 11.92 12.826 12.318 12.671 12.704C12.615 12.842 12.6 13.034 12.6 13.226V16.827H10.456C10.456 16.827 10.485 10.985 10.456 10.38H12.6V11.293L12.586 11.314H12.6V11.293C12.885 10.853 13.393 10.228 14.532 10.228C15.942 10.228 17 11.15 17 13.13ZM8.213 7.271C7.48 7.271 7 7.753 7 8.385C7 9.005 7.466 9.5 8.185 9.5H8.199C8.947 9.5 9.412 9.004 9.412 8.385C9.398 7.753 8.947 7.271 8.213 7.271ZM7.127 16.827H9.271V10.38H7.127V16.827Z",
    pathD2:
      "M6 4C5.46957 4 4.96086 4.21071 4.58579 4.58579C4.21071 4.96086 4 5.46957 4 6V18C4 18.5304 4.21071 19.0391 4.58579 19.4142C4.96086 19.7893 5.46957 20 6 20H18C18.5304 20 19.0391 19.7893 19.4142 19.4142C19.7893 19.0391 20 18.5304 20 18V6C20 5.46957 19.7893 4.96086 19.4142 4.58579C19.0391 4.21071 18.5304 4 18 4H6ZM6 2H18C19.0609 2 20.0783 2.42143 20.8284 3.17157C21.5786 3.92172 22 4.93913 22 6V18C22 19.0609 21.5786 20.0783 20.8284 20.8284C20.0783 21.5786 19.0609 22 18 22H6C4.93913 22 3.92172 21.5786 3.17157 20.8284C2.42143 20.0783 2 19.0609 2 18V6C2 4.93913 2.42143 3.92172 3.17157 3.17157C3.92172 2.42143 4.93913 2 6 2Z",
    viewBox: "0 0 24 24",
  },
  linkedinTeamPage: {
    pathD:
      "M5.57143 6.78571V12.5M12.4286 12.5V9.64286C12.4286 9.03665 12.1878 8.45527 11.7591 8.02661C11.3304 7.59796 10.7491 7.35714 10.1429 7.35714C9.53665 7.35714 8.95527 7.59796 8.52661 8.02661C8.09796 8.45527 7.85714 9.03665 7.85714 9.64286V12.5V6.78571M5 5.07143H6.14286M2.14286 0.5H15.8571C16.1602 0.5 16.4509 0.620408 16.6653 0.834735C16.8796 1.04906 17 1.33975 17 1.64286V15.3571C17 15.6602 16.8796 15.9509 16.6653 16.1653C16.4509 16.3796 16.1602 16.5 15.8571 16.5H2.14286C1.83975 16.5 1.54906 16.3796 1.33474 16.1653C1.12041 15.9509 1 15.6602 1 15.3571V1.64286C1 1.33975 1.12041 1.04906 1.33474 0.834735C1.54906 0.620408 1.83975 0.5 2.14286 0.5Z",
    viewBox: "0 0 24 24",
  },
};

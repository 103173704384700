import React from "react";

import InterviewBulletPointStyles from "../InterviewBulletPoint/InterviewBulletPoint.module.scss";
import bulletPointImage from "assets/images/interview-summary-bullet-point.png";

const InterviewBulletPoint = ({ content }) => {
  return (
    <div className={InterviewBulletPointStyles.bulletPoint}>
      <img
        className={InterviewBulletPointStyles.bulletPointImg}
        src={bulletPointImage}
        alt="bullet point"
      />
      <div className={InterviewBulletPointStyles.activity}>{content}</div>
    </div>
  );
};

export default InterviewBulletPoint;

import QuestionnaireEnum from "./QuestionnaireEnum";

const MapLabelQuestionnaireEnum = {
  [QuestionnaireEnum.PATH_QUESTION_SECTOR]: "formSecteurActivite",
  [QuestionnaireEnum.PATH_QUESTION_AGE]: "formAge",
  [QuestionnaireEnum.PATH_QUESTION_EXPERIENCE]: "formExperience",
  [QuestionnaireEnum.PATH_QUESTION_JOB]: "formMetier",
  [QuestionnaireEnum.PATH_QUESTION_JOB_2]: "formMetier",
  [QuestionnaireEnum.PATH_READY_TO_APPLY]: "formPretpourPostuler",
  [QuestionnaireEnum.PATH_READY_TO_INTERVIEW]: "formEntretien",
  [QuestionnaireEnum.PATH_NEED_TO_TRAIN]: "formBesoinDeFormation",
  [QuestionnaireEnum.PATH_READY_TO_DISCOVER_NEW_JOB]: "formDecouverteMetier",
  [QuestionnaireEnum.PATH_AVAILABILITY_TO_TRAIN]: "formDispoFormation",
  [QuestionnaireEnum.PATH_KNOW_YOURSELF]: "formOutilConnaissanceDeSoi",
  [QuestionnaireEnum.PATH_QUESTION_STATUT_PRO]: "formStatutPro",
  [QuestionnaireEnum.PATH_QUESTION_ZIPCODE]: "formZipcode",
  [QuestionnaireEnum.PATH_QUESTION_LOCALITY]: "formLocality",
};

export default MapLabelQuestionnaireEnum;

import React, { useCallback } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

import Interweave from "interweave";
import clsx from "clsx";

import { useMediaQueries } from "hooks/useMediaQueries";

import { mdiArrowLeft } from "@mdi/js";
import MdiIconButton from "../MdiIconButton";
import Button from "components/common/Button(ToBeUpdated)";
import InterviewTemplateAdviceRocket from "assets/images/interview-template-advice-rocket.png";
import TemplateAdviceInterviewStyles from "./TemplateAdviceInterview.module.scss";
import { MapPathnameToProgressNumberEnum } from "../../../utils/constants/enums/MapPathnameToProgressNumberEnum";

const TemplateAdviceInterview = ({ textAdvice, onNextClick, adviceNumber }) => {
  const history = useHistory();
  const { isMobile, isTablet, isDesktop } = useMediaQueries();
  const { t } = useTranslation("interview");
  const { title, imageAlt, textButton } = t("interviewAdvice", {
    returnObjects: true,
    number: adviceNumber,
  });

  const { previousPage } =
    MapPathnameToProgressNumberEnum[history.location.pathname];
  const goBack = useCallback(
    () => (previousPage ? history.push(previousPage) : history.goBack()),
    [previousPage, history]
  );

  return (
    <div className={TemplateAdviceInterviewStyles.container}>
      <div className={TemplateAdviceInterviewStyles.backArrow}>
        <MdiIconButton
          buttonStyles={{ backgroundColor: "white" }}
          mdiIcon={mdiArrowLeft}
          onClick={goBack}
        />
      </div>
      <div className={TemplateAdviceInterviewStyles.section}>
        <div className={TemplateAdviceInterviewStyles.topSection}>
          <img src={InterviewTemplateAdviceRocket} alt={imageAlt} />
          <div className={TemplateAdviceInterviewStyles.title}>
            <Interweave content={title} />
          </div>
          <div className={TemplateAdviceInterviewStyles.advice}>
            <Interweave content={textAdvice} />
          </div>
        </div>
        <div
          className={clsx({
            [TemplateAdviceInterviewStyles.bottomSectionS]:
              isMobile || isTablet,
            [TemplateAdviceInterviewStyles.bottomSectionL]: isDesktop,
          })}
        >
          <Button
            text={textButton}
            type={"button"}
            style={{
              padding: "25px",
              width: "100%",
              backgroundColor: "white",
              color: "#00827F",
              maxWidth: "480px",
            }}
            onClick={onNextClick}
          />
        </div>
      </div>
    </div>
  );
};

export default TemplateAdviceInterview;

import sectionStyles from "./LinearProgress.module.scss";
import { LinearProgress as MuiLinearProgress, Typography } from "@mui/material";

export const LinearProgress = ({ progressValue }) => {
  return (
    <div>
      <MuiLinearProgress variant="determinate" value={progressValue} />
      <div
        style={{
          width: `${progressValue + 1}%`,
          textAlign: "right",
        }}
      >
        <Typography
          variant="paragraph"
          className={sectionStyles.linearProgressLabel}
        >
          {progressValue}%
        </Typography>
      </div>
    </div>
  );
};

import React, { useCallback, useMemo } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import Interweave from "interweave";
import clsx from "clsx";
import { v4 as uuidv4 } from "uuid";
import { mdiArrowLeft } from "@mdi/js";

import { MapPathnameToProgressNumberEnum } from "utils/constants/enums/MapPathnameToProgressNumberEnum";
import { useMediaQueries } from "hooks/useMediaQueries";

import {
  selectJobDetailPoleEmploie,
  selectJobLibelleAppellationCourt,
} from "store/interview/interviewSelectors";
import { saveInterviewJobDetails } from "domain/interview/interviewControllers";

import MdiIconButton from "components/common/MdiIconButton";
import Button from "components/common/Button(ToBeUpdated)";

import InterviewJobDetailsStyles from "../InterviewJobDetails/InterviewJobDetails.module.scss";

const InterviewJobDetails = () => {
  const { t } = useTranslation("interview");
  const { isTablet, isMobile, isDesktop } = useMediaQueries();
  const { title, skillsTitle, buttonLabelName } = t("jobDetails", {
    returnObjects: true,
  });
  const history = useHistory();
  const dispatch = useDispatch();
  const { previousPage } =
    MapPathnameToProgressNumberEnum[history.location.pathname];

  const goBack = useCallback(
    () => (previousPage ? history.push(previousPage) : history.goBack()),
    [previousPage, history]
  );
  const { domain, /* name, */ descriptions, skills } = useSelector(
    selectJobDetailPoleEmploie
  );
  const libelleAppellationCourt = useSelector(selectJobLibelleAppellationCourt);

  const updateInterviewTraining = useCallback(() => {
    dispatch(saveInterviewJobDetails(history));
  }, [dispatch, history]);

  const maxWidth = useMemo(
    () =>
      (isDesktop && "calc(31% - 24px)") ||
      (isMobile && "calc(95% - 24px)") ||
      (isTablet && "calc(70% - 24px)"),
    [isDesktop, isMobile, isTablet]
  );

  return (
    <>
      <div className={InterviewJobDetailsStyles.container}>
        <div className={InterviewJobDetailsStyles.backArrow}>
          <MdiIconButton
            buttonStyles={{ marginLeft: "4px" }}
            mdiIcon={mdiArrowLeft}
            onClick={goBack}
          />
        </div>
        <div className={InterviewJobDetailsStyles.middle}>
          <div className={InterviewJobDetailsStyles.title}>
            <Interweave content={title} />
          </div>
          <div
            className={clsx({
              [InterviewJobDetailsStyles.box]: true,
              [InterviewJobDetailsStyles.boxM]: isTablet,
              [InterviewJobDetailsStyles.boxS]: isMobile,
            })}
          >
            <div className={InterviewJobDetailsStyles.jobDomain}>{domain}</div>
            <div className={InterviewJobDetailsStyles.jobName}>
              {libelleAppellationCourt}
            </div>
            {/* <div className={InterviewJobDetailsStyles.jobLibelleCourt}>
              {libelleAppellationCourt}
            </div> */}
          </div>
          <div
            className={clsx({
              [InterviewJobDetailsStyles.jobDescription]: true,
              [InterviewJobDetailsStyles.jobDescriptionM]: isTablet,
              [InterviewJobDetailsStyles.jobDescriptionS]: isMobile,
            })}
          >
            <Interweave content={descriptions?.[0]} />
          </div>
          <div
            className={clsx({
              [InterviewJobDetailsStyles.skillSection]: true,
              [InterviewJobDetailsStyles.skillSectionM]: isTablet,
              [InterviewJobDetailsStyles.skillSectionS]: isMobile,
            })}
          >
            <div className={InterviewJobDetailsStyles.skillsTitle}>
              <Interweave content={skillsTitle} />
            </div>
            <div className={InterviewJobDetailsStyles.jobSkills}>
              {skills?.map((jobSkill) => (
                <div
                  key={uuidv4()}
                  className={InterviewJobDetailsStyles.jobSkillName}
                >
                  {jobSkill}
                </div>
              ))}
            </div>
          </div>
        </div>
        <footer className={InterviewJobDetailsStyles.footer}>
          <Button
            text={buttonLabelName}
            type="submit"
            style={{
              padding: "24px",
              maxWidth,
              width: "calc(100% - 24px)",
            }}
            onClick={updateInterviewTraining}
          />
        </footer>
      </div>
    </>
  );
};

export default InterviewJobDetails;

import { GoogleAnalyticsEvents } from "../analyticsConfig";

const goDirectlyToSolutionsPageDataGA = () => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: GoogleAnalyticsEvents.NePasRecevoirSuggestions,
  });
};

export default goDirectlyToSolutionsPageDataGA;

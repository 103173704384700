export const getSolutionTeamPicturesUrlFromFirebaseDevStorage = (assetName) =>
  `https://firebasestorage.googleapis.com/v0/b/mavoie-development.appspot.com/o/public%2Fteam%2F${assetName}?alt=media`;

export const getMissionPicturesUrlFromFirebaseDevStorage = (assetName) =>
  `https://firebasestorage.googleapis.com/v0/b/mavoie-development.appspot.com/o/public%2Fmission%2Fimages%2F${assetName}?alt=media`;

export const getDocumentsPDFUrlFromFirebaseDevStorage = (assetName) =>
  `https://firebasestorage.googleapis.com/v0/b/mavoie-development.appspot.com/o/public%2Fmission%2Fdocuments%2F${assetName}?alt=media&token=empty`;

export const getPillImageUrlFromFirebaseDevStorage = (assetName) =>
  `https://firebasestorage.googleapis.com/v0/b/mavoie-development.appspot.com/o/public%2Fpills%2F${assetName}?alt=media`;

export const getSolutionDirectorsBoardPicturesUrlFromFirebaseDevStorage = (
  assetName
) =>
  `https://firebasestorage.googleapis.com/v0/b/mavoie-development.appspot.com/o/public%2Fboard-of-directors%2F${assetName}?alt=media`;

export const InterviewFormNameEnum = {
  INTERVIEW_START: "InterviewStartForm",
  INTERVIEW_EMAIL: "InterviewEmailForm",
  INTERVIEW_COMPANY_ACTIVITES: "InterviewCompanyActivitesForm",
  INTERVIEW_COMPANY_VALUES: "InterviewCompanyValuesForm",
  INTERVIEW_COMPANY_SIMULATION: "InterviewCompanySimulationForm",
  INTERVIEW_JOB_SOFTSKILLS: "InterviewJobSoftSkillsForm",
  INTERVIEW_JOB_BESTOF: "InterviewJobBestofForm",
  INTERVIEW_JOB_CHALLENGES: "InterviewJobChallengesForm",
  INTERVIEW_PROFILE_SKILLRATE: "InterviewProfileSkillRateForm",
  INTERVIEW_PROFILE_SKILL_EXAMPLE: "InterviewProfileSkillExampleForm",
  INTERVIEW_FINISH: "InterviewFinishForm",
};

import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { pdf } from "@react-pdf/renderer";
import { Alert, Snackbar } from "@mui/material";
import Interweave from "interweave";
import { useSelector } from "react-redux";

import { mdiArrowLeft } from "@mdi/js";
import { useHistory } from "react-router-dom/cjs/react-router-dom";

import useSummaryInterviewData from "hooks/useSummaryInterviewData";
import clickSendInterviewSummaryDataGA from "config/googleAnalytics/clickSendInterviewSummaryDataGA";
import {
  blobToBase64,
  generateHtmlWInterviewSummaryMailDoc,
} from "utils/helpers/mailHelpers";

import { selectUser } from "store/users/usersSelectors";
import { sendMail } from "domain/mail/mailControllers";
import { MailFactory, MessageFactory } from "domain/mail/mailModels";

import Button from "components/common/Button(ToBeUpdated)";
import MdiIconButton from "components/common/MdiIconButton";

import GeneralInformations from "./GeneralInformations";
import CompanyInformations from "./CompanyInformations";
import JobInformations from "./JobInformations";
import ProfileInformations from "./ProfileInformations";
import InterviewPDF from "./InterviewPdf";
import InterviewSummaryStyles from "../InterviewSummary/InterviewSummary.module.scss";
import axios from "axios";
import endpoints from "utils/constants/endpoints";
import { selectCurrentInterviewId } from "store/interview/interviewSelectors";
import { sendTiktokEventData } from "domain/tiktokEvent/tiktokEventController";
import { TiktokEventEnum } from "utils/constants/enums/TiktokEventEnum";
import { getPhoneNumberFromCalendlyUserData } from "utils/helpers/usersHelper";

const InterviewSummary = () => {
  const { t } = useTranslation("interview");
  const [showAlert, setShowAlert] = useState(false);

  const {
    jobContent,
    companyContent,
    interviewDateContent,
    activitiesContent,
    companyAdviceC1,
    valuesContent,
    companyAdviceC2,
    simulation,
    companyAdviceC3,
    jobDomainContent,
    jobDescriptionsContent,
    jobSoftSkillsContent,
    jobBestOfContent,
    jobChallengesContent,
    jobAdviceC1,
    jobAdviceC2,
    jobAdviceC3,
    profileSkills,
    interviewEmail,
  } = useSummaryInterviewData();

  const {
    sendSummary,
    title,
    subtitle,
    emailSendAlert,
    logoImageAlt,
    logoImageSrc,
  } = t("interviewSummary", {
    returnObjects: true,
    company: companyContent,
  });

  const user = useSelector(selectUser);
  const interviewId = useSelector(selectCurrentInterviewId);
  const phoneNumber = getPhoneNumberFromCalendlyUserData(
    user.calendlyQuestionsAndAnswers
  );

  const history = useHistory();
  const goBack = useCallback(() => history.goBack(), [history]);

  const sendSummaryEmailCallback = useCallback(async () => {
    // Here Generating the pdf renderer
    const myPdf = pdf(
      <InterviewPDF
        data={{
          jobContent,
          companyContent,
          interviewDateContent,
          activitiesContent,
          companyAdviceC1,
          valuesContent,
          companyAdviceC2,
          simulation,
          companyAdviceC3,
          jobDomainContent,
          jobDescriptionsContent,
          jobSoftSkillsContent,
          jobBestOfContent,
          jobChallengesContent,
          jobAdviceC1,
          jobAdviceC2,
          jobAdviceC3,
          profileSkills,
          interviewEmail,
        }}
      />
    );
    // Get PDF Blob from myPdf object
    const pdfBlob = await myPdf.toBlob();
    // Convert the PDF Blob to base64 string using the blobToBase64 function
    const base64Text = await blobToBase64(pdfBlob);

    const currentDate = new Date()
      .toLocaleDateString("fr-FR")
      .replace(/\//g, "-");

    const { summaryFileName } = t("interviewSummary", {
      returnObjects: true,
      company: companyContent,
    });

    // Send an email using the sendMail controller
    const payload = await sendMail(
      user.uid,
      MailFactory({
        to: interviewEmail, // Recipient email address
        message: MessageFactory({
          subject: t("mail:interviewTemplate:subject"), // Email subject
          html: generateHtmlWInterviewSummaryMailDoc(), // HTML content of the email
          attachments: [
            {
              // PDF attachment details
              filename: `${currentDate}-${summaryFileName}.pdf`,
              content: base64Text, // Base64-encoded content of the PDF
              encoding: "base64", // Encoding type
            },
          ],
        }),
      })
    );
    const isSent = Boolean(payload);
    if (isSent) {
      // Notify user after the email is sent successfull with a flash message
      setShowAlert(true);
      // Send in Google Analytics the Info
      clickSendInterviewSummaryDataGA();
    }

    // Save in Database the progress and mark if the email is sent
    await axios({
      method: "put",
      url: endpoints.SAVE_INTERVIEW_PROGRESS(interviewId),
      headers: {
        "Content-Type": "application/json",
      },
      data: {
        trainingProgress: {
          trainingTag: "InterviewEnd",
          lastProgressNumber: "4.2",
        },
        emailSummaryStatus: {
          isSent,
        },
      },
    });

    sendTiktokEventData(
      TiktokEventEnum.download,
      user.uid,
      user.email,
      phoneNumber
    );
  }, [
    jobContent,
    companyContent,
    interviewDateContent,
    activitiesContent,
    companyAdviceC1,
    valuesContent,
    companyAdviceC2,
    simulation,
    companyAdviceC3,
    jobDomainContent,
    jobDescriptionsContent,
    jobSoftSkillsContent,
    jobBestOfContent,
    jobChallengesContent,
    jobAdviceC1,
    jobAdviceC2,
    jobAdviceC3,
    profileSkills,
    interviewEmail,
    user.uid,
    interviewId,
    t,
    phoneNumber,
    user.email,
  ]);

  return (
    <main id="capture" className={InterviewSummaryStyles.container}>
      <section
        name="page-navigation"
        className={InterviewSummaryStyles.navigationContainer}
      >
        <div className={InterviewSummaryStyles.backArrow}>
          <MdiIconButton
            buttonStyles={{
              backgroundColor: "white",
            }}
            mdiIcon={mdiArrowLeft}
            onClick={goBack}
          />
        </div>
        <div className={InterviewSummaryStyles.logoContainer}>
          <img
            className={InterviewSummaryStyles.logoImage}
            alt={logoImageAlt}
            loading="lazy"
            src={logoImageSrc}
          />
        </div>
      </section>
      <section name="page-header" className={InterviewSummaryStyles.head}>
        <div className={InterviewSummaryStyles.title}>
          <Interweave content={title} />
        </div>
        <div className={InterviewSummaryStyles.subtitle}>
          <Interweave content={subtitle} />
        </div>
        <div className={InterviewSummaryStyles.separator} />
      </section>

      <section className={InterviewSummaryStyles.interviewContainer}>
        <GeneralInformations />
        <CompanyInformations />
        <JobInformations />
        <ProfileInformations />
      </section>

      <section
        name="sendEmailAlert"
        className={InterviewSummaryStyles.containerAlert}
      >
        <Snackbar
          open={showAlert}
          autoHideDuration={5000}
          onClose={() => {
            setShowAlert(false);
          }}
        >
          <Alert
            icon={false}
            variant="filled"
            className={InterviewSummaryStyles.emailSendAlert}
            onClose={() => {
              setShowAlert(false);
            }}
          >
            {emailSendAlert}
          </Alert>
        </Snackbar>
      </section>

      <section
        name="sendEmail"
        className={InterviewSummaryStyles.stickySendEmailBtn}
      >
        <div className={InterviewSummaryStyles.containerSendEmail}>
          <Button
            onClick={() => {
              sendSummaryEmailCallback();
            }}
            width={80}
            text={sendSummary}
            styles={{
              maxWidth: "480px",
            }}
          />
        </div>
      </section>
    </main>
  );
};

export default InterviewSummary;

import { InterviewFormNameEnum } from "utils/constants/enums/InterviewFormNameEnum";
import { withConnectedFormik } from "hooks/withConnectedFormik";

import { saveJobChallengesInterviewForm } from "domain/interview/interviewControllers";
import {
  ValidationSchemas,
  InterviewInitialValues,
} from "domain/interview/interviewFormUtils";

import InterviewJobChallengesForm from "./InterviewJobChallengesForm";

export default withConnectedFormik(
  InterviewFormNameEnum.INTERVIEW_JOB_CHALLENGES,
  saveJobChallengesInterviewForm,
  InterviewInitialValues.INTERVIEW_JOB_CHALLENGES,
  ValidationSchemas.INTERVIEW_JOB_CHALLENGES
)(InterviewJobChallengesForm);

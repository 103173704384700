import { createTransform } from "redux-persist";

import StoreKeys from "utils/constants/store-keys.json";

const transformsSet = createTransform(
  // transform state on its way to being serialized and persisted.
  (inboundState, key) => {
    return { ...inboundState };
  },
  // transform state being rehydrated
  (outboundState, key) => {
    return { ...outboundState };
  },
  // define which reducers this transform gets called for.
  { whitelist: [StoreKeys.USERS, `${StoreKeys.INTERVIEW}.currentInterview`] }
);

export default transformsSet;

import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { v4 as uuidv4 } from "uuid";

import { Box, Grid, Tab, Tabs, useMediaQuery, useTheme } from "@mui/material";

import foundersPartners from "utils/translations/fr/foundersPartners.json";

import FoundersPartnersSectionStyles from "./FoundersPartnersSection.module.scss";

const FoundersPartnersSection = () => {
  const { t } = useTranslation("mission");

  const [tabValue, setTabValue] = useState(0);
  const handleChange = useCallback((event, newValue) => {
    setTabValue(newValue);
  }, []);

  const { founders, partners, sponsors } = foundersPartners;
  const foundersPartnerssData = founders.concat(sponsors.concat(partners));

  const { breakpoints } = useTheme();
  const isScreenUpSM = useMediaQuery(breakpoints.up("sm"));
  const isScreenDownSM = useMediaQuery(breakpoints.down("sm"));

  return (
    <>
      {isScreenUpSM && (
        <Tabs
          value={tabValue}
          onChange={handleChange}
          centered
          sx={{
            mb: 10,
          }}
        >
          <Tab
            label={t("foundersPartnersTab_1")}
            tabtype="tabFoundersPartners"
          />
          <Tab
            label={t("foundersPartnersTab_2")}
            tabtype="tabFoundersPartners"
          />
          <Tab
            label={t("foundersPartnersTab_3")}
            tabtype="tabFoundersPartners"
          />
          <Tab
            label={t("foundersPartnersTab_4")}
            tabtype="tabFoundersPartners"
          />
        </Tabs>
      )}

      {tabValue === 0 && (
        <Box className={FoundersPartnersSectionStyles.slider}>
          <Box className={FoundersPartnersSectionStyles.slideTrack}>
            {foundersPartnerssData.map((founderPartner) => (
              <Box
                key={uuidv4()}
                className={FoundersPartnersSectionStyles.slide}
              >
                <img
                  src={founderPartner.image}
                  height={founderPartner.height}
                  width={isScreenDownSM ? "60px" : founderPartner.width}
                  alt={founderPartner.alt}
                />
              </Box>
            ))}
          </Box>
        </Box>
      )}
      <Grid
        container
        width="70%"
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        {tabValue === 1 && (
          <>
            {founders.map((founder) => (
              <Grid item lg={2} key={uuidv4()}>
                <img
                  src={founder.image}
                  alt={founder.alt}
                  height={founder.height}
                  width={founder.width}
                  loading="lazy"
                />
              </Grid>
            ))}
          </>
        )}
      </Grid>
      {tabValue === 2 && (
        <Box className={FoundersPartnersSectionStyles.slider}>
          <Box className={FoundersPartnersSectionStyles.slideTrack}>
            {sponsors.map((sponsor) => (
              <Box
                key={uuidv4()}
                className={FoundersPartnersSectionStyles.slide}
              >
                <img
                  src={sponsor.image}
                  height={sponsor.height}
                  width={isScreenDownSM ? "60px" : sponsor.width}
                  alt={sponsor.alt}
                />
              </Box>
            ))}
          </Box>
        </Box>
      )}
      {tabValue === 3 && (
        <Box className={FoundersPartnersSectionStyles.slider}>
          <Box className={FoundersPartnersSectionStyles.slideTrack}>
            {partners.map((partner) => (
              <Box
                key={uuidv4()}
                className={FoundersPartnersSectionStyles.slide}
              >
                <img
                  src={partner.image}
                  height={partner.height}
                  width={isScreenDownSM ? "60px" : partner.width}
                  alt={partner.alt}
                />
              </Box>
            ))}
          </Box>
        </Box>
      )}
    </>
  );
};

export default FoundersPartnersSection;

const MuiBreadcrumbs = {
  styleOverrides: {
    root: ({ theme }) => ({
      padding: theme.spacing(1),
      color: theme.palette.breadcrumbs.textColor,
      "& a": {
        paddingLeft: "0",
        paddingRight: "0",
        minWidth: "0",
      },
    }),
  },
};

export default MuiBreadcrumbs;

import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import Interweave from "interweave";

import continueToSolutionDataGA from "config/googleAnalytics/continueToSolutionDataGA";
import subscribeDataGA from "config/googleAnalytics/subscribeDataGA";
import closeAndGoToSolutionDataGA from "config/googleAnalytics/closeAndGoToSolutionDataGA";
import { getAnswersByQid } from "utils/helpers/questionnaireHelper";
import { isEmailValidRFC822 } from "utils/helpers/usersHelper";
import { openInNewTabKeepFocus } from "utils/helpers/windowHelpers";

import { selectUserAnswers, selectUser } from "store/users/usersSelectors";
import { subscribeUserAction } from "domain/user/userControllers";
import { sendRecapMail } from "domain/mail/mailControllers";

import Icon from "../Icon";

import {
  Box,
  Typography,
  Modal,
  useTheme,
  Button,
  useMediaQuery,
  Input,
  Link,
} from "@mui/material";

const ModalMail = ({
  isOpen,
  externalOpenMailModal,
  selectedSolution,
  solutionsTextsForEmailTemplate,
  responsesTextsForEmailTemplate,
  suggestionsTextsForEmailTemplate,
  sendInBlueStarAttribute,
}) => {
  const { t } = useTranslation("solutions");
  const {
    intro,
    text,
    buttonLabel,
    placeholderText,
    continueWithoutEmail,
    mentionText,
    terms,
  } = t("emailPopUp", {
    returnObjects: true,
  });

  const [open, setOpen] = useState(isOpen);
  const [email, setEmail] = useState("");
  const [isEmailSaved, setIsEmailSaved] = useState(false);
  const user = useSelector(selectUser);
  const userAnswers = useSelector(selectUserAnswers);
  const dispatch = useDispatch();

  const theme = useTheme();
  const isScreenDownSM = useMediaQuery(theme.breakpoints.down("sm"));
  const isScreenDownLG = useMediaQuery(theme.breakpoints.down("lg"));

  const {
    variables: {
      modalMail: { desktop, mobile },
    },
    palette: {
      modalMail: {
        backgroundColor,
        titleColor,
        continuerWithoutEmailColor,
        termsTextColor,
        inputBorderColor,
      },
    },
    typography: {
      modalMail: {
        titleStyles,
        titleFontSizeDesktop,
        titleFontSizeMobile,
        termsStyles,
      },
    },
  } = theme;

  // ** This effect is triggered if the user has been saved
  useEffect(() => {
    if (user?.email && isEmailValidRFC822(user.email)) {
      setEmail(user.email);
      setIsEmailSaved(true);
    }
  }, [user]);

  // ** This effect is triggered if we want to open the modal from an external action outside this component
  useEffect(() => {
    setOpen(isOpen);
  }, [isOpen]);

  const handleClose = useCallback(() => {
    closeAndGoToSolutionDataGA({
      solutionNom: selectedSolution.title,
    });
    setOpen(false);
    externalOpenMailModal(false);
  }, [setOpen, externalOpenMailModal, selectedSolution]);

  const handleSaveMailClick = useCallback(async () => {
    if (isEmailSaved) {
      handleClose();
      return;
    }
    if (selectedSolution) {
      subscribeDataGA();
      window.open(selectedSolution.link, "_blank");
      continueToSolutionDataGA({
        solutionNom: selectedSolution.title,
        mailLaisse: true,
      });
      handleClose();
    }
    if (user) {
      const userWithEmail = Object.assign({}, user);
      const emailValue = email.trim();
      const answersEntered = getAnswersByQid(userAnswers, "q1");
      userWithEmail.firstName = answersEntered?.[0]?.value || "";
      sendRecapMail(
        user.uid,
        emailValue,
        userWithEmail.firstName,
        solutionsTextsForEmailTemplate,
        suggestionsTextsForEmailTemplate,
        responsesTextsForEmailTemplate
      );
      if (user.uid) {
        dispatch(
          subscribeUserAction(
            user.uid,
            emailValue,
            userWithEmail,
            userAnswers,
            sendInBlueStarAttribute
          )
        );
        setIsEmailSaved(true);
        handleClose();
      } else {
        console.warn("user email not saved", emailValue);
      }
    }
  }, [
    handleClose,
    isEmailSaved,
    setIsEmailSaved,
    user,
    userAnswers,
    solutionsTextsForEmailTemplate,
    suggestionsTextsForEmailTemplate,
    dispatch,
    email,
    selectedSolution,
    responsesTextsForEmailTemplate,
    sendInBlueStarAttribute,
  ]);

  return (
    <Modal
      open={open}
      onClose={handleClose}
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box
        width={
          isScreenDownSM
            ? mobile.popUpWidthSMDown
            : isScreenDownLG
            ? mobile.popUpWidthLGDown
            : desktop.popUpWidth
        }
        px={desktop.popUpPadding}
        pb={desktop.popUpPadding}
        pt={desktop.popUpPaddingTop}
        bgcolor={backgroundColor}
        borderRadius={desktop.popUpBorderRadius}
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
      >
        <Box
          position="relative"
          top={21}
          display={"flex"}
          justifyContent={"flex-end"}
          width="100%"
          onClick={handleClose}
          sx={{ cursor: "pointer" }}
        >
          <Icon iconName="close" iconSize={16} iconColor="black" />
        </Box>
        <Typography
          id="modal-modal-title"
          color={titleColor}
          sx={{
            ...titleStyles,
            fontSize: isScreenDownLG
              ? titleFontSizeMobile
              : titleFontSizeDesktop,
          }}
        >
          <Interweave content={intro} />
        </Typography>
        <Typography
          id="modal-modal-description"
          variant="paragraph"
          width={isScreenDownLG ? mobile.textWidth : desktop.textWidth}
          textAlign={isScreenDownLG ? "left" : "center"}
          marginTop={desktop.textMarginTop}
        >
          {text}
        </Typography>
        <Box
          width={isScreenDownLG ? "100%" : "50%"}
          mt={desktop.emailMarginTop}
          mb={1}
        >
          <Input
            fullWidth
            disableUnderline
            type="text"
            placeholder={user.email || placeholderText}
            onChange={(evt) => {
              setEmail(evt.target.value);
            }}
            sx={{ border: "1px solid", p: 4, borderColor: inputBorderColor }}
          />
          <br />
          <Typography color={termsTextColor} sx={termsStyles}>
            <Interweave content={mentionText} />
            <Link color={termsTextColor} href={t("path:path.terms")}>
              {terms}
            </Link>
          </Typography>
          <br />
          <Button
            variant="contained"
            color="orange"
            size="large"
            type="submit"
            fullWidth
            endIcon={<Icon iconName="arrowRightShort" iconSize={16} />}
            onClick={handleSaveMailClick}
            disabled={!user?.email && !isEmailValidRFC822(email)}
          >
            {buttonLabel}
          </Button>
        </Box>
        <Button
          variant="text"
          type="button"
          endIcon={
            <Icon
              iconName="arrowRightShort"
              iconSize={16}
              iconColor={continuerWithoutEmailColor}
            />
          }
          onClick={() => {
            continueToSolutionDataGA({
              solutionNom: selectedSolution.title,
              mailLaisse: false,
            });
            openInNewTabKeepFocus(selectedSolution.link);
          }}
          style={{ color: continuerWithoutEmailColor }}
        >
          {continueWithoutEmail}
        </Button>
      </Box>
    </Modal>
  );
};

export default ModalMail;

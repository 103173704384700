import { InterviewFactory } from "domain/interview/interviewModels";

const newInterviewReducer = {
  createNewInterviewData: (state, action) => {
    return {
      ...state,
      currentInterview: InterviewFactory(
        action.payload.userId,
        action.payload.interviewId
      ).instance,
    };
  },
};

export default newInterviewReducer;

const userManagementReducers = {
  saveUserQuestionnaire: (state, action) => {
    return {
      ...state,
      user: {
        ...state.user,
        questionnaire: action.payload,
      },
    };
  },

  saveAllDiagnostics: (state, action) => {
    return {
      ...state,
      user: {
        ...state.user,
        allDiagnostics: action.payload,
      },
    };
  },
};

export default userManagementReducers;

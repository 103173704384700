import SendInBlueStarAttributeEnum from "utils/constants/enums/SendInBlueStarAttributeEnum";
import GroupStarEnum from "./../constants/enums/GroupStarEnum";

/**
 * Data object representing SendInBlue data.
 *
 * @typedef {Object} SendInBlueData
 * @property {Object} [GroupStarEnum.CONVINCE] - Data for Convaincre en entretien group.
 * @property {Array} [GroupStarEnum.CONVINCE.attribute] - Attributes for Convaincre en entretien group.
 * @property {Object} [GroupStarEnum.APPLY] - Data for Savoir postuler group.
 * @property {Array} [GroupStarEnum.APPLY.attribute] - Attributes for Savoir postuler group.
 * @property {Object} [GroupStarEnum.LEARN] - Data for Se former à un métier group.
 * @property {Array} [GroupStarEnum.LEARN.attribute] - Attributes for Se former à un métier group.
 * @property {Object} [GroupStarEnum.KNOW] - Data for Se connaître group.
 * @property {Array} [GroupStarEnum.KNOW.attribute] - Attributes for Se connaître group.
 */
const sendInBlueData = {
  [GroupStarEnum.CONVINCE]: {
    // ** Convaincre en entretien
    attribute: [SendInBlueStarAttributeEnum.CONVAINCRE_ENTRETIEN],
  },
  [GroupStarEnum.APPLY]: {
    // ** Savoir postuler
    attribute: [SendInBlueStarAttributeEnum.SAVOIR_POSTULER],
  },
  [GroupStarEnum.LEARN]: {
    // ** Se former à un métier
    attribute: [SendInBlueStarAttributeEnum.SE_FORMER],
  },
  [GroupStarEnum.KNOW]: {
    // ** Se connaître
    attribute: [SendInBlueStarAttributeEnum.SE_CONNAITRE],
  },
};

export default sendInBlueData;

import { Box } from "@mui/material";

export const SolutionsNumberPill = ({ backgroundColor, color, count }) => {
  return (
    <Box
      mx={2}
      py={1}
      px={3}
      borderRadius={1}
      sx={{
        backgroundColor,
        color,
      }}
    >
      {count}
    </Box>
  );
};

import React from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Interweave from "interweave";

import {
  Box,
  Button,
  Container,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";

import { getSolutionTeamPicturesUrlFromFirebaseDevStorage } from "utils/helpers/assetsHelpers";

import TeamBoard from "components/common/TeamBoard";
import Icon from "components/common/Icon";

const TeamPage = () => {
  const history = useHistory();
  const { t } = useTranslation(["team", "directorsBoard", "path"]);
  const directorsData = t("directorsBoard:directors", { returnObjects: true });
  const squadData = t("team:squad", { returnObjects: true });

  const {
    breakpoints,
    variables: {
      spaceBetweenSectionsDesktop,
      spaceBetweenSectionsMobile,
      teamDirectorCard: {
        desktop: { gridItemMarginBottomDesktop },
        mobile: { gridItemMarginBottomMobile },
      },
      teamPage: {
        desktop: {
          containerMarginTopDesktop,
          containerMarginBottomDesktop,
          mavoieTeamTextWidthDesktop,
          H1MarginBottomDesktop,
          descriptionWidthDesktop,
          teamPictureLargeWidthDesktop,
          teamPictureLargeHeightDesktop,
          teamMembersH2MarginTopDesktop,
          teamMembersWidthDesktop,
          directorsBoardTitleMarginBottomDesktop,
          directorsBoardDescriptionWidthDesktop,
          contactUsPaddingDesktop,
          contactUSTitleMarginBottomDesktop,
          contactUsBorderRadiusDesktop,
          contactUsDescriptionWidthDesktop,
        },
        mobile: {
          containerMarginTopMobile,
          containerMarginBottomMobile,
          H1MarginBottomMobile,
          mavoieTeamTextWidthMobile,
          teamPictureLargeHeightMobile,
          teamMembersWidthMobile,
          teamMembersH2MarginTopMobile,
          directorsBoardDescriptionWidthMobile,
          contactUsDescriptionWidthMobile,
        },
      },
    },
    typography: {
      teamPage: { titleStylesDesktop, descriptionTextStyles },
    },
    palette: {
      teamPage: { bgcolor },
    },
  } = useTheme();

  const isScreenDownSM = useMediaQuery(breakpoints.down("sm"));
  const isScreenDownLG = useMediaQuery(breakpoints.down("lg"));

  const goToContactPage = () => {
    history.push(t("path:path.contact"));
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <Container
      maxWidth="xl"
      sx={{
        mt: isScreenDownSM
          ? containerMarginTopMobile
          : containerMarginTopDesktop,
        mb: isScreenDownSM
          ? containerMarginBottomMobile
          : containerMarginBottomDesktop,
        px: isScreenDownSM ? 5 : 0,
      }}
    >
      <section name="mavoieTeam">
        <Box
          display="flex"
          flexDirection={isScreenDownLG ? "column" : "row"}
          gap={4}
          alignItems="center"
          mb={
            isScreenDownSM
              ? spaceBetweenSectionsMobile
              : spaceBetweenSectionsDesktop
          }
        >
          <Box
            width={
              isScreenDownSM
                ? mavoieTeamTextWidthMobile
                : mavoieTeamTextWidthDesktop
            }
          >
            <Typography
              variant="h1"
              mb={isScreenDownSM ? H1MarginBottomMobile : H1MarginBottomDesktop}
            >
              <Interweave content={t("team:mavoieTeamTitle")} />
            </Typography>
            <Box width={descriptionWidthDesktop}>
              <Typography variant="paragraph" sx={descriptionTextStyles}>
                <Interweave content={t("team:description")} />
              </Typography>
            </Box>
          </Box>
          <Box>
            <img
              src={
                isScreenDownSM
                  ? getSolutionTeamPicturesUrlFromFirebaseDevStorage(
                      t("team:teamPictureSmall")
                    )
                  : getSolutionTeamPicturesUrlFromFirebaseDevStorage(
                      t("team:teamPictureLarge")
                    )
              }
              alt={t("team:mavoieTeamTitle")}
              style={{
                width: teamPictureLargeWidthDesktop,
                height: isScreenDownSM
                  ? teamPictureLargeHeightMobile
                  : teamPictureLargeHeightDesktop,
              }}
            />
          </Box>
        </Box>
      </section>

      <section name="teamMembers">
        <Typography
          variant="h2"
          textAlign="center"
          mb={
            isScreenDownSM
              ? teamMembersH2MarginTopMobile
              : teamMembersH2MarginTopDesktop
          }
        >
          {t("team:teamMembersTitle")}
        </Typography>
        <Box display="flex" justifyContent="center">
          <Box
            width={
              isScreenDownSM ? teamMembersWidthMobile : teamMembersWidthDesktop
            }
            textAlign="center"
            mb={
              isScreenDownSM
                ? spaceBetweenSectionsMobile - gridItemMarginBottomMobile
                : spaceBetweenSectionsDesktop - gridItemMarginBottomDesktop
            }
          >
            <TeamBoard squadData={squadData} />
          </Box>
        </Box>
      </section>

      <section name="directorsBoard">
        <Box display="flex" flexDirection="column" alignItems="center">
          <Typography
            mb={directorsBoardTitleMarginBottomDesktop}
            sx={titleStylesDesktop}
          >
            {t("directorsBoard:title")}
          </Typography>
          <Box
            width={
              isScreenDownSM
                ? directorsBoardDescriptionWidthMobile
                : directorsBoardDescriptionWidthDesktop
            }
            textAlign="center"
            mb={
              isScreenDownSM
                ? spaceBetweenSectionsMobile - gridItemMarginBottomMobile
                : spaceBetweenSectionsDesktop - gridItemMarginBottomDesktop
            }
          >
            <Typography variant="paragraph">
              <Interweave content={t("directorsBoard:text")} />
            </Typography>
            <TeamBoard directorsData={directorsData} />
          </Box>
        </Box>
      </section>

      <section name="contact-us">
        <Box
          display={isScreenDownSM ? "block" : "flex"}
          alignItems="center"
          bgcolor={bgcolor}
          p={contactUsPaddingDesktop}
          borderRadius={contactUsBorderRadiusDesktop}
        >
          <Box
            width={
              isScreenDownSM
                ? contactUsDescriptionWidthMobile
                : contactUsDescriptionWidthDesktop
            }
          >
            <Typography
              mb={contactUSTitleMarginBottomDesktop}
              sx={titleStylesDesktop}
            >
              <Interweave content={t("team:contactUsTitle")} />
            </Typography>
            <Typography variant="paragraph">
              <Interweave content={t("team:contactUsDescription")} />
            </Typography>
          </Box>
          <Box
            width={isScreenDownSM ? "100%" : "30%"}
            textAlign={isScreenDownSM ? "left" : "center"}
            mt={isScreenDownSM && 4}
          >
            <Button
              variant="contained"
              color="black"
              size="large"
              startIcon={<Icon iconName="arrowRightShort" />}
              onClick={goToContactPage}
            >
              {t("team:contactUsButton")}
            </Button>
          </Box>
        </Box>
      </section>
    </Container>
  );
};

export default TeamPage;

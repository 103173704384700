import React from "react";
import { useTranslation } from "react-i18next";

import GeneralInformationStyles from "./GeneralInformations.module.scss";
import InterviewSummaryStyles from "../InterviewSummary.module.scss";
import Interweave from "interweave";

import useSummaryInterviewData from "hooks/useSummaryInterviewData";

const InterviewGeneralInformations = () => {
  const { t } = useTranslation("interview");
  const { interviewEmail, jobContent, companyContent, interviewDateContent } =
    useSummaryInterviewData();
  const { generalInformations, email, jobLabel, companyName, date } = t(
    "interviewSummary",
    {
      returnObjects: true,
      company: companyContent,
    }
  );

  return (
    <section name="generalInformations">
      <div className={GeneralInformationStyles.generalInfosTitle}>
        <Interweave content={generalInformations} />
      </div>
      <div
        name="generalInfosBody"
        className={GeneralInformationStyles.generalInfosBody}
      >
        <div className={GeneralInformationStyles.interviewInfos}>
          <Interweave
            className={InterviewSummaryStyles.sectionSubtitle}
            content={email}
          />
          <Interweave
            className={GeneralInformationStyles.interviewEmail}
            content={interviewEmail}
          />
        </div>
        <div className={GeneralInformationStyles.interviewInfos}>
          <Interweave
            className={InterviewSummaryStyles.sectionSubtitle}
            content={jobLabel}
          />
          <Interweave
            className={InterviewSummaryStyles.sectionContent}
            content={jobContent}
          />
        </div>
        <div className={GeneralInformationStyles.interviewInfos}>
          <Interweave
            className={InterviewSummaryStyles.sectionSubtitle}
            content={companyName}
          />
          <Interweave
            className={InterviewSummaryStyles.sectionContent}
            content={companyContent}
          />
        </div>
        <div className={GeneralInformationStyles.interviewInfos}>
          <Interweave
            className={InterviewSummaryStyles.sectionSubtitle}
            content={date}
          />
          <Interweave
            className={InterviewSummaryStyles.sectionContent}
            content={interviewDateContent}
          />
        </div>
      </div>
    </section>
  );
};

export default InterviewGeneralInformations;

import { v4 as uuidv4 } from "uuid";
import Interweave from "interweave";

import { useTranslation } from "react-i18next";

import InterviewSummaryStyles from "../InterviewSummary.module.scss";
import useSummaryInterviewData from "hooks/useSummaryInterviewData";
import InterviewToolTipBox from "components/common/InterviewToolTipBox/InterviewToolTipBox";
import InterviewBulletPoint from "components/common/InterviewBulletPoint/InterviewBulletPoint";

import { InterviewAdviceNumberEnum } from "utils/constants/enums/InterviewAdviceNumberEnum";
import { LastProgressNumberEnum } from "utils/constants/enums/LastProgressNumberEnum";

const JobInformations = () => {
  const { t } = useTranslation("interview");

  const {
    jobContent,
    companyContent,
    jobDomainContent,
    jobDescriptionsContent,
    jobSoftSkillsContent,
    jobBestOfContent,
    jobChallengesContent,
    jobAdviceC1,
    jobAdviceC2,
    jobAdviceC3,
  } = useSummaryInterviewData();

  const {
    jobTitle,
    softSkillsTitle,
    bestOfTitle,
    jobChallengeTitle,
    noAnswer,
  } = t("interviewSummary", {
    returnObjects: true,
    company: companyContent,
  });

  return (
    <section name="jobInformations">
      <section
        name="jobTitle"
        className={InterviewSummaryStyles.containerTitle}
      >
        <Interweave
          className={InterviewSummaryStyles.sectionTitle}
          content={jobTitle}
        />
      </section>

      <section name="jobContent">
        <div className={InterviewSummaryStyles.containerInfos}>
          <Interweave
            className={InterviewSummaryStyles.sectionJobDetailTitle}
            content={`${jobDomainContent} : ${jobContent}`}
          />
          {jobDescriptionsContent?.[0] && (
            <Interweave
              className={InterviewSummaryStyles.sectionContentBlock}
              content={jobDescriptionsContent[0]}
            />
          )}
          <InterviewToolTipBox
            toolTipTextTitle={t("interviewSummary.toolTipTextTitle", {
              number:
                InterviewAdviceNumberEnum[
                  LastProgressNumberEnum.InterviewJob.C1
                ],
            })}
            toolTipTextContent={jobAdviceC1}
          />
          <div className={InterviewSummaryStyles.separator} />
          <Interweave
            className={InterviewSummaryStyles.sectionSubtitle}
            content={softSkillsTitle}
          />
          {jobSoftSkillsContent?.map((skill) => (
            <InterviewBulletPoint key={uuidv4()} content={skill} />
          ))}
          <InterviewToolTipBox
            toolTipTextTitle={t("interviewSummary.toolTipTextTitle", {
              number:
                InterviewAdviceNumberEnum[
                  LastProgressNumberEnum.InterviewJob.C2
                ],
            })}
            toolTipTextContent={jobAdviceC2}
          />
          <div className={InterviewSummaryStyles.separator} />
          <Interweave
            className={InterviewSummaryStyles.sectionSubtitle}
            content={bestOfTitle}
          />
          <Interweave
            className={InterviewSummaryStyles.sectionContentBlock}
            content={jobBestOfContent || noAnswer}
          />
          <Interweave
            className={InterviewSummaryStyles.sectionSubtitle}
            content={jobChallengeTitle}
          />
          <Interweave
            className={InterviewSummaryStyles.sectionContentBlock}
            content={jobChallengesContent || noAnswer}
          />
          <InterviewToolTipBox
            toolTipTextTitle={t("interviewSummary.toolTipTextTitle", {
              number:
                InterviewAdviceNumberEnum[
                  LastProgressNumberEnum.InterviewJob.C3
                ],
            })}
            toolTipTextContent={jobAdviceC3}
          />
        </div>
      </section>
    </section>
  );
};

export default JobInformations;

import React, { useCallback, useRef, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { useTranslation } from "react-i18next";

import { FormationFactory } from "domain/solution/solutionModels";
import CardContent from "components/common/Carousels/Card/CardContent";
import SolutionCard from "components/Solutions/SolutionCard";

import { SolutionsCardStylesProvider } from "hooks/withSolutionCardStyles";

import { Box, Button, Container, useMediaQuery, useTheme } from "@mui/material";

const SuggestionsSection = ({ data, openMailModal, setSelectedSolution }) => {
  const theme = useTheme();
  const isScreenDownSM = useMediaQuery(theme.breakpoints.down("sm"));
  const { t } = useTranslation(["solutions"]);
  const cardGroupRefs = useRef([]);

  const cards = data.map((formationResult, index) => {
    const formation = formationResult || FormationFactory();
    return {
      content: (
        <CardContent
          openMailModal={openMailModal}
          setSelectedSolution={setSelectedSolution}
          index={index + 1}
          item={formation}
          section={"suggestions"}
        />
      ),
      title: formation.title,
    };
  });

  const CardGroup = React.forwardRef(({ cards }, ref) => (
    <SolutionsCardStylesProvider>
      <Box ref={ref} display="flex" flexWrap="wrap" justifyContent="center">
        {cards.map((card, index) => {
          const { name, srcLogo, partner, type, link, intro } =
            card.content.props.item;
          return (
            <Box mx={3} mb={8.5} key={uuidv4()}>
              <SolutionCard
                index={index + 1}
                srcLogo={srcLogo}
                title={`${name} - ${partner}`}
                type={type}
                link={link}
                intro={intro}
                openMailModal={openMailModal}
                setSelectedSolution={setSelectedSolution}
                section="suggestions"
              />
            </Box>
          );
        })}
      </Box>
    </SolutionsCardStylesProvider>
  ));

  const [currentFirstCard, setCurrentFirstCard] = useState(0);
  const [currentCardGroups, setCurrentCardGroups] = useState([
    <CardGroup key={0} cards={cards.slice(0, 3)} />,
  ]);

  const showNextCards = useCallback(() => {
    const nextFirstCard = currentFirstCard + 3;
    if (nextFirstCard < cards.length) {
      setCurrentFirstCard(nextFirstCard);
      setCurrentCardGroups((prevCardGroups) => [
        ...prevCardGroups,
        <CardGroup
          key={nextFirstCard}
          cards={cards.slice(nextFirstCard, nextFirstCard + 3)}
          ref={(el) => (cardGroupRefs.current[nextFirstCard] = el)}
        />,
      ]);

      // Scroll to the next CardGroup
      setTimeout(() => {
        cardGroupRefs.current[nextFirstCard].scrollIntoView({
          behavior: "smooth",
        });
      }, 0);
    }
  }, [currentFirstCard, cards]);

  return (
    <Container
      maxWidth="xl"
      sx={{
        marginBottom: isScreenDownSM ? 28 : 0,
      }}
    >
      {currentCardGroups}

      <Box textAlign="center">
        <Button
          variant="outlined"
          color="black"
          onClick={showNextCards}
          disabled={currentFirstCard + 3 >= cards.length}
        >
          {t("suggestionsButtonText")}
        </Button>
      </Box>
    </Container>
  );
};

export default React.memo(SuggestionsSection);

import React, { useCallback, useMemo } from "react";
import { useHistory, useParams } from "react-router-dom";
import Interweave from "interweave";
import { useSelector } from "react-redux";

import { selectProfileSkillsFromCurrentInterview } from "store/interview/interviewSelectors";

import { useWindowSize } from "hooks/useWindowSize";

import { mdiArrowLeft } from "@mdi/js";
import MdiIconButton from "../MdiIconButton";
import ToolTipIcon from "assets/images/interview-template-form-toolTip-icon.svg";
import Button from "../Button(ToBeUpdated)";
import { default as style } from "./TemplateFormInterview.module.scss";
import { MapPathnameToProgressNumberEnum } from "../../../utils/constants/enums/MapPathnameToProgressNumberEnum";
import { getPreviousPageDynamicaly } from "utils/helpers/profileSkillsHelpers";

const TemplateFormInterview = ({
  children,
  questionName,
  toolTipTextTitle,
  toolTipTextContent,
  isButtonDisabled,
  buttonLabelName,
  pageStepCount,
}) => {
  const history = useHistory();
  const { skillNumber } = useParams();
  const profileSkills = useSelector(selectProfileSkillsFromCurrentInterview);

  const previousPage = useMemo(
    () =>
      getPreviousPageDynamicaly(
        profileSkills,
        skillNumber,
        MapPathnameToProgressNumberEnum[history.location.pathname].previousPage
      ),
    [profileSkills, skillNumber, history.location.pathname]
  );

  const goBack = useCallback(
    () => (previousPage ? history.push(previousPage) : history.goBack()),
    [previousPage, history]
  );

  const viewPortHeight = useWindowSize()[1];

  return (
    <div className={style.container} style={{ minHeight: viewPortHeight }}>
      <div className={style.centerContainer}>
        <div className={style.backArrow}>
          <MdiIconButton mdiIcon={mdiArrowLeft} onClick={goBack} />
        </div>
        <div className={style.question}>
          <div className={style.questionName}>
            {pageStepCount && (
              <div className={style.pageStepCount}>{pageStepCount} / 3</div>
            )}
            <Interweave content={questionName} />
          </div>
          {children}
          <div>
            {toolTipTextTitle && toolTipTextContent && (
              <div className={style.toolTipBox}>
                <img src={ToolTipIcon} alt="interview question tip" />
                <div className={style.toolTipTextContainer}>
                  <p className={style.toolTipTextTitle}>
                    <Interweave content={toolTipTextTitle} />
                  </p>
                  <p className={style.toolTipTextContent}>
                    <Interweave content={toolTipTextContent} />
                  </p>
                </div>
              </div>
            )}
            <Button
              width={100}
              text={buttonLabelName}
              type="submit"
              {...(isButtonDisabled ? { disabled: isButtonDisabled } : {})}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default TemplateFormInterview;

import React from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import clsx from "clsx";

import { useMediaQueries } from "hooks/useMediaQueries";
import InterviewCompanyValuesFormStyles from "./InterviewCompanyValuesForm.module.scss";

import { selectCompanyNameFromCurrentInterview } from "store/interview/interviewSelectors";

import TemplateFormInterview from "components/common/TemplateFormInterview";
import MultiInputField from "components/common/MultiInputField";

const InterviewCompanyValuesForm = () => {
  const { t } = useTranslation("interview");
  const { isMobile } = useMediaQueries();
  const {
    questionName,
    toolTipTextTitle,
    toolTipTextContent,
    placeHolders,
    buttonLabelName,
  } = t("companyValues", {
    returnObjects: true,
    company: useSelector(selectCompanyNameFromCurrentInterview),
  });

  return (
    <TemplateFormInterview
      questionName={questionName}
      toolTipTextTitle={toolTipTextTitle}
      toolTipTextContent={toolTipTextContent}
      buttonLabelName={buttonLabelName}
    >
      <div
        className={clsx({
          [InterviewCompanyValuesFormStyles.multiInputListL]: true,
          [InterviewCompanyValuesFormStyles.multiInputListS]: isMobile,
        })}
      >
        <MultiInputField name="values" number={3} placeHolders={placeHolders} />
      </div>
    </TemplateFormInterview>
  );
};

export default InterviewCompanyValuesForm;

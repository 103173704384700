import { JobDescriptionPoleEmploieMapperFactory } from "domain/interview/interviewModels";

const jobDataReducer = {
  saveJobDetailPoleEmploieData: (state, action) => {
    return {
      ...state,
      jobData: {
        ...state.jobData,
        poleEmploieData: {
          ...(state.jobData?.poleEmploieData || {}),
          jobDetail: JobDescriptionPoleEmploieMapperFactory(action.payload)
            .instance,
        },
      },
    };
  },
  saveJobCodeRomeAndLibelleAppellationCourt: (state, action) => {
    return {
      ...state,
      jobData: {
        ...state.jobData,
        poleEmploieData: {
          ...(state.jobData?.poleEmploieData || {}),
          codeRome: action.payload?.codeRome,
          libelleAppellationCourt: action.payload?.libelleAppellationCourt,
        },
      },
    };
  },
};

export default jobDataReducer;

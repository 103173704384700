const variablesTheme = {
  // Navigation Menu Bar : components/common/NavBarMenu
  navbar: {
    bgColor: "rgba(255, 255, 255, 0.60)",
    zIndex: 40,
    py: 5,
    backdropFilter: "blur(7.5px)",
    menuToggledStyles: {
      position: "absolute",
      top: "140px",
      zIndex: 1500,
      marginLeft: "16px",
      height: "calc(100% - 164px)",
      display: "flex",
      flexDirection: "column",
      gap: "16px",
      width: "90%",
    },
    small: {
      top: 56,
      gap: 0,
      borderRadius: 20,
      px: 15,
      zIndex: 40,
      imgWidth: "58px",
    },
    large: {
      top: 0,
      gap: "12px",
      borderRadius: 0,
      px: 4,
      imgWidth: "80px",
    },
  },
  // All common variables for HomePage Sections : pages/HomePage
  homepage: {
    small: {
      spaceBetweenSectionsHP: 28, // 28 x 4px (spacing)
    },
    large: {
      spaceBetweenSectionsHP: 40, // 40 x 4px (spacing)
      imagePillHeightLargeInPixel: 240,
    },
  },
  questionnaire: {
    containerBreadcrumbs: { mt: 6.5, px: 4 },
    contentMaxWidth: "420px",
  },
  solutions: {
    containerBreadcrumbs: {
      mt: 4,
    },
  },
  missionPage: {
    containerMarginY: 5,
    desktop: {
      responseShortVideosPaddingYDesktop: 10,
      //mission
      missionDescriptionMarginTopDesktop: 10,
      missionPictureMarginTopDesktop: 6,
      missionPictureWidthDesktop: "100%",
      missionPictureHeightDesktop: "337px",
      //observations
      observationsWidthDesktop: "48%",
      //response
      responseContainerGapDesktop: 30,
      responseDescriptionWidthDesktop: "76%",
      //proposal
      proposalContainerGapDesktop: 20,
      proposalContainerMarginBottomDesktop: "66px",
      proposalPictureWidthDesktop: "100%",
      proposalPictureHeightDesktop: "623px",
      proposalPictureBoxWidthDesktop: "81%",
      proposalPictureBoxShadowDesktop: "4px 4px 10px 0px rgba(0, 0, 0, 0.20)",
      proposalPictureBorderRadiusDesktop: "20px",
      proposalTitleWidthDesktop: "100%",
      proposalTitleMarginBottomDesktop: 10,
      proposalButtonMarginTopDesktop: 8,
      //testimonies
      testimoniesSectionMarginBottomDesktop: 21.25,
      testimoniesSectionMarginBottomMobile: 28,
      //short videos
      shortVideosLeftBoxWidthDesktop: "45%",
      shortVideosTitleWidthDesktop: "100%",
      shortVideosTitleMarginBottomDesktop: 14,
      shortVideosDescriptionWidthDesktop: "80%",
      shortVideosLinksGapDesktop: 4.5,
      shortVideosLinksMarginTopDesktop: 4,
      shortVideosRightBoxWidthDesktop: "55%",
      shortVideosMarginBottomDesktop: 63,
      //ecosystem
      ecosystemContainerGapDesktop: 22,
      ecosystemBoxLeftWidthDesktop: "34%",
      ecosystemBoxLeftPaddingDesktop: "118px 40px 40px 40px",
      ecosystemBoxLeftBorderRadiusDesktop: "20px",
      ecosystemImageTopDesktop: "-25px",
      ecosystemImageMarginTopDesktop: -47.5,
      ecosystemBoxRightWidthDesktop: "66%",
      //foundersPartners
      foundersPartnersDescriptionWidthDesktop: "70%",
    },
    mobile: {
      responseShortVideosPaddingYMobile: 5,
      //mission
      teamPictureSmallHeightMobile: "194px",
      //observations
      observationsWidthMobile: "100%",
      //response
      responseContainerGapMobile: 8,
      responseDescriptionWidthMobile: "100%",
      //proposal
      proposalContainerGapMoible: 10,
      proposalPictureHeightMobile: "453px",
      //short videos
      shortVideosLeftBoxWidthMobile: "100%",
      shortVideosTitleMarginBottomMobile: 8,
      shortVideosDescriptionWidthMobile: "100%",
      shortVideosRightBoxWidthMobile: "100%",
      shortVideosCarouselMarginBottomMobile: 10,
      //ecosystem
      ecosystemLeftBoxWidthMobile: "100%",
      ecosystemRightBoxWidthMobile: "80%",
      ecosystemBoxMarginBottomMobile: 15,
      //foundersPartners
      foundersPartnersDescriptionWidthMobile: "100%",
    },
  },
  tabFoundersPartners: {
    tabBorderRadius: "24px",
    tabPadding: "10px 16px",
  },
  teamDirectorCard: {
    desktop: {
      gridItemMarginBottomDesktop: 17,
      teamMembersImageWidthDesktop: "220px",
      teamMembersImageHeightDesktop: "220px",
      teamMembersImageMarginBottomDesktop: 3,
      directorsBoardImageWidthDesktop: "260px",
      directorsBoardImageHeightDesktop: "260px",
    },
    mobile: {
      gridItemMarginBottomMobile: 15,
      teamMembersImageWidthMobile: "160px",
      teamMembersImageHeightMobile: "160px",
      directorsBoardImageWidthMobile: "160px",
      directorsBoardImageHeightMobile: "160px",
    },
  },
  teamPage: {
    desktop: {
      //mavoieTeam
      containerMarginTopDesktop: 20,
      containerMarginBottomDesktop: 28,
      mavoieTeamTextWidthDesktop: "58%",
      H1MarginBottomDesktop: 10,
      descriptionWidthDesktop: "94%",
      teamPictureLargeWidthDesktop: "100%",
      teamPictureLargeHeightDesktop: "337px",
      //teamMembers
      teamMembersH2MarginTopDesktop: 22,
      teamMembersWidthDesktop: "72%",
      //directorsBoard
      directorsBoardTitleMarginBottomDesktop: 12,
      directorsBoardDescriptionWidthDesktop: "72%",
      //contact-us
      contactUsPaddingDesktop: 10,
      contactUSTitleMarginBottomDesktop: 10,
      contactUsBorderRadiusDesktop: "10px",
      contactUsDescriptionWidthDesktop: "70%",
    },
    mobile: {
      //mavoieTeam
      containerMarginTopMobile: 14,
      containerMarginBottomMobile: 18,
      mavoieTeamTextWidthMobile: "100%",
      H1MarginBottomMobile: 9,
      teamPictureLargeHeightMobile: "194px",
      //teamMembers
      teamMembersH2MarginTopMobile: 12,
      teamMembersWidthMobile: "100%",
      //directorsBoard
      directorsBoardDescriptionWidthMobile: "100%",
      //contact-us
      contactUsDescriptionWidthMobile: "100%",
    },
  },
  modalMail: {
    desktop: {
      popUpWidth: "936px",
      popUpPadding: "20px",
      popUpPaddingTop: 2,
      popUpBorderRadius: "20px",
      textWidth: "53%",
      textMarginTop: 5,
      emailMarginTop: 10,
    },
    mobile: {
      popUpWidthSMDown: "258px",
      popUpWidthLGDown: "359px",
      textWidth: "100%",
    },
  },
  // Global variables : margins, global spacings
  containerPX: 4,
  spaceBetweenSectionsDesktop: 40,
  spaceBetweenSectionsMobile: 28,
  externalMargin: "20px",
  spaceBetweenH1AndParagraph: "36px",
  spaceBetweenH2AndParagraph: "32px",
  spaceBetweenSubtitleAndParagraph: "20px",
};

export default variablesTheme;

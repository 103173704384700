import React from "react";
import clsx from "clsx";
import commonStyles from "./Tag.module.scss";

const Tag = (props) => {
  const tagStyle = {
    background: props.bgColor,
    borderRadius: props.radius + "px",
    color: props.txColor,
    fontSize: props.height / 2 + "px",
    height: props.height + "px",
    justifyContent: "left",
    lineHeight: props.height + "px",
  };

  const tagSpanStyle = {
    paddingLeft: props.height / 4 + "px",
    paddingRight: props.height / 4 + "px",
  };

  return (
    <div className={clsx(commonStyles.tagContainer)} style={tagStyle}>
      <span style={tagSpanStyle}>{props.text}</span>
    </div>
  );
};

export default Tag;

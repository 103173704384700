import React, { useCallback, useState } from "react";
import {
  InstantSearch,
  connectSearchBox,
  Configure,
} from "react-instantsearch-dom";
import { useDispatch, useSelector } from "react-redux";

import { searchClient, searchIndex, hitsPerPage } from "config/initConfig";

import InputAutoComplete from "../InputAutoComplete";
import { saveJobCodeRomeAndLibelleAppellationCourt } from "store/interview/interviewSlice";
import { selectJobCodeRome } from "store/interview/interviewSelectors";

import "./AutoCompleteJobInterview.scss";

import Errors from "../../../utils/translations/en/errors.json";

const VirtalSearchBox = connectSearchBox(() => null);

const AutoCompleteJobInterview = ({
  setFieldValue,
  placeholder,
  name,
  value,
}) => {
  const [query, setQuery] = useState();
  const dispatch = useDispatch();

  const codeRome = useSelector(selectJobCodeRome);

  const onSuggestionSelected = useCallback(
    (_, { suggestion }) => {
      setQuery(suggestion.libelleAppellationCourt);
      setFieldValue("job", suggestion.libelleAppellationCourt);
      dispatch(
        saveJobCodeRomeAndLibelleAppellationCourt({
          codeRome: suggestion.codeRome,
          libelleAppellationCourt: suggestion.libelleAppellationCourt,
        })
      );
    },
    [setQuery, setFieldValue, dispatch]
  );

  const onSuggestionCleared = useCallback(() => {
    setQuery("");
    setFieldValue("job", "");
    dispatch(saveJobCodeRomeAndLibelleAppellationCourt(null));
  }, [setQuery, dispatch, setFieldValue]);

  const onChange = useCallback(
    (newValue, lastSelectedValue) => {
      setFieldValue("job", newValue);
      if (lastSelectedValue !== newValue) {
        setQuery("");
        dispatch(saveJobCodeRomeAndLibelleAppellationCourt(null));
      }
    },
    [setFieldValue, dispatch]
  );

  return (
    <div className="container">
      <InstantSearch indexName={searchIndex} searchClient={searchClient}>
        <Configure hitsPerPage={hitsPerPage} />
        <InputAutoComplete
          onSuggestionSelected={onSuggestionSelected}
          onSuggestionCleared={onSuggestionCleared}
          placeHolder={placeholder}
          name={name}
          onChange={onChange}
          value={value}
          theme={{
            container: "react-autosuggest-job__container",
            containerOpen: "react-autosuggest-job__container--open",
            input: "react-autosuggest-job__input",
            inputOpen: "react-autosuggest-job__input--open",
            inputFocused: "react-autosuggest-job__input--focused",
            suggestionsContainer:
              "react-autosuggest-job__suggestions-container",
            suggestionsContainerOpen:
              "react-autosuggest-job__suggestions-container--open",
            suggestionsList: "react-autosuggest-job__suggestions-list",
            suggestion: "react-autosuggest-job__suggestion",
            suggestionFirst: "react-autosuggest-job__suggestion--first",
            suggestionHighlighted:
              "react-autosuggest-job__suggestion--highlighted",
            sectionContainer: "react-autosuggest-job__section-container",
            sectionContainerFirst:
              "react-autosuggest-job__section-container--first",
            sectionTitle: "react-autosuggest-job__section-title",
          }}
        />
        {!codeRome && value && (
          <div className="errors">{Errors.INTERVIEW.CHOOSE_IN_LIST}</div>
        )}
      </InstantSearch>
      <InstantSearch indexName={searchIndex} searchClient={searchClient}>
        <VirtalSearchBox defaultRefinement={query} />
      </InstantSearch>
    </div>
  );
};

export default AutoCompleteJobInterview;

import { GoogleAnalyticsEvents } from "../analyticsConfig";

const navigateToDiagnosticDataGA = (source) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: GoogleAnalyticsEvents.NavigationVersDiagnostic,
    sourceNavigationVersDiagnostic: source,
  });
};

export default navigateToDiagnosticDataGA;

import { useField } from "formik";
import { useEffect, useState } from "react";

export const useLettersCount = (props) => {
  const { style } = props;
  const [field] = useField(props);
  const [errorMaxLetterMessage, setErrorMaxLetterMEssage] = useState("");
  useEffect(() => {
    setErrorMaxLetterMEssage(
      `${field?.value?.length}/${props.maxLength} caractères`
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [field]);
  return (
    <>
      {field?.value?.length > 0 && (
        <p className={style.errorMaxLetterMessage}>{errorMaxLetterMessage}</p>
      )}
    </>
  );
};

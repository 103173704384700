import InterviewSkillExamplePage from "./InterviewSkillExamplePage";
import { withConnectedFormik } from "../../../hooks/withConnectedFormik";
import { InterviewFormNameEnum } from "../../../utils/constants/enums/InterviewFormNameEnum";
import { saveProfileSkillExampleInterviewForm } from "../../../domain/interview/interviewControllers";
import {
  InterviewInitialValues,
  ValidationSchemas,
} from "../../../domain/interview/interviewFormUtils";

export default withConnectedFormik(
  InterviewFormNameEnum.INTERVIEW_PROFILE_SKILL_EXAMPLE,
  saveProfileSkillExampleInterviewForm,
  InterviewInitialValues.InterviewProfileSkillExampleForm,
  ValidationSchemas.INTERVIEW_PROFILE_SKILL_EXAMPLE
)(InterviewSkillExamplePage);

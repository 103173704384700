import React from "react";
import { useTranslation } from "react-i18next";

import TestimonyCarousel from "components/common/TestimoniesCarousel/TestimoniesCarousel";

import TestimoniesStyles from "./TestimoniesSection.module.scss";
import Interweave from "interweave";

import {
  Box,
  Container,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";

const TestimoniesSection = () => {
  const theme = useTheme();

  const isScreenUpLg = useMediaQuery(theme.breakpoints.up("lg"));

  const isScreenDownSM = useMediaQuery(theme.breakpoints.down("sm"));

  const { t } = useTranslation("testimony");

  const { containerPX } = theme.variables;

  return (
    <section name="testimoniesSection" className={TestimoniesStyles.section}>
      <Container sx={{ px: containerPX }} maxWidth="xl">
        <Box display="flex" justifyContent="center">
          <Typography variant="h2" mb={isScreenDownSM ? 8 : 10}>
            <Interweave content={t("title")} />
          </Typography>
        </Box>
      </Container>
      <Box
        py={10}
        display="flex"
        alignItems="center"
        flexDirection="column"
        sx={{ width: "100%", bgcolor: theme.palette.blue.complementary }}
      >
        <div className={TestimoniesStyles.reviewsScore}>
          <Interweave content={t("testimonyScore")} />
        </div>
        <Typography
          variant="h3"
          sx={{
            [theme.breakpoints.down("sm")]: {
              textAlign: "center",
              paddingLeft: "20px",
              paddingRight: "20px",
            },
          }}
        >
          <Interweave content={t("testimonyText")} />
        </Typography>
      </Box>
      <Container sx={{ px: containerPX }} maxWidth="xl">
        <Box display="flex" justifyContent="center">
          <TestimonyCarousel
            size={
              isScreenUpLg
                ? "isDesktop"
                : isScreenDownSM
                ? "isMobile"
                : "isTablet"
            }
          />
        </Box>
      </Container>
    </section>
  );
};

export default TestimoniesSection;

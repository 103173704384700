import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import Interweave from "interweave";
import Flip from "react-reveal/Flip";

import InterviewFinishImage from "assets/images/interview-finish-image.png";
import Button from "components/common/Button(ToBeUpdated)";

import InterviewFinishStyles from "./InterviewFinish.module.scss";
import SectionStyles from "../InterviewSteps/InterviewSteps.module.scss";
import InterviewGenericPageStyles from "../../common/InterviewGenericPage/InterviewGenericPage.module.scss";
import MdiIconButton from "../../common/MdiIconButton";
import { mdiArrowLeft } from "@mdi/js";
import { useSelector } from "react-redux";
import {
  selectJobDetailPoleEmploie,
  selectProfileSkillsFromCurrentInterview,
} from "../../../store/interview/interviewSelectors";
import { getPreviousPageDynamicaly } from "utils/helpers/profileSkillsHelpers";

import Path from "utils/translations/fr/path.json";

const InterviewFinish = () => {
  const { t } = useTranslation("interview");
  const history = useHistory();
  const { textTitle, textContent, buttonLabelName, imageAlt } = t(
    "interviewFinish",
    {
      returnObjects: true,
    }
  );
  const { skills } = useSelector(selectJobDetailPoleEmploie);
  const profileSkills = useSelector(selectProfileSkillsFromCurrentInterview);

  const lastSkill = skills?.length > 3 ? 3 : skills?.length;
  const previousPage = getPreviousPageDynamicaly(
    profileSkills,
    lastSkill + 1,
    Path["path.interview.profile.question2"].replace(":skillNumber", lastSkill)
  );

  const goBack = useCallback(
    () => (previousPage ? history.push(previousPage) : history.goBack()),
    [previousPage, history]
  );

  return (
    <>
      <div className={InterviewGenericPageStyles.backArrow}>
        <MdiIconButton
          buttonStyles={{
            backgroundColor: "white",
          }}
          mdiIcon={mdiArrowLeft}
          onClick={goBack}
        />
      </div>
      <div className={InterviewFinishStyles.section}>
        <div className={InterviewFinishStyles.middle}>
          <img src={InterviewFinishImage} alt={imageAlt} />
          <Flip left>
            <Interweave className={SectionStyles.title} content={textTitle} />
          </Flip>
          <Interweave className={SectionStyles.text} content={textContent} />
        </div>
        <Button
          text={buttonLabelName}
          type={"submit"}
          style={{
            padding: "25px",
            width: "90%",
            maxWidth: "480px",
          }}
        />
      </div>
    </>
  );
};

export default InterviewFinish;

export const FormationFactory = (
  intro,
  srcGif,
  title,
  filter,
  link,
  tags,
  srcLogo,
  descriptions,
  descriptionsGif
) => ({
  intro,
  srcGif,
  title,
  filter,
  link,
  tags,
  srcLogo,
  descriptions,
  descriptionsGif,
});

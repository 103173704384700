import endpoints from "utils/constants/endpoints";
import { tiktokEventFactory } from "./tiktokEventModels";
import Errors from "utils/translations/en/errors.json";
import axios from "axios";

export const sendTiktokEventData = async (
  event,
  userId,
  email,
  phone = "",
  eventId = "MaVoieWebEventId"
) => {
  const eventTime = Math.floor(Date.now() / 1000); // Unix timestamp in seconds
  const userAgent = window.navigator.userAgent;
  const pageUrl = window.location.href;
  const tiktokEventData = tiktokEventFactory(
    eventTime,
    event,
    eventId,
    pageUrl,
    userAgent,
    userId,
    phone,
    email
  );

  try {
    await axios({
      method: "post",
      url: endpoints.TIKTOK_TRACKING(),
      data: tiktokEventData,
    });
  } catch (error) {
    console.error(Errors.TIKTOK_SEND_ERROR, error);
  }
};

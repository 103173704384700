import React from "react";
import { useLocation } from "react-router-dom";

/**
 * Custom hook that returns a URLSearchParams object based on the current search query string.
 * @returns {URLSearchParams} The URLSearchParams object representing the current search query string.
 */
function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

export default useQuery;

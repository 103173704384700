import i18n from "i18n";

const homepage = {
  id: "accueil",
  parent: null,
  path: i18n.t("path:path.home"),
  label: i18n.t("header:breadcrumbs.breadcrumbsItemHome"),
};

const questionnaire = {
  id: "questionnaire",
  parent: homepage,
  path: i18n.t("path:path.questionnaire") + "/q1",
  label: i18n.t("header:breadcrumbs.breadcrumbsItemQuestionnaire"),
};

const questionnaireEmail = {
  id: "questionnaire-email",
  parent: homepage,
  path: i18n.t("path.questionnaire.email"),
  label: i18n.t("header:menu.menuItemQuestionnaire"),
};

const solutions = {
  id: "solutions",
  parent: questionnaire,
  path: i18n.t("path:path.solutions"),
  label: i18n.t("header:breadcrumbs.breadcrumbsItemSolutions"),
};

const terms = {
  id: "cgu",
  parent: homepage,
  path: i18n.t("path:path.terms"),
  label: i18n.t("header:breadcrumbs.breadcrumbsItemTerms"),
};

const contact = {
  id: "contacter-mavoie",
  parent: homepage,
  path: i18n.t("path:path.contact"),
  label: i18n.t("header:breadcrumbs.breadcrumbsItemContact"),
};

const mission = {
  id: "mission",
  parent: homepage,
  path: i18n.t("path:path.mission"),
  label: i18n.t("header:breadcrumbs.breadcrumbsItemMission"),
};

const team = {
  id: "equipe",
  parent: homepage,
  path: i18n.t("path:path.team"),
  label: i18n.t("header:breadcrumbs.breadcrumbsItemTeam"),
};

const getAllParents = (node) => {
  if (node.parent === null) return [node];
  return [...getAllParents(node.parent), node];
};

export const dictionaryPathNames = {
  accueil: getAllParents(homepage),
  questionnaire: getAllParents(questionnaire),
  solutions: getAllParents(solutions),
  mission: getAllParents(mission),
  cgu: getAllParents(terms),
  "contacter-mavoie": getAllParents(contact),
  "questionnaire-email": getAllParents(questionnaireEmail),
  equipe: getAllParents(team),
};

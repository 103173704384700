import React, { useCallback, useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { Button, useTheme } from "@mui/material";

import { selectUserAnswers, selectUserUID } from "store/users/usersSelectors";
import {
  getQuestionAnswers,
  formatQuestionAnswersDictionary,
  getVariablesValuesFromQuestionnaire,
  OrderedAnswersIndexesForResumePage,
} from "utils/helpers/questionnaireHelper";
import QuestionnaireEnum from "utils/constants/enums/QuestionnaireEnum";
import Interweave from "interweave";
import MapLabelBranchEnum from "utils/constants/enums/MapLabelBranchEnum";
import MapBranchIdGroupStar from "utils/constants/enums/MapBranchIdGroupStar";
import MapLabelQuestionnaireEnum from "utils/constants/enums/MapLabelQuestionnaireEnum";
import sendFormSuccessDataGA from "config/googleAnalytics/sendFormSuccessDataGA";
import SummaryTextFormStyles from "./SummaryTextForm.module.scss";
import { endForm } from "domain/user/userControllers";

const SummaryTextForm = ({ initialValue, stepData }) => {
  const history = useHistory();

  const {
    typography: { FontFamilies },
  } = useTheme();

  const { t } = useTranslation(["path", "solutions"]);

  const questionWithAnswers = useSelector(selectUserAnswers);

  const userId = useSelector(selectUserUID);

  const action = stepData.actions?.[0];

  useEffect(() => {
    endForm(userId);
  }, [userId]);

  const questionAnswerDict = useMemo(
    () => formatQuestionAnswersDictionary(questionWithAnswers),
    [questionWithAnswers]
  );

  const questionAnswerMap = useMemo(
    () => getQuestionAnswers(questionWithAnswers),
    [questionWithAnswers]
  );

  // Submit the form, Save in Database and go to next page
  const onSubmitCallback = useCallback(
    (e) => {
      // Last Step
      window.scrollTo({ top: 0 });
      e.preventDefault();
      const lastQuestion = questionWithAnswers[questionWithAnswers.length - 1];
      const branchId = lastQuestion?.answers?.[0]?.aid;
      history.push(t("path:path.questionnaire.email") + "/" + branchId);
      const dataLayerData = {
        formBranche: MapLabelBranchEnum[branchId],
        formEtoile: MapBranchIdGroupStar[branchId],
      };
      questionAnswerMap.forEach((elementQuestion) => {
        const labelFormField = MapLabelQuestionnaireEnum[elementQuestion.key];
        if (
          elementQuestion.key ===
          QuestionnaireEnum.PATH_QUESTION_ZIPCODE_LOCALITY
        ) {
          dataLayerData[
            MapLabelQuestionnaireEnum[QuestionnaireEnum.PATH_QUESTION_ZIPCODE]
          ] = elementQuestion.value.zipcode;
          dataLayerData[
            MapLabelQuestionnaireEnum[QuestionnaireEnum.PATH_QUESTION_LOCALITY]
          ] = elementQuestion.value.locality;
        } else if (labelFormField) {
          dataLayerData[labelFormField] = elementQuestion.value;
        }
      });

      //checking user input answer for GA
      // const q1bAnswer = questionWithAnswers.find(
      //   (question) =>
      //     question.qid === questionnaireData.q1b.qid &&
      //     question.answers[0].aid === questionnaireData.q1b.options[4].value.id
      // );

      // if (q1bAnswer) {
      //   dataLayerData.formStatutPro =
      //     questionnaireData.q1b.options[4].value.text;
      // }
      sendFormSuccessDataGA(dataLayerData);
    },
    [history, questionAnswerMap, questionWithAnswers, t]
  );

  return (
    <form onSubmit={onSubmitCallback}>
      <ul
        style={{
          textAlign: "left",
          listStyle: "inside",
          fontFamily: FontFamilies.ProximaNova,
        }}
      >
        {OrderedAnswersIndexesForResumePage.map((answerIndex) => {
          const answer = questionAnswerDict[answerIndex];
          return answer?.resume ? (
            <Interweave
              key={answerIndex}
              style={{ margin: "8px 0" }}
              tagName="li"
              className={SummaryTextFormStyles.bulletPoint}
              content={getVariablesValuesFromQuestionnaire(
                answer.resume,
                questionAnswerMap
              )}
            />
          ) : null;
        })}
      </ul>

      <Button
        sx={{ mt: 10 }}
        type="submit"
        size="large"
        fullWidth
        variant="contained"
        color="orange"
      >
        {action?.value?.text}
      </Button>
    </form>
  );
};

export default React.memo(SummaryTextForm);

import React, { useRef } from "react";
import PropTypes from "prop-types";
import { Field } from "formik";

import SimpleInputFieldStyles from "./SimpleInputField.module.scss";
import { useLettersCount } from "hooks/useLettersCount";
import { TextField } from "@mui/material";

const DEFAULT_MAX_LENGTH_CHARACTERS = 80;

const CustomInputComponent = React.memo(
  ({ value, withCount, ...otherProps }) => {
    const textInput = useRef();
    const displayLetterCount = useLettersCount({
      ...otherProps,
      style: SimpleInputFieldStyles,
    });

    return (
      <>
        <div>
          <TextField
            ref={textInput}
            defaultValue={value}
            fullWidth
            {...otherProps}
          />
        </div>
        {withCount && displayLetterCount}
      </>
    );
  }
);

const SimpleInputField = ({
  name,
  maxLength = DEFAULT_MAX_LENGTH_CHARACTERS,
  placeHolder = "",
  withCount = true,
  ...otherProps
}) => {
  return (
    <Field
      name={name}
      maxLength={maxLength}
      placeholder={placeHolder}
      as={CustomInputComponent}
      withCount={withCount}
      {...otherProps}
    />
  );
};

SimpleInputField.propTypes = {
  // name is a required field
  name: PropTypes.string,
};

export default SimpleInputField;

import React from "react";
import ReactDOMServer from "react-dom/server";
import { v4 as uuidv4 } from "uuid";
import Interweave from "interweave";
import { useTranslation } from "react-i18next";
import mailStyleData from "utils/data/mailStyleData";
import MailContentTemplateEnum from "../constants/enums/MailContentTemplateEnum";

export async function blobToBase64(blob) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result.split(",")[1]);
    reader.onerror = reject;
    reader.readAsDataURL(blob);
  });
}

export function EmailTemplateHTMLPage({ children }) {
  return (
    <html lang="en">
      <head>
        <meta charSet="utf-8" />
        <title>Email Template</title>
      </head>
      <body style={{ maxWidth: "880px" }}>{children}</body>
    </html>
  );
}

export function EmailContentTemplate({
  title,
  name,
  suggestionsTextsForEmailTemplate,
  solutionsTextsForEmailTemplate,
  responsesTextsForEmailTemplate,
}) {
  const { t } = useTranslation("mail");
  return (
    <div
      style={{
        backgroundColor: "white",
        padding: "0px 36px",
        margin: "0 auto",
        fontSize: "14px",
        textAlign: "center",
      }}
    >
      {t("mail:templates", { returnObjects: true }).map((template) => {
        if (template.title === title) {
          const parts = template.content.map((part) => {
            const content = part.content.map((paraph) => {
              if (paraph.indexOf("/>") > 0 || paraph.indexOf("</") > 0) {
                return <Interweave content={paraph} />;
              } else if (part.href) {
                const style = getContentTemplateStyle(
                  title,
                  part.title,
                  mailStyleData
                );
                return (
                  <a href={part.href} style={{ textDecoration: "none" }}>
                    <p style={style["style"]}>{paraph}</p>
                  </a>
                );
              } else if (paraph === "{hr}") {
                return <Interweave content={mailStyleData["params"]["hr"]} />;
              } else if (paraph === "{br}") {
                return <Interweave content={mailStyleData["params"]["br"]} />;
              } else if (paraph.indexOf("{name}") > 0) {
                if (!name) {
                  name = "";
                }
                const style = getContentTemplateStyle(
                  title,
                  part.title,
                  mailStyleData
                );
                const styledParaph = (
                  <p style={style["style"]}>{paraph.replace("{name}", name)}</p>
                );
                return styledParaph;
              } else if (paraph === "{solutions}") {
                if (!solutionsTextsForEmailTemplate) {
                  solutionsTextsForEmailTemplate = [];
                }
                return (
                  <p>
                    {solutionsTextsForEmailTemplate.map((solutionDetail) => {
                      return (
                        <React.Fragment key={uuidv4()}>
                          <a
                            href={solutionDetail.link}
                            style={{ textDecoration: "none" }}
                          >
                            <img
                              loading="lazy"
                              style={{
                                maxWidth: "200px",
                                maxHeight: "60px",
                              }}
                              alt="formation logo"
                              src={solutionDetail.logo}
                            />
                          </a>
                          <br />
                          <span style={{ fontWeight: "bold", color: "black" }}>
                            <a
                              href={solutionDetail.link}
                              style={{ fontWeight: "bold", color: "black" }}
                            >
                              {solutionDetail.title}
                            </a>
                          </span>
                          <br />
                          <span>
                            <b style={{ fontWeight: "bold", color: "#ea6625" }}>
                              C'est quoi ?
                            </b>
                            {solutionDetail.descriptions?.[0]
                              ?.replace("C'est quoi ?", "")
                              .replace("C’est quoi ?", "")}
                          </span>
                          <br />
                          <span>
                            <b style={{ fontWeight: "bold", color: "#ea6625" }}>
                              Et après ?
                            </b>
                            {solutionDetail.descriptions?.[1]?.replace(
                              "Et après ?",
                              ""
                            )}
                          </span>
                          <br />
                          <br />
                        </React.Fragment>
                      );
                    })}
                  </p>
                );
              } else if (paraph === "{suggestions}") {
                if (!suggestionsTextsForEmailTemplate) {
                  suggestionsTextsForEmailTemplate = [];
                }
                return (
                  <table>
                    {suggestionsTextsForEmailTemplate.map(
                      (solutionDetail, index) => {
                        if ((index + 1) % 3 === 0) {
                          return (
                            <React.Fragment key={uuidv4()}>
                              <td
                                valign="top"
                                style={{ padding: "3px 5px", width: "31%" }}
                              >
                                <div style={{ display: "inline" }}>
                                  <>
                                    <a
                                      href={solutionDetail.link}
                                      style={{ textDecoration: "none" }}
                                    >
                                      <img
                                        loading="lazy"
                                        style={{
                                          maxWidth: "150px",
                                          maxHeight: "60px",
                                        }}
                                        alt="formation logo"
                                        src={solutionDetail.logo}
                                      />
                                    </a>
                                    <br />
                                    <span
                                      style={{
                                        fontWeight: "bold",
                                        color: "black",
                                      }}
                                    >
                                      {solutionDetail.title}
                                    </span>
                                    <br />
                                    <span>{` ${solutionDetail.secondaryDescription}`}</span>
                                    <br />
                                    <br />
                                    <p>
                                      <a
                                        href={solutionDetail.link}
                                        style={{ textDecoration: "none" }}
                                      >
                                        <span
                                          style={{
                                            display: "block",
                                            backgroundColor: "#ea6625",
                                            borderRadius: "10px",
                                            padding: "5px 5px",
                                            color: "white",
                                            width: "100px",
                                            fontWeight: "bold",
                                            margin: "0 auto",
                                          }}
                                        >
                                          Découvrir
                                        </span>
                                      </a>
                                    </p>
                                  </>
                                </div>
                              </td>
                              <tr>
                                <td>&nbsp;</td>
                              </tr>
                            </React.Fragment>
                          );
                        } else {
                          return (
                            <td
                              key={uuidv4()}
                              valign="top"
                              style={{ padding: "3px 5px", width: "31%" }}
                            >
                              <div style={{ display: "inline", width: "33%" }}>
                                <>
                                  <a
                                    href={solutionDetail.link}
                                    style={{ textDecoration: "none" }}
                                  >
                                    <img
                                      loading="lazy"
                                      style={{
                                        maxWidth: "150px",
                                        maxHeight: "60px",
                                      }}
                                      alt="formation logo"
                                      src={solutionDetail.logo}
                                    />
                                  </a>
                                  <br />
                                  <span
                                    style={{
                                      fontWeight: "bold",
                                      color: "black",
                                    }}
                                  >
                                    {solutionDetail.title}
                                  </span>
                                  <br />
                                  <span>{` ${solutionDetail.secondaryDescription}`}</span>
                                  <br />
                                  <br />
                                  <div
                                    style={{
                                      position: "relative",
                                      bottom: "2px",
                                    }}
                                  >
                                    <a
                                      href={solutionDetail.link}
                                      style={{ textDecoration: "none" }}
                                    >
                                      <span
                                        style={{
                                          display: "block",
                                          backgroundColor: "#ea6625",
                                          borderRadius: "10px",
                                          padding: "5px 5px",
                                          color: "white",
                                          width: "100px",
                                          fontWeight: "bold",
                                          margin: "0 auto",
                                        }}
                                      >
                                        Découvrir
                                      </span>
                                    </a>
                                  </div>
                                </>
                              </div>
                            </td>
                          );
                        }
                      }
                    )}
                  </table>
                );
              } else if (paraph === "{resumes}") {
                if (!responsesTextsForEmailTemplate) {
                  responsesTextsForEmailTemplate = [];
                }
                const style = getContentTemplateStyle(
                  title,
                  part.title,
                  mailStyleData
                );
                return (
                  <p style={style["style"]}>
                    {responsesTextsForEmailTemplate.map((response) => (
                      <p key={uuidv4()} style={style["style"]}>
                        <Interweave content={`• ${response}`} />
                      </p>
                    ))}
                  </p>
                );
              } else if (paraph === "{suggestions_simple}") {
                if (!solutionsTextsForEmailTemplate) {
                  solutionsTextsForEmailTemplate = [];
                }
                const style = getContentTemplateStyle(
                  title,
                  part.title,
                  mailStyleData
                );
                return (
                  <p>
                    {solutionsTextsForEmailTemplate.map((solutionDetail) => (
                      <React.Fragment key={uuidv4()}>
                        <p style={style["style"]}>
                          <span>
                            👉{" "}
                            <a href={solutionDetail.link}>
                              {solutionDetail.title}
                            </a>
                            :
                          </span>
                          <span>{` ${solutionDetail.descriptions.join(
                            ","
                          )}`}</span>
                        </p>
                      </React.Fragment>
                    ))}
                  </p>
                );
              } else {
                const style = getContentTemplateStyle(
                  title,
                  part.title,
                  mailStyleData
                );
                const styledParaph = <p style={style["style"]}>{paraph}</p>;
                return styledParaph;
              }
            });
            return content;
          });
          return <>{parts}</>;
        } else {
          return "";
        }
      })}
    </div>
  );
}

export function HeaderTemplateEmail() {
  const { t } = useTranslation("mail");
  return (
    <div>
      <img
        loading="lazy"
        style={{
          maxWidth: "880px",
          width: "100%",
        }}
        alt="background header"
        src={t("mail:header:bg-src")}
      />
    </div>
  );
}

export function InterviewSummaryHeader() {
  const { t } = useTranslation("mail");

  return (
    <div name="interview-summary-header">
      <div style={{ marginBottom: "20px" }}>
        <div
          style={{
            display: "inline-block",
            width: "50%",
          }}
        >
          <img
            loading="lazy"
            style={{
              width: "60px",
            }}
            alt="background header"
            src={t("mail:interviewTemplate:logo-src")}
          />
        </div>

        <div
          style={{
            display: "inline-block",
            width: "50%",
            textAlign: "right",
            verticalAlign: "top",
            paddingTop: "10px",
          }}
        >
          <b>#1entretien1job</b>
        </div>
      </div>
      <hr />
    </div>
  );
}
export function InterviewSummaryContent() {
  const { t } = useTranslation("mail");

  return (
    <div name="interview-summary-content">
      {/* main body */}
      <div style={{ textAlign: "center", padding: "50px 0" }}>
        <h1 style={{ fontFamily: "DM Serif Text" }}>
          {t("mail:interviewTemplate:body:title")}
        </h1>
        <div style={{ fontFamily: "Proxima Nova", whiteSpace: "pre-wrap" }}>
          <h3 style={{ color: "#00827F" }}>
            {t("mail:interviewTemplate:body:subtitle")}
          </h3>
          <p>{t("mail:interviewTemplate:body:content")}</p>
        </div>
      </div>

      {/* Go forward / RDV  */}
      <div
        style={{
          backgroundColor: "#FFF2EB",
          borderRadius: "10px",
          padding: "2% 5%",
          margin: "40px 0",
        }}
      >
        <div
          style={{ display: "inline-block", width: "65%", minWidth: "480px" }}
        >
          <h2 style={{ fontFamily: "DM Serif Text" }}>
            {t("mail:interviewTemplate:body:goForwardTitle")}
          </h2>
          <p style={{ fontFamily: "Proxima Nova", whiteSpace: "pre-wrap" }}>
            {t("mail:interviewTemplate:body:goForwardText")}
          </p>
        </div>

        <div
          style={{
            display: "inline-block",
            width: "35%",
            minWidth: "200px",
            textAlign: "right",
            verticalAlign: "middle",
            height: "65px",
          }}
        >
          <a
            href={t("mail:interviewTemplate:body:appointmentLink")}
            style={{ textDecoration: "none" }}
          >
            <span
              style={{
                backgroundColor: "#0D131B",
                borderRadius: "10px",
                padding: "10px 15px",
                color: "white",
                width: "150px",
              }}
            >
              <img
                alt="arrow"
                style={{ height: "10px", width: "10px" }}
                src={t("mail:interviewTemplate:body:arrow-icon-src")}
              ></img>
              &nbsp;{t("mail:interviewTemplate:body:appointmentButtonText")}
            </span>
          </a>
        </div>
      </div>
    </div>
  );
}

export function InterviewSummaryFooter() {
  const { t } = useTranslation("mail");
  return (
    <div name="interview-summary-footer" style={{ textAlign: "center" }}>
      <p>{t("mail:interviewTemplate:footer:followUsText")}</p>
      <div name="follow-us">
        <a
          href={t("mail:interviewTemplate:footer:discord-url")}
          style={{ cursor: "pointer" }}
        >
          <img
            loading="lazy"
            style={{
              width: "25px",
              height: "25px",
              marginRight: "6px",
            }}
            alt="discord"
            src={t("mail:interviewTemplate:footer:discord-icon-src")}
          />
        </a>
        <a href={t("mail:footer:instagram-url")} style={{ cursor: "pointer" }}>
          <img
            loading="lazy"
            style={{
              width: "25px",
              height: "25px",
              marginRight: "6px",
            }}
            alt="instagram"
            src={t("mail:interviewTemplate:footer:instagram-icon-src")}
          />
        </a>
        <a href={t("mail:footer:linkedin-url")} style={{ cursor: "pointer" }}>
          <img
            loading="lazy"
            style={{
              width: "25px",
              height: "25px",
              marginRight: "6px",
            }}
            alt="linkedin"
            src={t("mail:interviewTemplate:footer:linkedin-icon-src")}
          />
        </a>
        <a
          href={t("mail:interviewTemplate:footer:tiktok-url")}
          style={{ cursor: "pointer" }}
        >
          <img
            loading="lazy"
            style={{
              width: "25px",
              height: "25px",
            }}
            alt="tiktok"
            src={t("mail:interviewTemplate:footer:tiktok-icon-src")}
          />
        </a>
      </div>
      <hr />
      <div name="mavoie-contact" style={{ marginTop: "20px" }}>
        <div
          style={{
            display: "inline-block",
            width: "10%",
            textAlign: "left",
          }}
        >
          <img
            loading="lazy"
            style={{
              width: "40px",
            }}
            alt="background header"
            src={t("mail:interviewTemplate:logo-src")}
          />
        </div>

        <div
          style={{
            display: "inline-block",
            width: "70%",
          }}
        >
          <p>
            {t("mail:interviewTemplate:footer:mv-address")}&nbsp;-&nbsp;
            <a
              href={t("mail:interviewTemplate:footer:mv-website")}
              style={{ color: "black" }}
            >
              {t("mail:interviewTemplate:footer:mv-website")}
            </a>
          </p>
        </div>
        <div
          style={{
            display: "inline-block",
            width: "20%",
            minWidth: "120px",
            textAlign: "right",
          }}
        >
          <a
            href={`mailto:${t("mail:interviewTemplate:footer:mv-email")}`}
            style={{ color: "black" }}
          >
            {t("mail:interviewTemplate:footer:mv-contact")}
          </a>
        </div>
      </div>
    </div>
  );
}

export function FooterTemplateEmail() {
  const { t } = useTranslation("mail");
  return (
    <div style={{ height: "400px" }}>
      <div
        style={{
          maxWidth: "880px",
          width: "100%",
          height: "400px",
          backgroundSize: "100%",
          backgroundRepeat: "no-repeat",
          backgroundImage: "url(" + t("mail:footer:bg-src") + ")",
          zIndex: "1",
        }}
      >
        <div
          style={{
            width: "100%",
          }}
        >
          <div
            style={{
              display: "inline-block",
              height: "100px",
              width: "70%",
              marginLeft: "15%",
              marginTop: "21%",
            }}
          >
            {/* first row */}
            <div
              style={{
                display: "inline-block",
                width: "52%",
                textAlign: "center",
                borderRightColor: "white",
                borderRightStyle: "solid",
                marginRight: "2%",
              }}
            >
              {/* First Col in row 1 */}
              <div
                style={{
                  display: "inline-block",
                  color: "white",
                  fontSize: "16px",
                }}
              >
                {t("mail:footer:message")}
              </div>
              <div style={{ cursor: "pointer", width: "100%" }}>
                <a href={t("mail:footer:url-rdv")}>
                  <button
                    style={{
                      width: "75%",
                      fontSize: "20px",
                      padding: "8px",
                      backgroundColor: "#00827F",
                      color: "white",
                      border: "none",
                      cursor: "pointer",
                      borderRadius: "25px",
                      marginTop: "20px",
                    }}
                    type="button"
                  >
                    {t("mail:footer:btn-text")}
                  </button>
                </a>
              </div>
            </div>
            <div
              style={{
                display: "inline-block",
                width: "45%",
                color: "white",
                fontSize: "18px",
              }}
            >
              {/* Second Col in row 1 */}
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  color: "white",
                  marginBottom: "8px",
                  cursor: "pointer",
                }}
              >
                <img
                  loading="lazy"
                  style={{
                    width: "25px",
                    height: "25px",
                    marginRight: "6px",
                  }}
                  alt="contact"
                  src={t("mail:footer:mail-icon-src")}
                />
                <span style={{}}>{t("mail:footer:mail-contact")}</span>
              </div>
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  color: "white",
                  marginBottom: "8px",
                  cursor: "pointer",
                }}
              >
                <img
                  loading="lazy"
                  style={{
                    width: "25px",
                    height: "25px",
                    marginBottom: "-6px",
                    marginRight: "6px",
                  }}
                  alt="official-website"
                  src={t("mail:footer:url-icon-src")}
                />
                <span style={{}}>{t("mail:footer:url")}</span>
              </div>
            </div>
          </div>
          <div
            style={{
              display: "inline-block",
              width: "100%",
              height: "28px",
            }}
          />
        </div>
        <div
          style={{
            display: "inline-block",
            width: "100%",
            height: "40px",
            textAlign: "center",
          }}
        >
          {/* Second row */}
          <img
            loading="lazy"
            style={{ cursor: "pointer", marginRight: "6px" }}
            alt="youtube"
            src={t("mail:footer:youtube-icon-src")}
          />
          <a href={t("mail:footer:instagram-url")}>
            <img
              loading="lazy"
              style={{ cursor: "pointer", marginRight: "6px" }}
              alt="instagram"
              src={t("mail:footer:instagram-icon-src")}
            />
          </a>
          <a href={t("mail:footer:linkedin-url")}>
            <img
              loading="lazy"
              style={{ cursor: "pointer", marginRight: "6px" }}
              alt="linkedIn"
              src={t("mail:footer:linkedin-icon-src")}
            />
          </a>
          <a href={t("mail:footer:facebook-url")}>
            <img
              loading="lazy"
              style={{ cursor: "pointer", marginRight: "6px" }}
              alt="facebook"
              src={t("mail:footer:facebook-icon-src")}
            />
          </a>
          <a href={t("mail:footer:twitter-url")}>
            <img
              loading="lazy"
              style={{ cursor: "pointer", marginRight: "6px" }}
              alt="twitter"
              src={t("mail:footer:twitter-icon-src")}
            />
          </a>
        </div>
        <div
          style={{
            display: "inline-block",
            width: "100%",
            height: "40px",
          }}
        />
      </div>
    </div>
  );
}

export function SolutionContentEmail({
  name,
  solutionsTextsForEmailTemplate,
  suggestionsTextsForEmailTemplate,
}) {
  return (
    <EmailContentTemplate
      title={MailContentTemplateEnum.solution}
      name={name}
      suggestionsTextsForEmailTemplate={suggestionsTextsForEmailTemplate}
      solutionsTextsForEmailTemplate={solutionsTextsForEmailTemplate}
    />
  );
}

export function SolutionAndResponsesContentEmail({
  responsesTextsForEmailTemplate,
  solutionsTextsForEmailTemplate,
}) {
  return (
    <EmailContentTemplate
      title={MailContentTemplateEnum.solutionAndResponses}
      solutionsTextsForEmailTemplate={solutionsTextsForEmailTemplate}
      responsesTextsForEmailTemplate={responsesTextsForEmailTemplate}
    />
  );
}

export function AutomaticResponseContactContentEmail({ name }) {
  return (
    <EmailContentTemplate
      title={MailContentTemplateEnum.automaticResponseContact}
      name={name}
    />
  );
}

export function FeedbackMailViaPageContact({ name, email, reason, question }) {
  return (
    <>
      <ul>
        <li>Prénom : {name}</li>
        <li>Email : {email}</li>
        <li>Raison : {reason}</li>
        <li>Message : {question}</li>
      </ul>
    </>
  );
}

export const generateHtmlWAutomaticResponseContactDoc = (name) => {
  const html = ReactDOMServer.renderToStaticMarkup(
    <EmailTemplateHTMLPage>
      <AutomaticResponseContactContentEmail name={name} />
    </EmailTemplateHTMLPage>
  );
  return "<!DOCTYPE html>" + html;
};

export const generateHtmlWSolutionEmailDoc = (
  name,
  solutionsTextsForEmailTemplate,
  suggestionsTextsForEmailTemplate
) => {
  const html = ReactDOMServer.renderToStaticMarkup(
    <EmailTemplateHTMLPage>
      <HeaderTemplateEmail />
      <SolutionContentEmail
        name={name}
        solutionsTextsForEmailTemplate={solutionsTextsForEmailTemplate}
        suggestionsTextsForEmailTemplate={suggestionsTextsForEmailTemplate}
      />
      <FooterTemplateEmail />
    </EmailTemplateHTMLPage>
  );
  return "<!DOCTYPE html>" + html;
};

export const generateHtmlWSolutionAndResponsesEmailDoc = (
  solutionsTextsForEmailTemplate,
  responsesTextsForEmailTemplate
) => {
  const html = ReactDOMServer.renderToStaticMarkup(
    <EmailTemplateHTMLPage>
      <SolutionAndResponsesContentEmail
        responsesTextsForEmailTemplate={responsesTextsForEmailTemplate}
        solutionsTextsForEmailTemplate={solutionsTextsForEmailTemplate}
      />
    </EmailTemplateHTMLPage>
  );
  return "<!DOCTYPE html>" + html;
};

export const generateHtmlWFeedbackMailDoc = (name, email, reason, question) => {
  const html = ReactDOMServer.renderToStaticMarkup(
    <EmailTemplateHTMLPage>
      <FeedbackMailViaPageContact
        name={name}
        email={email}
        reason={reason}
        question={question}
      />
    </EmailTemplateHTMLPage>
  );
  return "<!DOCTYPE html>" + html;
};

export const generateHtmlWInterviewSummaryMailDoc = () => {
  const html = ReactDOMServer.renderToStaticMarkup(
    <EmailTemplateHTMLPage>
      <InterviewSummaryHeader />
      <InterviewSummaryContent />
      <InterviewSummaryFooter />
    </EmailTemplateHTMLPage>
  );
  return "<!DOCTYPE html>" + html;
};

export const getSubjectMailWithVariables = (subjectEnum, params) => {
  let subjectMail = subjectEnum;
  for (let i = 0; i < params.length; i++) {
    subjectMail = subjectMail.replace("<<" + i + ">>", params[i]);
  }

  return subjectMail;
};

const getContentTemplateStyle = (
  contentTemplateTitle,
  partTitle,
  stylesTemplateList
) => {
  const stylesTemplate = stylesTemplateList[contentTemplateTitle];
  const stylesList = stylesTemplate ? stylesTemplate["content"] : [];
  const styles = stylesList.filter((style) => style.title === partTitle);
  return styles?.length ? styles[0] : "";
};

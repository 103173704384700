import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { BrowserRouter, Switch } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { loginUserAnonymAction } from "domain/user/userControllers";

import HomePage from "pages/HomePage";
import QuestionnairePage from "pages/QuestionnairePage";
import TermsPage from "pages/TermsPage";
import TeamPage from "pages/TeamPage";
import MissionPage from "pages/MissionPage";
import ContactPage from "pages/ContactPage";
import SolutionsPage from "pages/SolutionsPage";
import CalendlyPage from "pages/CalendlyPage";
import InterviewPage from "pages/InterviewPage";

import Redirect from "components/common/Redirect";
import Route from "components/common/Route";
import Layout from "components/Layout";
import { invokeAppKeys } from "config/initConfig";

import QuestionnaireEmailPage from "components/Questionnaire/QuestionnaireEmailPage";
import QuestionnaireLoaderPage from "components/Questionnaire/QuestionnaireLoaderPage";

const Router = () => {
  const { t } = useTranslation("path");
  const dispatch = useDispatch();

  useEffect(() => {
    (async () => {
      await invokeAppKeys();
      dispatch(loginUserAnonymAction());
    })();
  }, [dispatch]);

  return (
    <BrowserRouter>
      <Layout>
        <Switch>
          <Route exact path={t("path.home")}>
            <HomePage />
          </Route>
          <Route exact path={t("path.questionnaire") + "/:qid"}>
            <QuestionnairePage />
          </Route>
          <Route exact path={t("path.questionnaire.email") + "/:aid"}>
            <QuestionnaireEmailPage />
          </Route>
          <Route exact path={t("path.questionnaire.loader")}>
            <QuestionnaireLoaderPage />
          </Route>
          <Route exact path={t("path.solutions") + "/:aid"}>
            <SolutionsPage />
          </Route>
          <Route exact path={t("path.mission")}>
            <MissionPage />
          </Route>
          <Route path={t("path.team")}>
            <TeamPage />
          </Route>
          <Route exact path={t("path.terms")}>
            <TermsPage />
          </Route>
          <Route exact path={t("path.contact")}>
            <ContactPage />
          </Route>
          <Route exact path={t("path.calendly")}>
            <CalendlyPage />
          </Route>
          <Route path={t("path.interview")}>
            <InterviewPage />
          </Route>
          <Redirect to={t("path.home")} />
        </Switch>
      </Layout>
    </BrowserRouter>
  );
};

export default Router;

import { GoogleAnalyticsEvents } from "./../analyticsConfig";

const clickSuggestionSolutionDataGA = ({ solutionNom, mailLaisse }) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: GoogleAnalyticsEvents.SolutionSecondaireJyVais,
    solutionNom,
    mailLaisse,
  });
};

export default clickSuggestionSolutionDataGA;

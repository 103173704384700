import env from "../../.env.json";

// ? Only when working on serve mode
// ? replace env.url_api_fb with "http://localhost:5001/mavoie-development/europe-west1"

/**
 * Adds a query parameter to a base URL.
 *
 * @param {string} baseUrl - The base URL.
 * @param {string} name - The name of the query parameter.
 * @param {string} value - The value of the query parameter.
 * @returns {string} The updated URL with the added query parameter.
 */
const addParam = (baseUrl, name, value) => {
  if (value !== undefined) {
    return baseUrl + `&${name}=${value}`;
  }
  return baseUrl;
};

/**
 * Object containing various endpoints for API calls.
 * @typedef {Object} Endpoints
 * @property {function(string): string} CONF_FIREBASE - Returns the Firebase configuration endpoint with the specified source.
 * @property {function(): string} CONF_ALGOLIA - Returns the Algolia configuration endpoint.
 * @property {function(string): string} GET_CALENDLY_INFO - Returns the Calendly information endpoint with the specified URL.
 * @property {function(): string} SUBSCRIBE_NEWSLETTER - Returns the endpoint for subscribing to the newsletter.
 * @property {function(string, string): string} CHECK_USER_BY_PARAM - Returns the endpoint for checking a user by the specified key-value pair.
 * @property {function(string): string} UPDATE_USER - Returns the endpoint for updating a user with the specified user ID.
 * @property {function(string): string} SEND_MAIL_USER - Returns the endpoint for sending a mail to the user with the specified user ID.
 * @property {function(string, string, string, string, string, string, string, string, string): string} SUBSCRIBE_SENDINBLUE - Returns the endpoint for subscribing to Sendinblue with the specified parameters.
 * @property {function(string): string} GET_JOB_DETAIL_FROM_POLE_EMPLOIE - Returns the endpoint for getting job details from Pole Emploie with the specified code Rome.
 * @property {function(string): string} SAVE_EMAIL_INTERVIEW - Returns the endpoint for saving email interview with the specified interview ID.
 * @property {function(string): string} SAVE_START_INTERVIEW_INFO - Returns the endpoint for saving start interview information with the specified interview ID.
 * @property {function(string, string): string} CREATE_NEW_INTERVIEW - Returns the endpoint for creating a new interview with the specified user ID and interview ID.
 * @property {function(string): string} SAVE_INTERVIEW_PROGRESS - Returns the endpoint for saving interview progress with the specified interview ID.
 * @property {function(string): string} SAVE_INTERVIEW_COMPANY_ACTIVITIES_DATA - Returns the endpoint for saving interview company activities data with the specified interview ID.
 * @property {function(string): string} SAVE_INTERVIEW_COMPANY_VALUES_DATA - Returns the endpoint for saving interview company values data with the specified interview ID.
 * @property {function(string): string} SAVE_INTERVIEW_COMPANY_SIMULATION_DATA - Returns the endpoint for saving interview company simulation data with the specified interview ID.
 * @property {function(string): string} SAVE_INTERVIEW_JOB_SOFTSKILLS_DATA - Returns the endpoint for saving interview job soft skills data with the specified interview ID.
 * @property {function(string): string} SAVE_INTERVIEW_JOB_CHALLENGES_DATA - Returns the endpoint for saving interview job challenges data with the specified interview ID.
 * @property {function(string): string} SAVE_INTERVIEW_JOB_BESTOF_DATA - Returns the endpoint for saving interview job best-of data with the specified interview ID.
 * @property {function(string): string} SAVE_INTERVIEW_PROFILE_SKILLRATE_DATA - Returns the endpoint for saving interview profile skill rate data with the specified interview ID.
 * @property {function(string): string} SAVE_INTERVIEW_PROFILE_SKILL_EXAMPLE_DATA - Returns the endpoint for saving interview profile skill example data with the specified interview ID.
 * @property {function(string): string} SAVE_INTERVIEW_JOB_DETAIL - Returns the endpoint for saving interview job detail with the specified interview ID.
 * @property {function(string): string} GET_TREE_DATA_BY_TOKEN_ID - Returns the endpoint for getting tree data by the specified token ID.
 * @property {function(): string} GET_TREE_DATA_PROD - Returns the endpoint for getting tree data in production.
 * @property {function(): string} TIKTOK_TRACKING - Returns the endpoint for tracking TikTok.
 */

const endpoints = {
  CONF_FIREBASE: (source) =>
    env.url_api_fb + `/confs/firebase?source=${source}`,
  CONF_ALGOLIA: () => env.url_api_fb + "/confs/algolia",
  GET_CALENDLY_INFO: (url) =>
    `${env.url_api_fb}/triggers/get-calendly-info?uri=${url}`,
  SUBSCRIBE_NEWSLETTER: () =>
    env.url_api_fb + "/triggers/subscribe-to-newsletter",
  // ** Services :
  CHECK_USER_BY_PARAM: (key, value) =>
    `${env.url_api_fb}/services/check-user-by-param?key=${key}&value=${value}`,
  UPDATE_USER: (userId) =>
    `${env.url_api_fb}/services/update-user?userId=${userId}`,
  SEND_MAIL_USER: (userId) =>
    `${env.url_api_fb}/services/send-mail?userId=${userId}`,
  SUBSCRIBE_SENDINBLUE: (
    source,
    email,
    sendInBlueStarAttribute,
    prenom,
    // paramentre "rdv_decouverte" mis en commentaire pour teser si on peut l'enlever : le but est de lui donner une valeur directement en beckend
    /* rdv_decouverte, */
    sendInBlueInterviewAttribute,
    interviewDate,
    companyName,
    type_de_coaching,
    age,
    status_pro
  ) => {
    let baseUrl = `${env.url_api_fb}/triggers/subscribe-to-sending-blue?`;
    baseUrl = addParam(baseUrl, "source", source);
    baseUrl = addParam(baseUrl, "email", email);
    baseUrl = addParam(
      baseUrl,
      "sendInBlueStarAttribute",
      sendInBlueStarAttribute
    );
    baseUrl = addParam(baseUrl, "prenom", prenom);
    baseUrl = addParam(baseUrl, "age", age);
    baseUrl = addParam(baseUrl, "status_pro", status_pro);
    // paramentre "rdv_decouverte" mis en commentaire pour teser si on peut l'enlever : le but est de lui donner une valeur directement en beckend
    // baseUrl = addParam(baseUrl, "rdv_decouverte", rdv_decouverte);
    baseUrl = addParam(
      baseUrl,
      "sendInBlueInterviewAttribute",
      sendInBlueInterviewAttribute
    );
    baseUrl = addParam(baseUrl, "interviewDate", interviewDate);
    baseUrl = addParam(baseUrl, "companyName", companyName);
    baseUrl = addParam(baseUrl, "type_de_coaching", type_de_coaching);
    return baseUrl;
  },
  GET_JOB_DETAIL_FROM_POLE_EMPLOIE: (codeRome) =>
    `${env.url_api_fb}/triggers/get-job-detail-pole-emploie?codeRome=${codeRome}`,
  // ** Interview :
  SAVE_EMAIL_INTERVIEW: (interviewId) =>
    `${env.url_api_fb}/interview/save-email-interview?interviewId=${interviewId}`,
  SAVE_START_INTERVIEW_INFO: (interviewId) =>
    `${env.url_api_fb}/interview/save-interview-form-data?interviewId=${interviewId}`,
  CREATE_NEW_INTERVIEW: (userId, interviewId) =>
    `${env.url_api_fb}/interview/create-new-interview?interviewId=${interviewId}&userId=${userId}`,
  SAVE_INTERVIEW_PROGRESS: (interviewId) =>
    `${env.url_api_fb}/interview/save-interview-progress?interviewId=${interviewId}`,
  SAVE_INTERVIEW_COMPANY_ACTIVITIES_DATA: (interviewId) =>
    `${env.url_api_fb}/interview/save-interview-company-activites-data?interviewId=${interviewId}`,
  SAVE_INTERVIEW_COMPANY_VALUES_DATA: (interviewId) =>
    `${env.url_api_fb}/interview/save-interview-company-values-data?interviewId=${interviewId}`,
  SAVE_INTERVIEW_COMPANY_SIMULATION_DATA: (interviewId) =>
    `${env.url_api_fb}/interview/save-interview-company-simulation-data?interviewId=${interviewId}`,
  SAVE_INTERVIEW_JOB_SOFTSKILLS_DATA: (interviewId) =>
    `${env.url_api_fb}/interview/save-interview-job-softskills-data?interviewId=${interviewId}`,
  SAVE_INTERVIEW_JOB_CHALLENGES_DATA: (interviewId) =>
    `${env.url_api_fb}/interview/save-interview-job-challenges-data?interviewId=${interviewId}`,
  SAVE_INTERVIEW_JOB_BESTOF_DATA: (interviewId) =>
    `${env.url_api_fb}/interview/save-interview-job-bestof-data?interviewId=${interviewId}`,
  SAVE_INTERVIEW_PROFILE_SKILLRATE_DATA: (interviewId) =>
    `${env.url_api_fb}/interview/save-interview-profile-skillrate-data?interviewId=${interviewId}`,
  SAVE_INTERVIEW_PROFILE_SKILL_EXAMPLE_DATA: (interviewId) =>
    `${env.url_api_fb}/interview/save-interview-profile-skill-example-data?interviewId=${interviewId}`,
  SAVE_INTERVIEW_JOB_DETAIL: (interviewId) =>
    `${env.url_api_fb}/interview/save-interview-job-detail?interviewId=${interviewId}`,
  // ** Server NestJs :
  GET_TREE_DATA_BY_TOKEN_ID: (tokenId) => {
    if (tokenId && tokenId !== "undefined" && tokenId !== "") {
      return `${env.url_api_fb}/api/trees/data/${tokenId}`;
    }
    return `${env.url_api_fb}/api/trees/prod-data`;
  },
  GET_TREE_DATA_PROD: () => `${env.url_api_fb}/api/trees/prod-data`,
  // ** Tiktok :
  TIKTOK_TRACKING: () => `${env.url_api_fb}/api/tiktok/event/track`,
};

export default endpoints;

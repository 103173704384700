import React, { useEffect } from "react";
import { Route as RCCRoute } from "react-router-dom";
import { useSelector } from "react-redux";
import { getDocumentTitleNameFromPathname } from "utils/constants/documentTitleNames";
import {
  getCanonicalLinkFromPathname,
  getDocumentDescriptionsFromPathname,
  getDocumentTitleDescriptionsFromPathname,
} from "utils/constants/documentDescriptions";
import { selectUserUID } from "store/users/usersSelectors";
import { TiktokEventEnum } from "utils/constants/enums/TiktokEventEnum";

import { sendTiktokEventData } from "domain/tiktokEvent/tiktokEventController";

const Route = (props) => {
  const userUID = useSelector(selectUserUID);

  useEffect(() => {
    sendTiktokEventData(TiktokEventEnum.viewContent, userUID);
  }, [props.location.pathname, userUID]);

  useEffect(() => {
    const documentTitle = getDocumentTitleNameFromPathname(
      props.location.pathname
    );
    document.title = documentTitle;
    const documentDescription = getDocumentDescriptionsFromPathname(
      props.location.pathname
    );

    const canonicalLink = getCanonicalLinkFromPathname(props.location.pathname);

    if (documentDescription) {
      document.getElementsByTagName("meta")["description"].content =
        documentDescription;
    }
    const documentTitleDescription = getDocumentTitleDescriptionsFromPathname(
      props.location.pathname
    );
    if (documentTitleDescription) {
      document.getElementsByTagName("meta")["title"].content =
        documentTitleDescription;
    }

    if (canonicalLink) {
      document.getElementsByTagName("link")["canonical"].href = canonicalLink;
    }
  }, [props.location.pathname]);
  return <RCCRoute {...props}>{props.children}</RCCRoute>;
};

export default Route;

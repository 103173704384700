import React from "react";
import { useTranslation } from "react-i18next";
import Interweave from "interweave";

import TermsPageStyles from "./TermsPage.module.scss";

import { Box, Container, Typography, useTheme } from "@mui/material";

import { v4 as uuidv4 } from "uuid";

const TermsPage = () => {
  const { t } = useTranslation("mentions");
  const theme = useTheme();

  return (
    <Container maxWidth="xl" className={TermsPageStyles.ContentContainer}>
      <Box mb={10}>
        <Interweave content={t("mentions:title")} />
      </Box>
      <Box>
        <Typography
          variant="paragraph"
          sx={{
            fontSize: "18px",
            [theme.breakpoints.down("sm")]: {
              fontSize: "14px",
            },
          }}
        >
          {t("mentions:content", { returnObjects: true }).map((chapiter) => {
            const chapiterTitle = <Interweave content={chapiter.title} />;
            const chapiterContent = chapiter.content.map((paraph) => {
              return <Interweave key={uuidv4()} content={paraph} />;
            });
            return (
              <React.Fragment key={uuidv4()}>
                {chapiterTitle} {chapiterContent}
              </React.Fragment>
            );
          })}
        </Typography>
      </Box>
    </Container>
  );
};

export default TermsPage;

import React, { useCallback, useMemo } from "react";
import { useParams, useHistory } from "react-router-dom";

import InterviewSteps from "components/Interviews/InterviewSteps";
import InterviewHome from "components/Interviews/InterviewHome/InterviewHome";
import MdiIconButton from "components/common/MdiIconButton";
import InterviewGenericPageEnum from "utils/constants/enums/InterviewGenericPageEnum";
import InterviewPageEnum from "utils/constants/enums/InterviewPageEnum";

import { mdiArrowLeft } from "@mdi/js";
import InterviewGenericPageStyles from "./InterviewGenericPage.module.scss";
import { useTranslation } from "react-i18next";
import { getInterviewInfoPage } from "../../../utils/helpers/pathnameHelper";

const InterviewGenericPage = ({ page, children }) => {
  const { t } = useTranslation("path");
  const { step } = useParams();
  const history = useHistory();
  const { previousPage } = useMemo(() => getInterviewInfoPage(page), [page]);

  if (
    (step && !Object.values(InterviewGenericPageEnum).includes(step)) ||
    !Object.values(InterviewPageEnum).includes(page)
  ) {
    history.push(t("path.interview"));
  }

  const goBack = useCallback(
    () => (previousPage ? history.push(previousPage) : history.goBack()),
    [previousPage, history]
  );

  return (
    <>
      {page !== InterviewPageEnum.HOME && (
        <div className={InterviewGenericPageStyles.backArrow}>
          <MdiIconButton
            buttonStyles={{
              backgroundColor: "white",
            }}
            mdiIcon={mdiArrowLeft}
            onClick={goBack}
          />
        </div>
      )}
      <div className={InterviewGenericPageStyles.section}>
        {step === InterviewGenericPageEnum.ETAPE && (
          <InterviewSteps page={page} />
        )}
        {page === InterviewPageEnum.HOME && <InterviewHome />}
        {children}
      </div>
    </>
  );
};

export default InterviewGenericPage;

import { GoogleAnalyticsEvents } from "../analyticsConfig";

const clicRDVDataGA = (valeurRDV) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: GoogleAnalyticsEvents.CliqueRDV,
    valeurRDV,
  });
};

export default clicRDVDataGA;

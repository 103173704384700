import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { Button, Typography } from "@mui/material";

import { saveAnswer, saveLastProgress } from "store/users/usersSlice";
import { selectTempAnswer, selectUserUID } from "store/users/usersSelectors";
import { updateUserQuestionnaireAction } from "domain/user/userControllers";
import AutoCompleteLocationMUI from "components/common/AutoCompleteLocationMUI";
import Icon from "components/common/Icon";

const QuestionAutcompleteLocationForm = ({ stepData, initialAnswer }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { t } = useTranslation(["path", "solutions"]);

  const userId = useSelector(selectUserUID);

  const action = stepData.actions?.[0];

  const tempAnswers = useSelector(selectTempAnswer);

  useEffect(
    () => {
      dispatch(
        saveAnswer({
          qid: stepData.qid,
          question: stepData.question,
          type: stepData.type,
          answers: tempAnswers,
          resume: tempAnswers?.[0]?.value?.locality
            ? "Ta ville est : <b>" + tempAnswers[0].value.locality + "</b>"
            : tempAnswers?.[0]?.value?.zipcode
            ? "Ton code postal : <b>" + tempAnswers[0].value.zipcode + "</b>"
            : "",
        })
      );
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      dispatch,
      stepData.qid,
      stepData.question,
      stepData.type,
      // eslint-disable-next-line react-hooks/exhaustive-deps
      tempAnswers?.[0]?.value,
    ]
  );

  // Submit the form, Save in Database and go to next page
  const onSubmitCallback = useCallback(
    (e) => {
      window.scrollTo({ top: 0 });
      e.preventDefault();
      dispatch(saveLastProgress(stepData.progress));
      if (userId) {
        dispatch(updateUserQuestionnaireAction());
      }
      history.push(t("path:path.questionnaire") + "/" + action.path);
    },
    [stepData.progress, dispatch, action.path, userId, history, t]
  );

  return (
    <form onSubmit={onSubmitCallback}>
      <AutoCompleteLocationMUI
        qid={stepData.qid}
        question={stepData.question}
        type={stepData.type}
        anonymous={stepData.anonymous}
        actions={stepData.actions}
        noOptionsText={stepData.noOptionsText}
        placeHolder={stepData.placeHolder}
        resume={stepData.resume}
      />
      <Typography
        mt={2}
        my={2}
        textAlign={"left"}
        fontStyle={"italic"}
        fontSize={14}
      >
        {stepData.saveZipcodeText}
      </Typography>

      <Button
        sx={{ mt: 10 }}
        type="submit"
        size="large"
        fullWidth
        variant="contained"
        color="orange"
        disabled={!tempAnswers?.[0]?.value}
        endIcon={<Icon iconName="arrowRightShort" iconSize={16} />}
      >
        {action?.value?.text}
      </Button>
    </form>
  );
};

export default React.memo(QuestionAutcompleteLocationForm);

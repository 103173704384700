export const InterviewAdviceNumberEnum = {
  "1.1c": 1,
  "1.2c": 2,
  "1.3c": 3,
  "2.1c": 4,
  "2.2c": 5,
  "2.3c": 6,
  "3.1qc.1": 7,
  "3.1qc.2": 7,
  "3.1qc.3": 7,
};

import DocumentTitleEnum from "./enums/DocumentTitleEnum";

/**
 * Retrieves the document title name based on the provided pathname.
 * @param {string} pathname - The current pathname.
 * @returns {string} - The document title name.
 */
export const getDocumentTitleNameFromPathname = (pathname) => {
  if (pathname?.includes("/accueil")) {
    return DocumentTitleEnum.HOMEPAGE;
  } else if (pathname?.includes("/questionnaire-email")) {
    return DocumentTitleEnum.FORM_EMAIL;
  } else if (pathname?.includes("/questionnaire/resume")) {
    return DocumentTitleEnum.RESUME;
  } else if (pathname?.includes("/q1a")) {
    return DocumentTitleEnum.FORM.Q1A;
  } else if (pathname?.includes("/q1b")) {
    return DocumentTitleEnum.FORM.Q1B;
  } else if (pathname?.includes("/q1c")) {
    return DocumentTitleEnum.FORM.Q1C;
  } else if (pathname?.includes("/questionnaire")) {
    return `${DocumentTitleEnum.FORM.TEXT}${pathname.replace(
      "/questionnaire/",
      "-"
    )}`;
  } else if (pathname?.includes("/solutions")) {
    return DocumentTitleEnum.SOLUTIONS;
  } else if (pathname?.includes("/rdv-decouverte")) {
    return DocumentTitleEnum.DISCOVERY;
  } else if (pathname?.includes("/equipe")) {
    return DocumentTitleEnum.TEAM;
  } else if (pathname?.includes("/cgu")) {
    return DocumentTitleEnum.CGU;
  } else if (pathname?.includes("/mission")) {
    return DocumentTitleEnum.MISSION;
  } else if (pathname?.includes("/contacter-mavoie")) {
    return DocumentTitleEnum.CONTACT;
  } else if (pathname?.includes("/1entretien1job/fiche-entretien")) {
    return DocumentTitleEnum.INTERVIEW.START;
  } else if (pathname?.includes("/1entretien1job/resume-entrainement")) {
    return DocumentTitleEnum.INTERVIEW.F2_SUMMARY;
  } else if (pathname?.includes("/1entretien1job/entreprise/e1-activites")) {
    return DocumentTitleEnum.INTERVIEW.E1_ACTIVITY;
  } else if (pathname?.includes("/1entretien1job/entreprise/ec1-conseil")) {
    return DocumentTitleEnum.INTERVIEW.EC1_ADVICE;
  } else if (pathname?.includes("/1entretien1job/entreprise/e2-valeurs")) {
    return DocumentTitleEnum.INTERVIEW.E2_VALUES;
  } else if (pathname?.includes("/1entretien1job/entreprise/ec2-conseil")) {
    return DocumentTitleEnum.INTERVIEW.EC2_ADVICE;
  } else if (pathname?.includes("/1entretien1job/entreprise/e3-simulation")) {
    return DocumentTitleEnum.INTERVIEW.E3_SIMULATION;
  } else if (pathname?.includes("/1entretien1job/entreprise/ec3-conseil")) {
    return DocumentTitleEnum.INTERVIEW.EC3_ADVICE;
  } else if (pathname?.includes("/1entretien1job/poste/p1-fiche-metier")) {
    return DocumentTitleEnum.INTERVIEW.P1_JOB;
  } else if (pathname?.includes("/1entretien1job/poste/pc1-conseil")) {
    return DocumentTitleEnum.INTERVIEW.PC1_ADVICE;
  } else if (pathname?.includes("/1entretien1job/poste/pc2-conseil")) {
    return DocumentTitleEnum.INTERVIEW.PC2_ADVICE;
  } else if (pathname?.includes("/1entretien1job/poste/pc3-conseil")) {
    return DocumentTitleEnum.INTERVIEW.PC3_ADVICE;
  } else if (
    pathname?.includes("/1entretien1job/poste/p2-competences-transversales")
  ) {
    return DocumentTitleEnum.INTERVIEW.P2_SOFTSKILLS;
  } else if (pathname?.includes("/1entretien1job/poste/p3-meilleur-du-job")) {
    return DocumentTitleEnum.INTERVIEW.P3_BESTOF;
  } else if (pathname?.includes("/1entretien1job/poste/p4-defis-du-job")) {
    return DocumentTitleEnum.INTERVIEW.P4_CHALLENGES;
  } else if (pathname?.includes("/1entretien1job/entreprise")) {
    return DocumentTitleEnum.INTERVIEW.COMPANY;
  } else if (pathname?.includes("/1entretien1job/poste")) {
    return DocumentTitleEnum.INTERVIEW.JOB;
  } else if (pathname?.includes("/1entretien1job/profil")) {
    return DocumentTitleEnum.INTERVIEW.PROFIL;
  } else if (pathname?.includes("/1entretien1job/fin-entrainement")) {
    return DocumentTitleEnum.INTERVIEW.F1_FINISH;
  } else if (pathname?.includes("/1entretien1job/profil/note-competence")) {
    return DocumentTitleEnum.INTERVIEW.C_SKILLRATE;
  } else if (pathname?.includes("/1entretien1job/profil/exemple-competence")) {
    return DocumentTitleEnum.INTERVIEW.CE_SKILL_EXAMPLE;
  } else if (pathname?.includes("/1entretien1job")) {
    return DocumentTitleEnum.INTERVIEW.HOME;
  }
  return pathname;
};

import React from "react";
import { useTranslation } from "react-i18next";
import Interweave from "interweave";

import { useTheme } from "@mui/system";
import { Container, Grid, Typography, Box, useMediaQuery } from "@mui/material";

import StarsSectionStyles from "./StarsSection.module.scss";
import starsData from "utils/data/starsData";
import GroupStarEnum from "utils/constants/enums/GroupStarEnum";

const StarsSection = () => {
  const { t } = useTranslation("stars");
  const {
    variables: {
      homepage: { small: variablesSM, large: variablesLarge },
      containerPX,
    },
    breakpoints,
    typography: {
      homePage: { starsSectionSubtitle },
    },
  } = useTheme();

  const isScreenDownSM = useMediaQuery(breakpoints.down("sm"));
  const isScreenDownXL = useMediaQuery(breakpoints.down("xl"));

  return (
    <section name="starsSection">
      <Container
        sx={{
          px: containerPX,
          my: isScreenDownSM
            ? variablesSM.spaceBetweenSectionsHP
            : variablesLarge.spaceBetweenSectionsHP,
        }}
        maxWidth="xl"
      >
        <Box px={isScreenDownXL && !isScreenDownSM ? 12 : 0}>
          <div className={StarsSectionStyles.title}>
            <Typography variant="h2" sx={{ lineHeight: "140%" }}>
              <Interweave content={t("starTitle")} />
            </Typography>
          </div>
          <Grid container spacing={10}>
            <Grid item xs={12} sm={6} md={6} lg={3}>
              <div className={StarsSectionStyles.star}>
                <div className={StarsSectionStyles.starIcon}>
                  {starsData[GroupStarEnum.KNOW].svg}
                </div>
                <Box className={StarsSectionStyles.starText}>
                  <div className={StarsSectionStyles.subtitle}>
                    <Typography
                      sx={{
                        ...starsSectionSubtitle,
                      }}
                    >
                      <Interweave content={t("knowStarTitle")} />
                    </Typography>
                  </div>
                  <div className={StarsSectionStyles.text}>
                    <Typography variant="paragraph">
                      <Interweave content={t("knowStarContent")} />
                    </Typography>
                  </div>
                </Box>
              </div>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={3}>
              <div className={StarsSectionStyles.star}>
                <div className={StarsSectionStyles.starIcon}>
                  {starsData[GroupStarEnum.LEARN].svg}
                </div>
                <Box
                  sx={{
                    [breakpoints.down("lg")]: {
                      border: "none",
                    },
                  }}
                  className={StarsSectionStyles.starText}
                >
                  <div className={StarsSectionStyles.subtitle}>
                    <Typography
                      sx={{
                        ...starsSectionSubtitle,
                      }}
                    >
                      <Interweave content={t("learnStarTitle")} />
                    </Typography>
                  </div>
                  <div className={StarsSectionStyles.text}>
                    <Typography variant="paragraph">
                      <Interweave content={t("learnStarContent")} />
                    </Typography>
                  </div>
                </Box>
              </div>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={3}>
              <div className={StarsSectionStyles.star}>
                <div className={StarsSectionStyles.starIcon}>
                  {starsData[GroupStarEnum.APPLY].svg}
                </div>
                <Box className={StarsSectionStyles.starText}>
                  <div className={StarsSectionStyles.subtitle}>
                    <Typography
                      sx={{
                        ...starsSectionSubtitle,
                      }}
                    >
                      <Interweave content={t("applyStarTitle")} />
                    </Typography>
                  </div>
                  <div className={StarsSectionStyles.text}>
                    <Typography variant="paragraph">
                      <Interweave content={t("applyStarContent")} />
                    </Typography>
                  </div>
                </Box>
              </div>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={3}>
              <div className={StarsSectionStyles.star}>
                <div className={StarsSectionStyles.starIcon}>
                  {starsData[GroupStarEnum.CONVINCE].svg}
                </div>
                <Box
                  className={StarsSectionStyles.starText}
                  sx={{ border: "none", paddingRight: "0", width: "91%" }}
                >
                  <div className={StarsSectionStyles.subtitle}>
                    <Typography
                      sx={{
                        ...starsSectionSubtitle,
                      }}
                    >
                      <Interweave content={t("convinceStarTitle")} />
                    </Typography>
                  </div>
                  <div className={StarsSectionStyles.text}>
                    <Typography variant="paragraph">
                      <Interweave content={t("convinceStarContent")} />
                    </Typography>
                  </div>
                </Box>
              </div>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </section>
  );
};

export default StarsSection;

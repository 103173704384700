import { useCallback } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Interweave from "interweave";
import {
  Box,
  Button,
  Divider,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";

import navigateToDiagnosticDataGA from "config/googleAnalytics/navigateToDiagnosticDataGA";

import Icon from "components/common/Icon";
import logoGoogleOrgImage from "assets/images/logoGoogleOrg.svg";

import NewsletterForm from "./NewsletterForm";

import navigateToCalendlyDataGA from "config/googleAnalytics/navigateToCalendlyDataGA";
import ValueRDVhomepageEnum from "utils/constants/enums/ValueRDVhomepageEnum";

import FooterSectionStyles from "./FooterSection.module.scss";

const scrollToTop = (section) => () => {
  if (section === "calendly") {
    navigateToCalendlyDataGA(ValueRDVhomepageEnum.FROM_FOOTER);
  }
  window.scrollTo({ top: 0, behavior: "smooth" });
};

const FooterSection = () => {
  const theme = useTheme();
  const isScreenDownSM = useMediaQuery(theme.breakpoints.down("sm"));
  const { t } = useTranslation("homePage", "path");

  const navLinks = useCallback(
    (links) => (
      <div>
        {links.map((section) => (
          <Button
            key={section}
            variant="text"
            color="black"
            onClick={scrollToTop(section)}
            className={FooterSectionStyles.navLinkButton}
            href={t(`path:path.${section}`)}
            sx={{ marginBottom: isScreenDownSM ? "5px" : "0" }}
          >
            {t(`homePage:footersection.${section}`)}
          </Button>
        ))}
      </div>
    ),
    [t, isScreenDownSM]
  );

  const externalLink = useCallback(
    (link) => (
      <Button
        key={link}
        variant="text"
        color="black"
        target="_blank"
        href={t(`footersection.${link}-url`)}
        className={FooterSectionStyles.navLinkButton}
      >
        {t(`homePage:footersection.${link}`)}
      </Button>
    ),
    [t]
  );

  const socialMediaLinks = useCallback(
    (medias) => (
      <p>
        {medias.map((media) => (
          <Button
            key={media}
            variant="text"
            color="black"
            target="_blank"
            href={t(`footersection.${media}-url`)}
            className={FooterSectionStyles.socialMediaLink}
          >
            <img
              width="22px"
              height="22px"
              alt={t(`footersection.${media}-icon-alt`)}
              src={t(`footersection.${media}-icon-src`)}
            ></img>
          </Button>
        ))}
      </p>
    ),
    [t]
  );

  return (
    <Box
      p={isScreenDownSM ? 5 : 12}
      sx={{
        bgcolor: theme.palette.orange.orange100,
      }}
    >
      <Grid container spacing={0} className={FooterSectionStyles.gridContainer}>
        <Grid
          item
          xs={12}
          sm={6}
          lg={2}
          sx={{ marginBottom: isScreenDownSM ? "28px" : "0" }}
        >
          <Link to="/">
            <img
              width={isScreenDownSM ? "58px" : "104px"}
              height={isScreenDownSM ? "36px" : "64px"}
              src={t("footersection.logo-src")}
              alt={t("footersection.logo-alt")}
            />
          </Link>
        </Grid>
        <Grid item xs={12} sm={6} lg={4}>
          <Typography
            variant="paragraph"
            className={FooterSectionStyles.navLinksTitle}
          >
            <Interweave content={t("footersection.about")} />
          </Typography>
          {navLinks(["home", "mission", "team", "interview"])}
          <Button
            className={FooterSectionStyles.diagnosticButton}
            variant="contained"
            href={t("path:path.questionnaire") + "/q1"}
            startIcon={<Icon iconSize={16} iconName="timer" />}
            color="black"
            size="large"
            sx={{ marginBottom: isScreenDownSM ? "28px" : "0" }}
            onClick={() => {
              navigateToDiagnosticDataGA("Footer");
            }}
          >
            <Box mt={1}>{t("homePage:roleSection.button")}</Box>
          </Button>
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          lg={3}
          sx={{ marginBottom: isScreenDownSM ? "28px" : "0" }}
        >
          <Typography
            variant="paragraph"
            className={FooterSectionStyles.navLinksTitle}
          >
            <Interweave content={t("footersection.meetUs")} />
          </Typography>
          {navLinks([
            /* "press", "partnership", "support",  */ "calendly",
            "contact",
          ])}
          {externalLink("join-us")}
        </Grid>
        <Grid item sm={6} lg={3}>
          <Typography
            variant="paragraph"
            className={FooterSectionStyles.sectionTitle}
          >
            <Interweave content={t("footersection.subscribeNewsletter")} />
          </Typography>
          <NewsletterForm />
          <Typography
            variant="paragraph"
            className={FooterSectionStyles.sectionTitle}
          >
            <Interweave content={t("footersection.followUs")} />
          </Typography>
          {socialMediaLinks(["discord", "linkedin", "instagram", "tiktok"])}
        </Grid>
        <Divider className={FooterSectionStyles.divider} />
        <Grid
          item
          xs={12}
          md={6}
          lg={4}
          xl={8}
          sx={{ marginBottom: isScreenDownSM ? "28px" : "0" }}
        >
          <Typography variant="paragraph">
            <Interweave
              content={t("footersection.googleText")}
              className={FooterSectionStyles.supportedBy}
            />
          </Typography>
          <a
            href={t("footersection.googleLink")}
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              loading="lazy"
              src={logoGoogleOrgImage}
              alt={t("footersection.logoGoogleAlt")}
              className={FooterSectionStyles.googleLogo}
            />
          </a>
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          lg={8}
          xl={4}
          className={FooterSectionStyles.terms}
        >
          <Button variant="text" color="black" href={t("path:path.terms")}>
            {t("footersection.terms")}
          </Button>

          <Button variant="text" color="black" href={t("path:path.terms")}>
            {t("footersection.privacyPolicy")}
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default FooterSection;

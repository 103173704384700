import React, { useState, useEffect, useCallback } from "react";
import styles from "./ButtonScrollTopPage.module.scss";
import ArrowCircleUp from "assets/images/ArrowCircleUp.svg";

const scrollTop = () => {
  window.scrollTo({ top: 300, behavior: "smooth" });
};

const ButtonScrollTopPage = () => {
  const [showScroll, setShowScroll] = useState(false);

  const checkScrollTop = useCallback(() => {
    if (!showScroll && window.pageYOffset > 400) {
      setShowScroll(true);
    } else if (showScroll && window.pageYOffset <= 400) {
      setShowScroll(false);
    }
  }, [showScroll, setShowScroll]);

  useEffect(() => {
    window.addEventListener("scroll", checkScrollTop);
    return function cleanup() {
      window.removeEventListener("scroll", checkScrollTop);
    };
  }, [checkScrollTop]);

  return (
    <div>
      {showScroll && (
        <img
          loading="lazy"
          alt="arrow circle up"
          src={ArrowCircleUp}
          className={styles.scrollTop}
          onClick={scrollTop}
        />
      )}
    </div>
  );
};

export default ButtonScrollTopPage;

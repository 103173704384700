import React, { useCallback, useMemo } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Interweave from "interweave";

import {
  Box,
  Button,
  IconButton,
  List,
  useMediaQuery,
  useTheme,
} from "@mui/material";

import navigateToDiagnosticDataGA from "config/googleAnalytics/navigateToDiagnosticDataGA";

import Icon from "components/common/Icon/Icon";
import LogoMV from "assets/images/logos/logo-mavoieV3.png";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";

import NavBarMenuStyles from "./NavBarMenu.module.scss";

const NavBarMenu = ({ menuToggled, toggleMenu }) => {
  const theme = useTheme();
  const { t } = useTranslation("header");
  const isScreenDownLG = useMediaQuery(theme.breakpoints.down("lg"));
  const isScreenDownSM = useMediaQuery(theme.breakpoints.down("sm"));

  const {
    navbar: {
      menuToggledStyles,
      zIndex,
      bgColor,
      backdropFilter,
      py,
      small: variablesSM,
      large: variablesLarge,
    },
  } = theme.variables;

  const closeMenu = useCallback(() => {
    toggleMenu(false);
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [toggleMenu]);

  const { menuItem_1, menuItem_2, menuItem_3 } = t("menu", {
    returnObjects: true,
  });

  const navMenu = useMemo(
    () => (
      <nav>
        <List
          sx={{
            ...(menuToggled
              ? menuToggledStyles
              : {
                  display: "flex",
                  flexDirection: isScreenDownLG ? "column" : "row",
                  gap: isScreenDownLG ? variablesLarge.gap : variablesSM.gap,
                }),
          }}
        >
          <Link onClick={closeMenu} to="/mission">
            <Button
              islink="true"
              color="black"
              size="large"
              sx={{
                fontWeight: 400,
                ...(menuToggled ? { paddingLeft: 0 } : {}),
              }}
            >
              <Interweave content={menuItem_1} />
            </Button>
          </Link>
          <Link onClick={closeMenu} to="/equipe">
            <Button
              islink="true"
              color="black"
              size="large"
              sx={{
                fontWeight: 400,
                ...(menuToggled ? { paddingLeft: 0 } : {}),
              }}
            >
              <Interweave content={menuItem_2} />
            </Button>
          </Link>
          <Link onClick={closeMenu} to="/contacter-mavoie">
            <Button
              islink="true"
              color="black"
              size="large"
              sx={{
                fontWeight: 400,
                ...(menuToggled ? { paddingLeft: 0 } : {}),
              }}
            >
              <Interweave content={menuItem_3} />
            </Button>
          </Link>
          <Link
            onClick={closeMenu}
            style={{ marginTop: menuToggled ? "auto" : 0 }}
            to={t("path:path.questionnaire") + "/q1"}
          >
            <Button
              variant="contained"
              color="black"
              size="large"
              startIcon={<Icon iconName="timer" />}
              style={{ width: menuToggled ? "100%" : "auto" }}
              onClick={() => {
                navigateToDiagnosticDataGA("BarreDeNavigation");
              }}
            >
              &nbsp;{t("homePage:roleSection.button")}
            </Button>
          </Link>
        </List>
      </nav>
    ),
    [
      isScreenDownLG,
      menuItem_1,
      menuItem_2,
      menuItem_3,
      t,
      closeMenu,
      variablesLarge,
      variablesSM,
      menuToggled,
      menuToggledStyles,
    ]
  );

  return (
    <>
      {menuToggled && navMenu}
      <Box
        name="navbar"
        py={py}
        px={isScreenDownLG ? variablesLarge.px : variablesSM.px}
        borderRadius={
          isScreenDownLG
            ? variablesLarge.borderRadius
            : variablesSM.borderRadius
        }
        top={isScreenDownLG ? variablesLarge.top : variablesSM.top}
        maxWidth="1180px"
        mx="auto"
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        position="sticky"
        bgcolor={bgColor}
        zIndex={zIndex}
        overflow={menuToggled ? "hidden" : "inherit"}
        sx={{
          backdropFilter: menuToggled ? "none" : backdropFilter,
        }}
      >
        <Link to="/" onClick={closeMenu}>
          <img
            src={LogoMV}
            width={
              isScreenDownSM ? variablesSM.imgWidth : variablesLarge.imgWidth
            }
            height={"auto"}
            alt="logo-MaVoie"
          />
        </Link>
        {isScreenDownLG ? (
          <>
            <input
              type="checkbox"
              id="navi-toggle"
              value={menuToggled}
              className={NavBarMenuStyles.checkbox}
            />
            <IconButton
              style={{ background: "transparent" }}
              onClick={() => {
                toggleMenu(!menuToggled);
              }}
            >
              {menuToggled ? (
                <CloseIcon fontSize="large" />
              ) : (
                <MenuIcon fontSize="large" />
              )}
            </IconButton>
          </>
        ) : (
          <>{navMenu}</>
        )}
      </Box>
    </>
  );
};

export default NavBarMenu;

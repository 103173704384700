import { GoogleAnalyticsEvents } from "../analyticsConfig";

const actionConfirmRDVDataGA = () => {
  window.dataLayer = window.dataLayer || [];
  // Here checking the source of click RDV in a reversed array of dataLayer to ensure the most recent click event
  const valeurRDV = (window.dataLayer.reverse() || []).find(
    (d) => d?.event === GoogleAnalyticsEvents.CliqueRDV
  )?.valeurRDV;
  window.dataLayer.push({
    event: GoogleAnalyticsEvents.PrendreRDV,
    valeurRDV,
  });
};

export default actionConfirmRDVDataGA;

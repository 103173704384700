import DocumentDescriptionEnum from "./enums/DocumentDescriptionEnum";
import DocumentTitleDescriptionEnum from "./enums/DocumentTitleDescriptionEnum";

/**
 * Retrieves the document description based on the provided pathname.
 * @param {string} pathname - The current pathname.
 * @returns {string|null} - The document description or null if not found.
 */
export const getDocumentDescriptionsFromPathname = (pathname) => {
  if (pathname?.includes("/accueil")) {
    return DocumentDescriptionEnum.HOMEPAGE;
  } else if (pathname?.includes("/equipe")) {
    return DocumentDescriptionEnum.TEAM;
  } else if (pathname?.includes("/mission")) {
    return DocumentDescriptionEnum.MISSION;
  } else if (pathname?.includes("/1entretien1job")) {
    return DocumentDescriptionEnum.INTERVIEW;
  }

  return null;
};

/**
 * Returns the canonical link based on the provided pathname.
 * @param {string} pathname - The current pathname.
 * @returns {string} - The canonical link corresponding to the pathname.
 */
export const getCanonicalLinkFromPathname = (pathname) => {
  if (pathname?.includes("/accueil")) {
    return "https://mavoie.org/accueil";
  } else if (pathname?.includes("/equipe")) {
    return "https://mavoie.org/equipe";
  } else if (pathname?.includes("/mission")) {
    return "https://mavoie.org/mission";
  } else if (pathname?.includes("/1entretien1job")) {
    return "https://mavoie.org/1entretien1job";
  } else if (pathname?.includes("/cgu")) {
    return "https://mavoie.org/cgu";
  } else if (pathname?.includes("/rdv-decouverte")) {
    return "https://mavoie.org/rdv-decouverte";
  } else if (pathname?.includes("/contacter-mavoie")) {
    return "https://mavoie.org/contacter-mavoie";
  }

  return "https://mavoie.org/accueil";
};

/**
 * Retrieves the document title description based on the provided pathname.
 * @param {string} pathname - The current pathname.
 * @returns {string|null} - The document title description or null if not found.
 */
export const getDocumentTitleDescriptionsFromPathname = (pathname) => {
  if (pathname?.includes("/accueil")) {
    return DocumentTitleDescriptionEnum.HOMEPAGE;
  } else if (pathname?.includes("/equipe")) {
    return DocumentTitleDescriptionEnum.TEAM;
  } else if (pathname?.includes("/mission")) {
    return DocumentTitleDescriptionEnum.MISSION;
  } else if (pathname?.includes("/1entretien1job")) {
    return DocumentTitleDescriptionEnum.INTERVIEW;
  }

  return null;
};

import { useSelector } from "react-redux";

import {
  selectCompanyActivitiesFromCurrentInterview,
  selectCompanyNameFromCurrentInterview,
  selectCompanySimulationFromCurrentInterview,
  selectCompanyValuesFromCurrentInterview,
  selectEmailFromCurrentInterview,
  selectInterviewCompanyAdviceC1,
  selectInterviewCompanyAdviceC2,
  selectInterviewCompanyAdviceC3,
  selectInterviewDateFromCurrentInterview,
  selectInterviewJobAdviceC1,
  selectInterviewJobAdviceC2,
  selectInterviewJobAdviceC3,
  selectJobBestOfFromCurrentInterview,
  selectJobChallengesFromCurrentInterview,
  selectJobDescriptionsFromCurrentInterview,
  selectJobDomainFromCurrentInterview,
  selectJobNameFromCurrentInterview,
  selectJobSoftSkillsFromCurrentInterview,
  selectProfileSkillsFromCurrentInterview,
} from "store/interview/interviewSelectors";

const useSummaryInterviewData = () => {
  const interviewEmail = useSelector(selectEmailFromCurrentInterview);

  const jobContent = useSelector(selectJobNameFromCurrentInterview);
  const interviewDateContent = useSelector(
    selectInterviewDateFromCurrentInterview
  );
  const companyContent = useSelector(selectCompanyNameFromCurrentInterview);

  const activitiesContent = useSelector(
    selectCompanyActivitiesFromCurrentInterview
  );
  const valuesContent = useSelector(selectCompanyValuesFromCurrentInterview);
  const simulation = useSelector(selectCompanySimulationFromCurrentInterview);
  const companyAdviceC1 = useSelector(selectInterviewCompanyAdviceC1);
  const companyAdviceC2 = useSelector(selectInterviewCompanyAdviceC2);
  const companyAdviceC3 = useSelector(selectInterviewCompanyAdviceC3);

  const jobDomainContent = useSelector(selectJobDomainFromCurrentInterview);
  const jobDescriptionsContent = useSelector(
    selectJobDescriptionsFromCurrentInterview
  );
  const jobSoftSkillsContent = useSelector(
    selectJobSoftSkillsFromCurrentInterview
  );
  const jobBestOfContent = useSelector(selectJobBestOfFromCurrentInterview);
  const jobChallengesContent = useSelector(
    selectJobChallengesFromCurrentInterview
  );
  const jobAdviceC1 = useSelector(selectInterviewJobAdviceC1);
  const jobAdviceC2 = useSelector(selectInterviewJobAdviceC2);
  const jobAdviceC3 = useSelector(selectInterviewJobAdviceC3);

  const profileSkills = useSelector(selectProfileSkillsFromCurrentInterview);

  return {
    jobContent,
    companyContent,
    interviewDateContent,
    activitiesContent,
    companyAdviceC1,
    valuesContent,
    companyAdviceC2,
    simulation,
    companyAdviceC3,
    jobDomainContent,
    jobDescriptionsContent,
    jobSoftSkillsContent,
    jobBestOfContent,
    jobChallengesContent,
    jobAdviceC1,
    jobAdviceC2,
    jobAdviceC3,
    profileSkills,
    interviewEmail,
  };
};

export default useSummaryInterviewData;

import { GoogleAnalyticsEvents } from "./../analyticsConfig";

const subscribeDataGA = (source, userEmail, phoneNumber) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: GoogleAnalyticsEvents.Inscription,
    sourceInscription: source,
    userEmail,
    phoneNumber,
  });
};

export default subscribeDataGA;

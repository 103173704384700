import React from "react";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";
import Interweave from "interweave";
import {
  Container,
  Box,
  Grid,
  Typography,
  useMediaQuery,
  Button,
} from "@mui/material";
import { useTheme } from "@mui/system";
import { getPillImageUrlFromFirebaseDevStorage } from "utils/helpers/assetsHelpers";

import Icon from "components/common/Icon/Icon";

const PillCardWithCTA = ({
  children,
  bgcolor,
  pillLarge,
  pillSmall,
  pillAlt,
  title,
  handleOnClickCalendly,
  buttonText,
  buttonLink,
  leftGridStyle,
  containerStyle,
  iconName = "arrowRightShort",
}) => {
  const { t } = useTranslation(["homePage", "path"]);

  const theme = useTheme();
  const isScreenDownSM = useMediaQuery(theme.breakpoints.down("sm"));
  const isScreenUpLG = useMediaQuery(theme.breakpoints.up("lg"));
  const {
    homepage: { small: variablesSM, large: variablesLarge },
    containerPX,
  } = theme.variables;

  const { pathname } = useLocation();
  const isSolutionsPage = pathname.includes(t("path:path.solutions"));

  return (
    <>
      <Container
        sx={{
          px: containerPX,
          my: isScreenDownSM
            ? variablesSM.spaceBetweenSectionsHP
            : variablesLarge.spaceBetweenSectionsHP,
        }}
        style={containerStyle || {}}
        maxWidth="xl"
      >
        <Box
          px={isScreenDownSM ? 5 : 12}
          py={12}
          borderRadius="10px"
          display="flex"
          sx={{
            bgcolor,
            [theme.breakpoints.down("lg")]: {
              flexDirection: "column",
            },
          }}
        >
          <Grid
            container
            spacing={2}
            sx={{
              [theme.breakpoints.down("lg")]: {
                flexDirection: "column-reverse",
                justifyContent: "space-around",
                bottom: variablesLarge.imagePillHeightLargeInPixel / 2 + "px",
                position: "relative",
                marginBottom:
                  -variablesLarge.imagePillHeightLargeInPixel / 2 + "px",
              },
            }}
          >
            <Grid
              sx={{
                ...(leftGridStyle || {}),
              }}
              item
              lg={8}
            >
              <Typography
                variant="h2"
                width={
                  isScreenUpLG ? (isSolutionsPage ? "65%" : "100%") : "100%"
                }
              >
                <Interweave content={t(title)} />
              </Typography>
              {children}
              <Box>
                <Link to={t(buttonLink && buttonLink)}>
                  <Button
                    variant="outlined"
                    startIcon={<Icon iconName={iconName} iconSize={16} />}
                    color="black"
                    size={isScreenDownSM ? "small" : "large"}
                    onClick={handleOnClickCalendly}
                    sx={{
                      bgcolor,
                      textAlign: "left",
                    }}
                  >
                    {t(buttonText)}
                  </Button>
                </Link>
              </Box>
            </Grid>
            {isScreenUpLG ? (
              <Grid item sx={{ textAlign: "right" }} lg={4}>
                <img
                  src={getPillImageUrlFromFirebaseDevStorage(pillLarge)}
                  loading="lazy"
                  alt={t(pillAlt)}
                />
              </Grid>
            ) : (
              <Grid
                sx={{
                  textAlign: "center",
                  height: variablesLarge.imagePillHeightLargeInPixel + "px",
                }}
                item
                lg={4}
              >
                <img
                  src={getPillImageUrlFromFirebaseDevStorage(pillSmall)}
                  loading="lazy"
                  alt={t(pillAlt)}
                />
              </Grid>
            )}
          </Grid>
        </Box>
      </Container>
    </>
  );
};

export default PillCardWithCTA;

import React from "react";
import clsx from "clsx";
import inputStyles from "./SimpleInput.module.scss";

const defautProps = {
  shadow: "0px 4px 12px rgba(34, 40, 47, 0.1)",
  fontFamily: "ProximaSoft",
  fontWeight: 600,
  width: 60,
};

const SimpleInput = (props) => {
  const styleCustom = {
    boxShadow: props.hasShadow ? defautProps.shadow : "unset",
    fontFamily: props.fontFamily ? props.fontFamily : defautProps.fontFamily,
    fontStyle: props.isBold ? "bold" : "normal",
    fontWeight: props.fontWeight ? props.fontWeight : defautProps.fontWeight,
    height: props.height ? props.height : defautProps.height,
    width: props.width ? props.width + "%" : defautProps.width + "%",
  };

  if (props.isTextArea) {
    return (
      <div className={clsx(inputStyles.container)}>
        <textarea
          type="text"
          placeholder={props.placeholder}
          className={clsx(inputStyles.customInput)}
          style={styleCustom}
          rows="6"
          cols="50"
          onChange={props.onChange}
        />
      </div>
    );
  } else {
    return (
      <div className={clsx(inputStyles.container)}>
        <input
          type="text"
          placeholder={props.placeholder}
          className={clsx(inputStyles.customInput)}
          style={styleCustom}
          onChange={props.onChange}
        />
      </div>
    );
  }
};

export default SimpleInput;

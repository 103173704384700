import { useMediaQuery } from "react-responsive";

export function useMediaQueries() {
  const isBigScreen = useMediaQuery({ minWidth: 1980 });
  const isDesktop = useMediaQuery({ minWidth: 1024 });
  const isTablet = useMediaQuery({ maxWidth: 1023, minWidth: 498 }); // Median : 760px
  const isMobile = useMediaQuery({ maxWidth: 497 });
  const isSmallScreen = useMediaQuery({ maxWidth: 358 });

  const isCalendlyDesktopMode = useMediaQuery({ minWidth: 649 });

  return {
    isBigScreen,
    isDesktop,
    isTablet,
    isMobile,
    isSmallScreen,
    isCalendlyDesktopMode,
  };
}

import React from "react";
import comboStyles from "./SimpleComboBox.module.scss";
import { v4 as uuidv4 } from "uuid";

const defaultProps = {
  shadow: "0px 4px 12px rgba(34, 40, 47, 0.1)",
  fontFamily: "ProximaSoft",
  fontWeight: 400,
  height: "auto",
  width: 100,
};

const SimpleComboBox = (props) => {
  const styleCustom = {
    boxShadow: props.hasShadow ? defaultProps.shadow : "unset",
    fontFamily: props.fontFamily ? props.fontFamily : defaultProps.fontFamily,
    fontStyle: props.isBold ? "bold" : "normal",
    fontWeight: props.fontWeight ? props.fontWeight : defaultProps.fontWeight,
    height: props.height ? props.height : defaultProps.height,
    width: props.width ? props.width + "%" : defaultProps.width + "%",
  };

  return (
    <div className={comboStyles.container}>
      <div className={comboStyles.customSelectbox}>
        <select
          name="reasonContact"
          id="reasonSelect"
          className={comboStyles.customInput}
          style={styleCustom}
          onChange={props.onChange}
          defaultValue={props.defaultValue}
        >
          {props.data.map((optionValue) => {
            return (
              <option value={optionValue} key={optionValue || uuidv4()}>
                {optionValue}
              </option>
            );
          })}
        </select>
      </div>
    </div>
  );
};

export default SimpleComboBox;

// LastProgressNumberEnum
export const LastProgressNumberEnum = {
  InterviewStart: {
    0: "0", // Home
    1: "0.1", // Fiche entretien
    2: "0.2", //  Email
  },
  InterviewSteps: {
    1: "1", // entreprise
    2: "2", // job
    3: "3", // profil
  },
  InterviewCompany: {
    Q1: "1.1q", // E1
    Q2: "1.2q", // E2
    Q3: "1.3q", // E3
    C1: "1.1c", // EC1
    C2: "1.2c", // EC2
    C3: "1.3c", // EC3
  },
  InterviewJob: {
    Q1: "2.1q", // P1
    Q2: "2.2q", // P2
    Q3: "2.3q", // P3
    Q4: "2.4q", // P4

    C1: "2.1c", // PC1
    C2: "2.2c", // PC2
    C3: "2.3c", // PC3
  },
  InterviewProfile: {
    Q1: {
      // C
      1: "3.1q.1",
      2: "3.1q.2",
      3: "3.1q.3",
    },
    Q2: {
      1: "3.2q.1",
      2: "3.2q.2",
      3: "3.2q.3",
    }, // CE
    C1: {
      1: "3.1qc.1",
      2: "3.1qc.2",
      3: "3.1qc.3",
    },
  },
  InterviewEnd: {
    1: "4.1", // InterviewFinish
    2: "4.2", // InterviewSummary
  },
};

import React, {
  useEffect,
  useRef,
  createContext,
  useContext,
  Component,
} from "react";

const ArticleWidthSolution = 368;
// const ArticleOffSetHeightSolution = 200;
// const SolutionImgRadiusSize = 20; // same value in SCSS $img-radius-size

export const SolutionCardStylesContext = createContext({
  textContainerHeight: 0,
  titleContainerHeight: 0,
});

export class SolutionsCardStylesProvider extends Component {
  state = {
    setMaxTextContainerHeight: (textContainerHeight) => {
      this.setState((state) => ({
        textContainerHeight: Math.max(
          textContainerHeight,
          state.textContainerHeight
        ),
      }));
    },
    setMaxTitleContainerHeight: (titleContainerHeight) => {
      this.setState((state) => ({
        titleContainerHeight: Math.max(
          titleContainerHeight,
          state.titleContainerHeight
        ),
      }));
    },
    textContainerHeight: 0,
    titleContainerHeight: 0,
  };

  render() {
    return (
      <SolutionCardStylesContext.Provider value={this.state}>
        {this.props.children}
      </SolutionCardStylesContext.Provider>
    );
  }
}

export const useChildrenSolutionCardStyles = () => {
  const textContainerRef = useRef();
  const titleContainerRef = useRef();

  const {
    textContainerHeight,
    titleContainerHeight,
    setMaxTextContainerHeight,
    setMaxTitleContainerHeight,
  } = useContext(SolutionCardStylesContext);

  useEffect(() => {
    setMaxTextContainerHeight(textContainerRef.current?.offsetHeight || 0);
    setMaxTitleContainerHeight(titleContainerRef.current?.offsetHeight || 0);
  }, [setMaxTextContainerHeight, setMaxTitleContainerHeight]);

  return {
    textContainerRef,
    titleContainerRef,
    articleStyle: {
      borderRadius: "20px",
      boxShadow: " 5px 5px 10px 0px rgba(0, 0, 0, 0.20)",
      width: ArticleWidthSolution,
      // fix the width the time the text container append its content
      minWidth: textContainerHeight ? "auto" : ArticleWidthSolution + "px",
      // simulate main container height, content is in absolute position
      // paddingBottom:
      //   textContainerHeight +
      //   ArticleOffSetHeightSolution -
      //   SolutionImgRadiusSize +
      //   "px",
      // Commenting paddingBottom for testing
      marginBottom: "34px",
    },
    textContainerStyle: {
      height: textContainerHeight ? textContainerHeight + "px" : "auto",
    },
    // 34px is removed for suggestions
    titleContainerStyle: {
      height: titleContainerHeight ? titleContainerHeight : "auto",
    },
  };
};

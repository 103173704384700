import React from "react";
import Interweave from "interweave";
import { v4 as uuidv4 } from "uuid";

import { Box, Grid, Typography, useMediaQuery, useTheme } from "@mui/material";

import {
  getSolutionTeamPicturesUrlFromFirebaseDevStorage,
  getSolutionDirectorsBoardPicturesUrlFromFirebaseDevStorage,
} from "utils/helpers/assetsHelpers";

import linkedInIcon from "assets/images/teamPage_linkedIn-icon.png";

const TeamDirectorsBoard = ({ squadData, directorsData }) => {
  const {
    breakpoints,
    variables: {
      teamDirectorCard: {
        desktop: {
          gridItemMarginBottomDesktop,
          teamMembersImageWidthDesktop,
          teamMembersImageHeightDesktop,
          teamMembersImageMarginBottomDesktop,
          directorsBoardImageWidthDesktop,
          directorsBoardImageHeightDesktop,
        },
        mobile: {
          gridItemMarginBottomMobile,
          teamMembersImageWidthMobile,
          teamMembersImageHeightMobile,
          directorsBoardImageWidthMobile,
          directorsBoardImageHeightMobile,
        },
      },
    },
    typography: {
      teamPage: {
        nameFontSizeDesktop,
        profileTextFontsizeDesktop,
        profileFamilyWeightDesktop,
        nameMarginRightDesktop,
        nameMarginLeftDesktop,
      },
    },
    palette: {
      teamDirectorsCard: { otherJobTextColor },
    },
  } = useTheme();

  const isScreenDownSM = useMediaQuery(breakpoints.down("sm"));

  const teamMembersStyles = {
    width: isScreenDownSM
      ? teamMembersImageWidthMobile
      : teamMembersImageWidthDesktop,
    height: isScreenDownSM
      ? teamMembersImageHeightMobile
      : teamMembersImageHeightDesktop,
    marginBottom: teamMembersImageMarginBottomDesktop,
  };

  const directorStyles = {
    width: isScreenDownSM
      ? directorsBoardImageWidthMobile
      : directorsBoardImageWidthDesktop,
    height: isScreenDownSM
      ? directorsBoardImageHeightMobile
      : directorsBoardImageHeightDesktop,
    marginBottom: teamMembersImageMarginBottomDesktop,
  };

  return (
    <Grid justifyContent="center" container mt={directorsData && 14}>
      {(squadData || directorsData || []).map((profile) => (
        <Grid
          key={uuidv4()}
          item
          xs={6}
          md={6}
          lg={4}
          marginBottom={
            isScreenDownSM
              ? gridItemMarginBottomMobile
              : gridItemMarginBottomDesktop
          }
        >
          <Box display="flex" flexDirection="column" alignItems="center">
            <Box sx={squadData ? teamMembersStyles : directorStyles}>
              <img
                loading="lazy"
                src={
                  squadData
                    ? getSolutionTeamPicturesUrlFromFirebaseDevStorage(
                        profile.asset
                      )
                    : getSolutionDirectorsBoardPicturesUrlFromFirebaseDevStorage(
                        profile.asset
                      )
                }
                style={teamMembersStyles}
                alt={profile.alt}
              />
            </Box>
            <Box display="flex" mb={1} alignItems="center">
              <Typography
                variant="paragraph"
                fontSize={nameFontSizeDesktop}
                fontFamily={profileFamilyWeightDesktop}
                marginRight={nameMarginRightDesktop}
                marginLeft={directorsData && nameMarginLeftDesktop}
              >
                <Interweave content={profile.name} />
              </Typography>
              <a href={profile.linkedIn} target="_blank" rel="noreferrer">
                <img src={linkedInIcon} alt="linkedIn" />
              </a>
            </Box>
            <Box display="flex" mb={1} alignItems="center">
              <Typography
                variant="paragraph"
                fontSize={profileTextFontsizeDesktop}
                textAlign="center"
              >
                <Interweave content={profile.job} />
              </Typography>
            </Box>
            <Box
              display="flex"
              mb={1}
              maxWidth={
                isScreenDownSM
                  ? teamMembersImageWidthMobile
                  : teamMembersImageWidthDesktop
              }
              alignItems="center"
            >
              <Typography
                variant="paragraph"
                fontSize={profileTextFontsizeDesktop}
                textAlign="center"
                color={otherJobTextColor}
              >
                <Interweave content={profile.otherJob} />
              </Typography>
            </Box>
          </Box>
        </Grid>
      ))}
    </Grid>
  );
};

export default TeamDirectorsBoard;

import { InterviewFormNameEnum } from "utils/constants/enums/InterviewFormNameEnum";
import { withConnectedFormik } from "hooks/withConnectedFormik";

import { saveCompanySimulationInterviewForm } from "domain/interview/interviewControllers";
import {
  ValidationSchemas,
  InterviewInitialValues,
} from "domain/interview/interviewFormUtils";

import InterviewCompanySimulationForm from "./InterviewCompanySimulationForm";

export default withConnectedFormik(
  InterviewFormNameEnum.INTERVIEW_COMPANY_SIMULATION,
  saveCompanySimulationInterviewForm,
  InterviewInitialValues.INTERVIEW_COMPANY_SIMULATION,
  ValidationSchemas.INTERVIEW_COMPANY_SIMULATION
)(InterviewCompanySimulationForm);

import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";
import Interweave from "interweave";
import { useTranslation } from "react-i18next";

const Banner = () => {
  const {
    breakpoints,
    palette: { green, white },
    typography: { SubtitleStyles, FontFamilies },
  } = useTheme();

  const { t } = useTranslation("header");
  const isScreenDownSmall = useMediaQuery(breakpoints.down("sm"));

  return (
    <Box
      name="banner"
      px={5}
      py={isScreenDownSmall ? 2 : 3}
      sx={{
        bgcolor: green.main,
        color: white.main,
        textAlign: "center",
      }}
    >
      <Typography
        sx={{
          ...SubtitleStyles,
          letterSpacing: 0,
          fontFamily: FontFamilies.ProximaNovaMedium,
          fontWeight: 500,
          lineHeight: "140%",
          fontSize: isScreenDownSmall
            ? SubtitleStyles.fontSizes.md
            : SubtitleStyles.fontSizes.lg,
        }}
      >
        <Interweave content={t("bannerText")} />
      </Typography>
    </Box>
  );
};

export default Banner;

import i18n from "i18n";

export const servicesPath = {
  mission: {
    orangePath:
      i18n.t("path:path.contact") +
      "?reason=" +
      i18n.t("contactPage:contact.list.reasons.3"),
    greenPath:
      i18n.t("path:path.contact") +
      "?reason=" +
      i18n.t("contactPage:contact.list.reasons.1"),
    brownPath:
      i18n.t("path:path.contact") +
      "?reason=" +
      i18n.t("contactPage:contact.list.reasons.4"),
    bluePath:
      i18n.t("path:path.contact") +
      "?reason=" +
      i18n.t("contactPage:contact.list.reasons.5"),
  },
  accueil: {
    orangePath: i18n.t("path:path.calendly"),
    greenPath: i18n.t("path:path.questionnaire") + "/q1",
    brownPath: i18n.t("path:path.interview"),
    bluePath: i18n.t("path:path.calendly"),
  },
};

import { withConnectedFormik } from "../../../hooks/withConnectedFormik";
import InterviewJobSoftSkillsForm from "./InterviewJobSoftSkillsForm";
import { InterviewFormNameEnum } from "../../../utils/constants/enums/InterviewFormNameEnum";
import { saveJobSoftSkillsInterviewForm } from "../../../domain/interview/interviewControllers";
import {
  InterviewInitialValues,
  ValidationSchemas,
} from "../../../domain/interview/interviewFormUtils";

export default withConnectedFormik(
  InterviewFormNameEnum.INTERVIEW_JOB_SOFTSKILLS,
  saveJobSoftSkillsInterviewForm,
  InterviewInitialValues.INTERVIEW_JOB_SOFTSKILLS,
  ValidationSchemas.INTERVIEW_JOB_SOFTSKILLS
)(InterviewJobSoftSkillsForm);

import { GoogleAnalyticsEvents } from "./../analyticsConfig";

const createInterviewDataGA = ({
  interviewDate,
  interviewEntreprise,
  interviewPoste,
}) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: GoogleAnalyticsEvents.CreationEntretien,
    interviewDate,
    interviewEntreprise,
    interviewPoste,
  });
};

export default createInterviewDataGA;

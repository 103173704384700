import React, { useState, useEffect, useMemo, useCallback } from "react";
import { saveTempAnswer } from "store/users/usersSlice";
import { useDispatch, useSelector } from "react-redux";

import {
  getAnswerInstance,
  getQuestionAnswers,
} from "utils/helpers/questionnaireHelper";

import env from "../../../.env.json";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import parse from "autosuggest-highlight/parse";

const AutoCompleteLocationMUI = ({
  qid,
  question,
  type,
  anonymous,
  actions,
  noOptionsText,
  placeHolder,
}) => {
  const [value, setValue] = useState(null);
  const [inputValue, setInputValue] = useState("");
  const [options, setOptions] = useState([]);
  const users = useSelector((state) => state.users);
  const dispatch = useDispatch();
  const questionAnswerMap = useMemo(
    () => getQuestionAnswers(users.questionWithAnswers),
    [users.questionWithAnswers]
  );
  const questionZipCode = useMemo(
    () => questionAnswerMap.find((question) => question.key === qid),
    [questionAnswerMap, qid]
  );
  const [mapsLoaded, setMapsLoaded] = useState(false);

  useEffect(() => {
    const scriptId = "google-maps";

    if (!document.getElementById(scriptId)) {
      const script = document.createElement("script");
      script.src = `${env.google_maps_api_url}?key=${env.google_maps_api_key}&libraries=places`;
      script.async = true;
      script.id = scriptId;

      script.onload = () => {
        setMapsLoaded(true);
      };

      document.head.appendChild(script);
    } else {
      setMapsLoaded(true);
    }
  }, []);

  useEffect(() => {
    if (mapsLoaded && questionZipCode?.value) {
      if (
        questionZipCode.value.zipcode === null &&
        questionZipCode.value.locality
      ) {
        setValue(questionZipCode.value.locality + ", France");
      } else if (questionZipCode.value.zipcode) {
        setValue(
          questionZipCode.value.zipcode +
            " " +
            (questionZipCode.value.locality || "") +
            ", France"
        );
      }
    }
  }, [mapsLoaded, questionZipCode]);

  useEffect(() => {
    const fetchPlacesAutoComplete = (input) => {
      if (mapsLoaded && window.google?.maps?.places) {
        const autocompleteService =
          new window.google.maps.places.AutocompleteService();
        autocompleteService.getPlacePredictions(
          {
            input: input,
            types: ["geocode"],
            componentRestrictions: {
              country: ["fr"],
            },
            fields: ["postal_code"],
          },
          (results) => {
            let newOptions = [];
            if (value) {
              newOptions = [value];
            }
            if (results) {
              newOptions = [...newOptions, ...results];
            }

            setOptions(newOptions);
          }
        );
      }
    };
    fetchPlacesAutoComplete(inputValue);
  }, [mapsLoaded, inputValue, value]);

  const getLocalityZipCodeByPlaceId = useCallback(
    (placeId) => {
      if (window.google?.maps?.places) {
        const placesService = new window.google.maps.places.PlacesService(
          document.createElement("div")
        );
        placesService.getDetails(
          {
            placeId,
            fields: ["address_components"],
          },
          (details) => {
            let zipcode = null;
            let locality = null;
            details?.address_components?.forEach((entry) => {
              if (entry.types?.[0] === "postal_code") {
                zipcode = entry.long_name;
              }
              if (entry.types?.[0] === "locality") {
                locality = entry.long_name;
              }
            });
            dispatch(
              saveTempAnswer([
                { ...getAnswerInstance(placeId, { zipcode, locality }), qid },
              ])
            );
          }
        );
      }
    },
    [dispatch, qid]
  );

  const onChange = useCallback(
    (newValue, lastSelectedValue) => {
      if (newValue?.place_id) {
        getLocalityZipCodeByPlaceId(newValue?.place_id);
      }
    },
    [getLocalityZipCodeByPlaceId]
  );

  return (
    <Autocomplete
      id="google-map-zipcode-citiesnames"
      getOptionLabel={(option) =>
        typeof option === "string" ? option : option.description
      }
      fullWidth
      options={options}
      autoComplete
      includeInputInList
      filterSelectedOptions
      value={value}
      noOptionsText={noOptionsText}
      onChange={(event, newValue, reason) => {
        if (reason === "clear") {
          dispatch(saveTempAnswer([]));
          setValue(null);
          return;
        }
        setOptions(newValue ? [newValue, ...options] : options);
        setValue(newValue);
        onChange(newValue);
      }}
      openOnFocus={false}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          InputLabelProps={{ style: { fontFamily: "ProximaSoft" } }} // font size of input label
          label={placeHolder}
        />
      )}
      filterOptions={(options, state) => {
        if (state.inputValue === "") {
          return [];
        }
        return options.filter(
          (option) =>
            option.description &&
            (option.types.includes("premise") ||
              option.types.includes("locality") ||
              option.types.includes("street_address") ||
              option.types.includes("postal_code"))
        );
      }}
      renderOption={(props, option) => {
        const matches =
          option?.structured_formatting?.main_text_matched_substrings || [];

        const parts = parse(
          option?.structured_formatting?.main_text,
          matches.map((match) => [match.offset, match.offset + match.length])
        );

        return (
          <li {...props}>
            <Grid container alignItems="center">
              <Grid item sx={{ display: "flex", width: 44 }}>
                <LocationOnIcon sx={{ color: "text.secondary" }} />
              </Grid>
              <Grid
                item
                sx={{ width: "calc(100% - 44px)", wordWrap: "break-word" }}
              >
                {parts.map((part, index) => (
                  <Box
                    key={part.text}
                    component="span"
                    sx={{ fontWeight: part.highlight ? "bold" : "regular" }}
                  >
                    {part.text}
                  </Box>
                ))}
                <Typography variant="body2" color="text.secondary">
                  {option?.structured_formatting?.secondary_text}
                </Typography>
              </Grid>
            </Grid>
          </li>
        );
      }}
    />
  );
};

export default AutoCompleteLocationMUI;

import React, { useCallback, useState, useMemo, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { useTheme } from "@mui/system";
import { Button, TextField } from "@mui/material";

import { saveAnswer, saveLastProgress } from "store/users/usersSlice";
import { selectUserUID } from "store/users/usersSelectors";
import { updateUserQuestionnaireAction } from "domain/user/userControllers";
import Icon from "components/common/Icon";

const QuestionOptionForm = ({ initialAnswer, stepData }) => {
  const {
    palette: { white, green },
    typography: { FontFamilies },
  } = useTheme();

  const dispatch = useDispatch();
  const { t } = useTranslation(["path", "solutions"]);
  const history = useHistory();
  const userId = useSelector(selectUserUID);

  const [checkedOptionId, setCheckedOptionId] = useState(null);
  const [showOtherStatutInput, setShowOtherStatutInput] = useState(false);
  const [path, setPath] = useState(null);

  const action = stepData.actions?.[0];

  // Handling the case of not empty initialAnswer ( in the case of click to return or already submitted form)
  // 1- Case : Updating next path value depending
  useEffect(() => {
    if (initialAnswer?.aid) {
      stepData?.options?.forEach((option) => {
        if (option?.value?.id === initialAnswer.aid) {
          setPath(option.path);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stepData?.options]);
  // 2 - Case : Updating the output of options (checked or show input in case of other statut)
  useEffect(() => {
    if (initialAnswer?.aid) {
      setCheckedOptionId(initialAnswer?.aid);
      setShowOtherStatutInput(initialAnswer?.aid === "q1b005");
    }
  }, [initialAnswer?.aid]);

  // Saving the field values on input change
  const onChangeCallbackClosure = useCallback(
    (id, text, resume, pathValue) => () => {
      setShowOtherStatutInput(id === "q1b005");
      setCheckedOptionId(id);
      if (pathValue) {
        setPath(pathValue);
      }
      const answer = {
        qid: stepData.qid,
        question: stepData.question,
        type: stepData.type,
        answers: [
          {
            aid: id,
            value: text,
          },
        ],
        resume: resume,
      };
      dispatch(saveAnswer(answer));
    },
    [stepData.qid, stepData.question, stepData.type, dispatch]
  );

  // Submit the form, Save in Database and go to next page
  const onSubmitCallback = useCallback(
    (e) => {
      window.scrollTo({ top: 0 });
      e.preventDefault();
      dispatch(saveLastProgress(stepData.progress));
      // Note : since the component don't destruct/recontruct we should clean the states before switching to pages in questionnaire
      setCheckedOptionId(null);
      setShowOtherStatutInput(false);
      setPath(null);

      if (userId) {
        dispatch(updateUserQuestionnaireAction());
      }
      history.push(t("path:path.questionnaire") + "/" + (path || action.path));
    },
    [dispatch, stepData, path, action.path, userId, history, t]
  );

  const ButtonOptions = useMemo(() => {
    return (stepData.options || []).map((option) => {
      const {
        value: { id, text, resume },
        path: pathValue,
      } = option;
      return (
        <Button
          key={id}
          variant="text"
          style={{
            backgroundColor:
              checkedOptionId === id ? green.green200 : white.main,
            borderColor: checkedOptionId === id ? green.main : white.neutral400,
            borderWidth: "1px",
            borderStyle: "solid",
          }}
          sx={{ mb: 4, fontFamily: FontFamilies.ProximaNovaBold }}
          color={"black"}
          type="button"
          fullWidth
          size="large"
          onClick={onChangeCallbackClosure(id, text, resume, pathValue)}
        >
          {text}
        </Button>
      );
    });
  }, [
    stepData.options,
    checkedOptionId,
    green.green200,
    green.main,
    white.main,
    white.neutral400,
    FontFamilies.ProximaNovaBold,
    onChangeCallbackClosure,
  ]);

  return (
    <form onSubmit={onSubmitCallback}>
      {ButtonOptions}
      {showOtherStatutInput && (
        <TextField
          placeholder={
            initialAnswer?.aid === "q1b005" && initialAnswer?.value !== "Autre"
              ? initialAnswer.value
              : stepData.otherStatutProPlaceholder
          }
          inputProps={{
            maxLength: 40,
          }}
          onChange={(e) => {
            onChangeCallbackClosure(
              "q1b005",
              e.target?.value,
              stepData.otherStatutProPlaceholder + e.target?.value
            )();
          }}
          fullWidth
        />
      )}
      <Button
        sx={{ mt: 10 }}
        type="submit"
        size="large"
        fullWidth
        variant="contained"
        color="orange"
        disabled={!checkedOptionId}
        endIcon={<Icon iconName="arrowRightShort" iconSize={16} />}
      >
        {action?.value?.text}
      </Button>
    </form>
  );
};

export default React.memo(QuestionOptionForm);

import Errors from "utils/translations/en/errors.json";
import { updateUser } from "domain/user/userControllers";

export async function updateUserContact(user, contact) {
  try {
    await updateUser(user.uid, {
      contacts: [
        ...(user.contacts || []),
        { date: new Date().toLocaleString("fr-Fr"), ...(contact || {}) },
      ],
    });
  } catch (error) {
    console.error(Errors.CONTACT.SERVER_ON_UPDATE, error, { user, contact });
  }
}

import React, { useCallback, useEffect, useRef, useState } from "react";

import VideosCarouselButtonsStyles from "./VideosCarouselButton.module.scss";

import { Box, Button, useTheme } from "@mui/material";

import Icon from "components/common/Icon";

const VideosCarouselButtons = ({ children }) => {
  const contentRef = useRef(null);
  const [isAtStart, setIsAtStart] = useState(true);
  const [isAtEnd, setIsAtEnd] = useState(false);

  const {
    palette: {
      videosCarouselButton: { buttonColor, isAtStartEndColor },
    },
  } = useTheme();

  useEffect(() => {
    if (contentRef) {
      const { current: content } = contentRef;
      const handleScroll = () => {
        setIsAtStart(content.scrollLeft === 0);
        setIsAtEnd(
          content.scrollLeft === content.scrollWidth - content.offsetWidth
        );
      };
      content.addEventListener("scroll", handleScroll);
      return () => {
        content.removeEventListener("scroll", handleScroll);
      };
    }
  }, [contentRef]);

  /**
   * Handles the scroll button click event.
   * @param {string} direction - The direction of the scroll button ("right" or "left").
   */
  const handleScrollButton = useCallback(
    (direction) => {
      const { current: content } = contentRef;
      if (content) {
        const scrollAmount = direction === "right" ? 335 : -335;
        content.scrollBy({ left: scrollAmount, behavior: "smooth" });
      }
    },
    [contentRef]
  );

  return (
    <>
      <Box className={VideosCarouselButtonsStyles.content} ref={contentRef}>
        {children}
      </Box>
      <Box display="flex" alignItems="center" justifyContent="center" gap={4}>
        <Button
          onClick={() => handleScrollButton("left")}
          sx={{
            minWidth: "5%",
            padding: 0,
          }}
          disabled={isAtStart}
        >
          <Icon
            iconName="arrowLeftShort"
            iconColor={isAtStart ? isAtStartEndColor : buttonColor}
          />
        </Button>
        <Button
          onClick={() => handleScrollButton("right")}
          sx={{
            minWidth: "5%",
            padding: 0,
            marginTop: "2px",
          }}
          disabled={isAtEnd}
        >
          <Icon
            iconName="arrowRightShort"
            iconColor={isAtEnd ? isAtStartEndColor : buttonColor}
          />
        </Button>
      </Box>
    </>
  );
};

export default VideosCarouselButtons;

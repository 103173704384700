import React, { useMemo } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  Box,
  Button,
  Container,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import Interweave from "interweave";

import { getPillImageUrlFromFirebaseDevStorage } from "utils/helpers/assetsHelpers";
import {
  getAnswersByQid,
  getQuestionAnswers,
  getVariablesValuesFromQuestionnaire,
} from "utils/helpers/questionnaireHelper";
import { selectUserAnswers } from "store/users/usersSelectors";

import LinearProgress from "components/common/LinearProgress";
import QuestionTextForm from "components/Questionnaire/QuestionTextForm";
import Icon from "components/common/Icon/Icon";
import QuestionOptionForm from "components/Questionnaire/QuestionOptionForm";
import QuestionAutcompleteLocationForm from "components/Questionnaire/QuestionAutcompleteLocationForm";
import QuestionChoicesForm from "components/Questionnaire/QuestionChoicesForm";
import SummaryTextForm from "components/Questionnaire/SummaryTextForm";

function QuestionnairePage() {
  const { qid } = useParams();

  const history = useHistory();

  const { t } = useTranslation(["questionnaire", "path"]);

  const questionnaireData = t("data", { returnObjects: true });

  const {
    breakpoints,
    variables: {
      containerPX,
      questionnaire: { contentMaxWidth },
    },
    typography: { FontSizes, FontFamilies },
    palette: { blue },
  } = useTheme();
  const isScreenUpSM = useMediaQuery(breakpoints.up("sm"));

  const questionWithAnswers = useSelector(selectUserAnswers);

  const stepData = qid ? questionnaireData[qid] : questionnaireData.q1;

  const questionAnswerMap = useMemo(
    () => getQuestionAnswers(questionWithAnswers),
    [questionWithAnswers]
  );

  const introText = useMemo(
    () =>
      stepData.intro &&
      getVariablesValuesFromQuestionnaire(stepData.intro, questionAnswerMap),
    [stepData.intro, questionAnswerMap]
  );

  const questionText = useMemo(
    () =>
      stepData.question &&
      getVariablesValuesFromQuestionnaire(stepData.question, questionAnswerMap),
    [stepData.question, questionAnswerMap]
  );

  const initialAnswer = useMemo(() => {
    if (questionWithAnswers) {
      const answersEntered = getAnswersByQid(questionWithAnswers, qid);
      return answersEntered?.[0];
    }
    return null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [qid]);

  return (
    <Container
      sx={{
        px: containerPX,
        mt: 5,
      }}
      maxWidth="xl"
    >
      <LinearProgress progressValue={stepData?.progress} />
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        textAlign="center"
        mt={12}
      >
        {isScreenUpSM && (
          <Box>
            <img
              src={getPillImageUrlFromFirebaseDevStorage(
                "PillPaulineSmallBlue.png"
              )}
              loading="lazy"
              alt={t("interviewSection.imgPillAlt")}
            />
          </Box>
        )}
        <Box mt={2} maxWidth={contentMaxWidth}>
          <Typography
            sx={{
              color: blue.main,
              "@media (max-width: 576px)": {
                fontSize: FontSizes.sm,
              },
              // Since it's a paragraph by default in breakpoint it will change to xs
              fontSize: FontSizes.md,
            }}
            variant="paragraph"
          >
            <Interweave content={introText} />
          </Typography>
        </Box>
        <Box
          sx={{
            mt: 7,
            "@media (max-width: 576px)": {
              mt: 4,
            },
          }}
          maxWidth={contentMaxWidth}
        >
          <Typography
            sx={{
              fontSize: FontSizes.xl2,
              fontFamily: FontFamilies.ProximaNovaBold,
              "@media (max-width: 576px)": {
                fontSize: FontSizes.lg,
              },
            }}
            variant="h2"
          >
            <Interweave content={questionText} />
          </Typography>
        </Box>
        <Box
          sx={{
            mt: 10,
            mb: 38,
            "@media (max-width: 576px)": {
              mt: 8,
            },
          }}
          maxWidth={contentMaxWidth}
          width="100%"
        >
          {stepData.type === "text" && (
            <QuestionTextForm
              stepData={stepData}
              initialValue={initialAnswer?.value}
            />
          )}
          {stepData.type === "option" && (
            <QuestionOptionForm
              stepData={stepData}
              initialAnswer={initialAnswer}
            />
          )}
          {stepData.type === "autoCompleteLocationMUI" && (
            <QuestionAutcompleteLocationForm
              initialAnswer={initialAnswer}
              stepData={stepData}
            />
          )}
          {stepData.type === "multichoice" && (
            <QuestionChoicesForm
              stepData={stepData}
              initialAnswer={initialAnswer}
            />
          )}
          {stepData.type === "resume" && (
            <SummaryTextForm stepData={stepData} />
          )}
          <Button
            fullWidth
            variant="text"
            color="black"
            onClick={() => {
              history.goBack();
            }}
            sx={{ mt: 2 }}
            startIcon={<Icon iconName="arrowLeftShort" iconSize={16} />}
          >
            {t("goBack")}
          </Button>
        </Box>
      </Box>
    </Container>
  );
}
export default QuestionnairePage;

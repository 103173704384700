import { GoogleAnalyticsEvents } from "../analyticsConfig";

const closeAndGoToSolutionDataGA = ({ solutionNom }) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: GoogleAnalyticsEvents.FermerPopinSolution,
    solutionNom,
  });
};

export default closeAndGoToSolutionDataGA;

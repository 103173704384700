import GroupStarEnum from "./GroupStarEnum";

const MapBranchIdGroupStar = {
  q3ca001: GroupStarEnum.CONVINCE,
  q3ca002: GroupStarEnum.APPLY,
  q3fa001: GroupStarEnum.LEARN,
  q3dlk002: GroupStarEnum.LEARN,
  q4aa002: GroupStarEnum.LEARN,
  q4aa001: GroupStarEnum.LEARN,
  q4aa003: GroupStarEnum.LEARN,
  q4aa004: GroupStarEnum.LEARN,
  q4ba001: GroupStarEnum.KNOW,
  q4ba002: GroupStarEnum.KNOW,
  q3fa003: GroupStarEnum.KNOW,
  q3ba002: GroupStarEnum.APPLY,
  q3ba003: GroupStarEnum.APPLY,
  q3fa002: GroupStarEnum.APPLY,
  q3ea001: GroupStarEnum.CONVINCE,
  q3ea003: GroupStarEnum.APPLY,
};

export default MapBranchIdGroupStar;

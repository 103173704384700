import React, { useMemo, useRef } from "react";
import PropTypes from "prop-types";
import { Field } from "formik";
import { v4 as uuidv4 } from "uuid";

import BulletPoint from "assets/images/bulletPoint.png";

import MultiInputFieldStyles from "./MultiInputField.module.scss";
import { useLettersCount } from "hooks/useLettersCount";

// const MIN_HEIGHT_TEXT_AREA = 34;

// ** 8 pixel corresponds to one character
// const CONVERSION_PIXEL_CHARACTER = 8;

const DEFAULT_NUMBER_FIELDS = 3;

const DEFAULT_MAX_LENGTH_CHARACTERS = 80;

const DEFAULT_PLACE_HOLDERS = new Array(3).fill("");

const CustomInputComponent = React.memo(({ value, ...otherProps }) => {
  const textInput = useRef();
  const displayLetterCount = useLettersCount({
    ...otherProps,
    style: MultiInputFieldStyles,
  });

  // Temporarly is used in page activity and values
  // const height = useMemo(() => {
  //   return (
  //     Math.max(
  //       (value?.length /
  //         (textInput?.current?.offsetWidth / CONVERSION_PIXEL_CHARACTER)) *
  //         MIN_HEIGHT_TEXT_AREA,
  //       MIN_HEIGHT_TEXT_AREA
  //     ) + "px"
  //   );
  // }, [value]);
  return (
    <>
      <div className={MultiInputFieldStyles.containerInput}>
        <img
          className={MultiInputFieldStyles.icon}
          alt={"bullet point"}
          src={BulletPoint}
        />
        <input
          {...otherProps}
          ref={textInput}
          // style={{ height }}
          // Temporarly use this style
          style={{
            padding: "0 15px",
            height: "45px",
            width: "85%",
          }}
          className={MultiInputFieldStyles.input}
          type="text"
          defaultValue={value}
        />
      </div>
      {displayLetterCount}
    </>
  );
});

const MultiInputField = ({
  name,
  number = DEFAULT_NUMBER_FIELDS,
  maxLength = DEFAULT_MAX_LENGTH_CHARACTERS,
  placeHolders = DEFAULT_PLACE_HOLDERS,
  ...otherProps
}) => {
  const fields = useMemo(
    () => {
      return new Array(number).fill("").map((_, index) => (
        <React.Fragment key={uuidv4()}>
          <Field
            name={`${name}[${index}]`}
            maxLength={maxLength}
            placeholder={placeHolders[index]}
            as={CustomInputComponent}
            {...otherProps}
          />
        </React.Fragment>
      ));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [number]
  );

  return <>{fields}</>;
};

MultiInputField.propTypes = {
  // name is a required field
  name: PropTypes.string,
};

export default MultiInputField;

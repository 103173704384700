import TreeDisplayEnum from "utils/constants/enums/TreeDisplayEnum";

/**
 * Getting Value from the filter by key (sectors , searchable , branches ... )
 *
 * @param {string} filter the property filter of the solution
 * @param {enum: SolutionsFilterEnum} key specify the type of filter applied
 * @returns {string} the value of the filter defined in solution  by the key SolutionsFilterEnum
 */
export function getSolutionFilterValue(filter, key) {
  let valueFilter = null;
  filter?.split("&")?.forEach((elementFilter) => {
    const arrayFilter = elementFilter.split(key + ":");
    if (arrayFilter.length > 1 && arrayFilter[1].includes(",")) {
      valueFilter = arrayFilter[1].split(",");
    } else if (arrayFilter.length > 1) {
      valueFilter = arrayFilter[1];
    }
  });
  return valueFilter;
}

/**
 * Helper to suffle the order of each solution
 *
 * @param {object} hydratedSolutions {formations : array, suggestions: array} the final result expected to show in the solution page
 * @param {enum: formations || suggestions} key to specify either suggestions or solutions we want to suffle
 *
 * ? Note : we use the algorithm called Fisher-Yates shuffle
 */
export function suffleOrderOfSolutions(
  hydratedSolutions,
  key = TreeDisplayEnum.formations
) {
  const solutions = hydratedSolutions[key];
  if (solutions?.length < 1) {
    return;
  }
  for (let i = solutions.length - 1; i > 0; i--) {
    let j = Math.floor(Math.random() * (i + 1)); // random index from 0 to i

    // swap elements solutions[i] and solutions[j]
    [solutions[i], solutions[j]] = [solutions[j], solutions[i]];
  }
}

/**
 * @const {Object} SINGULAR_UNITS - Object mapping singular unit names to their plural forms.
 */
const SINGULAR_UNITS = {
  minutes: "minute",
  heures: "heure",
  semaines: "semaine",
  mois: "mois",
  ans: "an",
};

/**
 * Formats the frequency value based on the specified unit.
 *
 * @param {number} value - The numerical value of the frequency.
 * @param {string} frequency - The unit of the frequency (e.g., "minutes", "heures", "semaines", "mois", "ans").
 * @returns {string} - The formatted frequency string.
 */
export function formatFrequency(value, frequency) {
  /**
   * @const {number} parsedValue - Parsed integer value of the frequency.
   */
  const parsedValue = parseInt(value);

  /**
   * @const {string} formattedFrequency - Formatted frequency string.
   */
  const formattedFrequency =
    parsedValue <= 1 ? SINGULAR_UNITS[frequency] || frequency : frequency;

  return formattedFrequency;
}

/**
 * Calculates the numbers of solutions based on the given formations.
 *
 * @param {Array} formations - The array of formations.
 * @returns {Object} - An object containing the numbers of solutions.
 */
export function getNumbersOfSolutions(formations) {
  const NumbersOfSolutions = {
    totalNumberOfSolutions: formations?.length,
    numberOfSolutionTypeFormation: formations?.filter(
      (solution) => solution.type === "Formation"
    ).length,
    numberOfSolutionTypeOutil: formations?.filter(
      (solution) => solution.type === "Outil numérique"
    ).length,
    numberOfSolutionTypeAccompagnement: formations?.filter(
      (solution) => solution.type === "Accompagnement"
    ).length,
  };

  return NumbersOfSolutions;
}

import { MapInterviewStepPageToPathnameEnum } from "../constants/enums/MapInterviewStepPageToPathnameEnum";
import InterviewPageEnum from "../constants/enums/InterviewPageEnum";

/**
 * Get info page from the MapInterviewStepPageToPathnameEnum if an entry exists
 *
 * @param {string} page The name of the page we want to retrieve info
 *
 * @returns {{ previousPage, nextPage, trainingProgress }}
 */
export const getInterviewInfoPage = (page) => {
  if (
    Object.values(InterviewPageEnum).includes(page) &&
    Object.keys(MapInterviewStepPageToPathnameEnum).includes(page)
  ) {
    return MapInterviewStepPageToPathnameEnum[page];
  } else {
    return { previousPage: null, nextPage: null, trainingProgress: null };
  }
};

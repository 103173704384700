import { GoogleAnalyticsEvents } from "../analyticsConfig";

const clickDownloadActivitiesReportDataGA = () => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: GoogleAnalyticsEvents.RapportActivitesTelecharge,
  });
};

export default clickDownloadActivitiesReportDataGA;

import { InterviewFormNameEnum } from "utils/constants/enums/InterviewFormNameEnum";
import { withConnectedFormik } from "hooks/withConnectedFormik";

import { saveEmailInterviewForm } from "domain/interview/interviewControllers";
import {
  ValidationSchemas,
  InterviewInitialValues,
} from "domain/interview/interviewFormUtils";

import InterviewMailScreen from "./InterviewMailScreen";

export default withConnectedFormik(
  InterviewFormNameEnum.INTERVIEW_EMAIL,
  saveEmailInterviewForm,
  InterviewInitialValues.InterviewEmailForm,
  ValidationSchemas.INTERVIEW_EMAIL
)(InterviewMailScreen);

import React, { useCallback } from "react";
import { useHistory, useLocation } from "react-router-dom";
import Interweave from "interweave";
import {
  Box,
  Container,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";

import clickBlocServiceDataGA from "config/googleAnalytics/clickBlocServiceDataGA";
import ValueBlocServiceEnum from "utils/constants/enums/ValueBlocServiceEnum";
import { servicesPath } from "utils/constants/enums/ServicesPathEnum";

import Icon from "components/common/Icon/Icon";

import ServicesSectionStyle from "./ServicesSection.module.scss";
import clicRDVDataGA from "config/googleAnalytics/clicRDVDataGA";
import SourceClickRDVEnum from "utils/constants/enums/SourceClickRDVEnum";

const ServicesSection = ({
  title,
  description,
  orangeText,
  greenText,
  brownText,
  blueText,
}) => {
  const {
    breakpoints,
    palette: { orange, green, blue, brown, white },
    typography: { ParagraphStyles },
  } = useTheme();
  const history = useHistory();
  const isScreenUpLg = useMediaQuery(breakpoints.up("lg"));
  const isScreenDownSM = useMediaQuery(breakpoints.down("sm"));

  const navigateToPath = useCallback(
    (path, nomBloc) => () => {
      history.push(path);
      clickBlocServiceDataGA(nomBloc);
      if (nomBloc === ValueBlocServiceEnum.EchangeCoach) {
        clicRDVDataGA(SourceClickRDVEnum.HOMEPAGE_FROM_COACH_SERVICE);
      }
      if (nomBloc === ValueBlocServiceEnum.DecouvrirFormation) {
        clicRDVDataGA(SourceClickRDVEnum.HOMEPAGE_FROM_FORMATION_SERVICE);
      }
      // Scroll to Top of the window
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
    [history]
  );

  const { pathname } = useLocation();
  const substring = pathname.slice(1);

  return (
    <section name="ServicesSection">
      <Container maxWidth="xl" sx={{ mb: 40 }}>
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          textAlign="center"
          mb={isScreenDownSM ? 8 : 18}
        >
          <Typography variant="h2" mb={description && 10}>
            <Interweave content={title} />
          </Typography>
          <Typography
            variant="paragraph"
            width={isScreenDownSM ? "100%" : "70%"}
          >
            <Interweave content={description} />
          </Typography>
        </Box>
        {isScreenUpLg ? (
          <div id="allboxes" className={ServicesSectionStyle.allboxes}>
            <button
              id="blue"
              onClick={navigateToPath(
                servicesPath[substring].bluePath,
                ValueBlocServiceEnum.EchangeCoach
              )}
              className={ServicesSectionStyle.blue}
            >
              <Typography
                variant="paragraph"
                sx={{
                  fontSize: ParagraphStyles.fontSizes.xl2,
                  fontWeight: 700,
                  color: white.main,
                }}
              >
                <Interweave content={blueText} />
              </Typography>
              <Box sx={{ width: "100%", textAlign: "right" }}>
                <Icon
                  svgProps={{
                    className: ServicesSectionStyle.arrowIcon,
                  }}
                  iconName="arrowQuickAccess"
                  iconColor={white.main}
                  iconSize={40}
                />
              </Box>
            </button>
            <button
              id="green"
              onClick={navigateToPath(
                servicesPath[substring].greenPath,
                ValueBlocServiceEnum.SeConnaitre
              )}
              className={ServicesSectionStyle.green}
            >
              <Typography
                variant="paragraph"
                sx={{
                  fontSize: ParagraphStyles.fontSizes.xl2,
                  fontWeight: 700,
                  color: white.main,
                }}
              >
                <Interweave content={greenText} />
              </Typography>
              <Box sx={{ width: "100%", textAlign: "right" }}>
                <Icon
                  svgProps={{
                    className: ServicesSectionStyle.arrowIcon,
                  }}
                  iconName="arrowQuickAccess"
                  iconColor={white.main}
                  iconSize={40}
                />
              </Box>
            </button>
            <button
              id="brown"
              onClick={navigateToPath(
                servicesPath[substring].brownPath,
                ValueBlocServiceEnum.EntrainementEntretien
              )}
              className={ServicesSectionStyle.brown}
            >
              <Typography
                variant="paragraph"
                sx={{
                  fontSize: ParagraphStyles.fontSizes.xl2,
                  fontWeight: 700,
                  color: white.main,
                }}
              >
                <Interweave content={brownText} />
              </Typography>
              <Box sx={{ width: "100%", textAlign: "right" }}>
                <Icon
                  svgProps={{
                    className: ServicesSectionStyle.arrowIcon,
                  }}
                  iconName="arrowQuickAccess"
                  iconColor={white.main}
                  iconSize={40}
                />
              </Box>
            </button>
            <button
              id="orange"
              onClick={navigateToPath(
                servicesPath[substring].orangePath,
                ValueBlocServiceEnum.DecouvrirFormation
              )}
              className={ServicesSectionStyle.orange}
            >
              <Typography
                variant="paragraph"
                sx={{
                  fontSize: ParagraphStyles.fontSizes.xl2,
                  fontWeight: 700,
                  color: white.main,
                }}
              >
                <Interweave content={orangeText} />
              </Typography>
              <Box sx={{ width: "100%", textAlign: "right" }}>
                <Icon
                  svgProps={{
                    className: ServicesSectionStyle.arrowIcon,
                  }}
                  iconName="arrowQuickAccess"
                  iconColor={white.main}
                  iconSize={40}
                />
              </Box>
            </button>
          </div>
        ) : (
          <>
            <Box
              p={3}
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              marginBottom={5}
              borderRadius={3}
              bgcolor={orange.main}
              onClick={navigateToPath(
                servicesPath[substring].orangePath,
                ValueBlocServiceEnum.DecouvrirFormation
              )}
            >
              <Typography
                variant="paragraph"
                sx={{
                  fontSize: ParagraphStyles.fontSizes.xs,
                  fontWeight: 700,
                  color: white.main,
                }}
              >
                <Interweave content={orangeText} />
              </Typography>
              <Box>
                <Icon
                  iconName="arrowQuickAccess"
                  iconColor={white.main}
                  iconSize={12}
                />
              </Box>
            </Box>
            <Box
              p={3}
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              marginBottom={5}
              borderRadius={3}
              bgcolor={green.main}
              onClick={navigateToPath(
                servicesPath[substring].greenPath,
                ValueBlocServiceEnum.SeConnaitre
              )}
            >
              <Typography
                variant="paragraph"
                sx={{
                  fontSize: ParagraphStyles.fontSizes.xs,
                  fontWeight: 700,
                  color: white.main,
                }}
              >
                <Interweave content={greenText} />
              </Typography>
              <Box>
                <Icon
                  iconName="arrowQuickAccess"
                  iconColor={white.main}
                  iconSize={12}
                />
              </Box>
            </Box>
            <Box
              p={3}
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              marginBottom={5}
              borderRadius={3}
              bgcolor={brown.main}
              onClick={navigateToPath(
                servicesPath[substring].brownPath,
                ValueBlocServiceEnum.EntrainementEntretien
              )}
            >
              <Typography
                variant="paragraph"
                sx={{
                  fontSize: ParagraphStyles.fontSizes.xs,
                  fontWeight: 700,
                  color: white.main,
                }}
              >
                <Interweave content={brownText} />
              </Typography>
              <Box>
                <Icon
                  iconName="arrowQuickAccess"
                  iconColor={white.main}
                  iconSize={12}
                />
              </Box>
            </Box>
            <Box
              p={3}
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              marginBottom={5}
              borderRadius={3}
              bgcolor={blue.main}
              onClick={navigateToPath(
                servicesPath[substring].bluePath,
                ValueBlocServiceEnum.EchangeCoach
              )}
            >
              <Typography
                variant="paragraph"
                sx={{
                  fontSize: ParagraphStyles.fontSizes.xs,
                  fontWeight: 700,
                  color: white.main,
                }}
              >
                <Interweave content={blueText} />
              </Typography>
              <Box>
                <Icon
                  iconName="arrowQuickAccess"
                  iconColor={white.main}
                  iconSize={12}
                />
              </Box>
            </Box>
          </>
        )}
      </Container>
    </section>
  );
};

export default ServicesSection;

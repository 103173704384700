import React from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import clsx from "clsx";

import { useMediaQueries } from "hooks/useMediaQueries";
import InterviewCompanySimulationFormStyles from "./InterviewCompanySimulationForm.module.scss";

import { selectCompanyNameFromCurrentInterview } from "store/interview/interviewSelectors";

import TemplateFormInterview from "components/common/TemplateFormInterview";
import SingleInputField from "components/common/SingleInputField";

const InterviewCompanySimulationForm = ({ values }) => {
  const { t } = useTranslation("interview");
  const { isMobile } = useMediaQueries();
  const {
    questionName,
    toolTipTextTitle,
    toolTipTextContent,
    placeHolder,
    buttonLabelName,
  } = t("companySimulation", {
    returnObjects: true,
    company: useSelector(selectCompanyNameFromCurrentInterview),
  });

  return (
    <TemplateFormInterview
      questionName={questionName}
      toolTipTextTitle={toolTipTextTitle}
      toolTipTextContent={toolTipTextContent}
      buttonLabelName={buttonLabelName}
    >
      <div
        className={clsx({
          [InterviewCompanySimulationFormStyles.multiInputListL]: true,
          [InterviewCompanySimulationFormStyles.multiInputListS]: isMobile,
        })}
      >
        <SingleInputField
          name="simulation"
          maxLength={400}
          placeHolder={placeHolder}
        />
      </div>
    </TemplateFormInterview>
  );
};

export default InterviewCompanySimulationForm;

import { subscribeUserQuestionnaireFormAction } from "domain/user/userControllers";
import {
  QuestionnaireEmailFormName,
  ValidationSchemasEmail,
} from "domain/user/userFormUtils";
import { withConnectedFormik } from "hooks/withConnectedFormik";

import QuestionnaireEmailPage from "./QuestionnaireEmailPage";

export default withConnectedFormik(
  QuestionnaireEmailFormName,
  subscribeUserQuestionnaireFormAction,
  { emailForm: null },
  ValidationSchemasEmail
)(QuestionnaireEmailPage);

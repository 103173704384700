const interviewProgressReducer = {
  saveInterviewProgressData: (state, action) => {
    return {
      ...state,
      currentInterview: {
        ...state.currentInterview,
        trainingProgress: action.payload,
      },
    };
  },
};

export default interviewProgressReducer;

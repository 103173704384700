import React from "react";

import { Container, useTheme } from "@mui/material";
import Box from "@mui/material/Box";

import SeparatorStyles from "./Separator.module.scss";

const Separator = () => {
  const theme = useTheme();
  return (
    <div className={SeparatorStyles.content}>
      <Container sx={{ px: theme.variables.containerPX }} maxWidth="xl">
        <Box className={SeparatorStyles.borderBottom} />
      </Container>
    </div>
  );
};

export default Separator;

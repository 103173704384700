import { GoogleAnalyticsEvents } from "../analyticsConfig";

const clickFilterSolutionsDataGA = (valeurFiltre) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: GoogleAnalyticsEvents.CliqueFiltreSolutions,
    valeurFiltreSolution: valeurFiltre,
  });
};

export default clickFilterSolutionsDataGA;

// @flow
// $FlowFixMe[cannot-resolve-module]
import { v4 as uuidv4 } from "uuid";
import { Model } from "utils/helpers/modelHelpers";
import type { ModelType } from "utils/helpers/modelHelpers";
import type {
  InterviewTrainingType,
  JobDescriptionType,
  TrainingProgressType,
  TrainingTagType,
} from "./interviewTypes";
import { LastProgressNumberEnum } from "utils/constants/enums/LastProgressNumberEnum";
import { TrainingTagEnum } from "utils/constants/enums/TrainingTagEnum";

const regexLineBreak = /\\n|\\r\\n|\\n\\r|\\r/g;

class InterviewModel extends Model {
  constructor(userUID: string, interviewId?: string) {
    const userInstance: InterviewTrainingType = {
      id: interviewId || uuidv4(),
      userUID,
      metaData: {}, // TODO(mehdi): add date and hour here
      trainingProgress: TrainingProgressFactory(
        TrainingTagEnum.InterviewStart,
        LastProgressNumberEnum.InterviewStart[0]
      ).instance,
    };
    super(userInstance);
  }
}

export const NewInterviewFactory = (userUID: string): ModelType => {
  return new InterviewModel(userUID);
};

export const InterviewFactory = (
  userUID: string,
  interviewId: string
): ModelType => {
  return new InterviewModel(userUID, interviewId);
};

class TrainingProgressModel extends Model {
  constructor(trainingTag: any, lastProgressNumber: string) {
    const trainingProgressInstance: TrainingProgressType = {
      trainingTag,
      lastProgressNumber,
    };
    super(trainingProgressInstance);
  }
}

export const TrainingProgressFactory = (
  trainingTag: TrainingTagType,
  lastProgressNumber: string
): ModelType => {
  return new TrainingProgressModel(trainingTag, lastProgressNumber);
};

class JobDescriptionModel extends Model {
  constructor(
    name: string,
    descriptions: string[],
    domain: string,
    skills: string[]
  ) {
    const jobDescriptionInstance: JobDescriptionType = {
      domain,
      name,
      descriptions,
      skills,
    };
    super(jobDescriptionInstance);
  }
}

export const NewJobDescriptionFactory = (
  domain: string,
  name: string,
  descriptions: Array<string>,
  skills: Array<string>
): ModelType => {
  return new JobDescriptionModel(name, descriptions, domain, skills);
};

export const JobDescriptionPoleEmploieMapperFactory = (
  jobDetailPoleEmploie: any
): ModelType => {
  const {
    libelle,
    definition,
    condition,
    domaineProfessionnel: { libelle: domain },
    competencesDeBase,
  } = jobDetailPoleEmploie;
  return new JobDescriptionModel(
    libelle,
    [
      definition?.replace(regexLineBreak, "<br>"),
      condition?.replace(regexLineBreak, "<br>"),
    ],
    domain,
    competencesDeBase.map((skill) => skill.libelle)
  );
};

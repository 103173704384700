const grayColor = "#868E96";

const neutralBlackColor = "#0D131B";

const tag1Color = "#D8FFD2";

const tag2Color = "#FBE2D5";

const tag3Color = "#FFECF9";

const contentColor = "#1D222A";

const capeVerdeDarkColor = "#013332";

const capeVerdeClearColor = "#00827f";

const capeVerdeBrightColor = "#017e7c";

const secondTitleMarginTopSize = "15px";

const tagCommonStyle = {
  padding: "6px",
  color: neutralBlackColor,
  borderRadius: "8px",
};

const darkBorderBottomStyle = {
  borderBottom: "1px solid #013332",
  paddingBottom: "10px",
};

const lightBorderBottomStyle = {
  borderBottom: "1px solid #CCD6D6",
  paddingBottom: "10px",
};

const interviewPDFStyles = {
  page: { paddingTop: "14px", paddingBottom: "24px" },
  container: {
    padding: "18px 60px",
    fontSize: "10px",
  },
  // HEADER
  logoSection: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: "20px",
  },
  logo: {
    width: "140px",
  },
  // Synthesis
  synthesis: {
    ...darkBorderBottomStyle,
    marginBottom: "10px",
  },
  synthesisTitle: {
    fontSize: "20px",
    paddingBottom: "10px",
    color: capeVerdeDarkColor,
  },
  informationsSection: {
    ...darkBorderBottomStyle,
    color: grayColor,
    paddingBottom: "10px",
  },
  informationsTitle: {
    color: capeVerdeDarkColor,
    marginBottom: "10px",
    fontWeight: "900",
    fontSize: "16px",
  },
  informationsJob: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: "10px",
  },
  informationsContent: {
    color: contentColor,
  },
  bulletPoint: {
    width: "12px",
    marginRight: "10px",
  },
  bulletPointContent: {
    display: "flex",
    flexDirection: "row",
    marginBottom: "10px",
  },
  toolTips: {
    color: capeVerdeDarkColor,
    display: "flex",
    flexDirection: "row",
    backgroundColor: "#E9ECEF",
    padding: "10px",
    borderRadius: "12px",
    marginTop: "10px",
    marginBottom: "10px",
  },
  toolTipPosition: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  toolTipIcon: {
    width: "22px",
    height: "22px",
    marginRight: "10px",
  },
  toolTipsTitle: {
    marginBottom: "5px",
  },
  adviceText: {
    width: "420px",
    lineHeight: "1.4",
  },
  sectionTitle: {
    color: contentColor,
    marginTop: secondTitleMarginTopSize,
    fontWeight: "900",
    fontSize: "16px",
  },
  contentSection: {
    ...lightBorderBottomStyle,
  },
  contentTextSection: {
    color: grayColor,
    marginTop: secondTitleMarginTopSize,
    marginBottom: "10px",
  },
  lastContentSection: {
    ...darkBorderBottomStyle,
  },
  lastContentSectionTextSection: {
    marginBottom: "10px",
  },
  titlePostSection: {
    color: capeVerdeClearColor,
    fontSize: "12px",
    marginTop: "10px",
    marginBottom: "6px",
  },
  descriptionPostSection: {
    color: capeVerdeDarkColor,
    fontSize: "10px",
    marginTop: "4px",
    marginBottom: "4px",
  },
  profileSkillContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    margin: "4px 0",
  },
  profileSkillTitle: {
    fontSize: "12px",
    width: "70%",
    color: capeVerdeBrightColor,
  },
  profileSkillTag0: {
    ...tagCommonStyle,
    backgroundColor: tag1Color,
  },
  profileSkillTag1: {
    ...tagCommonStyle,
    backgroundColor: tag2Color,
  },
  profileSkillTag2: {
    ...tagCommonStyle,
    backgroundColor: tag3Color,
  },
  noAnswerLine: {
    fontSize: "9px",
    marginBottom: "4px",
    color: capeVerdeDarkColor,
  },
  bulletPointProfile: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  bulletPointContentProfile: {
    display: "flex",
    flexDirection: "row",
    marginBottom: "10px",
    paddingRight: "20px",
  },
};

export default interviewPDFStyles;

import axios from "axios";

import createInterviewDataGA from "config/googleAnalytics/createInterviewDataGA";

import endpoints from "utils/constants/endpoints";
import Errors from "utils/translations/en/errors.json";
import Path from "utils/translations/fr/path.json";

import { NewInterviewFactory } from "domain/interview/interviewModels";

import {
  createNewInterviewData,
  saveCompanyActivitiesInterviewData,
  saveCompanyValuesInterviewData,
  saveEmailInterviewData,
  saveFormInterviewData,
  saveInterviewAdviceData,
  saveInterviewProfileAdviceData,
  saveCompanySimulationInterviewData,
  saveInterviewProgressData,
  saveJobDetailsInterviewData,
  saveJobSoftskillsInterviewData,
  saveJobChallengesInterviewData,
  saveJobBestofInterviewData,
  saveJobDetailPoleEmploieData,
  saveProfileSkillRateInterviewData,
  saveProfileSkillExampleInterviewData,
  setCurrentInterviewLoadingState,
  resetProfileSkillInterviewData,
} from "store/interview/interviewSlice";

import {
  selectCompanyNameFromCurrentInterview,
  selectCurrentInterviewId,
  selectEmailFromCurrentInterview,
  selectInterviewDateFromCurrentInterview,
  selectJobCodeRome,
  selectJobDetailPoleEmploie,
  selectProfileSkillsFromCurrentInterview,
} from "store/interview/interviewSelectors";

import { filterNullAndEmptyValues } from "./interviewFormUtils";
import { checkShowAdviceBySkillRate } from "utils/helpers/profileSkillsHelpers";
import { TrainingTagEnum } from "utils/constants/enums/TrainingTagEnum";
import { subscribeToSendinblue } from "domain/mail/mailControllers";
import SendInBlueSourceEnum from "utils/constants/enums/SendinBlueSourceEnum";
import SendInBlueInterviewAttributeEnum from "utils/constants/enums/SendInBlueInterviewAttributeEnum";

/**
 * Save company, job and date of interview in the starting form of interview
 *
 * @param {Object} values {companyFieldName: string, jobFieldName: string, interviewFieldDate: string}
 * @param {Object} history to redirect to the next page
 * @returns {void}
 */
export function saveStartInterviewFormData(values, history) {
  return async (dispatch, getState) => {
    dispatch(setCurrentInterviewLoadingState(true));
    const interviewId = selectCurrentInterviewId(getState());
    const codeRome = selectJobCodeRome(getState());
    try {
      await axios({
        method: "put",
        url: endpoints.SAVE_START_INTERVIEW_INFO(interviewId),
        headers: {
          "Content-Type": "application/json",
        },
        data: {
          companyFieldName: values.company,
          jobFieldName: values.job,
          interviewFieldDate: values.date,
        },
      });
      dispatch(setCurrentInterviewLoadingState(false));
      createInterviewDataGA({
        interviewDate: values.date,
        interviewEntreprise: values.company,
        interviewPoste: values.job,
      });
      history.push(Path["path.interview.email"]);
      dispatch(saveFormInterviewData(values));
      const {
        data: {
          response: { jobDetail },
        },
      } = await axios({
        method: "get",
        url: endpoints.GET_JOB_DETAIL_FROM_POLE_EMPLOIE(codeRome),
        headers: {
          "Content-Type": "application/json",
        },
      });
      dispatch(saveJobDetailPoleEmploieData(jobDetail));
    } catch (error) {
      dispatch(setCurrentInterviewLoadingState(false));
      console.error(Errors.INTERVIEW.ERROR_UPDATING_INFOS);
    }
  };
}

/**
 * Save email input in the interview training
 *
 * @param {Object} values {email: string}
 * @param {Object} history to redirect to the next page
 * @returns {void}
 */
export function saveEmailInterviewForm(values, history) {
  return async (dispatch, getState) => {
    dispatch(setCurrentInterviewLoadingState(true));
    const interviewId = selectCurrentInterviewId(getState());
    const interviewDate = selectInterviewDateFromCurrentInterview(getState());
    const companyName = selectCompanyNameFromCurrentInterview(getState());

    try {
      await axios({
        method: "put",
        url: endpoints.SAVE_EMAIL_INTERVIEW(interviewId),
        headers: {
          "Content-Type": "application/json",
        },
        data: {
          email: values.email,
        },
      });
      dispatch(setCurrentInterviewLoadingState(false));
      subscribeToSendinblue(SendInBlueSourceEnum.INTERVIEW, values.email, {
        sendInBlueInterviewAttribute:
          SendInBlueInterviewAttributeEnum.DEBUT_1E1J,
        interviewDate,
        companyName,
      });
      history.push(
        Path["path.interview.company.step"].replace(":step", "etape")
      );
      dispatch(saveEmailInterviewData(values.email));
    } catch (error) {
      dispatch(setCurrentInterviewLoadingState(false));
      console.error(Errors.INTERVIEW.ERROR_SAVING_EMAIL);
    }
  };
}

/**
 * Create a new interview training and save in the redux store
 *
 * @returns {void}
 */
export const createNewInterviewTraining = () => {
  return async (dispatch, getState) => {
    dispatch(setCurrentInterviewLoadingState(true));
    const userUID = getState().users?.user?.uid;
    const interviewId = NewInterviewFactory(userUID)?.instance?.id;
    const metaData = {
      platform: navigator?.platform,
      userAgent: navigator?.userAgent,
    };
    try {
      await axios({
        method: "post",
        url: endpoints.CREATE_NEW_INTERVIEW(userUID, interviewId),
        headers: {
          "Content-Type": "application/json",
        },
        data: { metaData },
      });
      dispatch(setCurrentInterviewLoadingState(false));
      dispatch(
        createNewInterviewData({ interviewId, userId: userUID }, metaData)
      );
    } catch (error) {
      dispatch(setCurrentInterviewLoadingState(false));
      console.error(Errors.ERROR_CREATING_INTERVIEW);
    }
  };
};

/**
 * Save company activities input in the interview training
 *
 * @param {Object} values {activites: ArrayOf<string>}
 * @param {Object} history to redirect to the next page
 * @returns {void}
 */
export function saveCompanyActivitiesInterviewForm(values, history) {
  return async (dispatch, getState) => {
    dispatch(setCurrentInterviewLoadingState(true));
    const interviewId = selectCurrentInterviewId(getState());
    const filteredActivities = filterNullAndEmptyValues(values.activities);

    try {
      await axios({
        method: "put",
        url: endpoints.SAVE_INTERVIEW_COMPANY_ACTIVITIES_DATA(interviewId),
        headers: {
          "Content-Type": "application/json",
        },
        data: {
          activities: filteredActivities,
        },
      });
      dispatch(setCurrentInterviewLoadingState(false));
      history.push(Path["path.interview.company.advice1"]);
      dispatch(saveCompanyActivitiesInterviewData(filteredActivities));
    } catch (error) {
      dispatch(setCurrentInterviewLoadingState(false));
      console.error(Errors.INTERVIEW.ERROR_SAVING_ACTIVITIES_COMPANY);
    }
  };
}

/**
 * Save softskills input in the interview training
 *
 * @param {Object} values {softkills: ArrayOf<string>}
 * @param {Object} history to redirect to the next page
 * @returns {void}
 */
export function saveJobSoftSkillsInterviewForm(values, history) {
  return async (dispatch, getState) => {
    dispatch(setCurrentInterviewLoadingState(true));
    const interviewId = selectCurrentInterviewId(getState());
    const filteredSoftskills = filterNullAndEmptyValues(values.softskills);

    try {
      await axios({
        method: "put",
        url: endpoints.SAVE_INTERVIEW_JOB_SOFTSKILLS_DATA(interviewId),
        headers: {
          "Content-Type": "application/json",
        },
        data: {
          softskills: filteredSoftskills,
        },
      });
      dispatch(setCurrentInterviewLoadingState(false));
      history.push(Path["path.interview.job.advice2"]);
      dispatch(saveJobSoftskillsInterviewData(filteredSoftskills));
    } catch (error) {
      dispatch(setCurrentInterviewLoadingState(false));
      console.error(Errors.INTERVIEW.ERROR_SAVING_SOFTSKILLS_JOB);
    }
  };
}

/**
 * Save bestofjob input in the interview training
 *
 * @param {Object} values {bestof: string}
 * @param {Object} history to redirect to the next page
 * @returns {void}
 */
export function saveJobBestofInterviewForm(values, history) {
  return async (dispatch, getState) => {
    dispatch(setCurrentInterviewLoadingState(true));
    const interviewId = selectCurrentInterviewId(getState());
    try {
      await axios({
        method: "put",
        url: endpoints.SAVE_INTERVIEW_JOB_BESTOF_DATA(interviewId),
        headers: {
          "Content-Type": "application/json",
        },
        data: {
          bestof: values.bestof,
        },
      });
      dispatch(setCurrentInterviewLoadingState(false));
      history.push(Path["path.interview.job.question4"]);
      dispatch(saveJobBestofInterviewData(values.bestof));
    } catch (error) {
      dispatch(setCurrentInterviewLoadingState(false));
      console.error(Errors.INTERVIEW.ERROR_SAVING_BESTOF_JOB);
    }
  };
}

/**
 * Save the step of interview progress
 *
 * @param {Object} trainingProgress to track the progress of interview
 * @param {Object} history to redirect to the next page
 * @param {string} path that indicates the next page
 * @returns {void}
 */
export const saveInterviewSteps = (trainingProgress, history, path) => {
  return async (dispatch, getState) => {
    dispatch(setCurrentInterviewLoadingState(true));
    const interviewId = selectCurrentInterviewId(getState());

    try {
      await axios({
        method: "put",
        url: endpoints.SAVE_INTERVIEW_PROGRESS(interviewId),
        headers: {
          "Content-Type": "application/json",
        },
        data: {
          trainingProgress,
        },
      });
      dispatch(setCurrentInterviewLoadingState(false));
      if (trainingProgress?.trainingTag === TrainingTagEnum.InterviewProfile) {
        dispatch(resetProfileSkillInterviewData());
      }
      dispatch(saveInterviewProgressData(trainingProgress));
      history.push(path);
    } catch (error) {
      dispatch(setCurrentInterviewLoadingState(false));
      console.error(Errors.ERROR_SAVING_INTERVIEW_PROGRESS);
    }
  };
};

/**
 * Save the company advice data in the interview training
 *
 * @param {Object} trainingProgress to track the progress of interview
 * @param {string} advice text displayed in the advice page
 * @param {Object} history to redirect to next page
 * @param {string} path that indicates the next page
 * @returns {void}
 */
export const saveInterviewCompanyAdvice = (
  trainingProgress,
  advice,
  history,
  path
) => {
  return async (dispatch, getState) => {
    dispatch(setCurrentInterviewLoadingState(true));
    const interviewId = selectCurrentInterviewId(getState());
    try {
      await axios({
        method: "put",
        url: endpoints.SAVE_INTERVIEW_PROGRESS(interviewId),
        headers: {
          "Content-Type": "application/json",
        },
        data: {
          trainingProgress,
          advices: {
            companyAdvices: [
              {
                id: trainingProgress.lastProgressNumber,
                adviceText: advice,
              },
            ],
          },
        },
      });
      dispatch(setCurrentInterviewLoadingState(false));

      history.push(path);

      dispatch(
        saveInterviewAdviceData({
          trainingProgress,
          advice: {
            id: trainingProgress.lastProgressNumber,
            adviceText: advice,
          },
        })
      );
    } catch (error) {
      dispatch(setCurrentInterviewLoadingState(false));
      console.error(Errors.ERROR_SAVING_INTERVIEW_PROGRESS);
    }
  };
};

/**
 * Save the job advice data in the interview training
 *
 * @param {Object} trainingProgress to track the progress of interview
 * @param {string} advice text displayed in the advice page
 * @param {Object} history to redirect to next page
 * @param {string} path that indicates the next page
 * @returns {void}
 */
export const saveInterviewJobAdvice = (
  trainingProgress,
  advice,
  history,
  path
) => {
  return async (dispatch, getState) => {
    dispatch(setCurrentInterviewLoadingState(true));
    const interviewId = selectCurrentInterviewId(getState());
    try {
      await axios({
        method: "put",
        url: endpoints.SAVE_INTERVIEW_PROGRESS(interviewId),
        headers: {
          "Content-Type": "application/json",
        },
        data: {
          trainingProgress,
          advices: {
            jobAdvices: [
              {
                id: trainingProgress.lastProgressNumber,
                adviceText: advice,
              },
            ],
          },
        },
      });
      dispatch(setCurrentInterviewLoadingState(false));

      history.push(path);

      dispatch(
        saveInterviewAdviceData({
          trainingProgress,
          advice: {
            id: trainingProgress.lastProgressNumber,
            adviceText: advice,
          },
        })
      );
    } catch (error) {
      dispatch(setCurrentInterviewLoadingState(false));
      console.error(Errors.ERROR_SAVING_INTERVIEW_PROGRESS);
    }
  };
};

/**
 * Save the profile advice data in the interview training
 *
 * @param {Object} trainingProgress to track the progress of interview
 * @param {string} advice text displayed in the advice page
 * @param {Object} history to redirect to next page
 * @param {string} path that indicates the next page
 * @returns {void}
 */
export const saveInterviewProfileAdvice = (
  trainingProgress,
  advice,
  history,
  path
) => {
  return async (dispatch, getState) => {
    dispatch(setCurrentInterviewLoadingState(true));
    const interviewId = selectCurrentInterviewId(getState());
    try {
      await axios({
        method: "put",
        url: endpoints.SAVE_INTERVIEW_PROGRESS(interviewId),
        headers: {
          "Content-Type": "application/json",
        },
        data: {
          trainingProgress,
          advices: {
            profileAdvices: [
              {
                id: trainingProgress.lastProgressNumber,
                adviceText: advice,
              },
            ],
          },
        },
      });
      dispatch(setCurrentInterviewLoadingState(false));

      history.push(path);

      dispatch(
        saveInterviewProfileAdviceData({
          trainingProgress,
          advice: {
            id: trainingProgress.lastProgressNumber,
            adviceText: advice,
          },
        })
      );
    } catch (error) {
      dispatch(setCurrentInterviewLoadingState(false));
      console.error(Errors.ERROR_SAVING_INTERVIEW_PROGRESS);
    }
  };
};

/**
 * Save company values input in the interview training
 *
 * @param {Object} values {values: ArrayOf<string>}
 * @param {Object} history to redirect to the next page
 * @returns {void}
 */
export function saveCompanyValuesInterviewForm(values, history) {
  return async (dispatch, getState) => {
    dispatch(setCurrentInterviewLoadingState(true));
    const interviewId = selectCurrentInterviewId(getState());
    const filteredValues = filterNullAndEmptyValues(values.values);

    try {
      await axios({
        method: "put",
        url: endpoints.SAVE_INTERVIEW_COMPANY_VALUES_DATA(interviewId),
        headers: {
          "Content-Type": "application/json",
        },
        data: {
          values: filteredValues,
        },
      });
      dispatch(setCurrentInterviewLoadingState(false));
      history.push(Path["path.interview.company.advice2"]);
      dispatch(saveCompanyValuesInterviewData(filteredValues));
    } catch (error) {
      dispatch(setCurrentInterviewLoadingState(false));
      console.error(Errors.INTERVIEW.ERROR_SAVING_VALUES_COMPANY);
    }
  };
}

/**
 * Save company simulation input in the interview training
 *
 * @param {Object} values {simulation: string}
 * @param {Object} history to redirect to the next page
 * @returns {void}
 */
export function saveCompanySimulationInterviewForm(values, history) {
  return async (dispatch, getState) => {
    dispatch(setCurrentInterviewLoadingState(true));
    const interviewId = selectCurrentInterviewId(getState());
    try {
      await axios({
        method: "put",
        url: endpoints.SAVE_INTERVIEW_COMPANY_SIMULATION_DATA(interviewId),
        headers: {
          "Content-Type": "application/json",
        },
        data: {
          simulation: values.simulation,
        },
      });
      dispatch(setCurrentInterviewLoadingState(false));
      history.push(Path["path.interview.company.advice3"]);
      dispatch(saveCompanySimulationInterviewData(values.simulation));
    } catch (error) {
      dispatch(setCurrentInterviewLoadingState(false));
      console.error(Errors.INTERVIEW.ERROR_SAVING_SIMULATION_COMPANY);
    }
  };
}

/**
 * Save job challenges input in the interview training
 *
 * @param {Object} values {challenge: string}
 * @param {Object} history to redirect to the next page
 * @returns {void}
 */
export function saveJobChallengesInterviewForm(values, history) {
  return async (dispatch, getState) => {
    dispatch(setCurrentInterviewLoadingState(true));
    const interviewId = selectCurrentInterviewId(getState());
    try {
      await axios({
        method: "put",
        url: endpoints.SAVE_INTERVIEW_JOB_CHALLENGES_DATA(interviewId),
        headers: {
          "Content-Type": "application/json",
        },
        data: {
          challenges: values.challenges,
        },
      });
      dispatch(setCurrentInterviewLoadingState(false));
      history.push(Path["path.interview.job.advice3"]);
      dispatch(saveJobChallengesInterviewData(values.challenges));
    } catch (error) {
      dispatch(setCurrentInterviewLoadingState(false));
      console.error(Errors.INTERVIEW.ERROR_SAVING_CHALLENGES_JOB);
    }
  };
}

/**
 * Save the interview job Description - P1
 *
 * @param {Object} history to redirect to the next page
 * @returns {void}
 */
export const saveInterviewJobDetails = (history) => {
  return async (dispatch, getState) => {
    dispatch(setCurrentInterviewLoadingState(true));
    const interviewId = selectCurrentInterviewId(getState());
    const jobDetail = selectJobDetailPoleEmploie(getState());

    try {
      await axios({
        method: "put",
        url: endpoints.SAVE_INTERVIEW_JOB_DETAIL(interviewId),
        headers: {
          "Content-Type": "application/json",
        },
        data: {
          jobDetail,
        },
      });
      dispatch(setCurrentInterviewLoadingState(false));
      dispatch(saveJobDetailsInterviewData(jobDetail));
      history.push(Path["path.interview.job.advice1"]);
    } catch (error) {
      dispatch(setCurrentInterviewLoadingState(false));
      console.error(Errors.ERROR_SAVING_INTERVIEW_PROGRESS);
    }
  };
};

/**
 * Save profile skill rate value in the interview training
 *
 * @param {Object} values {rate: number, id: number, name: string}
 * @param {Object} history to redirect to the next page
 * @return {void}
 */
export function saveProfileSkillRateInterviewForm(values, history) {
  return async (dispatch, getState) => {
    dispatch(setCurrentInterviewLoadingState(true));
    const interviewId = selectCurrentInterviewId(getState());
    const profileSkills = selectProfileSkillsFromCurrentInterview(getState());
    const showAdvice = checkShowAdviceBySkillRate(
      values.rate,
      values.id,
      profileSkills
    );

    try {
      await axios({
        method: "put",
        url: endpoints.SAVE_INTERVIEW_PROFILE_SKILLRATE_DATA(interviewId),
        headers: {
          "Content-Type": "application/json",
        },
        data: { name: values.name, rate: values.rate, id: values.id },
      });
      dispatch(setCurrentInterviewLoadingState(false));
      // Only when rate equals to 0 (ie skill is "") and when we haven't
      if (values.rate === 0 && showAdvice) {
        history.push(
          Path["path.interview.profile.advice"].replace(
            ":skillNumber",
            values.id
          )
        );
      } else if (values.rate === 0 && !showAdvice) {
        history.push(
          Path["path.interview.profile.question1"].replace(
            ":skillNumber",
            Number(values.id) + 1
          )
        );
      } else if (values.rate > 0) {
        history.push(
          Path["path.interview.profile.question2"].replace(
            ":skillNumber",
            values.id
          )
        );
      }

      dispatch(
        saveProfileSkillRateInterviewData({
          name: values.name,
          rate: values.rate,
          id: values.id,
        })
      );
    } catch (error) {
      dispatch(setCurrentInterviewLoadingState(false));
      console.error(Errors.INTERVIEW.ERROR_SAVING_SKILLRATE_PROFILE, error);
    }
  };
}

/**
 * Save profile skill example value in the interview training
 *
 * @param {Object} values {id: number, example: string, name: string}
 * @param {Object} history to redirect to the next page
 * @return {void}
 */
export function saveProfileSkillExampleInterviewForm(values, history) {
  return async (dispatch, getState) => {
    dispatch(setCurrentInterviewLoadingState(true));
    const interviewId = selectCurrentInterviewId(getState());

    try {
      await axios({
        method: "put",

        url: endpoints.SAVE_INTERVIEW_PROFILE_SKILL_EXAMPLE_DATA(interviewId),
        headers: {
          "Content-Type": "application/json",
        },
        data: { id: values.id, name: values.name, example: values.example },
      });
      dispatch(setCurrentInterviewLoadingState(false));

      const nextSkillId = +values.id + 1;

      if (+values.id === 3) {
        history.push(Path["path.interview.finish"]);
      } else {
        history.push(
          Path["path.interview.profile.question1"].replace(
            ":skillNumber",
            nextSkillId
          )
        );
      }
      dispatch(
        saveProfileSkillExampleInterviewData({
          name: values.name,
          example: values.example,
          id: values.id,
        })
      );
    } catch (error) {
      dispatch(setCurrentInterviewLoadingState(false));
      console.error(Errors.INTERVIEW.ERROR_SAVING_SKILL_EXAMPLE_PROFILE);
    }
  };
}

/**
 * Save the interview progress
 *
 * @param {Object} values { trainingProgress, redirectTo }
 * @param {Object} history to redirect to the next page
 * @returns {void}
 */
export const saveInterviewProgress = (values, history) => {
  return async (dispatch, getState) => {
    const interviewId = selectCurrentInterviewId(getState());

    try {
      await axios({
        method: "put",
        url: endpoints.SAVE_INTERVIEW_PROGRESS(interviewId),
        headers: {
          "Content-Type": "application/json",
        },
        data: {
          trainingProgress: values.trainingProgress,
        },
      });
      dispatch(saveInterviewProgressData(values.trainingProgress));
      if (
        values?.trainingProgress?.trainingTag === TrainingTagEnum.InterviewEnd
      ) {
        const email = selectEmailFromCurrentInterview(getState());
        if (email) {
          subscribeToSendinblue(SendInBlueSourceEnum.INTERVIEW, email, {
            sendInBlueInterviewAttribute:
              SendInBlueInterviewAttributeEnum.FIN_1E1J,
          });
        }
      }
      history.push(values.redirectTo);
    } catch (error) {
      console.error(Errors.ERROR_SAVING_INTERVIEW_PROGRESS);
    }
  };
};

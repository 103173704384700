import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Carousel } from "react-responsive-carousel";

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import TestimonyCarouselStyles from "./TestimoniesCarousel.module.scss";
import { v4 as uuidv4 } from "uuid";

import TestimonyCard from "../TestimonyCard";
import Icon from "../Icon/Icon";
import { Box, IconButton, useTheme } from "@mui/material";

const TestimoniesCarousel = ({ size }) => {
  const { t } = useTranslation("testimony");
  const testimoniesData = t("data", { returnObjects: true });
  const theme = useTheme();

  const newArrayTestimonies = useMemo(() => {
    const SlideNumberPerSize = { isDesktop: 3, isTablet: 2, isMobile: 1 };
    let arrayTestimonies = [];
    for (
      let index = 0;
      index < testimoniesData.length;
      index = index + SlideNumberPerSize[size]
    ) {
      arrayTestimonies.push(
        testimoniesData.slice(index, index + SlideNumberPerSize[size])
      );
    }
    return arrayTestimonies;
  }, [testimoniesData, size]);

  return (
    <div className={TestimonyCarouselStyles.carousel}>
      <Carousel
        showArrows={true}
        infiniteLoop={true}
        width={1205}
        showStatus={false}
        preventMovementUntilSwipeScrollTolerance={true}
        showThumbs={false}
        showIndicators={false}
        renderArrowPrev={(clickHandler, hasPrev) => {
          return (
            <Box
              onClick={clickHandler}
              sx={{
                position: "absolute",
                top: "20px",
                left: 0,
                width: "20px",
                cursor: "pointer",
                zIndex: 30,
                [theme.breakpoints.down("xl")]: {
                  top: "199px",
                  left: "48%",
                },
              }}
            >
              <IconButton
                sx={{
                  "&:hover": {
                    bgcolor: theme.palette.orange.complementary,
                  },
                }}
              >
                <Icon
                  iconSize="20px"
                  iconName="arrowLeftShort"
                  iconColor={theme.palette.orange.main}
                />
              </IconButton>
            </Box>
          );
        }}
        renderArrowNext={(clickHandler, hasNext) => {
          return (
            <Box
              onClick={clickHandler}
              sx={{
                position: "absolute",
                top: "20px",
                right: 20,
                width: "20px",
                cursor: "pointer",
                zIndex: 30,
                [theme.breakpoints.down("xl")]: {
                  top: "200px",
                  left: "51%",
                },
              }}
            >
              <IconButton
                sx={{
                  "&:hover": {
                    bgcolor: theme.palette.orange.complementary,
                  },
                }}
              >
                <Icon
                  iconSize="20px"
                  iconName="arrowRightShort"
                  iconColor={theme.palette.orange.main}
                />
              </IconButton>
            </Box>
          );
        }}
      >
        {newArrayTestimonies.map((testimonies) => (
          <div className={TestimonyCarouselStyles.carouselCards} key={uuidv4()}>
            <TestimonyCard testimonies={testimonies} />
          </div>
        ))}
      </Carousel>
    </div>
  );
};

export default TestimoniesCarousel;

import React from "react";

import { IconsEnum } from "utils/constants/enums/IconsEnum";

const Icon = ({ iconName, iconColor, iconSize = "16px", svgProps = {} }) => {
  return (
    <svg
      {...svgProps}
      width={iconSize}
      height={iconSize}
      viewBox={IconsEnum[iconName]?.viewBox}
      xmlns="http://www.w3.org/2000/svg"
    >
      {IconsEnum[iconName]?.pathD && (
        <path d={IconsEnum[iconName].pathD} fill={iconColor} />
      )}
      {IconsEnum[iconName]?.pathD2 && (
        <path d={IconsEnum[iconName].pathD2} fill={iconColor} />
      )}
    </svg>
  );
};

export default Icon;

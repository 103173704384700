import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import Button from "components/common/Button(ToBeUpdated)";
import SimpleInput from "components/common/SimpleInput";
import SimpleCombobox from "components/common/SimpleComboBox";

import { isEmailValidRFC822 } from "utils/helpers/usersHelper";
import {
  generateHtmlWFeedbackMailDoc,
  getSubjectMailWithVariables,
  generateHtmlWAutomaticResponseContactDoc,
} from "utils/helpers/mailHelpers";
import SubjectMailEnum from "utils/constants/enums/SubjectMailEnum";

import {
  saveContactName,
  saveContactEmail,
  saveContactQuestion,
  saveContactReason,
} from "store/contact/contactSlice";
import { selectContact } from "store/contact/contactSelectors";
import { selectUser } from "store/users/usersSelectors";

import OK from "assets/images/emojis/ok.svg";
import Interweave from "interweave";
import BgPopupConfirmation from "assets/images/bgPopupConfirmation.svg";
import { updateUserContact } from "domain/contact/contactControllers";
import { sendMail } from "domain/mail/mailControllers";
import { MailFactory, MessageFactory } from "domain/mail/mailModels";

import { SupportMail } from "utils/constants/mailConstants";

import {
  Box,
  Container,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";

import useQuery from "hooks/useQuery";

const PAGE = "contact";
const TYPE_NAME = "name";
const TYPE_EMAIL = "email";
const TYPE_QUESTION = "question";
const TYPE_REASON = "reason";
const WIDTH_INPUT = 80;
const RESET_INPUT_TEXT = "";

const ContactPage = () => {
  const theme = useTheme();
  const isScreenDownSM = useMediaQuery(theme.breakpoints.down("sm"));

  const { t } = useTranslation(["contactPage", "global"]);
  const REASONS_LIST = t("contact.list.reasons", {
    returnObjects: true,
  });
  const dispatch = useDispatch();
  const contact = useSelector(selectContact);
  const user = useSelector(selectUser);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [showMessage, setShowMessage] = useState(false);
  const RESET_INPUT_COMBO = REASONS_LIST[0];

  useEffect(() => {
    const isButtonDisabledTemp = !(
      contact?.email?.trim() !== "" &&
      isEmailValidRFC822(contact.email.trim()) &&
      contact?.reason !== REASONS_LIST[0] &&
      contact?.question?.trim() !== ""
    );
    setIsButtonDisabled(isButtonDisabledTemp);
  }, [contact, REASONS_LIST]);

  const query = useQuery();
  let reason = "";

  if (query.get("reason")) {
    reason = query.get("reason");
  }

  const handleChange = useCallback(
    (evt, type) => {
      switch (type) {
        case TYPE_NAME:
          dispatch(saveContactName(evt.target.value));
          break;
        case TYPE_EMAIL:
          dispatch(saveContactEmail(evt.target.value));
          break;
        case TYPE_QUESTION:
          dispatch(saveContactQuestion(evt.target.value));
          break;
        case TYPE_REASON:
          dispatch(saveContactReason(evt.target.value));
          break;
        default:
          break;
      }
    },
    [dispatch]
  );

  const handleClickSendSupportMail = useCallback(async () => {
    setShowMessage(true);
    setIsButtonDisabled(true);
    dispatch(saveContactName(RESET_INPUT_TEXT));
    dispatch(saveContactEmail(RESET_INPUT_TEXT));
    dispatch(saveContactQuestion(RESET_INPUT_TEXT));
    dispatch(saveContactReason(RESET_INPUT_COMBO));
    updateUserContact(user, contact);
    await sendMail(
      user.id,
      MailFactory({
        to: SupportMail,
        message: MessageFactory({
          subject: getSubjectMailWithVariables(
            SubjectMailEnum.FEEDBACK_CONTACT,
            [contact.name, contact.reason]
          ),
          html: generateHtmlWFeedbackMailDoc(
            contact.name,
            contact.email,
            contact.reason,
            contact.question
          ),
        }),
      })
    );
    await sendMail(
      user.id,
      MailFactory({
        from: SupportMail,
        to: contact.email,
        message: MessageFactory({
          subject: SubjectMailEnum.AUTOMATIC_RESPONSE_CONTACT,
          html: generateHtmlWAutomaticResponseContactDoc(contact.name),
        }),
      })
    );
  }, [
    setShowMessage,
    setIsButtonDisabled,
    dispatch,
    user,
    contact,
    RESET_INPUT_COMBO,
  ]);

  return (
    <Container maxwidth="xl">
      <Typography
        textAlign="center"
        px={isScreenDownSM ? 5 : 0}
        my={20}
        sx={{
          ...theme.typography.HeadingStyles,
          fontSize: isScreenDownSM
            ? theme.typography.FontSizes.xl5
            : theme.typography.FontSizes.xl8,
        }}
      >
        {t("contact.text.title")}
      </Typography>
      {!showMessage && (
        <Typography
          mb={5}
          px={isScreenDownSM ? 5 : 0}
          textAlign="center"
          fontSize={theme.typography.ParagraphStyles.sm}
          sx={{ ...theme.typography.ParagraphStyles }}
        >
          {t("contact.text.description")}
        </Typography>
      )}
      {showMessage ? (
        <Box
          mb={20}
          style={{
            backgroundImage: `url(${BgPopupConfirmation})`,
            backgroundPosition: "center",
            backgroundSize: "80%",
          }}
        >
          <Box textAlign={"center"} mb={5}>
            <img
              loading="lazy"
              alt="ok"
              src={OK}
              style={{ height: 66, width: "auto", zIndex: 1050 }}
            />
          </Box>
          <Box>
            <Typography
              mb={5}
              textAlign="center"
              fontSize={theme.typography.ParagraphStyles.sm}
              sx={{ ...theme.typography.ParagraphStyles }}
            >
              <Interweave content={t("contact.message.sent")} />
            </Typography>
          </Box>
        </Box>
      ) : (
        <>
          <Box>
            <SimpleInput
              key={PAGE + TYPE_NAME}
              content={TYPE_NAME}
              isTextArea={false}
              placeholder={t("contact.input.placeholder.name")}
              width={WIDTH_INPUT}
              onChange={(evt) => handleChange(evt, TYPE_NAME)}
            />
            <SimpleInput
              key={PAGE + TYPE_EMAIL}
              content={TYPE_EMAIL}
              isTextArea={false}
              placeholder={t("contact.input.placeholder.email")}
              width={WIDTH_INPUT}
              onChange={(evt) => handleChange(evt, TYPE_EMAIL)}
            />
            <SimpleCombobox
              key={PAGE + TYPE_REASON}
              data={REASONS_LIST}
              width={WIDTH_INPUT}
              onChange={(evt) => handleChange(evt, TYPE_REASON)}
              defaultValue={reason}
            />
            <SimpleInput
              key={PAGE + TYPE_QUESTION}
              content="default"
              isTextArea={true}
              placeholder={t("contact.input.placeholder.question")}
              width={WIDTH_INPUT}
              onChange={(evt) => handleChange(evt, TYPE_QUESTION)}
            />
          </Box>
          <Box mb={20}>
            <Button
              onClick={handleClickSendSupportMail}
              disabled={showMessage || isButtonDisabled}
              text={t("contact.button.text")}
              width={WIDTH_INPUT}
            />
          </Box>
        </>
      )}
    </Container>
  );
};

export default ContactPage;

import React from "react";
import { useTranslation } from "react-i18next";
import Interweave from "interweave";
import { useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";

import {
  Button,
  Container,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";

import { selectUserEmail, selectUserUID } from "store/users/usersSelectors";

import Icon from "components/common/Icon/Icon";

import clickSuggestionSolutionDataGA from "config/googleAnalytics/clickSuggestionSolutionDataGA";
import { updateUserAnalyticsSolutions } from "domain/user/userControllers";

const EventsSection = ({ events }) => {
  const userEmail = useSelector(selectUserEmail);
  const userId = useSelector(selectUserUID);
  const theme = useTheme();
  const isScreenSmall = useMediaQuery(theme.breakpoints.down("sm"));
  const { t } = useTranslation(["solutions"]);

  return (
    <Container maxWidth="xl">
      <Grid
        container
        spacing={2}
        mb={isScreenSmall ? 28 : 40}
        flexDirection={isScreenSmall && "column"}
        alignItems={isScreenSmall && "center"}
      >
        {events?.map((event) => {
          const { link, title, srcLogo, intro } = event;
          return (
            <React.Fragment key={uuidv4()}>
              <Grid
                item
                sm={12}
                md={5}
                mb={isScreenSmall && 4}
                display="flex"
                justifyContent="center"
              >
                <img
                  src={srcLogo}
                  loading="lazy"
                  alt={title}
                  style={{ width: "100%" }}
                />
              </Grid>
              <Grid
                item
                sm={12}
                md={7}
                display="flex"
                flexDirection="column"
                justifyContent="space-evenly"
                alignItems={isScreenSmall && "center"}
              >
                <Typography
                  textAlign={isScreenSmall && "center"}
                  mb={isScreenSmall && 4}
                  sx={{
                    ...theme.typography.HeadingStyles,
                    fontSize: isScreenSmall
                      ? theme.typography.FontSizes.xl
                      : theme.typography.FontSizes.xl6,
                  }}
                >
                  {intro}
                </Typography>
                <a
                  onClick={() => {
                    clickSuggestionSolutionDataGA({
                      solutionNom: title,
                      mailLaisse: Boolean(userEmail),
                    });
                    updateUserAnalyticsSolutions(
                      userId,
                      title,
                      false,
                      Boolean(userEmail)
                    );
                  }}
                  href={link}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Button
                    variant="outlined"
                    color="black"
                    size="large"
                    startIcon={
                      <Icon iconName="arrowRightShort" iconSize={16} />
                    }
                    sx={{ width: isScreenSmall ? "100%" : "20%" }}
                  >
                    <Interweave content={t("publicServicesEventsCTA")} />
                  </Button>
                </a>
              </Grid>
            </React.Fragment>
          );
        })}
      </Grid>
    </Container>
  );
};

export default EventsSection;

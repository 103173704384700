import { withConnectedFormik } from "../../../hooks/withConnectedFormik";
import InterviewJobBestofForm from "./InterviewJobBestofForm";
import { InterviewFormNameEnum } from "../../../utils/constants/enums/InterviewFormNameEnum";
import { saveJobBestofInterviewForm } from "../../../domain/interview/interviewControllers";
import {
  InterviewInitialValues,
  ValidationSchemas,
} from "../../../domain/interview/interviewFormUtils";

export default withConnectedFormik(
  InterviewFormNameEnum.INTERVIEW_JOB_BESTOF,
  saveJobBestofInterviewForm,
  InterviewInitialValues.INTERVIEW_JOB_BESTOF,
  ValidationSchemas.INTERVIEW_JOB_BESTOF
)(InterviewJobBestofForm);

import { useHistory, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { React, useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import {
  selectJobDetailPoleEmploie,
  selectProfileSkillsFromCurrentInterview,
} from "../../../store/interview/interviewSelectors";

import InterviewSkillExamplePageStyles from "./InterviewSkillExamplePage.module.scss";
import clsx from "clsx";

import { useMediaQueries } from "../../../hooks/useMediaQueries";

import SingleInputField from "components/common/SingleInputField";
import TemplateFormInterview from "components/common/TemplateFormInterview";
import { MapLastProgressNumberToPathnameEnum } from "utils/constants/enums/MapLastProgressNumberToPathnameEnum";
import { LastProgressNumberEnum } from "utils/constants/enums/LastProgressNumberEnum";
import { usePageStepCount } from "hooks/usePageStepCount";

const InterviewSkillExamplePage = ({
  setFieldValue,
  values,
  initialValues,
}) => {
  const { skills } = useSelector(selectJobDetailPoleEmploie);
  const profileSkills = useSelector(selectProfileSkillsFromCurrentInterview);
  const { skillNumber } = useParams();
  // the hook pageStepCount, will check if we are in "competences" step
  // and accept the skill number as props to display step counter.
  const pageStepCount = usePageStepCount(skillNumber);
  const { t } = useTranslation("interview");
  const { push } = useHistory();
  const { isMobile, isTablet } = useMediaQueries();

  const {
    questionName_skillRate_1,
    questionName_skillRate_2,
    placeHolders,
    toolTipTextContent,
    toolTipTextTitle,
    buttonLabelName,
  } = t("profileSkillExample", {
    returnObjects: true,
    skill: skills[skillNumber - 1],
    class: InterviewSkillExamplePageStyles.label,
  });

  const questionName =
    profileSkills?.[skillNumber - 1]?.rate === 1
      ? questionName_skillRate_1
      : questionName_skillRate_2;

  useEffect(
    () => {
      setFieldValue("id", skillNumber);
      setFieldValue("name", skills[skillNumber - 1]);
      const foundedSkill = initialValues?.exampleList?.find(
        (skillValue) => skillValue.id === skillNumber
      );
      if (foundedSkill?.example) {
        setFieldValue("example", foundedSkill.example);
      } else {
        setFieldValue("example", "");
      }
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [initialValues, skillNumber]
  );

  const defaultValue = useMemo(
    () =>
      (skillNumber &&
        initialValues?.exampleList?.find(
          (skillValue) => skillValue.id === skillNumber
        )?.example) ||
      "",
    [skillNumber, initialValues]
  );

  //Redirection to the finish page when the skill number is not included in the Profile Section of the map of last progress number to path
  if (
    !skills?.[skillNumber - 1] ||
    !MapLastProgressNumberToPathnameEnum[
      LastProgressNumberEnum.InterviewProfile.Q2[skillNumber]
    ]
  )
    push(
      MapLastProgressNumberToPathnameEnum[
        LastProgressNumberEnum.InterviewEnd[1]
      ].previousPage
    );

  return (
    <TemplateFormInterview
      pageStepCount={pageStepCount}
      questionName={questionName}
      toolTipTextContent={pageStepCount === "1" ? toolTipTextContent : ""}
      toolTipTextTitle={pageStepCount === "1" ? toolTipTextTitle : ""}
      buttonLabelName={buttonLabelName}
    >
      <div className={InterviewSkillExamplePageStyles.mainContainer}>
        <div className={InterviewSkillExamplePageStyles.label}>
          {values.name}
        </div>
        <div
          className={clsx({
            [InterviewSkillExamplePageStyles.inputContainer]: true,
            [InterviewSkillExamplePageStyles.inputContainerS]: isMobile,
            [InterviewSkillExamplePageStyles.inputContainerM]: isTablet,
          })}
        >
          <SingleInputField
            placeHolder={placeHolders}
            name="example"
            maxLength={400}
            defaultValue={defaultValue}
          />
        </div>
      </div>
    </TemplateFormInterview>
  );
};

export default InterviewSkillExamplePage;

import React from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import clsx from "clsx";

import { useMediaQueries } from "hooks/useMediaQueries";
import InterviewJobChallengesFormStyles from "./InterviewJobChallengesForm.module.scss";

import { selectCompanyNameFromCurrentInterview } from "store/interview/interviewSelectors";

import TemplateFormInterview from "components/common/TemplateFormInterview";
import SingleInputField from "components/common/SingleInputField";

const InterviewJobChallengesForm = () => {
  const { t } = useTranslation("interview");
  const { isMobile } = useMediaQueries();
  const {
    questionName,
    toolTipTextTitle,
    toolTipTextContent,
    placeHolder,
    buttonLabelName,
  } = t("jobChallenges", {
    returnObjects: true,
    job: useSelector(selectCompanyNameFromCurrentInterview),
  });

  return (
    <TemplateFormInterview
      questionName={questionName}
      toolTipTextTitle={toolTipTextTitle}
      toolTipTextContent={toolTipTextContent}
      buttonLabelName={buttonLabelName}
    >
      <div
        className={clsx({
          [InterviewJobChallengesFormStyles.multiInputListL]: true,
          [InterviewJobChallengesFormStyles.multiInputListS]: isMobile,
        })}
      >
        <SingleInputField
          name="challenges"
          maxLength={400}
          placeHolder={placeHolder}
        />
      </div>
    </TemplateFormInterview>
  );
};

export default InterviewJobChallengesForm;

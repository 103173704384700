import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

export const usePageStepCount = (skillNumber) => {
  const history = useHistory();
  const [stepPageCount, setPageStepCount] = useState("");
  useEffect(() => {
    const pathnameArray = history.location.pathname.split("/");
    if (
      pathnameArray.includes("note-competence") ||
      pathnameArray.includes("exemple-competence")
    ) {
      setPageStepCount(skillNumber);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history.location.pathname]);

  return stepPageCount;
};

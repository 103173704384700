import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import Interweave from "interweave";

import {
  Box,
  Container,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";

import PillCardWithCTA from "components/common/PillCardWithCTA";
import clicRDVDataGA from "config/googleAnalytics/clicRDVDataGA";
import SourceClickRDVEnum from "utils/constants/enums/SourceClickRDVEnum";

const CalendlyHPSection = () => {
  const theme = useTheme();

  const isScreenDownSM = useMediaQuery(theme.breakpoints.down("sm"));

  const { t } = useTranslation("solutions");

  const {
    calendlyText,
    calendlyButtonText,
    calendlyTitle,
    imgPillAlt,
    pillLarge,
    pillSmall,
  } = t("calendly", {
    returnObjects: true,
  });

  const handleOnClickCalendly = useCallback(() => {
    clicRDVDataGA(SourceClickRDVEnum.HOMEPAGE_FROM_CARD);
    window.open(t("path:path.calendly"), "_self").focus();
  }, [t]);

  const { containerPX } = theme.variables;

  return (
    <section name="calendly-hp">
      <Container
        sx={{
          px: containerPX,
        }}
        maxWidth="xl"
      >
        <PillCardWithCTA
          bgcolor={theme.pillCardWithCTA.calendlyHPSection.bgcolor}
          pillLarge={pillLarge}
          pillSmall={pillSmall}
          pillAlt={imgPillAlt}
          title={calendlyTitle}
          buttonText={calendlyButtonText}
          containerStyle={{
            marginBottom: isScreenDownSM ? "122px" : "260px",
          }}
          iconName="videoFill"
          handleOnClickCalendly={handleOnClickCalendly}
          leftGridStyle={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <Box mt={isScreenDownSM ? 8 : 6} mb={isScreenDownSM ? 7 : 6}>
            <Typography
              variant="paragraph"
              sx={{
                fontSize: isScreenDownSM ? "14px" : "18px",
              }}
            >
              <Interweave content={calendlyText} />
            </Typography>
          </Box>
        </PillCardWithCTA>
      </Container>
    </section>
  );
};

export default CalendlyHPSection;

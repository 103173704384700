import React from "react";
import { CircularProgress } from "@mui/material";

import CircularLoaderPageStyles from "./CircularLoaderPage.module.scss";

const CircularLoaderPage = () => (
  <div className={CircularLoaderPageStyles.container}>
    <CircularProgress style={{ color: "#ea6625" }} size={80} />
  </div>
);

export default CircularLoaderPage;

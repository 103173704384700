import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import clsx from "clsx";

import { useMediaQueries } from "hooks/useMediaQueries";
import InterviewCompanyActivitiesFormStyles from "./InterviewCompanyActivitiesForm.module.scss";

import { selectCompanyNameFromCurrentInterview } from "store/interview/interviewSelectors";

import TemplateFormInterview from "components/common/TemplateFormInterview";
import MultiInputField from "components/common/MultiInputField";

const InterviewCompanyActivitiesForm = ({ values }) => {
  const { t } = useTranslation("interview");
  const { isMobile } = useMediaQueries();
  const {
    questionName,
    toolTipTextTitle,
    toolTipTextContent,
    placeHolders,
    textRequired,
    buttonLabelName,
  } = t("companyActivites", {
    returnObjects: true,
    company: useSelector(selectCompanyNameFromCurrentInterview),
  });

  const isButtonDisabled = useMemo(() => {
    return values.activities?.join("") === "";
  }, [values.activities]);

  return (
    <TemplateFormInterview
      questionName={questionName}
      toolTipTextTitle={toolTipTextTitle}
      toolTipTextContent={toolTipTextContent}
      isButtonDisabled={isButtonDisabled}
      buttonLabelName={buttonLabelName}
    >
      <div
        className={clsx({
          [InterviewCompanyActivitiesFormStyles.multiInputListL]: true,
          [InterviewCompanyActivitiesFormStyles.multiInputListS]: isMobile,
        })}
      >
        <MultiInputField
          name="activities"
          number={5}
          maxLength={100}
          placeHolders={placeHolders}
        />
        <div className={InterviewCompanyActivitiesFormStyles.textRequired}>
          {textRequired}
        </div>
      </div>
    </TemplateFormInterview>
  );
};

export default InterviewCompanyActivitiesForm;

const DocumentTitleDescriptionEnum = {
  HOMEPAGE:
    "MaVoie t'aide à trouver les clés pour réussir ta recherche d'emploi",
  MISSION:
    "MaVoie agit contre l'isolement des NEETS et propose des solutions gratuites pour aider les jeunes générations à définir(déterminer) leur avenir professionnel",
  TEAM: "Notre Équipe",
  INTERVIEW:
    "un entretien un job t'aidera à réussir à ton entretien d'embouche",
};

export default DocumentTitleDescriptionEnum;

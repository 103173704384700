import { combineReducers } from "redux";
import { persistStore, persistReducer } from "redux-persist";
import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";

import { PERSIST_CONFIG_KEY } from "config/initConfig";

import usersReducer from "store/users/usersSlice";
import contactReducer from "store/contact/contactSlice";
import interviewReducer from "store/interview/interviewSlice";

import transformsSet from "./transformsSet";

const persistConfig = {
  key: PERSIST_CONFIG_KEY,
  devTools: process.env.NODE_ENV !== "production",
  storage, // defaults to localStorage for web
  transforms: [transformsSet],
};

const rootReducers = combineReducers({
  users: usersReducer,
  contact: contactReducer,
  interview: interviewReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducers);

const theStore = configureStore({
  reducer: persistedReducer,
  middleware: getDefaultMiddleware({
    serializableCheck: false,
  }),
});

export const persistor = persistStore(theStore);

export default theStore;

import React, { useCallback, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { useTheme } from "@mui/system";
import {
  Button,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";

import { saveAnswer, saveLastProgress } from "store/users/usersSlice";
import { selectUserUID } from "store/users/usersSelectors";
import { updateUserQuestionnaireAction } from "domain/user/userControllers";
import SectorsDataNameEnum from "utils/constants/enums/SectorsDataNameEnum";

import Icon from "components/common/Icon";

const QuestionChoicesForm = ({ initialAnswer, stepData }) => {
  const {
    palette: { green, black, white },
    typography: { FontFamilies },
  } = useTheme();
  const { t } = useTranslation(["path", "solutions"]);

  const dispatch = useDispatch();
  const history = useHistory();

  const userId = useSelector(selectUserUID);

  const [disabled, setDisabled] = useState(!initialAnswer?.aid);

  const actionLink = stepData.actions?.[0];

  const actionSubmit = stepData.actions?.[1];

  const sortedActivityArea = useMemo(() => {
    let sortingActivityArea = [];
    for (const sector in SectorsDataNameEnum) {
      sortingActivityArea.push({
        id: sector,
        name: SectorsDataNameEnum[sector],
      });
    }
    sortingActivityArea = sortingActivityArea.sort((a, b) =>
      a.name.localeCompare(b.name)
    );
    return sortingActivityArea;
  }, []);

  // Saving the field values on input change
  const onChangeCallbackClosure = useCallback(
    (id, text, resume) => () => {
      setDisabled(false);
      const answer = {
        qid: stepData.qid,
        question: stepData.question,
        type: stepData.type,
        answers: [
          {
            aid: id,
            value: text,
          },
        ],
        resume: resume,
      };
      dispatch(saveAnswer(answer));
    },
    [stepData.qid, stepData.question, stepData.type, dispatch]
  );

  // Submit the form, Save in Database and go to next page
  const onSubmitCallback = useCallback(
    (e) => {
      window.scrollTo({ top: 0 });
      e.preventDefault();
      dispatch(saveLastProgress(stepData.progress));
      if (userId) {
        dispatch(updateUserQuestionnaireAction());
      }
      history.push(t("path:path.questionnaire") + "/" + actionSubmit.path);
    },
    [dispatch, stepData.progress, actionSubmit.path, userId, history, t]
  );

  // On Click on "Je ne sais pas" : Save in Redux, Database and go to next page
  const onSkipQuestion = useCallback(() => {
    window.scrollTo({ top: 0 });
    onChangeCallbackClosure(stepData.qid, "", actionLink.value.resume)();
    dispatch(saveLastProgress(stepData.progress));
    if (userId) {
      dispatch(updateUserQuestionnaireAction());
    }
    history.push(t("path:path.questionnaire") + "/" + actionLink.path);
  }, [
    actionLink.path,
    actionLink.value.resume,
    dispatch,
    history,
    onChangeCallbackClosure,
    stepData.progress,
    stepData.qid,
    t,
    userId,
  ]);

  const RadioChoices = useMemo(
    () => (
      <RadioGroup
        aria-labelledby="sectors-radio-buttons-group-label"
        name="sectors-radio-buttons-group"
        defaultValue={initialAnswer?.aid}
      >
        {sortedActivityArea.map((option) => {
          const { id, name } = option;
          return (
            <FormControlLabel
              value={id}
              key={id}
              control={
                <Radio
                  sx={{
                    "&, &.Mui-checked": {
                      color: green.main,
                    },
                  }}
                  onChange={onChangeCallbackClosure(
                    id,
                    name,
                    actionSubmit.value.resume
                  )}
                />
              }
              sx={{
                borderTop: `1px solid ${white.neutral300}`,
                color: black.neutral600,
                margin: 0,
              }}
              label={
                <Typography
                  textAlign={"left"}
                  sx={{
                    fontFamily: FontFamilies.ProximaNovaBold,
                    color: black.neutral700,
                  }}
                  variant={"paragraph"}
                >
                  {name}
                </Typography>
              }
            />
          );
        })}
      </RadioGroup>
    ),
    [
      initialAnswer?.aid,
      sortedActivityArea,
      green.main,
      onChangeCallbackClosure,
      actionSubmit.value.resume,
      white.neutral300,
      black.neutral600,
      black.neutral700,
      FontFamilies.ProximaNovaBold,
    ]
  );

  return (
    <form onSubmit={onSubmitCallback}>
      <FormControl fullWidth>{RadioChoices}</FormControl>
      <Button
        sx={{ mt: 10 }}
        size="small"
        fullWidth
        variant="text"
        color="black"
        onClick={onSkipQuestion}
        type="button"
      >
        {actionLink?.value?.text}
      </Button>
      <Button
        sx={{ mt: 2 }}
        size="large"
        fullWidth
        variant="contained"
        color="orange"
        disabled={disabled}
        type="submit"
        endIcon={<Icon iconName="arrowRightShort" iconSize={16} />}
      >
        {actionSubmit?.value?.text}
      </Button>
    </form>
  );
};

export default React.memo(QuestionChoicesForm);

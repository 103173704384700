import { InterviewFormNameEnum } from "utils/constants/enums/InterviewFormNameEnum";

import { withConnectedFormik } from "hooks/withConnectedFormik";

import { saveStartInterviewFormData } from "domain/interview/interviewControllers";
import {
  ValidationSchemas,
  InterviewInitialValues,
} from "domain/interview/interviewFormUtils";

import InterviewStart from "./InterviewStart";

export default withConnectedFormik(
  InterviewFormNameEnum.INTERVIEW_START,
  saveStartInterviewFormData,
  InterviewInitialValues.INTERVIEW_START,
  ValidationSchemas.INTERVIEW_START
)(InterviewStart);

import { GoogleAnalyticsEvents } from "./../analyticsConfig";

const sendFormSuccessDataGA = ({
  formSecteurActivite,
  formExperience,
  formMetier,
  formPretpourPostuler,
  formEntretien,
  formBesoinDeFormation,
  formDecouverteMetier,
  formDispoFormation,
  formOutilConnaissanceDeSoi,
  formBranche,
  formEtoile,
  formAge,
  formStatutPro,
  formZipcode,
  formLocality,
}) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: GoogleAnalyticsEvents.FormSuccess,
    formSecteurActivite,
    formExperience,
    formMetier,
    formPretpourPostuler,
    formEntretien,
    formBesoinDeFormation,
    formDecouverteMetier,
    formDispoFormation,
    formOutilConnaissanceDeSoi,
    formBranche,
    formEtoile,
    formAge,
    formStatutPro,
    formZipcode,
    formLocality,
  });
};

export default sendFormSuccessDataGA;

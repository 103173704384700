import { GoogleAnalyticsEvents } from "../analyticsConfig";

const navigateToCalendlyDataGA = (source) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: GoogleAnalyticsEvents.CliqueRDVhomepage,
    valeurRDVhomepage: source,
  });
};

export default navigateToCalendlyDataGA;

import MailContentTemplateEnum from "./../constants/enums/MailContentTemplateEnum";

/**
 * Represents the styling data for different types of mail content templates.
 *
 * @typedef {Object} MailStyleData
 * @property {Object} [solution] - The styling data for the solution content template.
 * @property {string} solution.title - The title of the solution content template.
 * @property {Object[]} solution.content - The array of content objects for the solution content template.
 * @property {string} solution.content[].title - The title of the content part.
 * @property {Object} solution.content[].style - The CSS styling properties for the content part.
 * @property {Object} [solutionAndResponses] - The styling data for the solution and responses content template.
 * @property {string} solutionAndResponses.title - The title of the solution and responses content template.
 * @property {Object[]} solutionAndResponses.content - The array of content objects for the solution and responses content template.
 * @property {string} solutionAndResponses.content[].title - The title of the content part.
 * @property {Object} solutionAndResponses.content[].style - The CSS styling properties for the content part.
 * @property {Object} [automaticResponseContact] - The styling data for the automatic response contact content template.
 * @property {string} automaticResponseContact.title - The title of the automatic response contact content template.
 * @property {Object[]} automaticResponseContact.content - The array of content objects for the automatic response contact content template.
 * @property {string} automaticResponseContact.content[].title - The title of the content part.
 * @property {Object} automaticResponseContact.content[].style - The CSS styling properties for the content part.
 * @property {Object} params - Additional parameters for the styling data.
 * @property {string} params.hr - The HTML code for horizontal rule styling.
 * @property {string} params.br - The HTML code for line break.
 */
const mailStyleData = {
  [MailContentTemplateEnum.solution]: {
    title: "SolutionContent",
    content: [
      {
        title: "part1",
        style: {
          "background-color": "white",
          padding: "0px 36px",
          margin: "0 auto",
          "text-align": "center",
          "font-size": "14px",
          "font-weight": "bold",
          "line-height": "1.5",
        },
      },
      {
        title: "part2",
        style: {
          "font-weight": "bold",
          color: "#ea6625",
          margin: "0 auto",
          "font-size": "14px",
          "text-align": "center",
          "line-height": "1.5",
        },
      },
      {
        title: "part3",
        style: {
          "background-color": "white",
          padding: "0px 36px",
          margin: "0 auto",
          "font-size": "14px",
          "text-align": "center",
          "font-weight": "bold",
          "line-height": "1.5",
        },
      },
      {
        title: "part4",
        style: {
          "background-color": "white",
          padding: "0px 36px",
          margin: "0 auto",
          "font-size": "14px",
          "text-align": "center",
          "font-style": "italic",
          "font-weight": "bold",
          "line-height": "1.5",
        },
      },
      {
        title: "part5",
        style: {
          display: "block",
          "background-color": "#ea6625",
          "border-radius": "10px",
          padding: "2px 0px",
          color: "white",
          width: "70%",
          "font-weight": "bold",
          margin: "0 auto",
          "font-size": "13px",
          "text-align": "center",
          "line-height": "1.5",
        },
        href: "https://app.livestorm.co/association-mavoie/mavoie-des-solutions-concretes-de-retour-a-lemploi?type=detailed",
      },
      {
        title: "part6",
        style: {
          "background-color": "white",
          padding: "0px 0px",
          margin: "0 auto",
          "font-size": "14px",
          "text-align": "center",
          "font-weight": "bold",
          width: "100%",
          "line-height": "1.5",
        },
      },
      {
        title: "part7",
        style: {
          "background-color": "white",
          padding: "0px 36px",
          margin: "0 auto",
          "font-size": "14px",
          "text-align": "center",
          "line-height": "1.5",
        },
      },
    ],
  },
  [MailContentTemplateEnum.solutionAndResponses]: {
    title: "SolutionAndResponsesContent",
    content: [
      {
        title: "part1",
        style: {
          padding: "6px",
          margin: "2px",
          "font-size": "14px",
          "text-align": "left",
        },
      },
    ],
  },
  [MailContentTemplateEnum.automaticResponseContact]: {
    title: "AutomaticResponseContactContent",
    content: [
      {
        title: "part1",
        style: {
          padding: "6px",
          margin: "6px",
          "font-size": "14px",
          "line-height": "1.5",
          "text-align": "left",
        },
      },
    ],
  },
  params: {
    hr: "<hr style='height: 1px;border-width: 0;color: #ced4da;background-color: #ced4da;'/>",
    br: "<br/>",
  },
};

export default mailStyleData;

import { createSlice } from "@reduxjs/toolkit";
import StoreKeys from "utils/constants/store-keys.json";
import { NewContactFactory } from "domain/contact/contactModels";

export const contactSlice = createSlice({
  name: StoreKeys.CONTACT,
  initialState: NewContactFactory().instance,
  reducers: {
    saveContactName: (state, action) => {
      return {
        ...state,
        name: action.payload,
      };
    },
    saveContactEmail: (state, action) => {
      return {
        ...state,
        email: action.payload,
      };
    },
    saveContactReason: (state, action) => {
      return {
        ...state,
        reason: action.payload,
      };
    },
    saveContactQuestion: (state, action) => {
      return {
        ...state,
        question: action.payload,
      };
    },
  },
});

export const {
  saveContactName,
  saveContactEmail,
  saveContactReason,
  saveContactQuestion,
} = contactSlice.actions;

export default contactSlice.reducer;

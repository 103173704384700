import Icon from "@mdi/react";
import { mdiStar } from "@mdi/js";
import GMBReviews from "utils/data/homepageData";
import { useTranslation } from "react-i18next";
import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";

const GoogleNote = () => {
  const {
    breakpoints,
    typography: { ParagraphStyles },
  } = useTheme();
  const { t } = useTranslation("homePage");
  const isScreenDownSM = useMediaQuery(breakpoints.down("sm"));

  return (
    <Box display="flex" alignItems="center" mt={8}>
      <Typography
        sx={{ ...ParagraphStyles, fontSize: isScreenDownSM && "12px" }}
      >
        {GMBReviews.note}
      </Typography>
      &nbsp;
      <Box>
        <Icon size={0.5} path={mdiStar} color="#FFD232" />
        <Icon size={0.5} path={mdiStar} color="#FFD232" />
        <Icon size={0.5} path={mdiStar} color="#FFD232" />
        <Icon size={0.5} path={mdiStar} color="#FFD232" />
        <Icon size={0.5} path={mdiStar} color="#FFD232" />
      </Box>
      <Typography
        sx={{ ...ParagraphStyles, fontSize: isScreenDownSM && "12px" }}
      >
        &nbsp;( {GMBReviews.reviews_count}&nbsp;{t("homePage:roleSection.gmb")})
      </Typography>
    </Box>
  );
};

export default GoogleNote;

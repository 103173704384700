import React, { useCallback } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";

import TemplateAdviceInterview from "components/common/TemplateAdviceInterview";

import MapAdvicePageEnum from "utils/constants/enums/MapAdvicePageEnum";
import InterviewPageEnum from "utils/constants/enums/InterviewPageEnum";

const InterviewAdvice = ({ advicePage, page }) => {
  const { skillNumber } = useParams();
  const { text, trainingProgress, path, controller, adviceNumber } = skillNumber
    ? MapAdvicePageEnum?.[InterviewPageEnum.SC]?.[skillNumber]
    : MapAdvicePageEnum?.[advicePage]?.[page];

  const history = useHistory();
  const dispatch = useDispatch();
  const onNextClick = useCallback(() => {
    dispatch(controller(trainingProgress, text, history, path));
  }, [text, trainingProgress, dispatch, history, path, controller]);
  return (
    <TemplateAdviceInterview
      adviceNumber={adviceNumber}
      textAdvice={text}
      onNextClick={onNextClick}
    />
  );
};

export default InterviewAdvice;

import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Interweave from "interweave";

import {
  Box,
  Button,
  Container,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";

import jobReadyBW from "assets/images/logos/jobreadyB&W.svg";
import generationFranceBW from "assets/images/logos/generationfranceB&W.svg";
import bayesImpactBW from "assets/images/logos/bayesimpactB&W.svg";
import chanceBW from "assets/images/logos/chanceB&W.svg";
import Icon from "components/common/Icon/Icon";

const MissionPartnersSection = () => {
  const theme = useTheme();
  const isScreenDownSM = useMediaQuery(theme.breakpoints.down("sm"));
  const isScreenDownLG = useMediaQuery(theme.breakpoints.down("lg"));

  const {
    homepage: { small: variablesSM, large: variablesLarge },
  } = theme.variables;

  const { t } = useTranslation("missionPartners");
  return (
    <section name="mission-Partners">
      <Box
        mb={
          isScreenDownSM
            ? variablesSM.spaceBetweenSectionsHP
            : variablesLarge.spaceBetweenSectionsHP
        }
        py={10}
        sx={{ width: "100%", bgcolor: theme.palette.green.complementary }}
      >
        <Container maxWidth="xl">
          <Grid container spacing={2} px={5}>
            <Grid item lg={6}>
              <Typography
                variant="h2"
                mb={10}
                sx={{
                  textAlign: (isScreenDownSM || isScreenDownLG) && "center",
                }}
              >
                <Interweave content={t("title")} />
              </Typography>
              <Box
                sx={{
                  display: isScreenDownSM ? "block" : "none",
                }}
              >
                <img
                  src={t("teamImageSmall")}
                  alt={t("teamImageAltText")}
                  style={{
                    width: "100%",
                  }}
                />
              </Box>
              <Box
                lg={6}
                sx={{
                  display: isScreenDownLG && !isScreenDownSM ? "flex" : "none",
                  justifyContent: "center",
                }}
              >
                <img src={t("teamImageLarge")} alt={t("teamImageAltText")} />
              </Box>
              <Box mt={(isScreenDownSM || isScreenDownLG) && 8}>
                <Typography variant="paragraph" mb={10}>
                  <Interweave content={t("mavoieDescription")} />
                </Typography>
              </Box>
              <br />
              <Box>
                <Typography variant="paragraph">
                  <Interweave content={t("mavoieDescription2")} />
                </Typography>
              </Box>
              <Grid
                container
                my={8}
                spacing={2}
                justifyContent="space-around"
                alignItems="center"
              >
                <Grid item lg={3}>
                  <img
                    src={jobReadyBW}
                    alt={t("partnerAltText1")}
                    style={{
                      width: isScreenDownSM ? "71px" : "110px",
                      height: isScreenDownSM ? "19px" : "23.831px",
                    }}
                  />
                </Grid>
                <Grid item lg={3}>
                  <img
                    src={generationFranceBW}
                    alt={t("partnerAltText2")}
                    style={{
                      width: isScreenDownSM ? "71px" : "110px",
                      height: isScreenDownSM ? "29px" : "41px",
                    }}
                  />
                </Grid>
                <Grid item lg={3}>
                  <img
                    src={bayesImpactBW}
                    alt={t("partnerAltText3")}
                    style={{
                      width: isScreenDownSM ? "71px" : "100px",
                      height: isScreenDownSM ? "23px" : "33px",
                    }}
                  />
                </Grid>
                <Grid item lg={3}>
                  <img
                    src={chanceBW}
                    alt={t("partnerAltText4")}
                    style={{
                      width: isScreenDownSM ? "71px" : "100px",
                      height: isScreenDownSM ? "12px" : "17px",
                    }}
                  />
                </Grid>
              </Grid>
              <Link to={t("path:path.mission")}>
                <Button
                  variant="outlined"
                  startIcon={<Icon iconSize={16} iconName="arrowRightShort" />}
                  color="black"
                  size="large"
                  sx={{ bgcolor: theme.palette.green.complementary }}
                >
                  {t("CTAText")}
                </Button>
              </Link>
            </Grid>
            <Grid
              item
              lg={6}
              sx={{
                display: (isScreenDownSM || isScreenDownLG) && "none",
              }}
            >
              <img
                src={t("teamImageLarge")}
                alt={t("teamImageAltText")}
                style={{ width: "100%", marginTop: "-100px" }}
              />
            </Grid>
          </Grid>
        </Container>
      </Box>
    </section>
  );
};

export default MissionPartnersSection;

import React, { useState, useMemo } from "react";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Box, Container, useMediaQuery, ThemeProvider } from "@mui/material";

import theme from "styles/theme/theme";

import FooterSection from "components/FooterSection";
import Banner from "components/common/Banner";
import NavBarMenu from "components/common/NavBarMenu";
import MuiBreadcrumbs from "components/common/MuiBreadcrumbs";
import AppointementSicky from "components/AppointementSticky";

const Header = ({ children }) => {
  const isScreenDownLG = useMediaQuery(theme.breakpoints.down("lg"));

  const { t } = useTranslation("path");

  const { pathname } = useLocation();

  const showHeader =
    pathname.includes(t("path.home")) ||
    pathname.includes(t("path.terms")) ||
    pathname.includes(t("path.mission")) ||
    pathname.includes(t("path.contact")) ||
    pathname.includes(t("path.team"));
  // In this moment to be shown in all pages except the interview page
  const showFooter =
    !pathname.includes(t("path.interview")) &&
    !pathname.includes(t("questionnaire-loader"));

  const showBreadcrumbs =
    pathname.includes("questionnaire/") ||
    pathname.includes("questionnaire-email") ||
    pathname.includes(t("path.solutions")) ||
    pathname.includes(t("path.mission")) ||
    pathname.includes(t("path.terms")) ||
    pathname.includes(t("path.contact")) ||
    pathname.includes(t("path.team"));

  const showAppointementSticky =
    pathname.includes("questionnaire/q2") ||
    pathname.includes("questionnaire/q3") ||
    pathname.includes("questionnaire/q4") ||
    pathname.includes("questionnaire/resume") ||
    pathname.includes("questionnaire-email") ||
    pathname.includes(t("path.solutions"));

  const [menuToggled, toggleMenu] = useState(false);

  const variablesTheme = theme.variables;

  // This constant is created to customize the style of breadcrumbs container depending on the page
  const breadcrumbsContainerStyle = useMemo(() => {
    const defaultStyle = { mt: 4, px: 4 };
    if (!variablesTheme) {
      return defaultStyle;
    }
    if (pathname?.includes("questionnaire/")) {
      return (
        variablesTheme?.["questionnaire"]?.containerBreadcrumbs || defaultStyle
      );
    }
    if (pathname?.includes(t("path.solutions"))) {
      return (
        variablesTheme?.["solutions"]?.containerBreadcrumbs || defaultStyle
      );
    }
    return defaultStyle;
  }, [variablesTheme, pathname, t]);

  return (
    <>
      <ThemeProvider theme={theme}>
        {(showHeader || pathname.includes(t("path.calendly"))) && <Banner />}
        <Box sx={{ mt: showHeader && (isScreenDownLG ? 0 : 14), padding: 0 }}>
          {showHeader && (
            <NavBarMenu menuToggled={menuToggled} toggleMenu={toggleMenu} />
          )}
          {isScreenDownLG && pathname.includes(t("path.calendly")) && (
            <NavBarMenu menuToggled={menuToggled} toggleMenu={toggleMenu} />
          )}
          {showBreadcrumbs && !menuToggled && (
            <Container
              width="100%"
              sx={breadcrumbsContainerStyle}
              maxWidth="xl"
            >
              <MuiBreadcrumbs pathname={pathname} />
            </Container>
          )}
          {!menuToggled && children}
        </Box>
        {showAppointementSticky && <AppointementSicky />}
        {showFooter && !menuToggled && <FooterSection />}
      </ThemeProvider>
    </>
  );
};

export default Header;

import { GoogleAnalyticsEvents } from "../analyticsConfig";

const clickBlocServiceDataGA = (nomBloc) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: GoogleAnalyticsEvents.BlocServiceAccueil,
    nomBloc,
  });
};

export default clickBlocServiceDataGA;

import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import clsx from "clsx";
import Interweave from "interweave";

import { useMediaQueries } from "hooks/useMediaQueries";
import InterviewHomeStyles from "./InterviewHome.module.scss";
import InterviewHomeImage from "assets/images/interview-home-image.png";

import Button from "components/common/Button(ToBeUpdated)";

import { createNewInterviewTraining } from "domain/interview/interviewControllers";

const InterviewHome = () => {
  const { isTablet, isDesktop, isMobile } = useMediaQueries();
  const { t } = useTranslation("interview", "path");
  const dispatch = useDispatch();
  const history = useHistory();
  const { imageAlt, title, step1, step2, step3 } = t(
    "interview:interviewHome",
    {
      returnObjects: true,
    }
  );

  const newInterviewTraining = () => {
    dispatch(createNewInterviewTraining());
    history.push(t("path:path.interview.start"));
  };

  return (
    <>
      <div
        className={clsx({
          [InterviewHomeStyles.frame]: isDesktop || isTablet || isMobile,
          [InterviewHomeStyles.frameMarginL]: isDesktop,
          [InterviewHomeStyles.frameMarginM]: isTablet,
          [InterviewHomeStyles.frameMarginS]: isMobile,
        })}
      >
        <img
          src={InterviewHomeImage}
          alt={imageAlt}
          className={InterviewHomeStyles.image}
        />
      </div>
      <Interweave
        containerTagName="h1"
        className={InterviewHomeStyles.title}
        content={title}
      />
      <ul className={InterviewHomeStyles.list}>
        <h2>
          <li>{step1}</li>
        </h2>
        <h2>
          <li>{step2}</li>
        </h2>
        <h2>
          <li>{step3}</li>
        </h2>
      </ul>
      <div
        className={clsx({
          [InterviewHomeStyles.buttonL]: isDesktop,
          [InterviewHomeStyles.buttonM]: isTablet,
          [InterviewHomeStyles.buttonS]: isMobile,
        })}
      >
        <Button
          text={"C'est parti"}
          type={"submit"}
          style={{
            padding: "25px",
            marginTop: "30%",
            width: "100%",
          }}
          onClick={newInterviewTraining}
        />
      </div>
    </>
  );
};

export default InterviewHome;

import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Interweave from "interweave";
import { Box, Button, Container, useMediaQuery, useTheme } from "@mui/material";

import navigateToDiagnosticDataGA from "config/googleAnalytics/navigateToDiagnosticDataGA";

import GoogleNote from "components/common/GoogleNote";
import Icon from "components/common/Icon/Icon";

import visuelHero from "assets/images/visuelHero.webp";
import RoleStyles from "./RoleSection.module.scss";
import navigateToCalendlyDataGA from "config/googleAnalytics/navigateToCalendlyDataGA";
import ValueRDVhomepageEnum from "utils/constants/enums/ValueRDVhomepageEnum";

const RoleSection = () => {
  const theme = useTheme();
  const { t } = useTranslation(["homePage"]);
  const isScreenDownSM = useMediaQuery(theme.breakpoints.down("sm"));

  const {
    homepage: { small: variablesSM, large: variablesLarge },
    containerPX,
  } = theme.variables;

  return (
    <section name="roleSection">
      <Container
        sx={{
          px: containerPX,
          mt: isScreenDownSM ? 0 : 16,
          mb: isScreenDownSM
            ? variablesSM.spaceBetweenSectionsHP
            : variablesLarge.spaceBetweenSectionsHP,
        }}
        maxWidth="xl"
      >
        <div className={RoleStyles.flexBoxContainer}>
          <div className={RoleStyles.left}>
            <Interweave
              className={RoleStyles.roleSectionTitle}
              tagName="h1"
              content={t("homePage:roleSection.title")}
            />
            <Interweave
              className={RoleStyles.roleSectionSubTitle}
              tagName="p"
              content={t("homePage:roleSection.subtitle")}
            />
            <Box
              sx={{
                display: "flex",
                flexDirection: isScreenDownSM ? "column" : "row",
                gap: isScreenDownSM ? "16px" : "36px",
              }}
            >
              <Link to={t("path:path.questionnaire") + "/q1"}>
                <Button
                  variant="contained"
                  color="black"
                  size="large"
                  startIcon={<Icon iconName="timer" />}
                  onClick={() => {
                    navigateToDiagnosticDataGA("SectionRoles");
                  }}
                >
                  &nbsp;{t("homePage:roleSection.button")}
                </Button>
              </Link>
              <Link to={t("path:path.calendly")}>
                <Button
                  variant="outlined"
                  color="black"
                  size="large"
                  startIcon={<Icon iconName="arrowRightShort" />}
                  onClick={() => {
                    navigateToCalendlyDataGA(
                      ValueRDVhomepageEnum.FROM_ROLE_SECTION
                    );
                  }}
                >
                  &nbsp;{t("homePage:roleSection.buttonRdv")}
                </Button>
              </Link>
            </Box>
            <GoogleNote />
          </div>
          <div className={RoleStyles.right}>
            <img
              loading="lazy"
              alt={t("homePage:roleSection.image")}
              className={RoleStyles.herosImage}
              src={visuelHero}
            />
          </div>
        </div>
      </Container>
    </section>
  );
};

export default RoleSection;

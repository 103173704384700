import TagManager from "react-gtm-module";
// import ReactPixel from "react-facebook-pixel";
import env from "../.env.json";

export const GoogleAnalyticsEvents = {
  FormSuccess: "FormSuccess",
  SolutionPrincipaleJyVais: "SolutionPrincipaleJyVais",
  SolutionSecondaireJyVais: "SolutionSecondaireJyVais",
  Inscription: "Inscription",
  InscriptionVolontaire: "InscriptionVolontaire",
  LienVersSolution: "LienVersSolution",
  FermerPopinSolution: "FermerPopinSolution",
  CreationEntretien: "CreationEntretien",
  ResumeEntrainementTelecharge: "ResumeEntrainementTelecharge",
  NePasRecevoirSuggestions: "NePasRecevoirSuggestions",
  RapportActivitesTelecharge: "RapportActivitesTelecharge",
  ResumeEntrainementEnvoieMail: "ResumeEntrainementEnvoieMail",
  BlocServiceAccueil: "BlocServiceAccueil",
  NavigationVersDiagnostic: "NavigationVersDiagnostic",
  CliqueFiltreSolutions: "CliqueFiltreSolutions",
  CliqueRDV: "CliqueRDV",
  PrendreRDV: "PrendreRDV",
};

/* AxeptIO Config */

const clientId = env.axeptio_client_id;

const cookiesVersion = env.axeptio_cookies_version;

/* Google Tag Manager Config */

const tagManagerArgs = {
  gtmId: env.google_tag_manager_id,
};

/* Initializing the configs */
export const initializeAnalyticsConfig = () => {
  TagManager.initialize(tagManagerArgs);

  window.axeptioSettings = {
    clientId,
    cookiesVersion,
    googleConsentMode: {
      default: {
        ad_storage: "denied",
        ad_user_data: "denied",
        ad_personalization: "denied",
        wait_for_update: 500,
      },
    },
  };

  // Initializing the cookies
  const axeptioJs = document.createElement("script");
  axeptioJs.setAttribute("async", "");
  axeptioJs.setAttribute("src", "https://static.axept.io/sdk.js");
  document.head.appendChild(axeptioJs);

  void 0 === window._axcb && (window._axcb = []);
};

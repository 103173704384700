import { TrainingProgressFactory } from "domain/interview/interviewModels";
import { LastProgressNumberEnum } from "utils/constants/enums/LastProgressNumberEnum";
import { TrainingTagEnum } from "utils/constants/enums/TrainingTagEnum";

const interviewJobReducer = {
  saveJobDetailsInterviewData: (state, action) => {
    return {
      ...state,
      currentInterview: {
        ...state.currentInterview,
        jobDetail: action.payload,
        trainingProgress: TrainingProgressFactory(
          TrainingTagEnum.InterviewJob,
          LastProgressNumberEnum.InterviewJob["Q1"]
        ).instance,
      },
    };
  },

  saveJobSoftskillsInterviewData: (state, action) => {
    return {
      ...state,
      currentInterview: {
        ...state.currentInterview,
        job: {
          ...state.currentInterview.job,
          softskills: action.payload,
        },
        trainingProgress: TrainingProgressFactory(
          TrainingTagEnum.InterviewJob,
          LastProgressNumberEnum.InterviewJob["Q2"]
        ).instance,
      },
    };
  },

  saveJobBestofInterviewData: (state, action) => {
    return {
      ...state,
      currentInterview: {
        ...state.currentInterview,
        job: {
          ...state.currentInterview.job,
          bestof: action.payload,
        },
        trainingProgress: TrainingProgressFactory(
          TrainingTagEnum.InterviewJob,
          LastProgressNumberEnum.InterviewJob["Q3"]
        ).instance,
      },
    };
  },

  saveJobChallengesInterviewData: (state, action) => {
    return {
      ...state,
      currentInterview: {
        ...state.currentInterview,
        job: {
          ...state.currentInterview.job,
          challenges: action.payload,
        },
        trainingProgress: TrainingProgressFactory(
          TrainingTagEnum.InterviewJob,
          LastProgressNumberEnum.InterviewJob["Q4"]
        ).instance,
      },
    };
  },
};

export default interviewJobReducer;

import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

import useQuery from "hooks/useQuery";

import { setTreeTokenId } from "store/users/usersSlice";

import ButtonScrollTopPage from "components/ButtonScrollTopPage";
import StarsSection from "components/Home/StarsSection";
import TestimoniesSection from "components/Home/TestimoniesSection";
import RoleSection from "components/Home/RoleSection";
import Separator from "components/common/Separator";
import InterviewSection from "components/Home/InterviewSection";
import MissionPartnersSection from "components/Home/MissionPartnersSection";
import VideoSection from "components/Home/VideoSection";
import CalendlyHPSection from "components/Home/CalendlyHPSection";
import ServicesSection from "components/common/ServicesSection";

import "./HomePage.module.scss";

function HomePage() {
  const query = useQuery();
  const dispatch = useDispatch();
  const { t } = useTranslation("homePage");

  let treeId = null;

  let authToken = null;

  if (query.get("treeTokenId")) {
    treeId = query.get("treeTokenId");
  }

  if (query.get("authToken")) {
    authToken = query.get("authToken");
  }

  useEffect(() => {
    // Getting params of tree id and auth token from url and save them in redux
    dispatch(
      setTreeTokenId({
        treeId,
        authToken,
      })
    );
  }, [treeId, authToken, dispatch]);

  return (
    <>
      <RoleSection />
      <Separator />
      <StarsSection />
      <InterviewSection />
      <TestimoniesSection />
      <CalendlyHPSection />
      <MissionPartnersSection />
      <VideoSection />
      <ServicesSection
        title={t("servicesSection.title")}
        orangeText={t("servicesSection.orangeText")}
        greenText={t("servicesSection.greenText")}
        brownText={t("servicesSection.brownText")}
        blueText={t("servicesSection.blueText")}
      />
      <ButtonScrollTopPage />
    </>
  );
}

export default HomePage;

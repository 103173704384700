import InterviewFinish from "./InterviewFinish";
import { withConnectedFormik } from "../../../hooks/withConnectedFormik";
import { InterviewFormNameEnum } from "../../../utils/constants/enums/InterviewFormNameEnum";
import { saveInterviewProgress } from "../../../domain/interview/interviewControllers";
import {
  InterviewInitialValues,
  ValidationSchemas,
} from "../../../domain/interview/interviewFormUtils";

export default withConnectedFormik(
  InterviewFormNameEnum.INTERVIEW_FINISH,
  saveInterviewProgress,
  InterviewInitialValues.INTERVIEW_FINISH,
  ValidationSchemas.INTERVIEW_FINISH
)(InterviewFinish);

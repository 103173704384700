import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import Interweave from "interweave";

import { Button, Menu, MenuItem, useTheme } from "@mui/material";

import clickFilterSolutionsDataGA from "config/googleAnalytics/clickFilterSolutionsDataGA";
import { getNumbersOfSolutions } from "utils/helpers/solutionsHelper";

import Icon from "../Icon";
import FormationCountNumberPill from "../SolutionsNumberPill";

const FilterMenu = ({ formations, handleFilterChange }) => {
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (tabText) => (event) => {
    clickFilterSolutionsDataGA(tabText);

    setAnchorEl(null);
    handleFilterChange(event.target.value || 0);
  };

  const { t } = useTranslation(["solutions"]);
  const { tabText_1, tabText_2, tabText_3, tabText_4 } = t("tabText", {
    returnObjects: true,
  });

  const {
    totalNumberOfSolutions,
    numberOfSolutionTypeFormation,
    numberOfSolutionTypeOutil,
    numberOfSolutionTypeAccompagnement,
  } = useMemo(() => getNumbersOfSolutions(formations), [formations]);

  const { typography, palette } = theme;

  return (
    <>
      <Button
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        variant="text"
        color="black"
        onClick={handleClick}
        endIcon={<Icon iconName="keyboardArrowDown" iconSize={16} />}
        sx={{
          fontSize: typography.FontSizes.md,
          padding: "16px 20px",
          borderRadius: "10px 10px 0px 0px",
          backgroundColor: palette.green.complementary,
        }}
      >
        <Interweave content={t("filterMenuText")} />
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose()}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem
          value={0}
          onClick={handleClose(tabText_1)}
          sx={{ textTransform: "uppercase", fontWeight: 700 }}
        >
          {tabText_1}
          <FormationCountNumberPill
            backgroundColor={palette.green.main}
            color={palette.green.complementary}
            count={totalNumberOfSolutions}
          />
        </MenuItem>
        {numberOfSolutionTypeFormation > 0 && (
          <MenuItem
            value={1}
            onClick={handleClose(tabText_2)}
            sx={{ textTransform: "uppercase", fontWeight: 700 }}
          >
            {tabText_2}
            <FormationCountNumberPill
              backgroundColor={palette.green.main}
              color={palette.green.complementary}
              count={numberOfSolutionTypeFormation}
            />
          </MenuItem>
        )}
        {numberOfSolutionTypeOutil > 0 && (
          <MenuItem
            value={2}
            onClick={handleClose(tabText_3)}
            sx={{ textTransform: "uppercase", fontWeight: 700 }}
          >
            {tabText_3}
            <FormationCountNumberPill
              backgroundColor={palette.green.main}
              color={palette.green.complementary}
              count={numberOfSolutionTypeOutil}
            />
          </MenuItem>
        )}
        {numberOfSolutionTypeAccompagnement > 0 && (
          <MenuItem
            value={3}
            onClick={handleClose(tabText_4)}
            sx={{ textTransform: "uppercase", fontWeight: 700 }}
          >
            {tabText_4}
            <FormationCountNumberPill
              backgroundColor={palette.green.main}
              color={palette.green.complementary}
              count={numberOfSolutionTypeAccompagnement}
            />
          </MenuItem>
        )}
      </Menu>
    </>
  );
};

export default FilterMenu;

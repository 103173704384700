import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import Interweave from "interweave";
import Zoom from "react-reveal/Zoom";

import VideoSectionStyles from "./VideoSection.module.scss";
import {
  Box,
  Container,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";

const VideoSection = () => {
  const { t } = useTranslation("homePage");
  const theme = useTheme();
  const isScreenDownSM = useMediaQuery(theme.breakpoints.down("sm"));
  const isScreenDownLg = useMediaQuery(theme.breakpoints.down("lg"));

  const {
    homepage: { small: variablesSM, large: variablesLarge },
  } = theme.variables;

  const [animation, setAnimation] = useState(0);

  const handleAnimationStart = useCallback(() => {
    const videoImage = document.querySelector("#videoImage");
    videoImage.style.display = "none";
    setAnimation(1);
  }, [setAnimation]);

  const handleAnimationEnd = useCallback(() => {
    if (animation === 1) {
      const video = document.querySelector("#video");
      video.style.display = "block";
    }
  }, [animation]);

  return (
    <section className={VideoSectionStyles.sectionVideo}>
      <Container maxWidth="xl">
        <Typography
          variant="h2"
          mb={isScreenDownSM ? 8 : 18}
          sx={{ textAlign: "center" }}
        >
          <Interweave content={t("videoSection.title")} />
        </Typography>
        <Box
          mb={
            isScreenDownSM
              ? variablesSM.spaceBetweenSectionsHP
              : variablesLarge.spaceBetweenSectionsHP
          }
          display="flex"
          justifyContent="center"
          sx={{ "&:hover": { cursor: "pointer" } }}
        >
          <div
            className={VideoSectionStyles.videoAnimation}
            onClick={handleAnimationStart}
            onAnimationEnd={handleAnimationEnd}
            animation={animation}
          >
            <img
              src="https://firebasestorage.googleapis.com/v0/b/mavoie-development.appspot.com/o/public%2Fhomepage%2FvideoSectionImage.png?alt=media"
              alt="video-mavoie"
              id="videoImage"
              style={{
                width: isScreenDownLg && "706px",
                display: isScreenDownSM ? "none" : "block",
              }}
            />
            <Zoom>
              {/* <iframe
                id="video"
                className={VideoSectionStyles.videoPlayer}
                style={{ display: isScreenDownSM ? "block" : "none" }}
                alt={t("videoSection.altText")}
                src={t("videoSection.youtubeLink")}
                title={t("videoSection.youtubeTitle")}
                frameBorder="0"
                allow="accelerometer; autoplay=1; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen
              ></iframe> */}
              <video
                id="video"
                className={VideoSectionStyles.videoPlayer}
                style={{ display: isScreenDownSM ? "block" : "none" }}
                loop
                controls
                controlsList="nodownload"
                muted
                volume="true"
                autoPlay
                playsInline
                // preload="none"
                src="https://firebasestorage.googleapis.com/v0/b/mavoie-development.appspot.com/o/public%2Fvideo%2FLumento_MaVoie_V8.mp4?alt=media"
                type="video/mp4"
                onContextMenu={(e) => {
                  e.preventDefault();
                  return false;
                }}
              />
            </Zoom>
          </div>
        </Box>
      </Container>
    </section>
  );
};

export default VideoSection;

import InterviewSkillRatePage from "./InterviewSkillRatePage";
import { withConnectedFormik } from "../../../hooks/withConnectedFormik";
import { InterviewFormNameEnum } from "../../../utils/constants/enums/InterviewFormNameEnum";
import { saveProfileSkillRateInterviewForm } from "../../../domain/interview/interviewControllers";
import {
  InterviewInitialValues,
  ValidationSchemas,
} from "../../../domain/interview/interviewFormUtils";

export default withConnectedFormik(
  InterviewFormNameEnum.INTERVIEW_PROFILE_SKILLRATE,
  saveProfileSkillRateInterviewForm,
  InterviewInitialValues.InterviewProfileSkillRateForm,
  ValidationSchemas.INTERVIEW_PROFILE_SKILLRATE
)(InterviewSkillRatePage);

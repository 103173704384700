import { InterviewFormNameEnum } from "utils/constants/enums/InterviewFormNameEnum";
import { withConnectedFormik } from "hooks/withConnectedFormik";

import { saveCompanyValuesInterviewForm } from "domain/interview/interviewControllers";
import {
  ValidationSchemas,
  InterviewInitialValues,
} from "domain/interview/interviewFormUtils";

import InterviewCompanyValuesForm from "./InterviewCompanyValuesForm";

export default withConnectedFormik(
  InterviewFormNameEnum.INTERVIEW_COMPANY_VALUES,
  saveCompanyValuesInterviewForm,
  InterviewInitialValues.INTERVIEW_COMPANY_VALUES,
  ValidationSchemas.INTERVIEW_COMPANY_VALUES
)(InterviewCompanyValuesForm);

export const regexEmail =
  // eslint-disable-next-line no-useless-escape
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

/**
 * Converts a date string in the format "dd/mm/yyyy" to "yyyy-mm-dd" format.
 * @param {string} date - The date string to be converted.
 * @returns {string} The converted date string in "yyyy-mm-dd" format.
 */
export function getSendinBlueFormatDate(date) {
  if (date?.includes("/")) {
    const dateInfo = date.split("/");
    return `${dateInfo[0]}-${dateInfo[1]}-${dateInfo[2]}`;
  }
  return "";
}

/**
 * Checks if the given email is valid according to the RFC 822 standard.
 * @param {string} email - The email address to validate.
 * @returns {boolean} - Returns true if the email is valid, otherwise false.
 * @throws {Error} - Throws an error if the input email is not a string.
 */
export function isEmailValidRFC822(email) {
  if (typeof email !== "string") {
    // Handle Error
    throw new Error("Invalid input of email");
  }
  // We do the matching with lower cases because the regex only contains lower cases
  return email.toLowerCase().match(regexEmail);
}

/**
 * Retrieves the questionnaire answers with RGPD rules applied.
 *
 * @param {Array} questionnaire - The array of questionnaire objects.
 * @param {boolean} isUserAnonym - Indicates whether the user is anonymous.
 * @returns {Array} - The modified questionnaire array with RGPD rules applied.
 */
export const getQuestionnaireAnswersWithRgpdRules = (
  questionnaire,
  isUserAnonym
) => {
  let questionnaireRgpd = [];
  for (let i = 0; i < questionnaire.length; i++) {
    questionnaireRgpd[i] = {};
    questionnaireRgpd[i].type = questionnaire[i].type;
    questionnaireRgpd[i].question = questionnaire[i].question;
    questionnaireRgpd[i].answers = questionnaire[i].answers;
    questionnaireRgpd[i].qid = questionnaire[i].qid;
    // if (
    //   // Only on the Question of name
    //   isUserAnonym && questionnaireRgpd[i].qid === "q1"
    // ) {
    //   questionnaireRgpd[i].answers = [];
    // }
  }

  return questionnaireRgpd;
};

/**
 * Retrieves the user's phone numbers from the given calendlyQuestionsAndAnswers array.
 * @param {Array} calendlyQuestionsAndAnswers - The array containing the calendly questions and answers.
 * @returns {string|undefined} - The user's phone number, or undefined if not found.
 */
export const getPhoneNumberFromCalendlyUserData = (
  calendlyQuestionsAndAnswers
) => {
  return calendlyQuestionsAndAnswers?.find((v) =>
    ["Numéro de téléphone", "Teléphone"].includes(v.question)
  )?.answer;
};

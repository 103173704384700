import { InterviewFormNameEnum } from "utils/constants/enums/InterviewFormNameEnum";
import { withConnectedFormik } from "hooks/withConnectedFormik";

import { saveCompanyActivitiesInterviewForm } from "domain/interview/interviewControllers";
import {
  ValidationSchemas,
  InterviewInitialValues,
} from "domain/interview/interviewFormUtils";

import InterviewCompanyActivitiesForm from "./InterviewCompanyActivitiesForm";

export default withConnectedFormik(
  InterviewFormNameEnum.INTERVIEW_COMPANY_ACTIVITES,
  saveCompanyActivitiesInterviewForm,
  InterviewInitialValues.INTERVIEW_COMPANY_ACTIVITES,
  ValidationSchemas.INTERVIEW_COMPANY_ACTIVITES
)(InterviewCompanyActivitiesForm);

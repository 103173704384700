import React, { useMemo, useRef } from "react";
import PropTypes from "prop-types";
import { Field } from "formik";

import SingleInputFieldStyles from "./SingleInputField.module.scss";
import { useLettersCount } from "hooks/useLettersCount";

const MIN_HEIGHT_TEXT_AREA = 105;

// below, the value that you should change if you want to adjust
// textarea height depends of typed character.
const ADAPTED_HEIGHT_TEXT_AREA = 30;

// ** 7.5 pixel corresponds to one character
const CONVERSION_PIXEL_CHARACTER = 7.5;

const DEFAULT_MAX_LENGTH_CHARACTERS = 80;

const CustomInputComponent = React.memo(({ value, ...otherProps }) => {
  const textInput = useRef();
  const displayLettersCount = useLettersCount({
    ...otherProps,
    style: SingleInputFieldStyles,
  });

  // Temporarly is used in page activity and values
  const height = useMemo(() => {
    return (
      Math.max(
        (value?.length /
          (textInput?.current?.offsetWidth / CONVERSION_PIXEL_CHARACTER)) *
          ADAPTED_HEIGHT_TEXT_AREA,
        otherProps.minHeightTextArea || MIN_HEIGHT_TEXT_AREA
      ) + "px"
    );
  }, [value, otherProps]);

  return (
    <>
      <div className={SingleInputFieldStyles.containerInput}>
        <textarea
          {...otherProps}
          ref={textInput}
          style={{ height }}
          className={SingleInputFieldStyles.input}
          type="text"
          defaultValue={value}
        />
      </div>
      {displayLettersCount}
    </>
  );
});

const SingleInputField = ({
  name,
  maxLength = DEFAULT_MAX_LENGTH_CHARACTERS,
  placeHolder = "",
  ...otherProps
}) => {
  return (
    <Field
      name={name}
      maxLength={maxLength}
      placeholder={placeHolder}
      as={CustomInputComponent}
      {...otherProps}
    />
  );
};

SingleInputField.propTypes = {
  // name is a required field
  name: PropTypes.string,
};

export default SingleInputField;

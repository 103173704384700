import Interweave from "interweave";

import { v4 as uuidv4 } from "uuid";
import InterviewSummaryStyles from "../InterviewSummary.module.scss";
import useSummaryInterviewData from "hooks/useSummaryInterviewData";
import { useTranslation } from "react-i18next";
import InterviewBulletPoint from "components/common/InterviewBulletPoint/InterviewBulletPoint";
import InterviewToolTipBox from "components/common/InterviewToolTipBox/InterviewToolTipBox";
import { InterviewAdviceNumberEnum } from "utils/constants/enums/InterviewAdviceNumberEnum";
import { LastProgressNumberEnum } from "utils/constants/enums/LastProgressNumberEnum";

const CompanyInformations = () => {
  const { t } = useTranslation("interview");
  const {
    companyContent,
    activitiesContent,
    companyAdviceC1,
    companyValues,
    valuesContent,
    companyAdviceC2,
    simulation,
    companyAdviceC3,
  } = useSummaryInterviewData();

  const { companyTitle, companyActivites, noAnswer, companySimulationTitle } =
    t("interviewSummary", {
      returnObjects: true,
      company: companyContent,
    });

  return (
    <section name="companyInformations">
      <div
        name="companyTitle"
        className={InterviewSummaryStyles.containerTitle}
      >
        <Interweave
          className={InterviewSummaryStyles.sectionTitle}
          content={companyTitle}
        />
      </div>

      <div name="companyContent">
        <div className={InterviewSummaryStyles.containerInfos}>
          <Interweave
            className={InterviewSummaryStyles.sectionSubtitle}
            content={companyActivites}
          />
          {activitiesContent?.map((activity) => (
            <InterviewBulletPoint key={uuidv4()} content={activity} />
          ))}
          <InterviewToolTipBox
            toolTipTextTitle={t("interviewSummary.toolTipTextTitle", {
              number:
                InterviewAdviceNumberEnum[
                  LastProgressNumberEnum.InterviewCompany.C1
                ],
            })}
            toolTipTextContent={companyAdviceC1}
          />
          <div className={InterviewSummaryStyles.separator} />
          <Interweave
            className={InterviewSummaryStyles.sectionSubtitle}
            content={companyValues}
          />
          {valuesContent?.length > 0 ? (
            valuesContent.map((value) => (
              <InterviewBulletPoint key={uuidv4()} content={value} />
            ))
          ) : (
            <Interweave
              className={InterviewSummaryStyles.sectionContentBlock}
              content={noAnswer}
            />
          )}
          <InterviewToolTipBox
            toolTipTextTitle={t("interviewSummary.toolTipTextTitle", {
              number:
                InterviewAdviceNumberEnum[
                  LastProgressNumberEnum.InterviewCompany.C2
                ],
            })}
            toolTipTextContent={companyAdviceC2}
          />
          <div className={InterviewSummaryStyles.separator} />
          <Interweave
            className={InterviewSummaryStyles.sectionSubtitle}
            content={companySimulationTitle}
          />
          <Interweave
            className={InterviewSummaryStyles.sectionContentBlock}
            content={simulation || noAnswer}
          />
          <InterviewToolTipBox
            toolTipTextTitle={t("interviewSummary.toolTipTextTitle", {
              number:
                InterviewAdviceNumberEnum[
                  LastProgressNumberEnum.InterviewCompany.C3
                ],
            })}
            toolTipTextContent={companyAdviceC3}
          />
        </div>
      </div>
    </section>
  );
};

export default CompanyInformations;

import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { Button, TextField } from "@mui/material";

import { saveAnswer, saveLastProgress } from "store/users/usersSlice";
import { selectUserUID } from "store/users/usersSelectors";
import {
  startForm,
  updateUserQuestionnaireAction,
} from "domain/user/userControllers";

import Icon from "components/common/Icon";

const QuestionTextForm = ({ initialValue, stepData }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [isDisabled, setDisabled] = useState(!initialValue);

  const { t } = useTranslation(["path", "solutions"]);

  const userId = useSelector(selectUserUID);

  const action = stepData.actions?.[0];

  useEffect(() => {
    startForm(userId);
  }, [userId]);

  // Saving the field values on input change
  const onChangeCallback = useCallback(
    (e) => {
      if (e?.target?.value?.length >= 0) {
        setDisabled(false);
        const answersChanged = [
          {
            aid: "",
            value: e.target.value,
          },
        ];
        const answer = {
          qid: stepData.qid,
          question: stepData.question,
          type: stepData.type,
          answers: answersChanged,
        };
        dispatch(saveAnswer(answer));
      }
    },
    [stepData.qid, stepData.question, stepData.type, dispatch]
  );

  // Submit the form, Save in Database and go to next page
  const onSubmitCallback = useCallback(
    (e) => {
      window.scrollTo({ top: 0 });
      e.preventDefault();
      dispatch(saveLastProgress(stepData.progress));
      if (userId) {
        dispatch(updateUserQuestionnaireAction());
      }
      history.push(t("path:path.questionnaire") + "/" + action.path);
    },
    [dispatch, stepData.progress, userId, history, t, action.path]
  );

  return (
    <form onSubmit={onSubmitCallback}>
      <TextField
        placeholder={isDisabled ? stepData.placeHolder : initialValue}
        inputProps={{
          maxLength: 20,
        }}
        onChange={onChangeCallback}
        fullWidth
      />
      <Button
        sx={{ mt: 10 }}
        type="submit"
        size="large"
        fullWidth
        variant="contained"
        color="orange"
        disabled={isDisabled}
        endIcon={<Icon iconName="arrowRightShort" iconSize={16} />}
      >
        {action?.value?.text}
      </Button>
    </form>
  );
};

export default React.memo(QuestionTextForm);

import Path from "utils/translations/fr/path.json";
import { LastProgressNumberEnum } from "./LastProgressNumberEnum";

//For back button

export const MapPathnameToProgressNumberEnum = {
  // Home
  [Path["path.interview"]]: {
    progressNumber: LastProgressNumberEnum.InterviewStart[0],
    nextPage: Path["path.interview.start"],
  },
  // Fiche entretien
  [Path["path.interview.start"]]: {
    progressNumber: LastProgressNumberEnum.InterviewStart[1],
    nextPage: Path["path.interview.email"],
    previousPage: Path["path.interview"],
  },
  // Email
  [Path["path.interview.email"]]: {
    progressNumber: LastProgressNumberEnum.InterviewStart[2],
    nextPage: Path["path.interview.company.step"].replace(":step", "etape"),
    previousPage: Path["path.interview.start"],
  },

  // Company
  [Path["path.interview.company.step"].replace(":step", "etape")]: {
    progressNumber: LastProgressNumberEnum.InterviewSteps[1],
    nextPage: Path["path.interview.company.question1"],
    previousPage: Path["path.interview.email"],
  },
  // InterviewCompany Section
  [Path["path.interview.company.question1"]]: {
    progressNumber: LastProgressNumberEnum.InterviewCompany.Q1,
    nextPage: Path["path.interview.company.advice1"],
    previousPage: Path["path.interview.company.step"].replace(":step", "etape"),
  },
  [Path["path.interview.company.advice1"]]: {
    progressNumber: LastProgressNumberEnum.InterviewCompany.C1,
    nextPage: Path["path.interview.company.question2"],
    previousPage: Path["path.interview.company.question1"],
  },
  [Path["path.interview.company.question2"]]: {
    progressNumber: LastProgressNumberEnum.InterviewCompany.Q2,
    nextPage: Path["path.interview.company.advice2"],
    previousPage: Path["path.interview.company.advice1"],
  },
  [Path["path.interview.company.advice2"]]: {
    progressNumber: LastProgressNumberEnum.InterviewCompany.C2,
    nextPage: Path["path.interview.company.question3"],
    previousPage: Path["path.interview.company.question2"],
  },
  [Path["path.interview.company.question3"]]: {
    progressNumber: LastProgressNumberEnum.InterviewCompany.Q3,
    nextPage: Path["path.interview.company.advice3"],
    previousPage: Path["path.interview.company.advice2"],
  },
  [Path["path.interview.company.advice3"]]: {
    progressNumber: LastProgressNumberEnum.InterviewCompany.C3,
    nextPage: Path["path.interview.job.step"].replace(":step", "etape"),
    previousPage: Path["path.interview.company.question3"],
  },

  // Job
  [Path["path.interview.job.step"].replace(":step", "etape")]: {
    progressNumber: LastProgressNumberEnum.InterviewSteps[2],
    nextPage: Path["path.interview.job.question1"],
    previousPage: Path["path.interview.company.advice3"],
  },
  // InterviewJob Section:
  [Path["path.interview.job.question1"]]: {
    progressNumber: LastProgressNumberEnum.InterviewJob.Q1,
    nextPage: Path["path.interview.job.advice1"],
    previousPage: Path["path.interview.job.step"].replace(":step", "etape"),
  },
  [Path["path.interview.job.advice1"]]: {
    progressNumber: LastProgressNumberEnum.InterviewJob.C1,
    nextPage: Path["path.interview.job.question2"],
    previousPage: Path["path.interview.job.question1"],
  },
  [Path["path.interview.job.question2"]]: {
    progressNumber: LastProgressNumberEnum.InterviewJob.Q2,
    nextPage: Path["path.interview.job.advice2"],
    previousPage: Path["path.interview.job.advice1"],
  },
  [Path["path.interview.job.advice2"]]: {
    progressNumber: LastProgressNumberEnum.InterviewJob.C2,
    nextPage: Path["path.interview.job.question3"],
    previousPage: Path["path.interview.job.question2"],
  },
  [Path["path.interview.job.question3"]]: {
    progressNumber: LastProgressNumberEnum.InterviewJob.Q3,
    nextPage: Path["path.interview.job.question4"],
    previousPage: Path["path.interview.job.advice2"],
  },
  [Path["path.interview.job.question4"]]: {
    progressNumber: LastProgressNumberEnum.InterviewJob.Q4,
    nextPage: Path["path.interview.job.advice3"],
    previousPage: Path["path.interview.job.question3"],
  },
  [Path["path.interview.job.advice3"]]: {
    progressNumber: LastProgressNumberEnum.InterviewJob.C3,
    nextPage: Path["path.interview.profile.step"].replace(":step", "etape"),
    previousPage: Path["path.interview.job.question4"],
  },

  // Profile
  [Path["path.interview.profile.step"].replace(":step", "etape")]: {
    progressNumber: LastProgressNumberEnum.InterviewSteps[2],
    nextPage: Path["path.interview.profile.question1"],
    previousPage: Path["path.interview.job.advice3"],
  },
  // Profile Section:
  [Path["path.interview.profile.question1"].replace(":skillNumber", "1")]: {
    progressNumber: LastProgressNumberEnum.InterviewProfile.Q1[1],
    nextPage: Path["path.interview.profile.question2"].replace(
      ":skillNumber",
      "1"
    ),
    previousPage: Path["path.interview.profile.step"].replace(":step", "etape"),
  },
  [Path["path.interview.profile.question2"].replace(":skillNumber", "1")]: {
    progressNumber: LastProgressNumberEnum.InterviewProfile.Q2[1],
    nextPage: Path["path.interview.profile.question1"].replace(
      ":skillNumber",
      "2"
    ),
    previousPage: Path["path.interview.profile.question1"].replace(
      ":skillNumber",
      "1"
    ),
  },
  [Path["path.interview.profile.question1"].replace(":skillNumber", "2")]: {
    progressNumber: LastProgressNumberEnum.InterviewProfile.Q1[2],
    nextPage: Path["path.interview.profile.question2"].replace(
      ":skillNumber",
      "2"
    ),
    previousPage: Path["path.interview.profile.question2"].replace(
      ":skillNumber",
      "1"
    ),
  },
  [Path["path.interview.profile.question2"].replace(":skillNumber", "2")]: {
    progressNumber: LastProgressNumberEnum.InterviewProfile.Q2[2],
    nextPage: Path["path.interview.profile.question1"].replace(
      ":skillNumber",
      "3"
    ),
    previousPage: Path["path.interview.profile.question1"].replace(
      ":skillNumber",
      "2"
    ),
  },
  [Path["path.interview.profile.question1"].replace(":skillNumber", "3")]: {
    progressNumber: LastProgressNumberEnum.InterviewProfile.Q1[3],
    nextPage: Path["path.interview.profile.question2"].replace(
      ":skillNumber",
      "3"
    ),
    previousPage: Path["path.interview.profile.question2"].replace(
      ":skillNumber",
      "2"
    ),
  },
  [Path["path.interview.profile.question2"].replace(":skillNumber", "3")]: {
    progressNumber: LastProgressNumberEnum.InterviewProfile.Q2[3],
    nextPage: Path["path.interview.finish"],
    previousPage: Path["path.interview.profile.question1"].replace(
      ":skillNumber",
      "3"
    ),
  },

  //profile advice skillRate 1
  [Path["path.interview.profile.advice"].replace(":skillNumber", "1")]: {
    progressNumber: LastProgressNumberEnum.InterviewProfile.C1[1],
    nextPage: Path["path.interview.profile.question1"].replace(
      ":skillNumber",
      "2"
    ),
    previousPage: Path["path.interview.profile.question1"].replace(
      ":skillNumber",
      "1"
    ),
  },
  //profile advice skillRate 2
  [Path["path.interview.profile.advice"].replace(":skillNumber", "2")]: {
    progressNumber: LastProgressNumberEnum.InterviewProfile.C1[2],
    nextPage: Path["path.interview.profile.question2"].replace(
      ":skillNumber",
      "2"
    ),
    previousPage: Path["path.interview.profile.question1"].replace(
      ":skillNumber",
      "2"
    ),
  },
  //profile advice skillRate 3
  [Path["path.interview.profile.advice"].replace(":skillNumber", "3")]: {
    progressNumber: LastProgressNumberEnum.InterviewProfile.C1[2],
    nextPage: Path["path.interview.profile.question2"].replace(
      ":skillNumber",
      "2"
    ),
    previousPage: Path["path.interview.profile.question1"].replace(
      ":skillNumber",
      "3"
    ),
  },
  // Finish
  [Path["path.interview.finish"]]: {
    progressNumber: LastProgressNumberEnum.InterviewEnd[1],
    // Temporarily linked with the page 'step', waiting for the dev of 'question2'
    previousPage: Path["path.interview.profile.question2"].replace(
      ":skillNumber",
      "3"
    ),
    // Temporarily linked with the page 'finish', waiting for the dev of 'resume'
    nextPage: Path["path.interview.finish"],
  },
};

import {
  Box,
  Button,
  IconButton,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useState, useCallback } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import clicRDVDataGA from "config/googleAnalytics/clicRDVDataGA";

import { getPillImageUrlFromFirebaseDevStorage } from "utils/helpers/assetsHelpers";
import SourceClickRDVEnum from "utils/constants/enums/SourceClickRDVEnum";

import Icon from "components/common/Icon";

const AppointementSticky = () => {
  const theme = useTheme();
  const isScreenDownSM = useMediaQuery(theme.breakpoints.down("sm"));
  const { pathname } = useLocation();

  const { t } = useTranslation(["calendly", "path"]);
  const stickyTexts = t("sticky", { returnObjects: true });

  const history = useHistory();

  const [isOpen, setOpen] = useState(true);

  const redirectToCalendlyPage = useCallback(() => {
    if (
      pathname.includes("questionnaire/q2") ||
      pathname.includes("questionnaire/q3") ||
      pathname.includes("questionnaire/q4")
    ) {
      clicRDVDataGA(SourceClickRDVEnum.QUESTIONNAIRE_FROM_SECTOR);
    } else if (pathname.includes("questionnaire/resume")) {
      clicRDVDataGA(SourceClickRDVEnum.QUESTIONNAIRE_FROM_RESUME);
    } else if (pathname.includes("questionnaire-email")) {
      clicRDVDataGA(SourceClickRDVEnum.QUESTIONNAIRE_FROM_EMAIL);
    } else if (pathname.includes(t("path:path.solutions"))) {
      clicRDVDataGA(SourceClickRDVEnum.QUESTIONNAIRE_FROM_RESULT);
    }
    history.push(t("path:path.calendly"));
  }, [history, pathname, t]);

  return (
    <>
      {isOpen ? (
        <Box
          position="sticky"
          bottom={4}
          zIndex={100}
          width={"100%"}
          display="flex"
          flexDirection="row"
          height={150}
          left="100vw"
          maxWidth={isScreenDownSM ? "100%" : "400px"}
        >
          <Box width={20} />
          <Box
            width={"100%"}
            display="flex"
            flexDirection="row"
            borderRadius="20px"
            sx={{
              backgroundColor: "#EEF4FE",
            }}
            marginBottom={isScreenDownSM ? "4px" : "26px"}
            padding={4}
          >
            <Box
              paddingRight={4}
              height={"100%"}
              display={"flex"}
              alignItems={"center"}
              width={120}
            >
              <img
                src={getPillImageUrlFromFirebaseDevStorage(
                  "PillPaulineSmallBlue.png"
                )}
                alt={stickyTexts.iconAlt}
                style={{ width: "100%" }}
              ></img>
            </Box>
            <Box
              display={"flex"}
              alignItems={"start"}
              justifyContent={"space-around"}
              flexDirection={"column"}
              width={"100%"}
            >
              <Box>
                <Typography fontFamily={"ProximaNovaBold"} fontSize={14}>
                  {stickyTexts.title}
                </Typography>
                <Typography fontFamily={"ProximaNova"} fontSize={14}>
                  {stickyTexts.content}
                </Typography>
              </Box>
              <Button
                variant="outlined"
                startIcon={<Icon iconName={"videoFill"} iconSize={16} />}
                color="black"
                size="small"
                sx={{ background: "transparent" }}
                onClick={redirectToCalendlyPage}
              >
                {stickyTexts.cta}
              </Button>
            </Box>
            <Box width="16px">
              <IconButton
                sx={{
                  background: "transparent",
                  padding: 0,
                }}
                onClick={() => {
                  setOpen(false);
                }}
              >
                <ExpandMoreIcon />
              </IconButton>
            </Box>
          </Box>
          <Box width={20} />
        </Box>
      ) : (
        <Box
          component="img"
          src={getPillImageUrlFromFirebaseDevStorage(
            "PillPaulineSmallBlue.png"
          )}
          alt={stickyTexts.iconAlt}
          width={100}
          position="sticky"
          zIndex={100}
          bottom={isScreenDownSM ? 4 : 30}
          marginRight={"20px"}
          left="100vw"
          sx={{
            cursor: "pointer",
          }}
          marginBottom={isScreenDownSM ? 0 : "26px"}
          onClick={() => {
            setOpen(true);
          }}
        />
      )}
    </>
  );
};

export default AppointementSticky;

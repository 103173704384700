import {
  saveInterviewCompanyAdvice,
  saveInterviewJobAdvice,
  saveInterviewProfileAdvice,
} from "domain/interview/interviewControllers";
import { TrainingProgressFactory } from "domain/interview/interviewModels";
import InterviewPageEnum from "./InterviewPageEnum";
import { LastProgressNumberEnum } from "./LastProgressNumberEnum";
import { TrainingTagEnum } from "./TrainingTagEnum";
import InterviewJson from "utils/translations/fr/interview.json";
import Path from "utils/translations/fr/path.json";
import { InterviewAdviceNumberEnum } from "./InterviewAdviceNumberEnum";

const MapAdvicePageEnum = {
  [InterviewPageEnum.EC]: {
    1: {
      trainingProgress: TrainingProgressFactory(
        TrainingTagEnum.InterviewCompany,
        LastProgressNumberEnum.InterviewCompany["C1"]
      ).instance,
      text: InterviewJson.interviewAdvice?.textAdviceEC1,
      adviceNumber:
        InterviewAdviceNumberEnum[
          LastProgressNumberEnum.InterviewCompany["C1"]
        ],
      path: Path["path.interview.company.question2"],
      controller: saveInterviewCompanyAdvice,
    },
    2: {
      trainingProgress: TrainingProgressFactory(
        TrainingTagEnum.InterviewCompany,
        LastProgressNumberEnum.InterviewCompany["C2"]
      ).instance,
      text: InterviewJson.interviewAdvice?.textAdviceEC2,
      adviceNumber:
        InterviewAdviceNumberEnum[
          LastProgressNumberEnum.InterviewCompany["C2"]
        ],
      path: Path["path.interview.company.question3"],
      controller: saveInterviewCompanyAdvice,
    },
    3: {
      trainingProgress: TrainingProgressFactory(
        TrainingTagEnum.InterviewCompany,
        LastProgressNumberEnum.InterviewCompany["C3"]
      ).instance,
      text: InterviewJson.interviewAdvice?.textAdviceEC3,
      adviceNumber:
        InterviewAdviceNumberEnum[
          LastProgressNumberEnum.InterviewCompany["C3"]
        ],
      path: Path["path.interview.job.step"].replace(":step", "etape"),
      controller: saveInterviewCompanyAdvice,
    },
  },
  [InterviewPageEnum.PC]: {
    1: {
      trainingProgress: TrainingProgressFactory(
        TrainingTagEnum.InterviewJob,
        LastProgressNumberEnum.InterviewJob["C1"]
      ).instance,
      text: InterviewJson.interviewAdvice?.textAdvicePC1,
      adviceNumber:
        InterviewAdviceNumberEnum[LastProgressNumberEnum.InterviewJob["C1"]],
      path: Path["path.interview.job.question2"],
      controller: saveInterviewJobAdvice,
    },
    2: {
      trainingProgress: TrainingProgressFactory(
        TrainingTagEnum.InterviewJob,
        LastProgressNumberEnum.InterviewJob["C2"]
      ).instance,
      text: InterviewJson.interviewAdvice?.textAdvicePC2,
      adviceNumber:
        InterviewAdviceNumberEnum[LastProgressNumberEnum.InterviewJob["C2"]],
      path: Path["path.interview.job.question3"],
      controller: saveInterviewJobAdvice,
    },
    3: {
      trainingProgress: TrainingProgressFactory(
        TrainingTagEnum.InterviewJob,
        LastProgressNumberEnum.InterviewJob["C3"]
      ).instance,
      text: InterviewJson.interviewAdvice?.textAdvicePC3,
      adviceNumber:
        InterviewAdviceNumberEnum[LastProgressNumberEnum.InterviewJob["C3"]],
      path: Path["path.interview.profile.step"].replace(":step", "etape"),
      controller: saveInterviewJobAdvice,
    },
  },
  [InterviewPageEnum.SC]: {
    1: {
      trainingProgress: TrainingProgressFactory(
        TrainingTagEnum.InterviewProfile,
        LastProgressNumberEnum.InterviewProfile["C1"]["1"]
      ).instance,
      text: InterviewJson.interviewAdvice?.textAdviceSC,
      adviceNumber:
        InterviewAdviceNumberEnum[
          LastProgressNumberEnum.InterviewProfile["C1"][1]
        ],
      path: Path["path.interview.profile.question1"].replace(
        ":skillNumber",
        "2"
      ),
      controller: saveInterviewProfileAdvice,
    },
    2: {
      trainingProgress: TrainingProgressFactory(
        TrainingTagEnum.InterviewProfile,
        LastProgressNumberEnum.InterviewProfile["C1"]["2"]
      ).instance,
      text: InterviewJson.interviewAdvice?.textAdviceSC,
      adviceNumber:
        InterviewAdviceNumberEnum[
          LastProgressNumberEnum.InterviewProfile["C1"][2]
        ],
      path: Path["path.interview.profile.question1"].replace(
        ":skillNumber",
        "3"
      ),
      controller: saveInterviewProfileAdvice,
    },
    3: {
      trainingProgress: TrainingProgressFactory(
        TrainingTagEnum.InterviewProfile,
        LastProgressNumberEnum.InterviewProfile["C1"]["3"]
      ).instance,
      text: InterviewJson.interviewAdvice?.textAdviceSC,
      adviceNumber:
        InterviewAdviceNumberEnum[
          LastProgressNumberEnum.InterviewProfile["C1"][3]
        ],
      path: Path["path.interview.finish"],
      controller: saveInterviewProfileAdvice,
    },
  },
};

export default MapAdvicePageEnum;

import { TrainingProgressFactory } from "domain/interview/interviewModels";
import { TrainingTagEnum } from "utils/constants/enums/TrainingTagEnum";
import { LastProgressNumberEnum } from "utils/constants/enums/LastProgressNumberEnum";

const interviewFormReducer = {
  saveFormInterviewData: (state, action) => {
    return {
      ...state,
      currentInterview: {
        ...state.currentInterview,
        interviewFormData: action.payload,
        trainingProgress: TrainingProgressFactory(
          TrainingTagEnum.InterviewStart,
          LastProgressNumberEnum.InterviewStart[1]
        ).instance,
      },
    };
  },
};

export default interviewFormReducer;

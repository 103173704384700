import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import Interweave from "interweave";
import { InlineWidget } from "react-calendly";
import { useHistory } from "react-router-dom";

import useQuery from "hooks/useQuery";
import env from "../../.env.json";

import { useCalendlyEventsEffect } from "domain/solution/solutionHooks";
import {
  selectUserAnswers,
  selectHydratedSolutions,
  selectUserCreateDate,
  selectUserUID,
  selectUserEmail,
} from "store/users/usersSelectors";

import CalendlyStyles from "./CalendlyPage.module.scss";

import { Button, Container, Typography, useTheme } from "@mui/material";

function CalendlyPage() {
  const theme = useTheme();

  const { t } = useTranslation("calendly");

  const widgetRef = useRef(null);

  const history = useHistory();

  const userUID = useSelector(selectUserUID);

  const userEmail = useSelector(selectUserEmail);

  const userAnswers = useSelector(selectUserAnswers);

  const inscriptionDate = useSelector(selectUserCreateDate);

  const hydratedSolutions = useSelector(selectHydratedSolutions);

  const [displayMentions, setDisplayMentions] = useState(false);

  const [displayBackToHomeScreen, setDisplayBackToHomeScreen] = useState(false);

  useCalendlyEventsEffect(
    widgetRef,
    userUID,
    inscriptionDate,
    setDisplayMentions,
    setDisplayBackToHomeScreen,
    userAnswers,
    hydratedSolutions,
    Boolean(userEmail) // hasSubmitedEmailInForm
  );

  let namePrefill = "";

  let emailPrefill = "";

  let stepName = "agequestion";

  const query = useQuery();

  if (query.get("name")) {
    namePrefill = query.get("name");
  }
  if (query.get("email")) {
    emailPrefill = query.get("email");
  }
  if (query.get("step")) {
    stepName = query.get("step");
  }
  return (
    <>
      <Typography
        variant="h1"
        textAlign="center"
        mt={20}
        fontSize={theme.typography.FontSizes.xl8}
      >
        <Interweave noWrap content={t("title")} />
      </Typography>

      <InlineWidget
        pageSettings={{
          primaryColor: "00827f",
        }}
        ref={widgetRef}
        styles={{
          minWidth: "100%",
          height: "100vh",
        }}
        prefill={{
          name: namePrefill,
          email: emailPrefill,
        }}
        url={
          stepName === "agequestion"
            ? env.calendly_rdv_link_step_1
            : env.calendly_rdv_link_step_2
        }
      />
      {displayMentions && (
        <Interweave
          tagName="div"
          className={CalendlyStyles.mentions}
          content={t("mentions")}
        />
      )}
      {displayBackToHomeScreen && (
        <Container
          maxWidth="xl"
          sx={{ display: "flex", justifyContent: "center", mt: -20, mb: 20 }}
        >
          <Button
            variant="contained"
            color="orange"
            size="large"
            onClick={() => {
              history.push("/");
            }}
          >
            {t("backToHome")}
          </Button>
        </Container>
      )}
    </>
  );
}

export default CalendlyPage;

import { LastProgressNumberEnum } from "utils/constants/enums/LastProgressNumberEnum";

const interviewAdviceReducer = {
  saveInterviewAdviceData: (state, action) => {
    // remove stored advice if the id of the new advice match
    const advices = (state.currentInterview.advices || []).filter((advice) => {
      return advice.id !== action.payload.advice.id;
    });

    return {
      ...state,
      currentInterview: {
        ...state.currentInterview,
        trainingProgress: action.payload.trainingProgress,
        advices: [...advices, action.payload.advice],
      },
    };
  },

  saveInterviewProfileAdviceData: (state, action) => {
    // remove stored advice if the id of the new advice match
    const advices = (state.currentInterview.advices || []).filter((advice) => {
      return (
        advice.id !==
        ![
          LastProgressNumberEnum.InterviewProfile.C1[1],
          LastProgressNumberEnum.InterviewProfile.C1[2],
          LastProgressNumberEnum.InterviewProfile.C1[3],
        ].includes(advice.id)
      );
    });

    return {
      ...state,
      currentInterview: {
        ...state.currentInterview,
        trainingProgress: action.payload.trainingProgress,
        advices: [...advices, action.payload.advice],
      },
    };
  },
};

export default interviewAdviceReducer;

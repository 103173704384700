import { createSlice } from "@reduxjs/toolkit";
import StoreKeys from "utils/constants/store-keys.json";
import interviewFormReducer from "store/interview/reducers/interviewFormReducer";
import interviewEmailReducer from "store/interview/reducers/interviewEmailReducer";
import newInterviewReducer from "./reducers/interviewHomeReducer";
import interviewCompanyReducer from "./reducers/interviewCompanyReducer";
import interviewProgressReducer from "./reducers/interviewProgressReducer";
import saveInterviewAdviceReducer from "./reducers/interviewAdviceReducer";
import interviewJobReducer from "./reducers/interviewJobReducer";
import jobDataReducer from "./reducers/jobDataReducer";
import interviewProfileReducer from "./reducers/interviewProfileReducer";

const initialState = {
  currentInterview: null,
  isCurrentInterviewLoading: false,
  interviewsById: {},
  jobData: {
    poleEmploieData: {},
  },
};

export const interviewSlice = createSlice({
  name: StoreKeys.INTERVIEW,
  initialState,
  reducers: {
    // ADD here the redux ...nameOfReducer
    ...interviewFormReducer,
    ...interviewEmailReducer,
    ...interviewCompanyReducer,
    ...interviewJobReducer,
    ...interviewProfileReducer,
    ...newInterviewReducer,
    ...interviewProgressReducer,
    ...saveInterviewAdviceReducer,
    ...jobDataReducer,
    setCurrentInterviewLoadingState: (state, action) => ({
      ...state,
      isCurrentInterviewLoading: action.payload,
    }),
  },
});

export const {
  // Add the name of action (nameOfReduxAction***)
  saveFormInterviewData,
  createNewInterviewData,
  saveEmailInterviewData,
  saveCompanyActivitiesInterviewData,
  saveCompanyValuesInterviewData,
  saveInterviewProgressData,
  saveInterviewAdviceData,
  saveInterviewProfileAdviceData,
  saveCompanySimulationInterviewData,
  saveJobSoftskillsInterviewData,
  saveJobDetailsInterviewData,
  saveJobDetailsfromPoleEmploiData,
  saveJobChallengesInterviewData,
  saveJobBestofInterviewData,
  saveJobDetailPoleEmploieData,
  saveJobCodeRomeAndLibelleAppellationCourt,
  saveProfileSkillRateInterviewData,
  saveProfileSkillExampleInterviewData,
  resetProfileSkillInterviewData,
  setCurrentInterviewLoadingState,
} = interviewSlice.actions;

export default interviewSlice.reducer;

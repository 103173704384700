import type { TikTokEventType } from "./tiktokEventTypes";
import { Model } from "utils/helpers/modelHelpers";

class TikTokEventModel extends Model {
  constructor() {
    const tiktokEventInstance: TikTokEventType = {
      eventTime: 0,
      event: "",
      eventId: "",
      pageUrl: "",
      userAgent: "",
      userId: "",
    };
    super(tiktokEventInstance);
  }
}

export const newTikTokEventModel = () => {
  return new TikTokEventModel();
};

export const tiktokEventFactory = (
  eventTime,
  event,
  eventId,
  pageUrl,
  userAgent,
  userId,
  phone,
  email
) => {
  const tiktokEventData = {};
  if (eventTime) tiktokEventData.eventTime = eventTime;
  if (event) tiktokEventData.event = event;
  if (eventId) tiktokEventData.eventId = eventId;
  if (pageUrl) tiktokEventData.pageUrl = pageUrl;
  if (userAgent) tiktokEventData.userAgent = userAgent;
  if (userId) tiktokEventData.userId = userId;
  if (phone) tiktokEventData.phone = phone;
  if (email) tiktokEventData.email = email;
  return tiktokEventData;
};

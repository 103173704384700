export const selectUserAnswers = (state) => state.users?.questionWithAnswers;

export const selectTempAnswer = (state) => state.users?.tempAnswer;

export const selectUsers = (state) => state.users;

export const selectUser = (state) => state.users?.user;

export const selectLastProgress = (state) => state.users?.lastProgress;

export const selectUserEmail = (state) => state.users?.user?.email;

export const selectUserFirstName = (state) => state.users?.user?.firstName;

export const selectUserUID = (state) => state.users?.user?.uid;

export const selectUserCreateDate = (state) => state.users?.user?.createDate;

export const selectHydratedSolutions = (state) =>
  state.users?.hydratedSolutions;

export const selectTokenTreeId = (state) => state.users?.treeTokenId;

export const selectAuthTokenBackoffice = (state) =>
  state.users?.authTokenBackoffice;

export const selectUserQuestionnaire = (state) =>
  state.users?.user?.questionnaire;

export const selectUserDiagnostics = (state) =>
  state.users?.user?.allDiagnostics;

export const selectUserAnalyticSolutions = (state) =>
  state.users?.user?.analyticSolutions || {};

export const selectCalendlyQuestionsAndAnswers = (state) =>
  state.users?.user?.calendlyQuestionsAndAnswers;

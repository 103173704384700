import { withFormik } from "formik";
import { connect } from "react-redux";
import { compose } from "recompose";
import { withRouter } from "react-router-dom";
import React from "react";

import { selectCurrentInterview } from "store/interview/interviewSelectors";
import { InterviewInitialValues } from "domain/interview/interviewFormUtils";

/**
 *
 * @param {string} displayName The name that identifies the form
 * @param {function} dispatch The trigger action on the event form submit
 * @param {object} initialValues The initial values of the form
 * @param {object(yup)} validationSchema The condition to enable the submission of the form
 * @returns
 */
export const withConnectedFormik =
  (displayName, dispatch, initialValues, validationSchema = {}) =>
  (MyForm) =>
    compose(
      // TODO(Jong): try to seperate the redux connect to a customized HOC
      connect(
        (state) => ({ currentInterview: selectCurrentInterview(state) }),
        { dispatch }
      ),
      withRouter,
      withFormik({
        // TODO(Jong): try to seperate the logic of mapPropsToValues and InterviewInitialValues to a customized HOC
        mapPropsToValues: ({ currentInterview }) =>
          InterviewInitialValues[displayName]
            ? InterviewInitialValues[displayName](currentInterview)
            : initialValues,
        handleSubmit: (values, { props }) => {
          props.dispatch(values, props.history);
        },
        displayName,
        validationSchema,
      })
    )(
      React.memo(({ handleSubmit, ...props }) => (
        <form onSubmit={handleSubmit}>
          <MyForm {...props} />
        </form>
      ))
    );

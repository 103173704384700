const InterviewPageEnum = {
  COMPANY: "entreprise",
  JOB: "poste",
  PROFILE: "profil",
  HOME: "accueil",
  EC: "EC",
  PC: "PC",
  SC: "SC",
};

export default InterviewPageEnum;

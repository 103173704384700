import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";

import { Box, Container, Tab, Tabs, useTheme } from "@mui/material";

import { getNumbersOfSolutions } from "utils/helpers/solutionsHelper";
import SolutionsNumberPill from "../SolutionsNumberPill";
import clickFilterSolutionsDataGA from "config/googleAnalytics/clickFilterSolutionsDataGA";

const CustomTabs = ({ tabValue, handleTabsChange, formations }) => {
  const {
    palette: { green, brown },
  } = useTheme();

  const { t } = useTranslation(["solutions"]);
  const { tabText_1, tabText_2, tabText_3, tabText_4 } = t("tabText", {
    returnObjects: true,
  });

  const {
    totalNumberOfSolutions,
    numberOfSolutionTypeFormation,
    numberOfSolutionTypeOutil,
    numberOfSolutionTypeAccompagnement,
  } = useMemo(() => getNumbersOfSolutions(formations), [formations]);

  const tabsData = useMemo(
    () => [
      { label: tabText_1, count: totalNumberOfSolutions },
      { label: tabText_2, count: numberOfSolutionTypeFormation },
      { label: tabText_3, count: numberOfSolutionTypeOutil },
      { label: tabText_4, count: numberOfSolutionTypeAccompagnement },
    ],
    [
      tabText_1,
      tabText_2,
      tabText_3,
      tabText_4,
      totalNumberOfSolutions,
      numberOfSolutionTypeFormation,
      numberOfSolutionTypeOutil,
      numberOfSolutionTypeAccompagnement,
    ]
  );

  const renderTab = useCallback(
    (index, label, count) => (
      <Tab
        key={index}
        value={index}
        onClick={() => {
          clickFilterSolutionsDataGA(label);
        }}
        label={
          <Box display="flex" alignItems="center">
            {label}
            <SolutionsNumberPill
              backgroundColor={tabValue === index ? green.main : brown.brown700}
              color={
                tabValue === index ? green.complementary : brown.complementary
              }
              count={count}
            />
          </Box>
        }
        tabtype="tabGreen"
      />
    ),
    [
      tabValue,
      green.main,
      brown.brown700,
      green.complementary,
      brown.complementary,
    ]
  );

  const tabs = useMemo(() => {
    return tabsData.map(
      (tab, index) => tab.count > 0 && renderTab(index, tab.label, tab.count)
    );
  }, [renderTab, tabsData]);

  return (
    <Container maxWidth="xl">
      <Tabs value={tabValue} onChange={handleTabsChange}>
        {tabs}
      </Tabs>
    </Container>
  );
};

export default CustomTabs;

const MuiDrawer = {
  styleOverrides: {
    paper: ({ theme }) => ({
      width: "240px",
      padding: theme.spacing(2),
      backgroundColor: theme.palette.background.paper,
      color: theme.palette.text.primary,
      "&:hover": {
        backgroundColor: theme.palette.grey[200],
      },
    }),
  },
};

export default MuiDrawer;

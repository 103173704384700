import Path from "utils/translations/fr/path.json";
import InterviewPageEnum from "utils/constants/enums/InterviewPageEnum";
import MapAdvicePageEnum from "utils/constants/enums/MapAdvicePageEnum";
import { MapLastProgressNumberToPathnameEnum } from "utils/constants/enums/MapLastProgressNumberToPathnameEnum";

/**
 * Translates the profile skills with their rates.
 *
 * @param {Array} profileSkills - The array of profile skills.
 * @param {Object} profileSkillsTexts - The object containing translations for skill rates.
 * @returns {Array} - The translated profile skills array.
 */
export const getProfileSkillWithRateTranslations = (
  profileSkills,
  profileSkillsTexts
) => {
  const { skillRate_0, skillRate_1, skillRate_2, noSkillExamples } =
    profileSkillsTexts;
  return profileSkills.map((skill) => {
    let rateTranslation = skillRate_2;
    if (skill.rate === 0) {
      rateTranslation = skillRate_0;
    } else if (skill.rate === 1) {
      rateTranslation = skillRate_1;
    }
    skill = {
      ...skill,
      rateTranslation,
      example: skill.example
        ? `Exemple: \n ${skill.example}`
        : noSkillExamples?.[skill.rate] || "",
      hasExample: Boolean(skill.example),
    };
    return skill;
  });
};

/**
 * Transforms profile skills into an array of skills with translated rates for PDF use.
 *
 * @param {Array} profileSkills - The array of profile skills.
 * @param {Object} profileSkillsTexts - The object containing translations for skill rates.
 * @returns {Array} - The array of profile skills with translated rates.
 */
export const getProfileSkillWithRateTranslationsForPdfUse = (
  profileSkills,
  profileSkillsTexts
) => {
  const { skillRate_0, skillRate_1, skillRate_2, noSkillExamples } =
    profileSkillsTexts;
  return profileSkills.map((skill) => {
    let rateTranslation = skillRate_2;
    if (skill.rate === 0) {
      rateTranslation = skillRate_0;
    } else if (skill.rate === 1) {
      rateTranslation = skillRate_1;
    }
    skill = {
      ...skill,
      rateTranslation,
      example: skill.example,
      tips: noSkillExamples?.[skill.rate] || [],
      hasExample: Boolean(skill.example),
      styleTagName: `profileSkillTag${skill.rate}`,
    };
    return skill;
  });
};

/**
 * Dynamicaly selecting the previous page using the path, profile skills data
 *
 * @param {Object} profileSkills [0 : {name: string, rate: number, id: string, exemple: string, showAdvice: boolean}, 1: ..., 2: ...]
 * @param {number} skill current skill number, InterviewFinish number will be 4 by default
 * @param {string} path for question 2 example page
 * @returns {string} path for previous page
 */
export const getPreviousPageDynamicaly = (profileSkills, skill, path) => {
  let previousPage = path;

  const previousSkill = profileSkills?.filter(
    (element) => element.rate === 0 && element.id === `${skill - 1}`
  );

  if (previousSkill && previousSkill?.length !== 0) {
    if (
      skill !== "1" &&
      Object.values(...previousSkill).includes(true) &&
      !path.includes("note")
    ) {
      previousPage = Path["path.interview.profile.advice"].replace(
        ":skillNumber",
        `${skill - 1}`
      );
    }
    previousSkill?.forEach((element) => {
      if (!element.showAdvice && !path.includes("note")) {
        previousPage = Path["path.interview.profile.question1"].replace(
          ":skillNumber",
          `${skill - 1}`
        );
      }
    });
  }

  return previousPage;
};

/**
 * Dynamicaly selecting the next page using the lastProgressNumber info, profile skills data
 *
 * @param {Object} profileSkills [0 : {name: string, rate: number, id: string, exemple: string, showAdvice: boolean}, 1: ..., 2: ...]
 * @param {number} lastProgressNumber last progress number
 * @returns {string} path of next page
 */
export const getNextPageDynamically = (profileSkills, lastProgressNumber) => {
  let nextPage =
    MapLastProgressNumberToPathnameEnum[lastProgressNumber]?.nextPage;

  if (lastProgressNumber === "3.1q.1") {
    const skillAdvice = profileSkills.find((skill) => skill.id === "1");

    if (skillAdvice?.rate === 0 && skillAdvice?.showAdvice) {
      nextPage =
        MapLastProgressNumberToPathnameEnum[lastProgressNumber]?.advicePage;
    }
  }

  if (lastProgressNumber === "3.1q.2") {
    const skillAdvice = profileSkills.find((skill) => skill.id === "2");

    if (skillAdvice?.rate === 0 && skillAdvice?.showAdvice) {
      nextPage =
        MapLastProgressNumberToPathnameEnum[lastProgressNumber]?.advicePage;
    }
    if (skillAdvice?.rate === 0 && !skillAdvice?.showAdvice) {
      nextPage = MapAdvicePageEnum[InterviewPageEnum.SC][2].path;
    }
  }

  if (lastProgressNumber === "3.1q.3") {
    const skillAdvice = profileSkills.find((skill) => skill.id === "3");

    if (skillAdvice?.rate === 0 && skillAdvice?.showAdvice) {
      nextPage =
        MapLastProgressNumberToPathnameEnum[lastProgressNumber]?.advicePage;
    }
    if (skillAdvice?.rate === 0 && !skillAdvice?.showAdvice) {
      nextPage = MapAdvicePageEnum[InterviewPageEnum.SC][3].path;
    }
  }
  return nextPage;
};

/**
 * checking if we already has shown advice or not according to profile skills data stored in redux
 *
 * @param {ArrayOf<Object>} profileSkills
 * @returns {boolean}
 */
const hasAlreadyAdviceFromProfileSkills = (profileSkills, skillId) => {
  const hasAlreadyAdvice = profileSkills.find(
    (skill) => skill.showAdvice === true
  );

  if (
    (!hasAlreadyAdvice || hasAlreadyAdvice?.id === skillId) &&
    skillId !== 0
  ) {
    return true;
  }
  return false;
};

/**
 * checking by skill rate if we want to advice show page
 *
 * @param {number} skillRate
 * @param {ArrayOf<Object>} profileSkills
 * @returns {boolean}
 */
export const checkShowAdviceBySkillRate = (
  skillRate,
  skillId,
  profileSkills
) => {
  if (skillRate > 0) {
    return false;
  }
  return hasAlreadyAdviceFromProfileSkills(profileSkills, skillId);
};

/**
 * Data object representing social network mission page data.
 *
 * @typedef {Object} socialNetworkMissionPageData
 * @property {number} number - The number of videos.
 * @property {number} totalVideosWidth - The total width of all videos.
 * @property {string[]} sources - The sources of the videos.
 * @property {Object[]} videos - An array of video objects.
 * @property {string} videos.id - The ID of the video.
 * @property {string} videos.src - The source URL of the video.
 * @property {string} videos.type - The MIME type of the video.
 * @property {string} videos.from - The source of the video.
 * @property {number} videos.videoWidthPx - The width of the video in pixels.
 * @property {null} videos.ref - A reference to the video.
 */
const socialNetworkMissionPageData = {
  number: 3,
  totalVideosWidth: 327 * 3,
  sources: ["tiktok", "insta"],
  videos: [
    {
      id: "0",
      src: "https://firebasestorage.googleapis.com/v0/b/mavoie-development.appspot.com/o/public%2Fmission%2Fvideos%2FmissionPage-reel-video-1.mp4?alt=media",
      type: "video/mp4",
      from: "tiktok",
      videoWidthPx: 327,
      ref: null,
    },
    {
      id: "1",
      src: "https://firebasestorage.googleapis.com/v0/b/mavoie-development.appspot.com/o/public%2Fmission%2Fvideos%2FmissionPage-reel-video-2.mp4?alt=media",
      type: "video/mp4",
      from: "tiktok",
      videoWidthPx: 327,
      ref: null,
    },
    {
      id: "2",
      src: "https://firebasestorage.googleapis.com/v0/b/mavoie-development.appspot.com/o/public%2Fmission%2Fvideos%2FmissionPage-reel-video-3.mp4?alt=media",
      type: "video/mp4",
      from: "tiktok",
      videoWidthPx: 327,
      ref: null,
    },
  ],
};

export default socialNetworkMissionPageData;
